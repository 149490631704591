import { services } from '@fbc/core/services';
import { excludeVue2Attributes, mergeClass } from '@fbc/core/utils';
import { computed, defineComponent, ref, watch } from 'vue';
import resource from './s-fullname.json';
export default defineComponent({
    name: 's-fullname',
    inheritAttrs: false,
    emits: ['update:firstName', 'update:lastName', 'update:middleName', 'click:ok', 'click:cancel'],
    props: {
        firstName: { type: String, default: null },
        lastName: { type: String, default: null },
        middleName: { type: String, default: null },
        id: { type: String },
        label: { type: String },
        readonly: { type: Boolean, default: false },
        firstNameRequired: { type: Boolean },
        lastNameRequired: { type: Boolean },
        middleNameRequired: { type: Boolean },
        disabled: { type: Boolean },
        inputable: { type: Boolean },
        firstNameMaxLength: { type: Number, default: null },
        middleNameMaxLength: { type: Number, default: null },
        lastNameMaxLength: { type: Number, default: null },
    },
    setup(props, { emit }) {
        const { alert, i18n } = services;
        const local = (value) => i18n.extract(resource, value);
        const mutableLastName = ref(props.lastName);
        const mutableFirstName = ref(props.firstName);
        const mutableMiddleName = ref(props.middleName);
        const visible = ref(false);
        const nameReg = /^((\S+\s)+\S*|\S*)$/;
        const nameMaxLength = 32;
        const fullNameMaxLength = 64;
        const mutableTextValue = computed({
            get() {
                return [mutableLastName.value, mutableFirstName.value, mutableMiddleName.value].filter(Boolean).join(' ');
            },
            set(value) {
                if (props.inputable) {
                    // Фамилия Имя Отчество Отчество
                    const [lastNameNew = '', firstNameNew = '', ...middleNamesNew] = value.split(/\s/);
                    mutableLastName.value = lastNameNew;
                    mutableFirstName.value = firstNameNew;
                    mutableMiddleName.value = middleNamesNew.join(' ').trim();
                    emit('update:firstName', mutableFirstName.value);
                    emit('update:lastName', mutableLastName.value);
                    emit('update:middleName', mutableMiddleName.value);
                }
            },
        });
        function show() {
            if (props.readonly || props.disabled)
                return;
            visible.value = true;
        }
        const buttons = computed(() => props.readonly ? [] : [{ attributes: { id: 'btn', class: 'fb-common-edit', onClick: show } }]);
        function minNameLength(a) {
            return a ? Math.min(nameMaxLength, a) : nameMaxLength;
        }
        function okClick() {
            if (validation()) {
                alert({
                    caption: i18n.global('error'),
                    body: local('validationError'),
                    width: 400,
                });
                return;
            }
            emit('update:firstName', mutableFirstName.value);
            emit('update:lastName', mutableLastName.value);
            emit('update:middleName', mutableMiddleName.value);
            visible.value = false;
            emit('click:ok');
        }
        function cancelClick() {
            mutableLastName.value = props.lastName;
            mutableFirstName.value = props.firstName;
            mutableMiddleName.value = props.middleName;
            visible.value = false;
            emit('click:cancel');
        }
        function validation() {
            return ((props.firstNameRequired && !mutableFirstName.value) ||
                (props.middleNameRequired && !mutableMiddleName.value) ||
                (props.lastNameRequired && !mutableLastName.value));
        }
        watch(() => props.lastName, (value, old) => {
            value !== old && (mutableLastName.value = value);
        }, { immediate: true });
        watch(() => props.firstName, (value, old) => {
            value !== old && (mutableFirstName.value = value);
        }, { immediate: true });
        watch(() => props.middleName, (value, old) => {
            value !== old && (mutableMiddleName.value = value);
        }, { immediate: true });
        return {
            local,
            visible,
            buttons,
            mutableTextValue,
            nameReg,
            minNameLength,
            fullNameMaxLength,
            okClick,
            cancelClick,
            show,
            mutableMiddleName,
            mutableLastName,
            mutableFirstName,
            excludeVue2Attributes,
            mergeClass,
        };
    },
});
