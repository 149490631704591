import { defineFunctionalComponent } from '@fbc/core/utils';
import { h, resolveComponent } from 'vue';
import props from './types/Properties';
import { services } from '@fbc/core/services';
const ODataLookupComponent = defineFunctionalComponent({
    ...props,
    parameters: {
        type: Object,
        required: true,
    },
}, (props, options) => h(resolveComponent('s-lookup'), {
    ...props,
    dataProvider: new services.lookupODataProvider(props.parameters.api, props.parameters.key, props.parameters.config),
    'onUpdate:modelValue': (event) => options.emit('update:modelValue', event),
    'onUpdate:columns': (event) => options.emit('update:columns', event),
    ...options.attrs,
}, options.slots), ['update:modelValue', 'update:columns']);
export default ODataLookupComponent;
