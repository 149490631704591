import { merge, range } from 'lodash';
/**
 * Документация: https://docs.sheetjs.com/docs/csf/features/
 * Стилизация: https://gitbrent.github.io/xlsx-js-style/
 */
const defaultStyles = {
    link: '1068bf',
    border: '8e8e8e',
};
/**
 * Возвращает ссылку на ОЭФ CRM
 * @param moduleName название модуля
 * @param id ид сущности
 * @param router роутер
 */
export const getLink = (moduleName, id, router) => window.location.origin +
    '/' +
    router.resolve({
        name: moduleName,
        params: { id },
    }).href;
export const getLinkCell = (text, link, styles = defaultStyles) => ({
    alignment: { vertical: 'center', horizontal: 'left' },
    v: text ?? undefined,
    t: 's',
    l: {
        Target: link,
        ToolTip: text,
    },
    s: {
        font: { color: { rgb: styles.link } },
    },
});
export const getTextCell = (text) => ({
    alignment: { vertical: 'center', horizontal: 'left' },
    v: text ?? undefined,
    t: 's',
});
export const getNumberCell = (value) => ({
    alignment: { vertical: 'center', horizontal: 'right' },
    v: value ?? undefined,
    t: 'n',
});
export const getDateTimeCell = (value) => ({
    alignment: { vertical: 'center', horizontal: 'right' },
    v: value?.toLocal().toISO({ includeOffset: false }) ?? undefined,
    t: 'd',
});
getLinkCell.detail = (moduleName, text, entityId, router) => getLinkCell(text, getLink(moduleName, entityId, router));
export const cell = {
    link: getLinkCell,
    text: getTextCell,
    number: getNumberCell,
    dateTime: getDateTimeCell,
};
const columns = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
// Получает название колонки по номеру
function getColumn(number) {
    let id = '';
    let num = number;
    while (num > -1) {
        const position = num % columns.length;
        id = columns[position] + id;
        num = (num - (num % columns.length)) / columns.length - 1;
    }
    return id;
}
// Обратная функция для getColumn
function getColNumber(col) {
    return col
        .split('')
        .reverse()
        .map((x, i) => (' ' + columns).indexOf(x) * Math.pow(columns.length, i))
        .reduce((p, c) => p + c, -1);
}
/**
 * Применять после задания ячеек\
 * Устанавливает стили и автофильтр для заголовком документа excel
 * @param ws объект листа xlsx
 * @param columns ['A','B']
 */
export const setHeader = (ws, columns, styles = defaultStyles) => {
    ws['!autofilter'] = {
        ref: `A1:${getColumn(columns - 1)}1`,
    };
    for (const cell of range(0, columns).map(getColumn))
        Object.assign(ws[`${cell}1`], {
            s: {
                alignment: { vertical: 'center', horizontal: 'center' },
                font: { bold: true },
                border: { bottom: { style: 'thin', color: { rgb: styles.border } } },
            },
        });
};
/// извлекает из диапазона номер максимального значения
function getBorders(range) {
    const [min, max] = range?.split(':') ?? [];
    if (!max)
        return [];
    const maxColName = max.replace(/\d/g, '');
    const maxRow = Number.parseInt(max.replace(maxColName, ''));
    const maxCol = getColNumber(maxColName);
    return [maxRow, maxCol, min, max];
}
/**
 * Вызывает функцию обратного вызова для каждой ячейки из активного диапазона
 * @param ws объект листа xlsx
 * @param callback функция обратного вызова
 */
export function forEachCell(ws, callback) {
    const [maxRow, maxCol, , max] = getBorders(ws['!ref']);
    if (!max)
        return;
    for (let i = 1; i < maxRow + 1; i++)
        for (let j = 0; j < maxCol; j++) {
            const cellName = `${getColumn(j)}${i}`;
            const cell = ws[cellName];
            callback({ cellName, cell, row: i, col: getColumn(j), colNum: j });
        }
}
/**
 * Применять после задания ячеек\
 * Убирает границы всех ячеек в листе с отступом от данных, заданным margin\
 * ОСТОРОЖНО. Заполняет пустые ячейки пустыми строками и задает им строковый тип. Иначе границы не убираются.
 * Это увеличит размер файла, эксель как-то это обходит(если в нем сделать, то размер наоборот меньше)
 * @param ws объект листа xlsx
 * @param margin отступ вниз и влево от данных для удаления границ
 */
export function removeBorders(ws, margin = 24) {
    const [maxRow, maxCol, min, max] = getBorders(ws['!ref']);
    if (!max)
        return;
    const noBorder = { style: 'thin', color: { rgb: 'ffffff' } };
    const noBorderStyle = {
        s: {
            border: { bottom: noBorder, top: noBorder, left: noBorder, right: noBorder },
        },
    };
    const emptyCell = { v: '', t: 's', ...noBorderStyle };
    for (let i = 1; i < maxRow + margin + 1; i++)
        for (let j = 0; j < maxCol + margin; j++) {
            const cellNum = `${getColumn(j)}${i}`;
            const cell = ws[cellNum];
            if (typeof cell === 'object' && cell) {
                merge(cell, cell.v === undefined ? emptyCell : noBorderStyle); // У пустых ячеек не ставятся бордеры. Создаем заполненную пустой строкой
            }
            if (!cell)
                ws[cellNum] = emptyCell;
        }
    ws['!ref'] = `${min}:${getColumn(maxCol + margin - 1)}${maxRow + margin}`; // Задаем диапазон затронутых ячеек
}
/**
 * Сливает ячейки с одинаковыми значениями в колонке
 * @param ws объект листа xlsx
 * @param column название колонки: 'A', 'B' и т.п.
 */
export function mergeCellsInColumn(ws, column) {
    const colNumber = getColNumber(column);
    const merges = [];
    let start = 0;
    let lastCellValue;
    let endNum = 0;
    forEachCell(ws, ({ col, row, cell }) => {
        endNum = row;
        if (col !== column || cell.v === lastCellValue)
            return;
        if (start !== row - 1) {
            merges.push({ s: { c: colNumber, r: start - 1 }, e: { c: colNumber, r: row - 2 } });
        }
        lastCellValue = cell.v;
        start = row;
        if (cell.s)
            cell.s.alignment = { vertical: 'center', horizontal: 'left' };
        else
            cell.s = { alignment: { vertical: 'center', horizontal: 'left' } };
    });
    if (start !== endNum) {
        merges.push({ s: { c: colNumber, r: start - 1 }, e: { c: colNumber, r: endNum - 1 } }); // Добавляем последний диапазон в колонке, если он есть
    }
    ws['!merges'] = merges;
}
