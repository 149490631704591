let rippleTimeout;
const enabledMap = new WeakMap();
export default {
    beforeUpdate(el, binding) {
        enabledMap.set(el, binding.value ?? true);
    },
    mounted(el, binding) {
        enabledMap.set(el, binding.value ?? true);
        const startRipple = (e) => {
            if (el.ariaDisabled === 'true' ||
                el.attributes.getNamedItem('disabled')?.textContent === 'true' ||
                !(enabledMap.get(el) ?? true))
                return;
            // original https://codepen.io/jakob-e/pen/XZoZWQ
            if (rippleTimeout)
                window.clearTimeout(rippleTimeout);
            el.dataset.ripple = '';
            const touch = e instanceof TouchEvent ? e.touches[0] : e;
            const rect = el.getBoundingClientRect();
            const d = Math.sqrt(Math.pow(rect.width, 2) + Math.pow(rect.height, 2)) * 2;
            el.style.setProperty('--s', '0');
            el.style.setProperty('--o', '1');
            el.style.removeProperty('--t');
            el.style.removeProperty('--d');
            el.style.removeProperty('--x');
            el.style.removeProperty('--y');
            window.requestAnimationFrame(() => {
                el.style.removeProperty('--s');
                el.style.setProperty('--t', '1');
                el.style.setProperty('--o', '0');
                el.style.setProperty('--d', String(d));
                el.style.setProperty('--x', String(touch.clientX - rect.left));
                el.style.setProperty('--y', String(touch.clientY - rect.top));
                const durationFromStyle = getComputedStyle(el).getPropertyValue('--ripple-duration');
                rippleTimeout = window.setTimeout(clear, Number.isNaN(durationFromStyle) ? 600 : Math.max(Number(durationFromStyle), 600));
            });
        };
        el.addEventListener('mousedown', startRipple, { passive: true, capture: true });
        function clear() {
            delete el.dataset.ripple;
            el.style.removeProperty('--s'); // ripple scale
            el.style.removeProperty('--o'); // opacity
            el.style.removeProperty('--t'); // transition
            el.style.removeProperty('--d'); // ripple diameter
            el.style.removeProperty('--x'); // mouse X
            el.style.removeProperty('--y'); // mouse Y
        }
    },
};
