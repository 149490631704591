import { isNotNullOrUndefined } from '@fbc/core/utils';
import { orderBy } from 'lodash';
export class LookupStaticProvider {
    data;
    /**
     * @constructor
     * @param data Контейнер с реактивными данными
     */
    constructor(data) {
        this.data = data;
    }
    getData({ page, validFilters, sort } = {}) {
        const data = Array.isArray(this.data) ? this.data : this.data.value;
        const sorted = sort
            ? orderBy(data, ...sort.reduce((p, c) => (p[0].push(c.fieldName), p[1].push(c.sortOrder), p), [[], []]))
            : data;
        const filteredValue = validFilters?.length
            ? sorted.filter(x => validFilters.every(y => {
                const value = x[y.fieldName];
                switch (y.type) {
                    case 'equal':
                        return String(value).toLocaleLowerCase() === String(y.value).toLocaleLowerCase();
                    case 'notEqual':
                        return String(value).toLocaleLowerCase() !== String(y.value).toLocaleLowerCase();
                    case 'list':
                        return y.list.includes(value);
                    case 'number':
                        return numberFilter(y, value);
                    case 'dateTime':
                        return dateTimeFilter(y, value);
                    case 'text':
                        return textFilter(y, value);
                }
                return true;
            }))
            : sorted;
        return Promise.resolve([
            undefined,
            !page ? filteredValue : filteredValue.slice(page.num * page.size, (page.num + 1) * page.size),
            {
                totalResults: filteredValue.length,
                features: {
                    pagination: true,
                    sort: true,
                    filters: true,
                    pick: true,
                },
            },
        ]);
    }
}
const textFilter = (filter, value) => {
    switch (filter.op) {
        case '=':
            return String(value).toLocaleLowerCase() === String(filter.value).toLocaleLowerCase();
        case '!=':
            return String(value).toLocaleLowerCase() !== String(filter.value).toLocaleLowerCase();
        case 'contains':
            return String(value)
                .toLocaleLowerCase()
                .includes(filter.value?.toLocaleLowerCase() ?? '');
        case 'empty':
            return !value;
        case 'not empty':
            return Boolean(value);
        case 'starts':
            return String(value)
                .toLocaleLowerCase()
                .startsWith(filter.value?.toLocaleLowerCase() ?? '');
        case 'ends':
            return String(value)
                .toLocaleLowerCase()
                .endsWith(filter.value?.toLocaleLowerCase() ?? '');
    }
};
function numberFilter(y, value) {
    const n = Number(value);
    return isNotNullOrUndefined(y.from) && isNotNullOrUndefined(y.to)
        ? n >= y.from && n <= y.to
        : isNotNullOrUndefined(y.from)
            ? n >= y.from
            : isNotNullOrUndefined(y.to)
                ? n <= y.to
                : 0;
}
function dateTimeFilter(y, value) {
    const v = value;
    return isNotNullOrUndefined(y.from) && isNotNullOrUndefined(y.to)
        ? v >= y.from && v <= y.to
        : isNotNullOrUndefined(y.from)
            ? v >= y.from
            : isNotNullOrUndefined(y.to)
                ? v <= y.to
                : 0;
}
