import { fromIsoConverter } from '@fbc/core/utils';
export const getFeatures = (num) => ({
    pagination: Boolean(num & 1),
    sort: Boolean(num & 2),
    filters: Boolean(num & 4),
    pick: Boolean(num & 8),
});
const converters = new Map([
    ['DateTime', fromIsoConverter],
    ['DateTime | null', fromIsoConverter],
    ['boolean', (x) => x === 'T'],
    ['boolean | null', (x) => (x === null ? null : x === 'T')],
]);
/**
 * Ходит в глубину и конвертирует свойства у объекта, конвертеры в converters
 * Список свойств конвертирования в map, вида [{field: 'f', type: 'DateTime'}, {field: 'accounts.f', type: 'boolean | null'}]
 * Тип упрощен с
 * <
    DataMethods extends Record<string, { parameters: Record<string, unknown>; result: Record<string, unknown>[] }>,
    T extends keyof DataMethods,
    Keys extends DeepKeys<DataResult<DataMethods, T>>,
>
 * т.к. этот тип больше не поддерживается, mapData внутренняя реализация, а вычислять корректный тип накладно
 */
export function mapData(//DeepKeys<DataResult<T>>
result, map) {
    if (!map.length)
        return result;
    const mapper = (r) => Object.fromEntries(map
        .filter(x => x.field in r)
        .map(x => [x.field, converters.get(x.type)?.(r[x.field])]));
    const deepMapper = (r) => Object.fromEntries(Object.entries(r)
        .filter(x => x[1] && typeof x[1] === 'object')
        .map(x => [
        x[0],
        mapData(x[1], map
            .filter(y => y.field.startsWith(x[0]))
            .map(y => ({ field: y.field.replace(x[0] + '.', ''), type: y.type }))),
    ]));
    if (Array.isArray(result)) {
        return result.map((r) => Object.assign(r, mapper(r), deepMapper(r)));
    }
    return Object.assign(result, mapper(result), deepMapper(result));
}
