import { clone } from 'lodash';
import * as base from './base';
import * as manual from './manual';
export function mapToODataFilters(filters, configManualFilter, configFilter) {
    const { filter, manualFilter } = (filters ?? []).reduce((result, value) => {
        if (manual.typesGuard(value)) {
            const manualFilter = manual.map(value);
            if (!manualFilter)
                return result;
            result.manualFilter = result.manualFilter ? `${result.manualFilter} and ${manualFilter}` : manualFilter;
        }
        else if (base.typesGuard(value)) {
            const mappedFilter = base.map(value);
            if (Array.isArray(mappedFilter))
                result.filter = result.filter.concat(mappedFilter);
            else
                result.filter.push(mappedFilter);
        }
        return result;
    }, {
        filter: clone(configFilter) ?? [],
        manualFilter: configManualFilter,
    });
    return { manualFilter, filter };
}
