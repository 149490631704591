export default {
    dataProvider: {
        type: Object,
        required: true,
    },
    modelValue: { type: Object, default: () => null },
    getText: { type: Function, required: true },
    idField: { type: [String, Function], required: true },
    count: { type: Number, default: 10 },
    disabled: { type: Boolean },
    minSymbolsToSearch: { type: Number, default: 3 },
};
