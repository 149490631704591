/** Сортируются только дети для всех элементов. Быстрее раз в 6, чем deep 0.7 -> 0.13 */
const flat = (tree, comparator) => tree.traversal(item => {
    item.childs.sort(comparator);
}, 'pre');
/** Сортирует элементы в глубину, т.е сверху будет тот элемент, ребенок(или ребенок ребенка...) которого имеет максимальное значение целевой функции */
const deep = (tree, comparator) => {
    const maxChildsMap = new WeakMap();
    return tree.traversal(item => {
        item.childs.sort((a, b) => {
            // Получаем наибольший элемент, чтобы дети влияли на сортировку
            const first = max3(comparator, a, a.childs[0], maxChildsMap.get(a.childs[0]));
            const second = max3(comparator, b, b.childs[0], maxChildsMap.get(b.childs[0]));
            return comparator(first, second);
        });
        maxChildsMap.set(item, max3(comparator, item, item.childs[0], maxChildsMap.get(item.childs[0])));
    }, 'post');
};
export const sortStrategies = {
    /** Сортируются только дети для всех элементов. Быстрее раз в 6, чем deep 0.7 -> 0.13 */
    flat,
    /** Сортирует элементы в глубину, т.е сверху будет тот элемент, ребенок(или ребенок ребенка...) которого имеет максимальное значение целевой функции */
    deep,
};
/** Получение максимального элемента из 3 с определенными предусловиями из Tree.sort */
export const max3 = (comparator, a, b, c) => {
    // В 3 раза быстрее, чем ...elements 0.709 <- 2.278
    // b фактический может не быть, но если b нет, то и c нет, а описать такое не удастся. см. использование в sort
    if (!b)
        return a;
    if (!c)
        return comparator(a, b) < 0 ? a : b;
    return comparator(a, b) < 0 ? (comparator(a, c) < 0 ? a : c) : comparator(b, c) < 0 ? b : c;
};
