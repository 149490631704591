import { services } from '@fbc/core/services';
import { defineStore } from 'pinia';
import { reactive } from 'vue';
const isCustomSetting = (x) => Boolean(typeof x === 'object' &&
    x &&
    'name' in x &&
    typeof x.name === 'string' &&
    'category' in x &&
    typeof x.category === 'string' &&
    'type' in x &&
    typeof x.type === 'string');
export const useCustomSettingStore = (cache, app) => {
    const api = new services.webApi();
    return defineStore('custom-setting', () => {
        const loadingMap = reactive(new Map());
        function add(customSetting) {
            const key = `custom-setting:${customSetting.category}:${customSetting.name}`;
            cache.set(key, customSetting, app.manifest.app.optionCacheDuration);
        }
        function get(category, name) {
            const key = `custom-setting:${category}:${name}`;
            return cache.get(key, isCustomSetting);
        }
        function load(category, name, fileName) {
            const exist = get(category, name);
            if (exist) {
                log.dev.debug(fileName, `get custom setting from cache: `, exist);
                return exist;
            }
            const key = category + '|' + name;
            if (loadingMap.has(key))
                return loadingMap.get(key);
            if (process.env.NODE_ENV === 'development' && fileName !== 'never')
                log.dev.debug(fileName, `load custom setting: `, category, name);
            const promise = api.get('customSettings', { category, name }, fileName).then(([error, setting]) => {
                if (error) {
                    loadingMap.delete(key);
                    log.prod.error(fileName, error);
                    return null;
                }
                if (!setting)
                    return loadingMap.delete(key), null;
                add(setting);
                loadingMap.delete(key);
                return setting;
            });
            loadingMap.set(key, promise);
            return promise;
        }
        function removeFromCache(category, name) {
            const key = `custom-setting:${category}:${name}`;
            cache.remove(key);
        }
        return { load, removeFromCache };
    })();
};
