/**
 * Получение файла с сервера
 * @param title название файла
 * @param fileInBlob принятый blob
 */
export function getFileFromServer(title, fileInBlob, viewPdf = false) {
    if (viewPdf && fileInBlob.type === 'application/pdf') {
        window.open(window.URL.createObjectURL(fileInBlob), title);
        return;
    }
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(fileInBlob);
    downloadLink.download = title;
    downloadLink.click();
    downloadLink.remove();
}
