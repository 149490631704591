import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Transition as _Transition } from "vue";
const _hoisted_1 = ["onClick", "textContent"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_s_button = _resolveComponent("s-button");
    const _component_s_input = _resolveComponent("s-input");
    const _component_s_static_lookup = _resolveComponent("s-static-lookup");
    const _component_s_modal = _resolveComponent("s-modal");
    const _directive_tooltip = _resolveDirective("tooltip");
    return (_openBlock(), _createBlock(_component_s_modal, {
        modelValue: _ctx.visible,
        class: _normalizeClass(_ctx.$options.name),
        centralize: "",
        width: 450
    }, {
        header: _withCtx(() => [
            (_ctx.mode === 'new' && _ctx.hasExistsStaticGroups)
                ? _withDirectives((_openBlock(), _createBlock(_component_s_button, {
                    key: 0,
                    icon: "fb-common-left",
                    class: _normalizeClass(_ctx.$options.name + '__back-button'),
                    onClick: _cache[0] || (_cache[0] = ($event) => {
                        _ctx.transitionType = 'tab-reverse-transition';
                        _ctx.changeMode('exists');
                    }),
                    onMousedown: _cache[1] || (_cache[1] = _withModifiers(() => { }, ["stop"]))
                }, null, 8 /* PROPS */, ["class"])), [
                    [_directive_tooltip, _ctx.local('caption--exists')]
                ])
                : _createCommentVNode("v-if", true),
            _createTextVNode(" " + _toDisplayString(_ctx.mode === 'exists' ? _ctx.local('caption--exists') : _ctx.local('caption--new')), 1 /* TEXT */)
        ]),
        footer: _withCtx(() => [
            _createVNode(_component_s_button, {
                success: "",
                tabindex: "1",
                disabled: !_ctx.isValid || _ctx.module.loading.isLoading('data').value,
                onClick: _ctx.okClick
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$i18n.global('add')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
            }, 8 /* PROPS */, ["disabled", "onClick"]),
            _createVNode(_component_s_button, {
                tabindex: "2",
                disabled: _ctx.module.loading.isLoading('data').value,
                onClick: _ctx.hide
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$i18n.global('cancel')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
            }, 8 /* PROPS */, ["disabled", "onClick"])
        ]),
        default: _withCtx(() => [
            _createVNode(_Transition, { name: _ctx.transitionType }, {
                default: _withCtx(() => [
                    (_ctx.mode === 'exists')
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass([_ctx.$options.name + '__transition', _ctx.$options.name + '__exists'])
                        }, [
                            _createVNode(_component_s_button, {
                                class: _normalizeClass(_ctx.$options.name + '__create-static-group'),
                                action: "fb-common-plus",
                                onClick: _cache[2] || (_cache[2] = ($event) => {
                                    _ctx.transitionType = 'tab-transition';
                                    _ctx.changeMode('new');
                                })
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.local('create-static-group')), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["class"]),
                            _createVNode(_component_s_input, {
                                modelValue: _ctx.filter,
                                buttons: [
                                    {
                                        id: 'btnClear',
                                        attributes: {
                                            class: 'fb-common-close',
                                            onClick: () => (_ctx.filter = undefined),
                                        },
                                    },
                                ],
                                placeholder: _ctx.local('filter-placeholder'),
                                bordered: "",
                                onInput: _ctx.changeFilter
                            }, null, 8 /* PROPS */, ["modelValue", "buttons", "placeholder", "onInput"]),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$options.name + '__groups')
                            }, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.staticGroups, (group) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                        key: group.id,
                                        class: _normalizeClass([
                                            _ctx.$options.name + '__groups__group',
                                            { [_ctx.$options.name + '__groups__group--selected']: group === _ctx.selectedGroup },
                                        ]),
                                        onClick: ($event) => (_ctx.groupId = group.id),
                                        textContent: _toDisplayString(group.displayName)
                                    }, null, 10 /* CLASS, PROPS */, _hoisted_1));
                                }), 128 /* KEYED_FRAGMENT */))
                            ], 2 /* CLASS */)
                        ], 2 /* CLASS */))
                        : (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass([_ctx.$options.name + '__transition', _ctx.$options.name + '__new'])
                        }, [
                            _createVNode(_component_s_static_lookup, {
                                modelValue: _ctx.selectedGroup,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.selectedGroup) = $event)),
                                columns: _ctx.baseGroupColumns,
                                "onUpdate:columns": _cache[4] || (_cache[4] = ($event) => ((_ctx.baseGroupColumns) = $event)),
                                data: _ctx.module.groups,
                                idField: "id",
                                required: "",
                                label: _ctx.local('based-on-group')
                            }, null, 8 /* PROPS */, ["modelValue", "columns", "data", "label"]),
                            _createVNode(_component_s_input, {
                                modelValue: _ctx.displayName,
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event) => ((_ctx.displayName) = $event)),
                                label: _ctx.local('display-name'),
                                maxlength: "128",
                                trim: "",
                                required: ""
                            }, null, 8 /* PROPS */, ["modelValue", "label"])
                        ], 2 /* CLASS */))
                ]),
                _: 1 /* STABLE */
            }, 8 /* PROPS */, ["name"])
        ]),
        _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "class"]));
}
