import { isLogParameter } from '@fbc/core/utils';
import { services } from '..';
import { authFetch } from './authenticate-fetch';
const mapUnknownToServerError = (error) => isLogParameter(error)
    ? {
        message: error.message,
        stackTrace: error.stack,
    }
    : { message: JSON.stringify(error) };
export class AttachmentsApi {
    path;
    odata = new services.entitiesApi({});
    /**
     * @constructor
     * @param path Путь к Web API
     */
    constructor(path = window['fb-base-api-path'] + '/api/') {
        this.path = path;
    }
    async download(fileId) {
        const fileUrl = `${this.path}attachment/file/${fileId}`;
        try {
            const response = await authFetch(new Request(fileUrl, { cache: 'no-cache' }));
            if (!response.ok)
                return false;
            const filenameMatch = response.headers.get('Content-Disposition')?.match(/filename\*=UTF-8('*)(.+?)$/);
            const filename = filenameMatch ? filenameMatch[2] : 'download';
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(await response.blob());
            link.download = decodeURIComponent(filename);
            link.click();
            link.remove();
        }
        catch (error) {
            return mapUnknownToServerError(error);
        }
        return true;
    }
    read = (entityId, _, filename) => this.odata.where('attachments', {
        manualFilter: `associatedEntities/any(c: c/entityId eq '${entityId}')`,
    }, filename);
    readByAttachmentId = (attachmentId, filename) => this.odata.read('attachments', attachmentId, filename);
    async create(attachments, linkedEntities, filename) {
        if (process.env.NODE_ENV === 'development' && localStorage['fora.debug.api'])
            log.dev.debug(filename, 'create attachment entities', attachments, linkedEntities);
        const data = new FormData();
        await injectAntiForgery(data);
        attachments.forEach(a => data.append(a.description, a.file));
        data.append('payload', JSON.stringify(attachments));
        const queryString = linkedEntities
            .map((x, i) => `entities[${i}].entityName=${x.entityName}&entities[${i}].entityId=${x.entityId}`)
            .join('&');
        const response = await authFetch(`${this.path}attachment/file/upload?${queryString}`, {
            body: data,
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
        });
        try {
            const result = (await response.json());
            if (response.ok) {
                const attachments = result.map(a => this.odata.mapper.toClientView('attachments', a));
                return [undefined, attachments];
            }
            return [result, undefined];
        }
        catch {
            return getErrorMessage(response);
        }
    }
    async update(attachmentId, attachment, filename) {
        if (process.env.NODE_ENV === 'development' && localStorage['fora.debug.api'])
            log.dev.debug(filename, 'update attachment entity', attachmentId, attachment);
        const data = new FormData();
        await injectAntiForgery(data);
        data.append('payload', JSON.stringify(attachment));
        data.append(attachment.fileName, attachment.file);
        const response = await authFetch(`${this.path}attachment/file/${attachmentId}/update`, {
            body: data,
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
        });
        if (process.env.NODE_ENV === 'development' && localStorage['fora.debug.api'])
            log.dev.debug(filename, response);
        try {
            return response.ok ? [undefined, response.ok] : [(await response.json()), undefined];
        }
        catch {
            return getErrorMessage(response);
        }
    }
    async delete(attachmentId, filename) {
        const response = await authFetch(`${this.path}attachment/file/${attachmentId}`, {
            method: 'DELETE',
            mode: 'cors',
            credentials: 'include',
        });
        if (process.env.NODE_ENV === 'development' && localStorage['fora.debug.api'])
            log.dev.debug(filename, response);
        return response.ok;
    }
    async getAvailableTypes() {
        const response = await authFetch(`${this.path}attachment/availableTypes`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
        });
        return response.ok
            ? [undefined, (await response.json())]
            : [(await response.json()), undefined];
    }
}
async function injectAntiForgery(data) {
    const header = 'X-Fora-Antiforgery-Token';
    const field = 'Fora-Antiforgery-Token';
    const [error, result] = await authFetch(`${window['fb-base-api-path']}/auth/GenerateAntiforgery`, {
        method: 'POST',
        credentials: 'include',
    })
        .catch(reason => [reason, undefined])
        .then(response => (response instanceof Response ? [undefined, response.headers.get(header)] : response))
        .catch(reason => [reason, undefined]);
    if (error)
        log.prod.error(__filename, error);
    data.append(field, result ?? '');
}
function getErrorMessage(response) {
    switch (response.status) {
        case 413:
            return [{ message: services.i18n.extract(resources, 'size-error') }, undefined];
        case 503:
            return [{ message: services.i18n.extract(resources, 'access-error') }, undefined];
        default:
            return [{ message: services.i18n.extract(resources, 'unknown-error') }, undefined];
    }
}
const resources = {
    default: {
        'access-error': 'The maximum number of attachment upload requests has been exceeded',
        'size-error': 'The upload size limit has been exceeded',
        'unknown-error': 'An unexpected error occurred',
    },
    'ru-ru': {
        'access-error': 'Было превышено максимально допустимое число запросов на загрузку вложения',
        'size-error': 'Превышен допустимый размер загрузки',
        'unknown-error': 'Произошла непредвиденная ошибка',
    },
};
