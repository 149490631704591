import { Selection } from '.';
export class TraversalOperations {
    /**
     * @param reducer Выполняется для каждого элемента в дереве, накапливая результат в initial
     * @param initial начальное значение
     * @param strategy стратегия обхода
     * @returns результат
     */
    reduce(reducer, initial, strategy = 'pre') {
        let value = initial;
        this.traversal(item => {
            value = reducer(value, item);
        }, strategy);
        return value;
    }
    /**
     * Вызывает функцию предиката для каждого элемента в дереве,
     * пока предикат не возвратит значение, которое соответствует логическому значению False, или до конца массива.
     */
    every(predicate, strategy = 'pre') {
        let suitable = true;
        this.traversal(item => (suitable = predicate(item)), strategy);
        return suitable;
    }
    /**
     * Вызывает функцию предиката для каждого элемента в дереве,
     * пока предикат не возвратит значение, которое соответствует логическому значению True, или до конца массива.
     */
    some(predicate, strategy = 'pre') {
        let hasItem = true;
        this.traversal(item => (hasItem = !predicate(item)), strategy);
        return !hasItem;
    }
    /**
     * Выставляет visible false тем элементам, selector которых(или их детей) вернул false
     * @param selector функция выбора элемента
     * @param action вызывается после установки visible у ноды
     * @returns this
     */
    setVisibility(selector, action) {
        return this.traversal(item => {
            item.visible = item.childs.some(x => x.visible) || selector(item);
            action?.(item);
        }, 'post');
    }
    /**
     * Выставляет visible false тем элементам, label которых(или их детей) не содержит text
     * @param text текст элемента
     * @param like сравнивать через includes или ===
     * @param action вызывается после установки visible у ноды
     * @returns this
     */
    setVisibilityByLabel(text, like = true, action) {
        return this.setVisibility(item => (like ? item.label.toLocaleLowerCase().includes(text.toLocaleLowerCase()) : item.label === text), action);
    }
    /**
     * Ищет первый попавшийся элемент
     * @param selector функция выбора элементов
     * @param strategy стратегия обхода дерева, можно манипулировать тем, какой элемент попадется первым
     * @returns найденный элемент
     */
    first(selector, strategy = 'pre') {
        let founded;
        this.traversal(item => {
            const success = selector(item);
            if (!success)
                return;
            founded = item;
            return false;
        }, strategy);
        return founded;
    }
    /**
     * Ищет все элементы
     * @param selector функция выбора элементов
     * @param strategy стратегия обхода дерева, для оптимизации
     * @returns найденные элементы
     */
    all(selector, strategy = 'pre') {
        const founded = [];
        this.traversal(item => {
            const success = selector(item);
            if (!success)
                return;
            founded.push(item);
        }, strategy);
        return founded;
    }
    /**
     * @returns выбранные узлы дерева
     */
    getSelectedNodes() {
        return this.all(x => x.selected === Selection.Selected);
    }
    /**
     * @returns элементы в выбранных узлах дерева
     */
    getSelected() {
        return this.getSelectedNodes().map(x => x.data);
    }
}
