import { parseJson } from './parse-json';
export function logAccess({ entityId, type, entityType, description, isTab, isListView = false, forced, }) {
    const lastEntity = parseJson(sessionStorage.getItem('fora.entity.last'), (data) => typeof data === 'object' && data !== null && 'idForTab' in data && 'type' in data);
    if (!entityId ||
        (((isTab && lastEntity?.idForTab === entityId && lastEntity.type === type) ||
            (!isTab && lastEntity?.id === entityId)) &&
            !forced &&
            !isListView))
        return false;
    if (!isListView)
        sessionStorage.setItem('fora.entity.last', JSON.stringify({
            id: isTab ? lastEntity?.id : entityId,
            idForTab: isTab ? entityId : lastEntity?.idForTab,
            type,
        }));
    return navigator.sendBeacon('/api/system/log', new Blob([
        JSON.stringify({
            type,
            entityId,
            entityType,
            description,
            isTab,
            isListView,
        }),
    ], {
        type: 'application/json',
    }));
}
