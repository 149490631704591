import { h, mergeProps } from 'vue';
import icons from './icons-map';
const ColoredIconComponent = ({ icon, width, height }, context) => h('div', mergeProps({ innerHTML: icons[icon], style: { width, height } }, context.attrs), context.slots);
ColoredIconComponent.props = {
    icon: {
        type: String,
        required: true,
        validator: function (value) {
            return value in icons;
        },
    },
    width: String,
    height: String,
};
export default ColoredIconComponent;
