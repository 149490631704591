import { get, hasIn } from 'lodash';
/*
1 2
4 3
*/
export var Quarter;
(function (Quarter) {
    Quarter[Quarter["LeftTop"] = 0] = "LeftTop";
    Quarter[Quarter["RightTop"] = 1] = "RightTop";
    Quarter[Quarter["RightBottom"] = 3] = "RightBottom";
    Quarter[Quarter["LeftBottom"] = 4] = "LeftBottom";
})(Quarter || (Quarter = {}));
export const getToCenterPosition = (rect, padding = 0) => {
    const targetX = rect.left / 2 + rect.right / 2;
    const targetY = rect.top / 2 + rect.bottom / 2;
    const centerX = window.innerWidth / 2;
    const centerY = window.innerHeight / 2;
    if (targetX > centerX && targetY > centerY)
        return { top: rect.bottom, left: rect.left - padding, quarter: Quarter.RightBottom };
    if (targetX <= centerX && targetY > centerY)
        return { top: rect.top, left: rect.right + padding, quarter: Quarter.LeftBottom };
    if (targetX > centerX && targetY <= centerY)
        return { top: rect.bottom + padding, left: rect.left - padding, quarter: Quarter.RightTop };
    if (targetX <= centerX && targetY <= centerY)
        return { top: rect.bottom, left: rect.right + padding, quarter: Quarter.LeftTop };
};
export const getDisplayName = (columns, entity) => columns
    .filter(x => x.isDisplayName && hasIn(entity, x.field.split('/')))
    .map(x => x.formatter
    ? x.formatter(get(entity, x.field.split('/')), entity)
    : get(entity, x.field.split('/')))
    .join(' ');
