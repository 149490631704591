/**
 * Обрезает строку, если она больше заданного размера
 * @param value значение
 * @param length кол-во символов
 * @param showOverflow показывать ли ... в результате при переполнении
 */
export default function truncate(value, length = 15, showOverflow = false) {
    if (!value || typeof value !== 'string')
        return '';
    if (value.length <= length)
        return value;
    if (showOverflow)
        return value.substring(0, length > 3 ? length - 3 : 0) + '...';
    return value.substring(0, length);
}
export function truncateLeft(a, b, length = 15) {
    a ??= '';
    b ??= '';
    return truncate(a, length - b.length) + b;
}
export function truncateRight(a, b, length = 15) {
    a ??= '';
    b ??= '';
    return a + truncate(b, length - a.length);
}
/**
 * Вставляет в строку base строку inserted обрезая inserted, чтобы итоговая длинна была не больше maxLength
 * @param base базовая строка
 * @param inserted вставляемая строка
 * @param maxLength максимальная длинна итоговой строки
 * @param placeholder куда вставлять
 */
export const insertWithFixedMaxLength = (base, inserted, maxLength, placeholder) => base.replace(placeholder, truncate(inserted, maxLength - base.length));
