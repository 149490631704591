import { services } from '@fbc/core/services';
export const checkIsLoginPage = () => window.location.pathname.startsWith('/login');
export async function loadCurrentUser(store, router) {
    const { user: { getCurrentUser }, log, } = services;
    store.performance.start('load-user');
    const promise = getCurrentUser();
    store.user.promise = promise;
    const [error, user] = await promise;
    if (error) {
        log.dev.error(__filename, error);
        if (!checkIsLoginPage())
            router.push(`/login?ReturnUrl=${window.location.href}`);
        await new Promise(x => setTimeout(x, 2000)); // Чтобы лоадер не пропадал при переходе
    }
    if (user)
        store.user.setUser(user, true);
    store.performance.end('load-user', 'Загружена информация о пользователе', 'core', { user: user?.id });
}
