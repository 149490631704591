import { services } from '@fbc/core/services';
import { isLogParameter, isPropertyNotNullOrUndefined } from '@fbc/core/utils';
import { BaseComponents, EntityPageComponent, ListViewerComponent, PageComponent, PageNoAccess, } from '@fbc/ui/components';
import registerCustomElements from '@fbc/ui/components/custom';
import { directives } from '@fbc/ui/directives';
import { cloneDeep, pick } from 'lodash';
import { Settings } from 'luxon';
import { createPinia } from 'pinia';
import { createApp, h } from 'vue';
import { AppComponent, MainPageComponent } from './components/App/App';
import { global } from './global-dependencies';
import { router } from './routes';
const { authentication, modules } = services;
window['fb-auth'] = authentication.fetch;
export const pinia = createPinia();
pinia.use(({ store }) => {
    const initialState = cloneDeep(store.$state);
    store.$reset = () => store.$patch(initialState);
});
async function loadAppManifest(router) {
    const manifest = await window['fb-manifest-promise'];
    router.addRoute({
        path: '/',
        name: 'mainPageComponent',
        component: h(MainPageComponent),
        children: [
            ...getChildrenAppComponent(manifest),
            {
                path: '/no-access',
                name: 'page-no-access',
                component: h(PageNoAccess),
            },
        ],
    });
    return manifest;
}
const getChildrenAppComponent = (manifest) => manifest.manifests
    .filter(isPropertyNotNullOrUndefined('path'))
    .filter(x => {
    const parentSplitLength = x.path.includes(':id') ? 3 : 2;
    return x.path.split('/').length === parentSplitLength && x.path !== '/login' && x.path !== '/logoff';
})
    .map(route => {
    if (route.type === 'EntityPage') {
        const pathSplit = route.path.split('/');
        const entityName = pathSplit[1];
        return {
            path: `/${entityName}`,
            name: `${route.name}-list`,
            component: h(ListViewerComponent),
            meta: {
                ...pick(route, 'entityName', 'manifestName', 'title', 'description', 'securedAction', 'securedModule', 'tabContainerOrder', 'listViewer'),
                moduleType: route.type,
                manifestName: route.name,
            },
            children: [
                {
                    path: pathSplit[2],
                    name: route.name,
                    component: h(PageComponent, {
                        name: route.name,
                        key: route.name,
                    }),
                    meta: {
                        ...pick(route, 'entityName', 'manifestName', 'title', 'description', 'securedAction', 'securedModule', 'tabContainerOrder'),
                        moduleType: route.type,
                        manifestName: route.name,
                    },
                    children: loadRouterChildren(route.path, manifest.manifests),
                },
            ],
        };
    }
    return {
        path: route.path,
        name: route.name,
        component: h(PageComponent, {
            name: route.name,
            key: route.name,
        }),
        meta: {
            ...pick(route, 'entityName', 'manifestName', 'title', 'description', 'securedAction', 'securedModule', 'tabContainerOrder'),
            moduleType: route.type,
            manifestName: route.name,
        },
        children: loadRouterChildren(route.path, manifest.manifests),
    };
});
const loadRouterChildren = (parentPath, manifests) => {
    const parentPathLevel = parentPath.split('/').length;
    const childrenManifests = manifests.filter(x => x.path?.length !== parentPath.length &&
        x.path?.includes(parentPath) &&
        x.path.split('/').length === parentPathLevel + 1);
    if (!childrenManifests.length || parentPath === '/')
        return [];
    return childrenManifests.map(x => ({
        path: x.path ?? '',
        name: x.name,
        component: h(x.type === 'Page' ? PageComponent : EntityPageComponent, { name: x.name, key: x.name }),
        meta: {
            ...pick(x, 'entityName', 'manifestName', 'title', 'description', 'securedAction', 'securedModule', 'tabContainerOrder', 'listViewer'),
            moduleType: x.type,
            manifestName: x.name,
        },
        redirect: '',
        children: loadRouterChildren(x.path ?? '', manifests),
    }));
};
loadAppManifest(router).then(async (manifest) => {
    const app = createApp(AppComponent, { manifest })
        .use(pinia)
        .use(global)
        .use(modules.MicroModule)
        .use(router)
        .use(directives)
        .use(BaseComponents);
    registerCustomElements();
    const pluginModuleManifests = manifest.manifests.filter(x => x.type === 'Plugin' && !manifest.app.excludedPlugins.includes(x.name));
    if (pluginModuleManifests.length) {
        const load = (name) => services.store.app.moduleRegistry
            .load(name)
            .then(x => x?.default);
        const pluginModules = await Promise.all(pluginModuleManifests.map(x => load(x.name).then(plugin => ({ manifest: x, plugin }))));
        pluginModules.forEach(({ manifest, plugin }) => {
            if (!plugin)
                throw new Error('Не удалось загрузить модуль: ' + manifest.name);
            try {
                plugin(app, manifest);
            }
            catch (error) {
                log.prod.error(__filename, `Не удалось установить плагин ${manifest.name}`, isLogParameter(error) ? error : JSON.stringify(error));
                throw error;
            }
            log.prod.info(`${manifest.name}\\plugin`, `Плагин установлен. ${services.i18n.extractFromFlatResource(manifest.description ?? {})}`);
        });
    }
    Settings.defaultLocale = services.i18n.locale;
    app.mount('body');
});
