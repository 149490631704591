import { services } from '@fbc/core/services';
import { useScreen } from '@fbc/core/use-cases';
import { Settings } from 'luxon';
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useModuleRegistry } from './module-registry';
export const useAppStore = defineStore('app', () => {
    const modules = ref({});
    const hasAccess = ref();
    const placeholders = ref({});
    const defaultLanguage = ref('ru-ru');
    const language = ref(window.localStorage.getItem('fora.language'));
    watch(language, value => {
        if (value)
            window.localStorage.setItem('fora.language', value);
        else
            window.localStorage.removeItem('fora.language');
        document.documentElement.lang = services.i18n.locale;
        Settings.defaultLocale = services.i18n.locale;
    });
    const api = ref('/');
    const websocketServiceUrl = ref('/');
    const moduleRegistry = useModuleRegistry();
    const title = ref({
        base: 'CRM',
        divider: ' - ',
        order: ['base', 'route'],
    });
    const isDevelopment = process.env.NODE_ENV === 'development';
    const notWorkingTime = ref([]);
    const infoModules = ref([]);
    const manifest = ref({});
    const isDarkTheme = ref((localStorage.getItem('fora.theme') ??
        window.matchMedia?.('(prefers-color-scheme: dark)').matches ??
        document.documentElement.dataset.theme) === 'dark');
    watch(isDarkTheme, (isDark) => {
        document.documentElement.dataset.theme = isDark ? 'dark' : '';
        localStorage.setItem('fora.theme', isDark ? 'dark' : 'light');
    }, { immediate: true });
    const isNavbarCollapsed = ref(localStorage.getItem('fora.navbar') === '1');
    watch(isNavbarCollapsed, (isCollapsed) => {
        window.localStorage.setItem('fora.navbar', isCollapsed ? '1' : '0');
    }, { immediate: true });
    const globalTimeUpdateIntervalMs = ref(30 * 1000);
    function getModulesByPurpose(purpose) {
        return Object.values(modules.value).filter(x => x?.parameters?.purpose === purpose);
    }
    function registerBase(totalManifest) {
        manifest.value = totalManifest;
        modules.value = Object.freeze(Object.fromEntries(manifest.value.manifests.map(x => x).map(x => [x.name, x])));
        defaultLanguage.value = manifest.value.app.defaultLanguage;
        placeholders.value = manifest.value.app.placeholders;
        websocketServiceUrl.value = manifest.value.app.websocketServiceUrl;
        api.value = manifest.value.api;
        title.value = manifest.value.app.title;
        infoModules.value = manifest.value.app.infoModules;
        notWorkingTime.value = manifest.value.app.notWorkingTime;
        globalTimeUpdateIntervalMs.value = Math.max(manifest.value.app.globalTimeUpdateIntervalMs ?? 30 * 1000, 1000);
        document.documentElement.lang = defaultLanguage.value;
    }
    function registerNavigation(newHasAccess) {
        hasAccess.value = newHasAccess;
    }
    const { viewport, device } = useScreen();
    const screen = computed(() => ({
        ...viewport,
        device: device.value,
    }));
    return {
        modules,
        placeholders,
        defaultLanguage,
        language,
        api,
        websocketServiceUrl,
        moduleRegistry,
        title,
        isDevelopment,
        notWorkingTime,
        manifest,
        isDarkTheme,
        isNavbarCollapsed,
        globalTimeUpdateIntervalMs,
        getModulesByPurpose,
        registerBase,
        registerNavigation,
        infoModules,
        screen,
        setTheme,
    };
});
function setTheme(name) {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = `/assets/themes/${name}.css`;
    document.head.appendChild(link);
    link.addEventListener('load', () => (document.documentElement.dataset.theme = name), { once: true, passive: true });
}
