import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "s-data-table-filter-number" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_s_input = _resolveComponent("s-input");
    const _component_s_data_table_filter_base = _resolveComponent("s-data-table-filter-base");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_s_data_table_filter_base, {
            "is-selected": _ctx.isSelected,
            caption: _ctx.caption ?? '',
            "menu-class": "s-data-table-filter-list",
            onFilter: _ctx.filter,
            onClear: _ctx.clear,
            onShow: _ctx.show
        }, {
            default: _withCtx(() => [
                _createVNode(_component_s_input, {
                    modelValue: _ctx.min,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.min) = $event)),
                    type: "double",
                    placeholder: _ctx.$i18n.resource('global.date', 'from'),
                    "no-label": ""
                }, null, 8 /* PROPS */, ["modelValue", "placeholder"]),
                _createVNode(_component_s_input, {
                    modelValue: _ctx.max,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.max) = $event)),
                    type: "double",
                    placeholder: _ctx.$i18n.resource('global.date', 'to'),
                    "no-label": ""
                }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
            ]),
            _: 1 /* STABLE */
        }, 8 /* PROPS */, ["is-selected", "caption", "onFilter", "onClear", "onShow"])
    ]));
}
