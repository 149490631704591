import { defineComponent, h } from 'vue';
import { useRoute } from 'vue-router';
import ListViewerComponent from './list-viewer/ui/s-list-viewer.vue';
import { PageComponent } from './s-page';
export { ListViewerComponent };
export const EntityPageComponent = defineComponent({
    name: 's-entity-page',
    props: { name: { type: String, required: true } },
    setup(props) {
        const route = useRoute();
        const entityName = route.meta.entityName;
        if (!entityName)
            throw new Error('EntityName not defined');
        return () => h(ListViewerComponent, () => [
            h(PageComponent, {
                key: route.params.id[0],
                name: props.name,
                properties: { entityId: route.params.id, entityName, route },
            }),
        ]);
    },
});
