import { h, mergeProps } from 'vue';
import './s-placeholder.scss';
const PlaceholderComponent = ({ surface, contrast }, context) => h('div', mergeProps({
    class: {
        's-placeholder': true,
        's-placeholder--surface': surface !== undefined && surface !== false,
        's-placeholder--contrast': contrast !== undefined && contrast !== false,
    },
}, context.attrs));
PlaceholderComponent.props = { surface: { type: Boolean }, contrast: { type: Boolean } };
export default PlaceholderComponent;
