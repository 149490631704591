import { isEqual } from 'lodash';
import { watch } from 'vue';
export function registerWatchers(context) {
    const { filters, refreshConfig, currentPage, dataProvider, load, columns, filtersChanged } = context;
    watch(() => filters.value, () => {
        filtersChanged.value = true;
        if (!refreshConfig.noResetCurrentPage)
            currentPage.value = 0;
        load();
    }, { deep: true });
    watch(() => columns.value.map(({ width: _, ...rest }) => rest), (newValue, oldValue) => {
        if (isEqual(newValue, oldValue))
            return;
        if (!refreshConfig.noResetCurrentPage)
            currentPage.value = 0;
        load();
    }, { deep: true });
    watch(dataProvider, () => {
        if (!refreshConfig.noResetCurrentPage)
            currentPage.value = 0;
        load();
    }, { deep: true });
    return context;
}
