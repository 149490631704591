import { defineFunctionalComponent, filters } from '@fbc/core/utils';
import { h, mergeProps, resolveDirective, withDirectives } from 'vue';
const { capitalize, dateTimeRelative, formatLocal } = filters;
export default defineFunctionalComponent({ date: { type: Object, required: true } }, ({ date }, { attrs }) => withDirectives(h('time', mergeProps({
    datetime: date,
    textContent: `${capitalize(dateTimeRelative(date), { onlyFirstLetter: true })} ${formatLocal(date, 'HH:mm')}`,
}, attrs)), [
    [
        resolveDirective('tooltip'),
        { label: date.toLocal().toFormat('dd.MM.yyyy HH:mm'), openDelay: 100, closeDelay: 10 },
        '',
        { left: true },
    ],
]));
