import { isTruthy } from '@fbc/core/utils';
const minColumnSize = 48;
export function useDataTableResize(columns, props, columnRef, resizeMode, columnGap, rowPaddingLeft) {
    let cursorX;
    let resizeColumn;
    let nextColumn;
    function resizeStart(event, column) {
        window.getSelection()?.removeAllRanges();
        cursorX = event.pageX;
        resizeColumn = column;
        const frameWidth = ((columnRef.value?.offsetWidth ?? 0) -
            columns.value
                .map(x => x.width)
                .filter(isTruthy)
                .reduce((sum, current) => sum + current, 0) -
            rowPaddingLeft -
            columnGap * (columns.value.length - 1)) /
            (columns.value.filter(x => !x.width).length || 1);
        for (const col of columns.value) {
            col.width = (col.width ?? frameWidth) || 100;
        }
        nextColumn = columns.value[columns.value.findIndex(x => x.field === column.field) + 1] || undefined;
        document.addEventListener('mouseup', resizeEnd, { once: true });
        document.addEventListener('mousemove', resizeMove);
    }
    function resizeEnd() {
        document.removeEventListener('mousemove', resizeMove);
        cursorX = 0;
        resizeColumn = undefined;
    }
    function resizeMove(event) {
        window.requestAnimationFrame(() => {
            let diff = cursorX - event.pageX;
            cursorX = event.pageX;
            if (!resizeColumn)
                return;
            const oldColumnWidth = resizeColumn.width ?? 0;
            resizeColumn.width =
                oldColumnWidth - diff > minColumnSize && (nextColumn ? oldColumnWidth + diff > minColumnSize : true)
                    ? oldColumnWidth - diff
                    : oldColumnWidth;
            // Фикс бага, когда делаешь размер колонки меньше minColumnSize она перестает увеличиваться
            if (resizeColumn.width <= minColumnSize + 1) {
                resizeColumn.width = minColumnSize * 2;
                diff -= minColumnSize;
            }
            if (resizeMode !== 'nextColumn' || !nextColumn || props.unfixedWidth)
                return;
            const nextColumnWidth = nextColumn.width ?? 0;
            nextColumn.width =
                nextColumnWidth + diff > minColumnSize && resizeColumn.width - diff > minColumnSize
                    ? nextColumnWidth + diff
                    : nextColumnWidth;
        });
    }
    return resizeStart;
}
