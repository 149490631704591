import { defineAsyncComponent } from 'vue';
import DetailTabContainerComponent from '../detail-tab-container/s-detail-tab-container.vue';
import { EntityPageComponent, ListViewerComponent } from '../s-entity-page';
import { PageComponent } from '../s-page';
import ButtonComponent from './s-button/s-button';
import CalendarComponent from './s-calendar.vue';
import CheckboxComponent from './s-checkbox.vue';
import ColoredIconComponent from './s-colored-icon';
import ColumnVisibilitySettingComponent from './s-column-visibility-setting/s-column-visibility-setting.vue';
import DataTableComponent from './s-data-table/s-data-table.vue';
import EndlessDataTableComponent from './s-data-table/s-endless-data-table';
import PaginationDataTableComponent from './s-data-table/s-pagination-data-table/s-pagination-data-table';
import DateTimePeriodPickerComponent from './s-date-time-period-picker/s-date-time-period-picker.vue';
import DateTimePickerComponent from './s-date-time-picker.vue';
import EmailComponent from './s-email.vue';
import ExcelExportComponent from './s-excel-export.vue';
import FileInputComponent from './s-file-input.vue';
import FullnameComponent from './s-fullname/s-fullname.vue';
import DataInlineLookupComponent from './s-inline-lookup/s-data-inline-lookup';
import InlineLookupComponent from './s-inline-lookup/s-inline-lookup.vue';
import ODataInlineLookupComponent from './s-inline-lookup/s-odata-inline-lookup';
import TextInputComponent from './s-input/s-input.vue';
import LoaderComponent from './s-loader.vue';
import LogoComponent from './s-logo.vue';
import DataLookupComponent from './s-lookup/s-data-lookup.vue';
import LookupComponent from './s-lookup/s-lookup.vue';
import ODataLookupComponent from './s-lookup/s-odata-lookup.vue';
import PicklistLookupComponent from './s-lookup/s-picklist-lookup';
import StaticLookupComponent from './s-lookup/s-static-lookup.vue';
import MainContentToolbarComponent from './s-main-content-toolbar.vue';
import ModalComponent from './s-modal/s-modal.vue';
import PaginationComponent from './s-pagination.vue';
import PhoneComponent from './s-phone.vue';
import PicklistComponent from './s-picklist.vue';
import PlaceholderComponent from './s-placeholder/s-placeholder';
import PopupComponent from './s-popup.vue';
import ProgressModalComponent from './s-progress-modal/s-progress-modal.vue';
import ProgressbarComponent from './s-progressbar.vue';
import PropertyComponent from './s-property.vue';
import RadioButtonComponent from './s-radio-button.vue';
import RadioGroupComponent from './s-radio-group.vue';
import SectionComponent from './s-section.vue';
import SelectComponent from './s-select/s-select.vue';
import SpoilerComponent from './s-spoiler/s-spoiler.vue';
import StatebuttonComponent from './s-statebutton.vue';
import TabContainerComponent from './s-tab-container/s-tab-container.vue';
import TagsInputComponent from './s-tags-input/s-tags-input.vue';
import TextareaComponent from './s-textarea.vue';
import TimeComponent from './s-time';
import TimeZoneLookupComponent from './s-time-zone-lookup';
import TimePickerComponent from './s-timepicker.vue';
import ToggleComponent from './s-toggle.vue';
import TransitionCollapseHeightComponent from './s-transition-collapse-height.vue';
import WebAddressComponent from './s-web-address.vue';
import MenuIconComponent from './navigation/s-menu-icon';
import MenuLinkComponent from './navigation/s-menu-link.vue';
import NavigationContextMenuComponent from './navigation/s-navigation-context-menu.vue';
import NavigationMenuComponent from './navigation/s-navigation-menu.vue';
export const BaseComponents = {
    install(app) {
        app.component('s-detail-tab-container', DetailTabContainerComponent);
        app.component('s-list-viewer', ListViewerComponent);
        app.component('s-entity-page', EntityPageComponent);
        app.component('s-page', PageComponent);
        app.component('s-button', ButtonComponent);
        app.component('s-popup', PopupComponent);
        app.component('s-input', TextInputComponent);
        app.component('s-tab-container', TabContainerComponent);
        app.component('s-toggle', ToggleComponent);
        app.component('s-progressbar', ProgressbarComponent);
        app.component('s-data-table', DataTableComponent);
        app.component('s-endless-data-table', EndlessDataTableComponent);
        app.component('s-pagination-data-table', PaginationDataTableComponent);
        app.component('s-loader', LoaderComponent);
        app.component('s-pagination', PaginationComponent);
        app.component('s-modal', ModalComponent);
        app.component('s-checkbox', CheckboxComponent);
        app.component('s-select', SelectComponent);
        app.component('s-email', EmailComponent);
        app.component('s-web-address', WebAddressComponent);
        app.component('s-phone', PhoneComponent);
        app.component('s-time-zone-lookup', TimeZoneLookupComponent);
        app.component('s-codetext', defineAsyncComponent(() => import(/* webpackChunkName: 's-codetext' */ './s-codetext/s-codetext.vue')));
        app.component('s-calendar', CalendarComponent);
        app.component('s-colored-icon', ColoredIconComponent);
        app.component('s-date-time-period-picker', DateTimePeriodPickerComponent);
        app.component('s-date-time-picker', DateTimePickerComponent);
        app.component('s-fullname', FullnameComponent);
        app.component('s-tags-input', TagsInputComponent);
        app.component('s-lookup', LookupComponent);
        app.component('s-data-lookup', DataLookupComponent);
        app.component('s-odata-lookup', ODataLookupComponent);
        app.component('s-static-lookup', StaticLookupComponent);
        app.component('s-picklist-lookup', PicklistLookupComponent);
        app.component('s-inline-lookup', InlineLookupComponent);
        app.component('s-data-inline-lookup', DataInlineLookupComponent);
        app.component('s-odata-inline-lookup', ODataInlineLookupComponent);
        app.component('s-main-content-toolbar', MainContentToolbarComponent);
        app.component('s-picklist', PicklistComponent);
        app.component('s-placeholder', PlaceholderComponent);
        app.component('s-progress-modal', ProgressModalComponent);
        app.component('s-radio-button', RadioButtonComponent);
        app.component('s-radio-group', RadioGroupComponent);
        app.component('s-spoiler', SpoilerComponent);
        app.component('s-statebutton', StatebuttonComponent);
        app.component('s-textarea', TextareaComponent);
        app.component('s-timepicker', TimePickerComponent);
        app.component('s-transition-collapse-height', TransitionCollapseHeightComponent);
        app.component('s-time', TimeComponent);
        app.component('s-file-input', FileInputComponent);
        app.component('s-tree-view', defineAsyncComponent(() => import(/* webpackChunkName: 's-tree-view' */ './s-tree-view.vue')));
        app.component('s-tree-table', defineAsyncComponent(() => import(/* webpackChunkName: 's-tree-view' */ './s-tree-table.vue')));
        app.component('s-tree-select', defineAsyncComponent(() => import(/* webpackChunkName: 's-tree-view' */ './s-tree-select/s-tree-select.vue')));
        app.component('s-tree-picklist', defineAsyncComponent(() => import(/* webpackChunkName: 's-tree-view' */ './s-tree-picklist/s-tree-picklist.vue')));
        app.component('s-section', SectionComponent);
        app.component('s-entity-preview', defineAsyncComponent(() => import(/* webpackChunkName: 's-entity-preview' */ './s-lookup/s-entity-preview.vue')));
        app.component('s-excel-export', ExcelExportComponent);
        app.component('s-column-visibility-setting', ColumnVisibilitySettingComponent);
        app.component('s-dialog', defineAsyncComponent(() => import(/* webpackChunkName: 's-dialog' */ './s-dialog.vue'))); // Исключен, т.к. не используется, но потом мб на него перейти как на основу popup
        app.component('s-logo', LogoComponent);
        app.component('s-property', PropertyComponent);
        app.component('s-doughnut', defineAsyncComponent({
            loader: () => import(/* webpackChunkName: 'charts' */ './charts').then(x => x.DoughnutComponent),
            loadingComponent: LoaderComponent,
        }));
        app.component('s-bar-chart', defineAsyncComponent({
            loader: () => import(/* webpackChunkName: 'charts' */ './charts').then(x => x.BarChartComponent),
            loadingComponent: LoaderComponent,
        }));
        app.component('s-sales-funnel-chart', defineAsyncComponent({
            loader: () => import(/* webpackChunkName: 'charts' */ './charts').then(x => x.SalesFunnelComponent),
            loadingComponent: LoaderComponent,
        }));
        app.component('s-pie-chart', defineAsyncComponent({
            loader: () => import(/* webpackChunkName: 'charts' */ './charts').then(x => x.PieComponent),
            loadingComponent: LoaderComponent,
        }));
        app.component('s-duration-selector', defineAsyncComponent({
            loader: () => import(/* webpackChunkName: 's-duration-selector' */ '../duration-selector/s-duration-selector.vue').then(x => x.default),
            loadingComponent: LoaderComponent,
        }));
        app.component('s-menu-link', MenuLinkComponent);
        app.component('s-menu-icon', MenuIconComponent);
        app.component('s-navigation-menu', NavigationMenuComponent);
        app.component('s-navigation-context-menu', NavigationContextMenuComponent);
    },
};
