import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withKeys as _withKeys, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "s-lookup__loader"
};
const _hoisted_2 = { class: "s-lookup__body" };
const _hoisted_3 = {
    key: 0,
    class: "s-lookup__filter-container"
};
const _hoisted_4 = ["textContent"];
const _hoisted_5 = ["textContent"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_s_input = _resolveComponent("s-input");
    const _component_s_loader = _resolveComponent("s-loader");
    const _component_s_lookup_filter = _resolveComponent("s-lookup-filter");
    const _component_s_button = _resolveComponent("s-button");
    const _component_s_data_table = _resolveComponent("s-data-table");
    const _component_s_pagination = _resolveComponent("s-pagination");
    const _component_s_modal = _resolveComponent("s-modal");
    const _component_s_entity_preview = _resolveComponent("s-entity-preview");
    return (_openBlock(), _createElementBlock("div", {
        ref: "root",
        class: _normalizeClass([_ctx.mergeClass({ 's-lookup--disabled': _ctx.readonly || _ctx.disabled }, _ctx.$attrs.class), "s-lookup"]),
        style: _normalizeStyle(_ctx.$attrs.style)
    }, [
        _renderSlot(_ctx.$slots, "default", {
            label: _ctx.label,
            displayName: _ctx.displayName,
            show: _ctx.show,
            conditionalShow: _ctx.inputClick ? _ctx.inputClick : _ctx.show,
            search: _ctx.search,
            attrs: _ctx.$attrs
        }, () => [
            _createVNode(_component_s_input, _mergeProps({
                label: _ctx.label,
                disabled: _ctx.disabled,
                "model-value": _ctx.displayName,
                buttons: _ctx.buttons,
                bordered: _ctx.bordered,
                readonly: ""
            }, _ctx.$attrs, {
                onKeypress: _withKeys(_ctx.show, ["enter"]),
                onDblclick: _cache[0] || (_cache[0] = ($event) => (_ctx.inputClick ? _ctx.inputClick(_ctx.modelValue) : _ctx.show()))
            }), null, 16 /* FULL_PROPS */, ["label", "disabled", "model-value", "buttons", "bordered", "onKeypress"])
        ]),
        _createVNode(_component_s_modal, {
            ref: "modal",
            modelValue: _ctx.isVisible,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.isVisible) = $event)),
            centralize: _ctx.centralize,
            "onUpdate:centralize": _cache[5] || (_cache[5] = ($event) => ((_ctx.centralize) = $event)),
            class: _normalizeClass(["s-lookup", _ctx.modalClass]),
            caption: _ctx.caption,
            mobileFullscreen: ""
        }, {
            footer: _withCtx(() => [
                (_ctx.loaded && _ctx.feature.pagination && _ctx.page.count > 1)
                    ? (_openBlock(), _createBlock(_component_s_pagination, {
                        key: 0,
                        class: "s-lookup__pagination",
                        disabled: _ctx.loading,
                        totalVisible: Math.ceil(_ctx.$store.app.screen.width / 120),
                        length: _ctx.page.count,
                        "model-value": _ctx.page.num + 1,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.page.num = $event - 1), _ctx.search()))
                    }, null, 8 /* PROPS */, ["disabled", "totalVisible", "length", "model-value"]))
                    : _createCommentVNode("v-if", true),
                _createVNode(_component_s_button, {
                    style: _normalizeStyle({ visibility: _ctx.tableEntities.length ? 'visible' : 'hidden' }),
                    success: "",
                    class: "btn-footer-ok",
                    onClick: _ctx.okClick
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$i18n.global('ok')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                }, 8 /* PROPS */, ["style", "onClick"]),
                _createVNode(_component_s_button, {
                    class: "btn-footer-close",
                    onClick: _ctx.cancelClick
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$i18n.global('cancel')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"])
            ]),
            default: _withCtx(() => [
                (_ctx.loading && !_ctx.loaded)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createVNode(_component_s_loader, { count: 5 })
                    ]))
                    : _createCommentVNode("v-if", true),
                _createElementVNode("div", _hoisted_2, [
                    (_ctx.feature.filters)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters, (f, i) => {
                                return (_openBlock(), _createBlock(_component_s_lookup_filter, {
                                    key: i,
                                    index: i,
                                    filter: f,
                                    columns: _ctx.columns,
                                    onNew: _ctx.newFilter,
                                    onUpdate: ($event) => (Object.assign(f, $event)),
                                    onUpdateParameter: ($event) => (Object.assign(f, $event)),
                                    onRemove: ($event) => (_ctx.removeFilter(f)),
                                    onEnter: _cache[1] || (_cache[1] = ($event) => (_ctx.search()))
                                }, null, 8 /* PROPS */, ["index", "filter", "columns", "onNew", "onUpdate", "onUpdateParameter", "onRemove"]));
                            }), 128 /* KEYED_FRAGMENT */))
                        ]))
                        : _createCommentVNode("v-if", true),
                    (_ctx.page.totalResults)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            textContent: _toDisplayString(_ctx.$i18n.extract(_ctx.resource, 'rows', { totalResults: String(_ctx.page.totalResults) }))
                        }, null, 8 /* PROPS */, _hoisted_4))
                        : _createCommentVNode("v-if", true),
                    _createVNode(_component_s_button, {
                        class: "s-lookup__btn-search s-button--single",
                        onClick: _ctx.search
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$i18n.resource('global', 'search')), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["onClick"])
                ]),
                (_ctx.loaded)
                    ? (_openBlock(), _createBlock(_component_s_data_table, {
                        key: 1,
                        modelValue: _ctx.tableEntities,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.tableEntities) = $event)),
                        idField: _ctx.idField,
                        columns: _ctx.tableColumns,
                        items: _ctx.items,
                        loading: _ctx.loading,
                        allowSort: _ctx.feature.sort,
                        selectionMode: _ctx.allowMultiple ? 'multiple' : 'single',
                        externalSort: "",
                        style: _normalizeStyle(`--table-min-width: ${_ctx.tableColumns.reduce((p, c) => p + (c.width ?? 120), 0)}px; --lookup-table-height: ${_ctx.tableHeight}px`),
                        "onRow:dblclick": _ctx.rowDblClick,
                        "onUpdate:columns": _ctx.toggleSort
                    }, null, 8 /* PROPS */, ["modelValue", "idField", "columns", "items", "loading", "allowSort", "selectionMode", "style", "onRow:dblclick", "onUpdate:columns"]))
                    : _createCommentVNode("v-if", true),
                (_ctx.allowMultiple)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 2,
                        class: "s-lookup__hint",
                        textContent: _toDisplayString(_ctx.$i18n.global('hint-multiple'))
                    }, null, 8 /* PROPS */, _hoisted_5))
                    : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "centralize", "caption", "class"]),
        (_ctx.preview)
            ? (_openBlock(), _createBlock(_component_s_entity_preview, {
                key: 0,
                dataProvider: _ctx.dataProvider,
                visible: Boolean(_ctx.entityId),
                position: _ctx.previewPosition,
                entityId: _ctx.entityId,
                idField: _ctx.idField,
                columns: _ctx.columns,
                "onUpdate:visible": _cache[6] || (_cache[6] = ($event) => ($event || (_ctx.entityId = undefined)))
            }, null, 8 /* PROPS */, ["dataProvider", "visible", "position", "entityId", "idField", "columns"]))
            : _createCommentVNode("v-if", true)
    ], 6 /* CLASS, STYLE */));
}
