import { isDateTime } from '@fbc/core/utils';
import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import { reactive } from 'vue';
export const useCacheStore = defineStore('cache', () => {
    const cache = reactive(new Map());
    function get(key, guard) {
        const value = cache.get(key);
        if (guard(value?.data))
            return value?.data;
    }
    function set(key, value, duration, onBeforeClear) {
        cache.set(key, {
            data: value,
            end: !duration ? undefined : isDateTime(duration) ? duration.toUTC() : DateTime.utc().plus(duration),
            onBeforeClear,
        });
    }
    function remove(key) {
        cache.delete(key);
    }
    // Кеш существует пока живо приложение
    window.setInterval(() => {
        const now = DateTime.utc();
        [...cache.entries()]
            .filter(x => x[1].end && x[1].end <= now)
            .forEach(async (x) => {
            if (!x[1].onBeforeClear)
                return cache.delete(x[0]);
            const shouldClear = await x[1].onBeforeClear(x[1].data);
            if (shouldClear !== false)
                cache.delete(x[0]);
        });
    }, 10000);
    return { get, set, remove };
});
