/*
    ! В данном файле находятся функции валидации чисел, емэйлов, ИНН, ОГРН, ОКПО, КПП, КИО
*/
/**
 * Проверяет валидность ИНН юридического лица
 * @param value ИНН
 */
export function validateInnCorporate(value) {
    if (value === null || value === undefined || String(value).length !== 10)
        return false;
    const codeValueArray = String(value).split('').map(Number);
    const isValueOnlyDigits = codeValueArray.every(x => '0123456789'.includes(String(x)));
    return (isValueOnlyDigits &&
        codeValueArray[9] ===
            ((2 * codeValueArray[0] +
                4 * codeValueArray[1] +
                10 * codeValueArray[2] +
                3 * codeValueArray[3] +
                5 * codeValueArray[4] +
                9 * codeValueArray[5] +
                4 * codeValueArray[6] +
                6 * codeValueArray[7] +
                8 * codeValueArray[8]) %
                11) %
                10);
}
/**
 * Проверяет валидность ИНН физического лица
 * @param value ИНН
 */
export function validateInnIndividual(value) {
    if (value === null || value === undefined || String(value).length !== 12)
        return false;
    const codeValueArray = String(value).split('').map(Number);
    const isValueOnlyDigits = codeValueArray.every(x => '0123456789'.includes(String(x)));
    return (isValueOnlyDigits &&
        codeValueArray[10] ===
            ((7 * codeValueArray[0] +
                2 * codeValueArray[1] +
                4 * codeValueArray[2] +
                10 * codeValueArray[3] +
                3 * codeValueArray[4] +
                5 * codeValueArray[5] +
                9 * codeValueArray[6] +
                4 * codeValueArray[7] +
                6 * codeValueArray[8] +
                8 * codeValueArray[9]) %
                11) %
                10 &&
        codeValueArray[11] ===
            ((3 * codeValueArray[0] +
                7 * codeValueArray[1] +
                2 * codeValueArray[2] +
                4 * codeValueArray[3] +
                10 * codeValueArray[4] +
                3 * codeValueArray[5] +
                5 * codeValueArray[6] +
                9 * codeValueArray[7] +
                4 * codeValueArray[8] +
                6 * codeValueArray[9] +
                8 * codeValueArray[10]) %
                11) %
                10);
}
/**
 * Возвращает регулярное выражение для проверки типа
 * @param type тип
 */
export function regExpByType(type) {
    if (type instanceof RegExp)
        return type;
    switch (type) {
        case 'integer':
            return /^(-?[1-9]\d*|0)$/;
        case 'positive':
            return /^([1-9]\d*|0)$/;
        case 'double':
            return /^-?\d*[,.]?\d*$/;
        case 'hex':
            return /^[\da-f]*$/i;
        case 'percent':
            return /^-?\d*[,.]?\d*%?$/;
        /*
            '+@dsada.dsds' => ok;   '".+"@dsada.dsds' => ok;    'dsaa.dsa@dsa.ds' => ok;    'dsaa.dsa@[123.123.123.123]' => ok
            'dsaa.@dsa.ds' => not;  'dsaa.dsa@123.123.123.12' => not
        */
        case 'email':
            return /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/;
        case 'phone':
            return /^\+?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,11}$/im;
        case 'url':
            return /^(https?:\/\/)?([\w-]+[.])+[\w-]{2,6}(:\d+)?(\/[\w-]+(.\w+)*)*(\?\w+=\w+(&\w+=\w+)*)?$/iu;
    }
}
/**
 * Проверяет является ли строка валидным числом заданного типа в диапазоне [min,max](Если указано)
 * @param type Тип
 * @param value Значение
 * @param min Минимум
 * @param max Максимум
 */
export function validateNumber(type, value, min, max) {
    if (value === undefined)
        return '';
    if (value === null || value === '')
        return value;
    if ((type === 'integer' || type === 'double' || type === 'percent') && value === '-')
        return value;
    const regExp = regExpByType(type);
    const number = type === 'hex' ? Number.parseInt(value, 16) : Number.parseFloat(value);
    if (Number.isNaN(number))
        return null;
    if (regExp.test(value || '') &&
        (value === '-' || ((max === undefined || number <= max) && (min === undefined || number >= min))))
        return value;
    return null;
}
/**
 * Проверяет находится ли число в диапазоне [min,max]
 * @param value Число
 * @param min Минимум
 * @param max Максимум
 */
export const cutToRange = (value, min, max) => value <= min ? min : value >= max ? max : value;
/**
 * Проверяет валидность ОГРН Юридического лица
 * @param codeValue ОГРН
 */
export function validateOgrnCorporate(codeValue) {
    if (!codeValue)
        return false;
    codeValue = String(codeValue);
    return codeValue.length === 13 && codeValue.endsWith(String(Number(codeValue.slice(0, -1)) % 11).slice(-1));
}
/**
 * Проверяет валидность ОКПО Юридического лица
 * @param codeValue ОКПО
 */
export function validateOkpoCorporate(codeValue) {
    if (!codeValue)
        return false;
    codeValue = String(codeValue);
    const codeValueArray = codeValue.split('');
    if (codeValueArray.length !== 8)
        return false;
    let csum = (Number.parseInt(codeValueArray[0]) +
        2 * Number.parseInt(codeValueArray[1]) +
        3 * Number.parseInt(codeValueArray[2]) +
        4 * Number.parseInt(codeValueArray[3]) +
        5 * Number.parseInt(codeValueArray[4]) +
        6 * Number.parseInt(codeValueArray[5]) +
        7 * Number.parseInt(codeValueArray[6])) %
        11;
    if (csum === 10)
        csum =
            (3 * Number.parseInt(codeValueArray[0]) +
                4 * Number.parseInt(codeValueArray[1]) +
                5 * Number.parseInt(codeValueArray[2]) +
                6 * Number.parseInt(codeValueArray[3]) +
                7 * Number.parseInt(codeValueArray[4]) +
                8 * Number.parseInt(codeValueArray[5]) +
                9 * Number.parseInt(codeValueArray[6])) %
                11;
    if (csum === 10)
        csum = 0;
    return Number(codeValueArray[7]) === csum;
}
/**
 * Проверяет валидность ОКПО Физического лица
 * @param codeValue ОКПО
 */
export function validateOkpoIndividual(codeValue) {
    if (!codeValue)
        return false;
    codeValue = String(codeValue);
    const codeValueArray = codeValue.split('');
    if (codeValueArray.length !== 10)
        return false;
    let csum = (Number.parseInt(codeValueArray[0]) +
        2 * Number.parseInt(codeValueArray[1]) +
        3 * Number.parseInt(codeValueArray[2]) +
        4 * Number.parseInt(codeValueArray[3]) +
        5 * Number.parseInt(codeValueArray[4]) +
        6 * Number.parseInt(codeValueArray[5]) +
        7 * Number.parseInt(codeValueArray[6]) +
        8 * Number.parseInt(codeValueArray[7]) +
        9 * Number.parseInt(codeValueArray[8])) %
        11;
    if (csum === 10)
        csum =
            (3 * Number.parseInt(codeValueArray[2]) +
                4 * Number.parseInt(codeValueArray[3]) +
                5 * Number.parseInt(codeValueArray[4]) +
                6 * Number.parseInt(codeValueArray[5]) +
                7 * Number.parseInt(codeValueArray[6]) +
                8 * Number.parseInt(codeValueArray[7]) +
                9 * Number.parseInt(codeValueArray[8])) %
                11;
    if (csum === 10)
        csum = 0;
    return Number(codeValueArray[9]) === csum;
}
/**
 * Сгруппированные валидаторы кодов
 */
export const validators = {
    individual: {
        inn: validateInnIndividual,
        ogrn: validateOgrnCorporate,
        okpo: validateOkpoIndividual,
    },
    corporate: {
        inn: validateInnCorporate,
        ogrn: validateOgrnCorporate,
        okpo: validateOkpoCorporate,
    },
};
/**
 * Проверяет валидность кода
 * @param codeValue код
 * @param codeType тип кода
 */
export function validateCode(codeValue, codeType) {
    if (!codeValue)
        return true;
    switch (codeType) {
        case 'Inn':
            return validators.corporate.inn(codeValue) || validators.individual.inn(codeValue);
        case 'InnCorporate':
            return validators.corporate.inn(codeValue);
        case 'InnIndividual':
            return validators.individual.inn(codeValue);
        case 'Kpp':
            return /^\d{9}$/.test(codeValue);
        case 'Ogrn':
            return validators.corporate.ogrn(codeValue) || validators.individual.ogrn(codeValue);
        case 'OgrnCorporate':
            return validators.corporate.ogrn(codeValue);
        case 'OgrnIndividual':
            return validators.individual.ogrn(codeValue);
        case 'Okpo':
            return validators.corporate.okpo(codeValue) || validators.individual.okpo(codeValue);
        case 'OkpoCorporate':
            return validators.corporate.okpo(codeValue);
        case 'OkpoIndividual':
            return validators.individual.okpo(codeValue);
        case 'Kio':
            return /^\d{5,6}$/.test(codeValue);
        default:
            return false;
    }
}
