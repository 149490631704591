import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_s_data_table = _resolveComponent("s-data-table");
    const _component_s_button = _resolveComponent("s-button");
    const _component_s_modal = _resolveComponent("s-modal");
    return (_openBlock(), _createBlock(_component_s_modal, {
        modelValue: _ctx.visible,
        caption: _ctx.local('caption'),
        width: 400
    }, {
        footer: _withCtx(() => [
            _createVNode(_component_s_button, {
                success: "",
                onClick: _ctx.save
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.i18n.global('save')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"]),
            _createVNode(_component_s_button, { onClick: _ctx.hide }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.i18n.global('cancel')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"])
        ]),
        default: _withCtx(() => [
            _createVNode(_component_s_data_table, {
                columns: _ctx.columns,
                idField: "id",
                items: _ctx.roles,
                loading: _ctx.loading.isLocked.value,
                style: { "--body-max-height": "70vh" },
                allowResize: "",
                allowSort: ""
            }, null, 8 /* PROPS */, ["columns", "items", "loading"])
        ]),
        _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "caption"]));
}
