import { inject } from 'vue';
import { services } from '.';
function moduleDialog(value, parameters) {
    if (!value) {
        if (process.env.NODE_ENV === 'development')
            throw new Error('module dialog does not have name');
        return;
    }
    const store = services.store ?? inject('store');
    return store.moduleDialog.add(value, parameters);
}
export default moduleDialog;
