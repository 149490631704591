export const isODataError = (x) => x !== null && 'error' in x && typeof x.error === 'object' && x.error !== null && 'message' in x.error;
export const mapODataErrorToServerError = (odataError) => ({
    message: odataError.error.message,
    stackTrace: odataError.error.innererror?.stacktrace,
});
export const encodeODataQueryParameters = (query) => Object.entries(query.toObject())
    .map(x => `${x[0]}=${encodeURIComponent(x[1])}`)
    .join('&');
const mapExpandToQueryParameter = (propertiesArray, expandFilters = {}) => {
    const groups = new Map();
    for (const property of propertiesArray) {
        const [group, ...properties] = property.split('/');
        if (!groups.has(group)) {
            groups.set(group, []);
        }
        if (properties.length > 0) {
            groups.get(group)?.push(properties.join('/'));
        }
    }
    const expandItems = [...groups.entries()].map(([group, properties]) => {
        if (properties.length === 0) {
            return group;
        }
        const select = properties.filter(p => !p.includes('/')).join(',');
        const filter = expandFilters[group] ? `;$filter = ${expandFilters[group]}` : '';
        const expandProperties = properties.filter(p => p.includes('/'));
        if (expandProperties.length === 0) {
            return select ? `${group}($select=${select}${filter})` : group;
        }
        const subExpand = mapExpandToQueryParameter(expandProperties, expandFilters);
        return `${group}(${select ? `$select=${select};` : ``}$expand=${subExpand})`;
    });
    return expandItems.join(',');
};
const mapFilterToQueryParameter = (x) => x.operator === 'like' ? x.expression : `${String(x.fieldName)} ${x.operator} ${x.value}`;
/**
 * Форматирует параметры для запроса в OData
 * @param config параметры для OData
 */
export const formatODataQueryParameters = (config) => {
    let options = '';
    if (config.filter?.length)
        options += '&$filter=' + config.filter.map(mapFilterToQueryParameter).join(' and ');
    if (config.manualFilter)
        options += config.filter?.length ? ` and (${config.manualFilter})` : `&filter=${config.manualFilter}`;
    if (config.select?.length)
        options += '&$select=' + config.select.join(',');
    if (config.expand?.length)
        options += '&$expand=' + mapExpandToQueryParameter(config.expand, config.expandFilters);
    if ('skip' in config && config.skip && config.skip > 0)
        options += `&$skip=${config.skip}`;
    if ('top' in config && config.top && config.top > 0)
        options += `&$top=${config.top}`;
    if ('count' in config && config.count)
        options += `&$count=${String(config.count)}`;
    if ('orderBy' in config && config.orderBy?.length)
        options += config.orderBy.map(x => `&$orderBy=${String(x.field)} ${x.direction}`).join('');
    if (!options)
        return '';
    return options.substring(1);
};
