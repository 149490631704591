import { filters as coreFilters } from '@fbc/core/utils';
import { clone } from 'lodash';
import { mapToODataFilters } from './map-to-odata-filters';
/**
 * OData Provider
 */
export class TableODataProvider {
    api;
    key;
    config;
    /**
     * @constructor
     * @param parameters DTO параметры сущности
     */
    constructor(api, key, config = {}) {
        this.api = api;
        this.key = key;
        this.config = config;
    }
    get methodName() {
        return this.key;
    }
    get oDataConfig() {
        return this.isQuery(this.config) ? this.config : clone(this.config);
    }
    getConfig(parameters, fullConfig) {
        const oDataFilters = mapToODataFilters(parameters.filters, fullConfig.manualFilter, fullConfig.filter);
        return {
            ...fullConfig,
            select: fullConfig.select ?? parameters.pick,
            skip: parameters.page ? parameters.page.num * parameters.page.size : 0,
            top: parameters.page?.size ?? 0,
            count: true,
            filter: oDataFilters.filter,
            manualFilter: oDataFilters.manualFilter,
            orderBy: parameters.sort && this.convertSort(parameters.sort),
        };
    }
    isQuery(config) {
        return typeof config === 'function';
    }
    getData({ page, filters, sort, pick, } = {}) {
        const oDataService = this.api;
        if (!this.isQuery(this.config))
            return oDataService.where(this.key, this.getConfig({ page, filters, sort, pick }, this.config), __filename);
        const query = this.config;
        const config = x => {
            let c = query(x).count();
            if (page)
                c = c.paginate(page.size, page.num);
            if (pick && !c.toObject().$select)
                c = c.select(...pick);
            sort?.forEach(x => {
                c = c.orderBy(x.fieldName, x.sortOrder);
            });
            return c;
        };
        return oDataService.where(this.key, config, __filename);
    }
    convertSort(sort) {
        //! пока так, ибо поля в odata с заглавной
        return sort.map(x => ({
            field: coreFilters.capitalize(x.fieldName, { onlyFirstLetter: true }),
            direction: x.sortOrder,
        }));
    }
}
