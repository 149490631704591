import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createVNode as _createVNode, Teleport as _Teleport } from "vue";
const _hoisted_1 = { class: "s-content-toolbar" };
const _hoisted_2 = {
    key: 0,
    style: { "margin-right": "auto" }
};
const _hoisted_3 = {
    key: 1,
    style: { "margin-left": "auto" }
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = ["textContent"];
const _hoisted_6 = ["textContent"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_s_button = _resolveComponent("s-button");
    const _component_s_popup = _resolveComponent("s-popup");
    const _directive_waves = _resolveDirective("waves");
    return (_openBlock(), _createBlock(_Teleport, { to: "#list-toolbar" }, [
        _createElementVNode("div", _hoisted_1, [
            (_ctx.leftComponents.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leftComponents, (x, i) => {
                        return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
                            ('slot' in x)
                                ? _renderSlot(_ctx.$slots, x.slot, {
                                    key: 0,
                                    disposition: "toolbar"
                                })
                                : ('component' in x)
                                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(x.component), {
                                        key: 1,
                                        disposition: "toolbar"
                                    }))
                                    : (_openBlock(), _createBlock(_component_s_button, _mergeProps({
                                        key: 2,
                                        action: x.icon ?? '',
                                        "aria-label": x.text,
                                        disabled: x.disabled,
                                        ref_for: true
                                    }, x.attrs, {
                                        onClick: x.action
                                    }), {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(x.onlyIcon ? '' : x.text), 1 /* TEXT */)
                                        ]),
                                        _: 2 /* DYNAMIC */
                                    }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */, ["action", "aria-label", "disabled", "onClick"]))
                        ], 64 /* STABLE_FRAGMENT */));
                    }), 128 /* KEYED_FRAGMENT */))
                ]))
                : _createCommentVNode("v-if", true),
            (_ctx.rightComponents.length || _ctx.menuComponents.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rightComponents, (x, i) => {
                        return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
                            ('slot' in x)
                                ? _renderSlot(_ctx.$slots, x.slot, {
                                    key: 0,
                                    disposition: "toolbar"
                                })
                                : ('component' in x)
                                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(x.component), {
                                        key: 1,
                                        disposition: "toolbar"
                                    }))
                                    : (_openBlock(), _createBlock(_component_s_button, _mergeProps({
                                        key: 2,
                                        action: x.icon ?? '',
                                        "aria-label": x.text,
                                        disabled: x.disabled,
                                        ref_for: true
                                    }, x.attrs, {
                                        onClick: x.action
                                    }), {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(x.onlyIcon ? '' : x.text), 1 /* TEXT */)
                                        ]),
                                        _: 2 /* DYNAMIC */
                                    }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */, ["action", "aria-label", "disabled", "onClick"]))
                        ], 64 /* STABLE_FRAGMENT */));
                    }), 128 /* KEYED_FRAGMENT */)),
                    (_ctx.collapsed && _ctx.menuComponents.length)
                        ? (_openBlock(), _createBlock(_component_s_button, {
                            key: 0,
                            action: "fb-common-menu",
                            onClick: _ctx.show
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$i18n.global('actions')), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["onClick"]))
                        : _createCommentVNode("v-if", true)
                ]))
                : _createCommentVNode("v-if", true)
        ]),
        _createVNode(_component_s_popup, {
            visible: _ctx.menu.visible,
            "onUpdate:visible": _cache[1] || (_cache[1] = ($event) => ((_ctx.menu.visible) = $event)),
            position: _ctx.menu.position,
            transitionName: "s-navigation-popup-transition",
            class: "s-content-toolbar__menu",
            backdrop: _ctx.$store.app.isDarkTheme,
            centralizeOnMobile: ""
        }, {
            default: _withCtx(() => [
                _createElementVNode("ul", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuComponents, (x, i) => {
                        return (_openBlock(), _createElementBlock("li", {
                            key: i,
                            class: _normalizeClass({ 's-content-toolbar__menu-component': 'slot' in x }),
                            onHide: _cache[0] || (_cache[0] = ($event) => (_ctx.menu.visible = false))
                        }, [
                            ('slot' in x)
                                ? _renderSlot(_ctx.$slots, x.slot, {
                                    key: 0,
                                    disposition: "menu"
                                })
                                : ('component' in x)
                                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(x.component), {
                                        key: 1,
                                        disposition: "menu"
                                    }))
                                    : _withDirectives((_openBlock(), _createElementBlock("label", {
                                        key: 2,
                                        onClick: ($event) => ((_ctx.menu.visible = false), x.disabled || x.action())
                                    }, [
                                        _createElementVNode("i", {
                                            class: _normalizeClass(x.icon)
                                        }, null, 2 /* CLASS */),
                                        _createElementVNode("span", {
                                            textContent: _toDisplayString(x.text)
                                        }, null, 8 /* PROPS */, _hoisted_5),
                                        (x.tooltip)
                                            ? (_openBlock(), _createElementBlock("span", {
                                                key: 0,
                                                class: "s-content-toolbar__tooltip",
                                                textContent: _toDisplayString(x.tooltip)
                                            }, null, 8 /* PROPS */, _hoisted_6))
                                            : _createCommentVNode("v-if", true)
                                    ], 8 /* PROPS */, _hoisted_4)), [
                                        [_directive_waves]
                                    ])
                        ], 34 /* CLASS, NEED_HYDRATION */));
                    }), 128 /* KEYED_FRAGMENT */))
                ])
            ]),
            _: 3 /* FORWARDED */
        }, 8 /* PROPS */, ["visible", "position", "backdrop"])
    ]));
}
