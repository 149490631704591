import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "s-data-table-filter-list" };
const _hoisted_2 = { class: "s-data-table-filter-list__items-container" };
const _hoisted_3 = ["textContent"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_s_input = _resolveComponent("s-input");
    const _component_s_checkbox = _resolveComponent("s-checkbox");
    const _component_s_data_table_filter_base = _resolveComponent("s-data-table-filter-base");
    const _directive_tooltip = _resolveDirective("tooltip");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_s_data_table_filter_base, {
            "is-selected": _ctx.isSelected,
            caption: _ctx.caption ?? '',
            "menu-class": "s-data-table-filter-list",
            onFilter: _ctx.filter,
            onClear: _ctx.clear,
            onCancel: _ctx.cancel,
            onShow: _ctx.show
        }, {
            default: _withCtx(() => [
                _createVNode(_component_s_input, {
                    modelValue: _ctx.text,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.text) = $event)),
                    placeholder: _ctx.local('placeholder'),
                    onInput: _cache[1] || (_cache[1] = ($event) => (_ctx.text = $event))
                }, null, 8 /* PROPS */, ["modelValue", "placeholder"]),
                _createElementVNode("div", _hoisted_2, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (item) => {
                        return (_openBlock(), _createElementBlock("div", {
                            key: item.value,
                            class: "s-data-table-filter-list__item"
                        }, [
                            _withDirectives(_createVNode(_component_s_checkbox, {
                                label: String(item.value),
                                class: "s-data-table-filter-list__one-line text-ellipsis col-12",
                                modelValue: _ctx.values.includes(item.value),
                                "onUpdate:modelValue": ($event) => (_ctx.change($event, item.value))
                            }, null, 8 /* PROPS */, ["label", "modelValue", "onUpdate:modelValue"]), [
                                [
                                    _directive_tooltip,
                                    item.value,
                                    "s-data-table-filter-list-tooltip",
                                    {
                                        top: true,
                                        showOnlyIfOverflown: true
                                    }
                                ]
                            ]),
                            (item.count !== undefined)
                                ? (_openBlock(), _createElementBlock("span", {
                                    key: 0,
                                    textContent: _toDisplayString(item.count)
                                }, null, 8 /* PROPS */, _hoisted_3))
                                : _createCommentVNode("v-if", true)
                        ]));
                    }), 128 /* KEYED_FRAGMENT */))
                ])
            ]),
            _: 1 /* STABLE */
        }, 8 /* PROPS */, ["is-selected", "caption", "onFilter", "onClear", "onCancel", "onShow"])
    ]));
}
