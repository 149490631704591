import { computed } from 'vue';
import { useSemaphore } from './use-semaphore';
export const useMultiSemaphore = (allKeys) => {
    const semaphores = allKeys.reduce((acc, part) => Object.assign(acc, { [part]: useSemaphore() }), {});
    const lock = (key) => typeof key === 'string' ? semaphores[key].lock() : key.forEach((x) => semaphores[x].lock());
    const lockAll = () => allKeys.forEach((x) => semaphores[x].lock());
    const unlock = (key) => typeof key === 'string' ? semaphores[key].unlock() : key.forEach((x) => semaphores[x].unlock());
    const unlockAll = () => allKeys.forEach((x) => semaphores[x].unlock());
    const isLoading = (key) => semaphores[key].isLocked;
    const isLoadingSome = computed(() => allKeys.some((x) => semaphores[x].isLocked.value));
    const isLoadingSomeBy = (keys) => computed(() => (keys ?? allKeys).some((x) => semaphores[x].isLocked.value));
    const isLoadingEvery = computed(() => allKeys.every((x) => semaphores[x].isLocked.value));
    const isLoadingEveryBy = (keys) => computed(() => (keys ?? allKeys).every((x) => semaphores[x].isLocked.value));
    const clear = (key) => typeof key === 'string' ? semaphores[key].clear() : key.forEach((x) => semaphores[x].clear());
    const clearAll = () => allKeys.forEach((x) => semaphores[x].clear());
    return {
        lock,
        lockAll,
        unlock,
        unlockAll,
        isLoading,
        isLoadingSome,
        isLoadingSomeBy,
        isLoadingEvery,
        isLoadingEveryBy,
        clear,
        clearAll,
    };
};
