import { filters } from '@fbc/core/utils';
import { mapToODataFilters } from './map-to-odata-filters';
import { setFluentFilter } from './set-fluent-filter';
/**
 * OData Provider
 */
export class LookupODataProvider {
    api;
    key;
    config;
    /**
     * @constructor
     * @param parameters DTO параметры сущности
     */
    constructor(api, key, config = {}) {
        this.api = api;
        this.key = key;
        this.config = config;
    }
    get methodName() {
        return this.key;
    }
    getData({ page, validFilters, sort, pick } = {}) {
        const oDataService = this.api;
        const config = typeof this.config === 'object'
            ? this.getConfig({ page, validFilters, sort, pick }, this.config)
            : (x) => {
                let c = this.config(x).count();
                if (page)
                    c = c.paginate(page.size, page.num);
                if (pick && !c.toObject().$select)
                    c = c.select(...pick);
                c = setFluentFilter(validFilters, c);
                return c;
            };
        return oDataService.where(this.key, config, __filename);
    }
    getConfig(parameters, fullConfig) {
        const oDataFilters = mapToODataFilters(parameters.validFilters, fullConfig.manualFilter, fullConfig.filter);
        return {
            ...fullConfig,
            select: fullConfig.select ?? parameters.pick,
            skip: parameters.page ? parameters.page.num * parameters.page.size : 0,
            top: parameters.page?.size ?? 0,
            count: true,
            filter: oDataFilters.filter,
            manualFilter: oDataFilters.manualFilter,
            orderBy: parameters.sort && this.convertSort(parameters.sort),
        };
    }
    convertSort(sort) {
        //! пока так, ибо поля в odata с заглавной
        return sort.map(x => ({
            field: filters.capitalize(x.fieldName, { onlyFirstLetter: true }),
            direction: x.sortOrder,
        }));
    }
}
