import { ref } from 'vue';
export const useDrag = (initialLeft, initialTop) => {
    const left = ref(initialLeft);
    const top = ref(initialTop);
    function drag(d, e) {
        if (!d)
            return;
        d.classList.add('s-modal__modal--drag');
        const oX = Number.parseInt(d.style.left || ''), oY = Number.parseInt(d.style.top || ''), eX = xy(e), eY = xy(e, true);
        let stop;
        const onMove = (e) => {
            if (stop)
                return;
            d.style.top = String(xy(e, true) + oY - eY) + 'px';
            d.style.left = String(xy(e) + oX - eX) + 'px';
        };
        const onMoveEnd = () => {
            stop = 1;
            d.classList.remove('s-modal__modal--drag');
            document.removeEventListener('mousemove', onMove);
            document.removeEventListener('touchmove', onMove);
            document.removeEventListener('mouseup', onMoveEnd);
            document.removeEventListener('touchend', onMoveEnd);
            let leftLocal = Number.parseInt(d.style.left || '');
            let topLocal = Number.parseInt(d.style.top || '');
            if (topLocal < 0)
                topLocal = 0;
            if (leftLocal < 0)
                leftLocal = 0;
            if (leftLocal > window.innerWidth - 200)
                leftLocal -= 200;
            if (topLocal > window.innerHeight - 200)
                topLocal -= 200;
            d.style.top = `${topLocal}px`;
            d.style.left = `${leftLocal}px`;
            left.value = leftLocal;
            top.value = topLocal;
        };
        document.addEventListener('mousemove', onMove, { passive: true });
        document.addEventListener('touchmove', onMove, { passive: true });
        document.addEventListener('mouseup', onMoveEnd, { passive: true });
        document.addEventListener('touchend', onMoveEnd, { passive: true });
    }
    return { left, top, drag };
};
function xy(e, isY) {
    return isY ? ('pageY' in e ? e.pageY : e.touches[0]?.pageY) : 'pageX' in e ? e.pageX : e.touches[0]?.pageX;
}
