import capitalize from './filters/capitalize';
/**
 * Возвращает локализацию месяцев из Intl API
 *
 * @param locale локаль
 * @param type короткое или длинное название
 * @param capitalizeFirstLetter Переводить ли первую букву в верхний регистр
 */
export const getMonthsNames = (locale, type = 'long', capitalizeFirstLetter = true) => [...Array.from({ length: 12 }).keys()]
    .map(month => new Date(1970, month, 1).toLocaleString(locale, { month: type }))
    .map(x => (capitalizeFirstLetter ? capitalize(x) : x));
/**
 * Возвращает локализацию названий дней из Intl API
 * @param locale локаль
 * @param type короткое или длинное название
 */
export const getDayNames = (locale, type = 'short') => [...Array.from({ length: 7 }).keys()]
    .map(day => new Date(1970, 0, day + 5).toLocaleString(locale, { weekday: type }))
    .map(x => (type === 'long' ? x : x.substring(0, 2)));
