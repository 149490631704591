import { nextTick, ref, watch } from 'vue';
export function useUpdateMenuRoot(props, key) {
    const root = ref();
    function updatePosition(needUpdate = true) {
        if (!root.value)
            return;
        const menu = root.value.getBoundingClientRect();
        const position = props.position;
        if (!position) {
            root.value.style.left = '0px';
            root.value.style.top = '0px';
            return;
        }
        // Пары координат могут изменятся при вызовах (c left на right, например), очищаем старые значения
        root.value.style.left = '';
        root.value.style.top = '';
        root.value.style.bottom = '';
        root.value.style.right = '';
        if ('left' in position) {
            let left = position.left ?? 0;
            if (menu.width + left > window.innerWidth)
                left = window.innerWidth - menu.width - 2;
            if (left < 0)
                left = 0;
            root.value.style.left = String(left) + 'px';
        }
        if ('top' in position) {
            let top = position.top ?? 0;
            if (menu.height + top > window.innerHeight)
                top = window.innerHeight - menu.height - 2;
            if (top < 0)
                top = 0;
            root.value.style.top = String(top) + 'px';
        }
        if ('bottom' in position) {
            let bottom = position.bottom ?? 0;
            if (menu.height + bottom > window.innerHeight)
                bottom = window.innerHeight - menu.height - 2;
            if (bottom < 0)
                bottom = 0;
            root.value.style.bottom = String(bottom) + 'px';
        }
        if ('right' in position) {
            let right = position.right ?? 0;
            if (menu.width + right > window.innerWidth) {
                // Если на маленьких экранах у меню не зафиксирована ширина(по контенту), то меню становятся минимальной возможной ширины. Или нет, тут зависит рендерилось ли оно
                // Для левой позиции не повторяется, поэтому там такого костыля нет
                root.value.style.left = '0px';
            }
            else {
                if (right < 0)
                    right = 0;
                root.value.style.right = String(right) + 'px';
            }
        }
        if (needUpdate)
            nextTick(() => updatePosition(false));
    }
    watch(() => props[key], x => x && nextTick(updatePosition));
    watch(() => props.position, () => updatePosition(), { deep: true });
    return { root, updatePosition };
}
