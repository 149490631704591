import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode } from "vue";
const _hoisted_1 = ["for"];
const _hoisted_2 = ["value"];
const _hoisted_3 = ["aria-label", "onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_s_input = _resolveComponent("s-input");
    const _directive_tooltip = _resolveDirective("tooltip");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(_ctx.mergeClass(_ctx.rootClasses, _ctx.$attrs.class, _ctx.name)),
        style: _normalizeStyle(_ctx.$attrs.style)
    }, [
        (!_ctx.noLabel)
            ? (_openBlock(), _createElementBlock("label", {
                key: 0,
                class: _normalizeClass(_ctx.labelClasses),
                for: _ctx.id
            }, _toDisplayString(_ctx.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_1))
            : _createCommentVNode("v-if", true),
        _createElementVNode("div", {
            class: _normalizeClass(_ctx.name + '__content')
        }, [
            _createElementVNode("div", {
                class: _normalizeClass(_ctx.name + '__tags')
            }, [
                _createElementVNode("ul", {
                    class: _normalizeClass(_ctx.name + '__tags-inner'),
                    style: _normalizeStyle({ '--max-rows': _ctx.maxRows ?? 'unset' }),
                    role: "listitem",
                    onClick: _cache[0] || (_cache[0] =
                        //@ts-ignore
                        (...args) => (_ctx.focusInput && _ctx.focusInput(...args)))
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue ?? [], (item, index) => {
                        return _renderSlot(_ctx.$slots, "item", {
                            key: index,
                            item: item,
                            index: index,
                            removeItemByIndex: _ctx.removeItemByIndex,
                            getLabel: _ctx.getLabel
                        }, () => [
                            _createElementVNode("li", {
                                value: _ctx.getLabel(item),
                                class: _normalizeClass(_ctx.name + '__item')
                            }, [
                                _withDirectives((_openBlock(), _createElementBlock("span", null, [
                                    _createTextVNode(_toDisplayString(_ctx.getLabel(item)), 1 /* TEXT */)
                                ])), [
                                    [
                                        _directive_tooltip,
                                        _ctx.getLabel(item),
                                        void 0,
                                        {
                                            pre: true,
                                            showOnlyIfOverflown: true,
                                            enabledOnTouch: true
                                        }
                                    ]
                                ]),
                                (!_ctx.readonly && !_ctx.disabled)
                                    ? (_openBlock(), _createElementBlock("i", {
                                        key: 0,
                                        class: _normalizeClass([_ctx.name + '__item__remove-button', "fb-common-close"]),
                                        "aria-label": _ctx.$i18n.global('delete'),
                                        onClick: _withModifiers(($event) => (_ctx.removeItemByIndex(index)), ["prevent"])
                                    }, null, 10 /* CLASS, PROPS */, _hoisted_3))
                                    : _createCommentVNode("v-if", true)
                            ], 10 /* CLASS, PROPS */, _hoisted_2)
                        ]);
                    }), 128 /* KEYED_FRAGMENT */)),
                    (_ctx.counter)
                        ? (_openBlock(), _createElementBlock("label", {
                            key: 0,
                            "aria-hidden": "true",
                            class: _normalizeClass(_ctx.name + '__counter')
                        }, _toDisplayString(_ctx.counterText), 3 /* TEXT, CLASS */))
                        : _createCommentVNode("v-if", true)
                ], 6 /* CLASS, STYLE */)
            ], 2 /* CLASS */),
            (!_ctx.disabled)
                ? _renderSlot(_ctx.$slots, "default", {
                    key: 0,
                    addItem: _ctx.addItem,
                    removeItemByIndex: _ctx.removeItemByIndex,
                    allowAdd: _ctx.allowAdd
                }, () => [
                    _createVNode(_component_s_input, _mergeProps({ id: _ctx.id }, _ctx.attributes, {
                        readonly: !_ctx.allowAdd
                    }), null, 16 /* FULL_PROPS */, ["id", "readonly"])
                ])
                : _createCommentVNode("v-if", true)
        ], 2 /* CLASS */)
    ], 6 /* CLASS, STYLE */));
}
