import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = { "data-tab-type": "shadow" };
const _hoisted_2 = { "data-tab-type": "shadow" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
        class: "s-draggable-tab",
        draggable: "true",
        onDragstart: _cache[4] || (_cache[4] = ($event) => (_ctx.tabsSettingModule.onDragStart($event, _ctx.tab))),
        onDragover: _cache[5] || (_cache[5] = ($event) => (_ctx.tabsSettingModule.onDragOver($event, _ctx.tab))),
        onDrop: _ctx.tabsSettingModule.onDrop,
        onDragend: _ctx.tabsSettingModule.onDragEnd
    }, {
        default: _withCtx(() => [
            (_ctx.isDraggedOverTab && _ctx.tabsSettingModule.isDroppingBack)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "s-draggable-tab__shadow-tab",
                    style: _normalizeStyle(_ctx.tabsSettingModule.draggedTabSize),
                    "data-tab-type": "shadow",
                    draggable: "true",
                    onDragover: _cache[0] || (_cache[0] = ($event) => (_ctx.tabsSettingModule.onDragOver($event, _ctx.tab))),
                    onDrop: _cache[1] || (_cache[1] =
                        //@ts-ignore
                        (...args) => (_ctx.tabsSettingModule.onDrop && _ctx.tabsSettingModule.onDrop(...args)))
                }, [
                    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.tabsSettingModule.draggedTab?.title ?? ''), 1 /* TEXT */)
                ], 36 /* STYLE, NEED_HYDRATION */))
                : _createCommentVNode("v-if", true),
            _renderSlot(_ctx.$slots, "default"),
            (_ctx.isDraggedOverTab && _ctx.tabsSettingModule.isDroppingForward)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "s-draggable-tab__shadow-tab",
                    style: _normalizeStyle(_ctx.tabsSettingModule.draggedTabSize),
                    "data-tab-type": "shadow",
                    draggable: "true",
                    onDrop: _cache[2] || (_cache[2] =
                        //@ts-ignore
                        (...args) => (_ctx.tabsSettingModule.onDrop && _ctx.tabsSettingModule.onDrop(...args))),
                    onDragover: _cache[3] || (_cache[3] = ($event) => (_ctx.tabsSettingModule.onDragOver($event, _ctx.tab)))
                }, [
                    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.tabsSettingModule.draggedTab?.title ?? ''), 1 /* TEXT */)
                ], 36 /* STYLE, NEED_HYDRATION */))
                : _createCommentVNode("v-if", true)
        ]),
        _: 3 /* FORWARDED */
    }, 40 /* PROPS, NEED_HYDRATION */, ["onDrop", "onDragend"]));
}
