import { defineFunctionalComponent } from '@fbc/core/utils';
import { omit } from 'lodash';
import { h, resolveComponent } from 'vue';
import props from './props';
class ODataInlineLookupProvider {
    api;
    key;
    config;
    constructor(api, key, config) {
        this.api = api;
        this.key = key;
        this.config = config;
    }
    async getData(options) {
        const [error, data = []] = await this.api.where(this.key, x => this.config(x.paginate(options.count), options.text.replaceAll("'", "''")), __filename);
        if (error)
            log.prod.error(__filename, error);
        return data;
    }
}
const ODataInlineLookupComponent = defineFunctionalComponent({
    ...omit(props, 'dataProvider'),
    parameters: {
        type: Object,
        required: true,
    },
}, (props, options) => h(resolveComponent('s-inline-lookup'), {
    ...options.attrs,
    ...props,
    dataProvider: new ODataInlineLookupProvider(props.parameters.api, props.parameters.key, props.parameters.config),
}, options.slots));
export default ODataInlineLookupComponent;
