import { services } from '@fbc/core/services';
import { defineFunctionalComponent, isNotNullOrUndefined } from '@fbc/core/utils';
import { computed, h, markRaw, ref, resolveComponent, watch } from 'vue';
export const useDataTableSelection = (props, emit, getId, visibleItems) => {
    const { i18n } = services;
    const selectedAll = computed({
        get: () => {
            if (isNotNullOrUndefined(props.selectedAll))
                return props.selectedAll;
            if (props.items.length !== props.modelValue.length)
                return false;
            const modelValueIds = new Set(props.modelValue.map(getId));
            return props.items.every(x => modelValueIds.has(getId(x)));
        },
        set: value => {
            emit('update:modelValue', value ? props.items : []);
            emit('update:selectedAll', value);
        },
    });
    const lastSelectedEntity = ref();
    const shouldShowSelectionCheckboxes = computed(() => props.selectionMode === 'multiple' && props.showSelectionCheckboxes);
    const selectionColumn = computed(() => ({
        field: 'select',
        sortable: false,
        filterable: false,
        width: 40,
        cellTemplate: markRaw(defineFunctionalComponent({
            entity: { type: Object, required: true },
        }, ({ entity }) => h(resolveComponent('s-checkbox'), {
            modelValue: props.modelValue.some(x => getId(x) === getId(entity)),
            style: '--checkbox-clickable-area: 40px; --checkbox-size: 8px',
            'aria-label': i18n.global('select'),
            'onUpdate:modelValue': () => select(entity, { ctrlKey: true }),
            onClick: (event) => event.stopPropagation(),
            onDblclick: (event) => event.stopPropagation(),
            onContextmenu: (event) => event.stopPropagation(),
        }))),
        headerTemplate: props.selectionMode === 'multiple' && props.showSelectAllCheckbox && !props.loading && props.items.length
            ? markRaw(defineFunctionalComponent({}, () => h('div', h('div', {
                class: 's-data-table__header-cell__container s-data-table__header-cell__container--no-default',
            }, h(resolveComponent('s-checkbox'), {
                modelValue: selectedAll.value,
                style: '--checkbox-clickable-area: 40px; --checkbox-size: 8px',
                'aria-label': i18n.global('select-all'),
                'onUpdate:modelValue': (value) => {
                    selectedAll.value = value;
                    emit('click:selectAll', value);
                },
            })))))
            : undefined,
        align: {
            horizontal: 'center',
        },
    }));
    function select(entity, options = {}) {
        if (!props.selectionMode)
            return;
        if (props.modelValue.includes(entity)) {
            if (props.selectionMode === 'multiple' && !options.ctrlKey)
                emit('update:modelValue', []);
            else
                emit('update:modelValue', props.modelValue.filter(x => x !== entity));
        }
        else {
            switch (props.selectionMode) {
                case 'single':
                    emit('update:modelValue', [entity]);
                    break;
                case 'multiple':
                    if (options.shiftKey) {
                        const entityIndex = visibleItems.value.indexOf(entity);
                        const lastSelectedEntityIndex = visibleItems.value.indexOf(lastSelectedEntity.value);
                        const selectedEntities = [];
                        for (let i = Math.min(entityIndex, lastSelectedEntityIndex); i <= Math.max(entityIndex, lastSelectedEntityIndex); i++) {
                            const e = visibleItems.value[i];
                            if (!props.modelValue.includes(e))
                                selectedEntities.push(e);
                        }
                        if (selectedEntities.length)
                            emit('update:modelValue', [...props.modelValue, ...selectedEntities]);
                    }
                    else if (options.ctrlKey) {
                        emit('update:modelValue', [...props.modelValue, entity]);
                    }
                    else {
                        emit('update:modelValue', [entity]);
                    }
                    break;
                default:
                    break;
            }
            lastSelectedEntity.value = entity;
        }
        emit('select', entity, options);
    }
    watch(() => props.selectedAll, value => {
        if (!isNotNullOrUndefined(value) || selectedAll.value === value)
            return;
        selectedAll.value = value;
    });
    return {
        shouldShowSelectionCheckboxes,
        selectionColumn,
        select,
    };
};
