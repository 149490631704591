import { ref, watch } from 'vue';
export const useHorizontalScroll = (activeTabSelector) => {
    const tabsListElement = ref();
    const resizeObserver = new ResizeObserver(() => recalculateButtonsVisible());
    const isBackButtonVisible = ref(false);
    const isForwardButtonVisible = ref(false);
    const recalculateButtonsVisible = () => {
        const listElement = tabsListElement.value?.$el;
        isForwardButtonVisible.value = Boolean(listElement &&
            listElement.scrollWidth > listElement.clientWidth &&
            listElement.scrollWidth - listElement.clientWidth > listElement.scrollLeft);
        isBackButtonVisible.value =
            !!listElement && listElement.scrollWidth > listElement.clientWidth && listElement.scrollLeft > 0;
    };
    const useWheelScrolling = (e) => {
        e.preventDefault();
        if (!tabsListElement.value?.$el)
            return;
        tabsListElement.value.$el.scrollLeft += e.deltaY;
        recalculateButtonsVisible();
    };
    const scrollToActiveTab = (activeTabMarginRight = 50) => {
        const listElement = tabsListElement.value?.$el;
        const activeTab = tabsListElement.value?.$el.querySelector(activeTabSelector);
        if (!activeTab || !listElement)
            return;
        const listElementRect = listElement.getBoundingClientRect();
        const activeTabRect = activeTab.getBoundingClientRect();
        listElement.scrollBy(activeTabRect.left -
            listElementRect.left -
            (listElement.clientWidth - activeTab.clientWidth) +
            activeTabMarginRight, 0);
    };
    watch(() => tabsListElement.value, (newElement, oldElement) => {
        unregister(oldElement);
        register(newElement);
    });
    const register = (element) => {
        if (!element?.$el)
            return;
        element.$el.addEventListener('wheel', useWheelScrolling, { passive: false });
        element.$el.addEventListener('scroll', recalculateButtonsVisible, { passive: true });
        resizeObserver.observe(element.$el);
    };
    const unregister = (element) => {
        if (!element?.$el)
            return;
        element.$el.removeEventListener('wheel', useWheelScrolling);
        element.$el.removeEventListener('scroll', recalculateButtonsVisible);
        resizeObserver.unobserve(element.$el);
    };
    return {
        tabsListElement,
        isBackButtonVisible,
        isForwardButtonVisible,
        scrollToActiveTab,
        register,
        unregister,
        recalculateButtonsVisible,
    };
};
