import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "s-section" };
const _hoisted_2 = ["textContent"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("h1", null, [
            _renderSlot(_ctx.$slots, "header", {}, () => [
                (_ctx.caption)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        textContent: _toDisplayString(_ctx.caption)
                    }, null, 8 /* PROPS */, _hoisted_2))
                    : _createCommentVNode("v-if", true),
                _renderSlot(_ctx.$slots, "header-right")
            ])
        ]),
        _createElementVNode("div", {
            class: _normalizeClass(["s-section__body", _ctx.bodyClass])
        }, [
            _renderSlot(_ctx.$slots, "default")
        ], 2 /* CLASS */)
    ]));
}
