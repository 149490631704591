import { services } from '@fbc/core/services';
import { isNotNullOrUndefined } from '../guards';
import { Duration } from 'luxon';
/**
 * Выводит продолжительность
 * @param duration продолжительность объектом или количество миллисекунд
 */
export default (duration) => {
    if (!isNotNullOrUndefined(duration))
        return '';
    const durationObject = typeof duration === 'string'
        ? Duration.fromMillis(Number.parseInt(duration, 10) * 60 * 1000)
        : typeof duration === 'number'
            ? Duration.fromMillis(Math.round(duration) * 60 * 1000)
            : duration;
    if (!durationObject.isValid)
        return '';
    const { i18n } = services;
    return durationObject.as('minutes') <= 60
        ? durationObject.toFormat(`mm '${i18n.resource('global.date', 'minutes--short-hand')}'`)
        : durationObject.toFormat(`hh '${i18n.resource('global.date', 'hours--short-hand')} ' mm '${i18n.resource('global.date', 'minutes--short-hand')}'`);
};
