/**
 * Форматирует значение как деньги
 *
 * formatFinanceValue(3) -> "3,00"
 *
 * formatFinanceValue(3.12312) -> "3,12"
 *
 * @param value значение
 * @param noValue если исходное значение falsy вовращает это значение
 * @param [count=2] кол-во символов после запятой
 */
export const formatFinanceValue = (value, noValue = '', count = 2) => value
    ? value
        .toFixed(count)
        .replace(/(?=(\d{3})+\.)/g, ' ')
        .replace('.', ',')
        .trim()
    : noValue;
