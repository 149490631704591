import { services } from '@fbc/core/services';
import { DataCache } from '@fbc/core/use-cases';
import { isNotNullOrUndefined, isPropertyNotNullOrUndefined } from '@fbc/core/utils';
import { chunk } from 'lodash';
export class RawEndlessDataTableLoader {
    _pageSize;
    loader;
    cache;
    _currentPage = 1;
    _totalResults = 0;
    _pageCount = 0;
    _canAffectTotalResults = true;
    /**
     * @constructor
     * @param _pageSize Количество записей загружаемых для одной страницы
     * @param loader Функция для подгрузки данных для таблицы
     * @param cache Кеш
     */
    constructor(_pageSize, loader, cache = new DataCache()) {
        this._pageSize = _pageSize;
        this.loader = loader;
        this.cache = cache;
    }
    // Установить кэш для таблицы
    setCache(cache) {
        this.cache = cache;
    }
    /// Очистка кеша
    clearCache() {
        this.cache?.clear();
    }
    set pageSize(pageSize) {
        this._pageSize = pageSize;
    }
    get pageSize() {
        return this._pageSize;
    }
    set currentPage(page) {
        this._currentPage = page;
    }
    get currentPage() {
        return this._currentPage;
    }
    get totalResults() {
        return this._totalResults;
    }
    get pageCount() {
        return this._pageCount;
    }
    get canAffectTotalResults() {
        return this._canAffectTotalResults;
    }
    set canAffectTotalResults(value) {
        this._canAffectTotalResults = value;
    }
    init = (data, totalResults) => {
        this.currentPage = 1;
        this.setTotalResults(totalResults);
        const { cache } = this;
        if (!isNotNullOrUndefined(cache))
            return;
        const chunks = chunk(data, this._pageSize);
        chunks.forEach((data, index) => {
            const page = this.currentPage + index;
            cache.set(page, data);
        });
    };
    getData = (parameters) => {
        const pagesToLoad = [];
        if (this._currentPage >= 2)
            pagesToLoad.push(this._currentPage - 1);
        pagesToLoad.push(this._currentPage);
        if (this._canAffectTotalResults || this._currentPage + 1 <= Math.ceil(this._totalResults / this._pageSize))
            pagesToLoad.push(this._currentPage + 1);
        return this.getPagesData(pagesToLoad, parameters).finally(() => {
            this.canAffectTotalResults = false;
        });
    };
    getPagesData = async (pages, parameters) => {
        const pagesToFetch = pages.filter(page => !this.cache?.has(page));
        if (!pagesToFetch.length) {
            return pages.reduce((acc, page) => acc.concat(this.cache?.get(page)?.value ?? []), []);
        }
        const [firstPage] = pagesToFetch;
        const loaderData = await this.loader((firstPage - 1) * this._pageSize, this._pageSize * pagesToFetch.length, parameters);
        this.setTotalResults(loaderData.totalResults);
        if (!isNotNullOrUndefined(this.cache))
            return loaderData.data;
        const { cache } = this;
        const chunks = chunk(loaderData.data, this._pageSize);
        chunks.forEach((data, index) => {
            const page = firstPage + index;
            cache.set(page, data);
        });
        return pages.reduce((acc, page) => acc.concat(this.cache?.get(page)?.value ?? []), []);
    };
    setTotalResults(totalResults) {
        if (!isNotNullOrUndefined(totalResults))
            return;
        this._totalResults = totalResults;
        this._pageCount = Math.max(Math.ceil(totalResults / this._pageSize), 1);
        if (this._currentPage > this._pageCount)
            this._currentPage = this._pageCount;
    }
}
export class DataApiEndlessDataTableLoader extends RawEndlessDataTableLoader {
    _columns;
    getMethod;
    parameters;
    api = new services.dataApi();
    /**
     * @constructor
     * @param _pageSize Количество записей загружаемых для одной страницы
     * @param _columns Загружаемые поля таблицы
     * @param getMethod Метод загрузки данных через Data api
     * @param parameters Параметры для загрузки данных
     */
    constructor(_pageSize, _columns, getMethod, parameters, cache = new DataCache()) {
        super(_pageSize, () => new Promise(() => ({ data: [] })), cache);
        this._columns = _columns;
        this.getMethod = getMethod;
        this.parameters = parameters;
        this.loader = this.load;
    }
    // Функция для подгрузки данных для таблицы
    setLoadFunction(loader) {
        this.loader = loader;
    }
    set columns(columns) {
        this._columns = columns;
        this._currentPage = 1;
        this.cache?.clear();
    }
    get columns() {
        return this._columns;
    }
    sort = () => this._columns
        .filter(isPropertyNotNullOrUndefined('sortIndex'))
        .sort((a, b) => a.sortIndex - b.sortIndex)
        .map(x => ({ fieldName: x.field, sortOrder: x.sortOrder }))
        .filter(isPropertyNotNullOrUndefined('sortOrder'));
    load = async (startIndex, count) => {
        if (!isNotNullOrUndefined(this.getMethod)) {
            log.dev.error('Не установлен метод загрузки данных');
            return { data: [] };
        }
        const [error, result = [], meta] = await this.api.get(this.getMethod, this.parameters, __filename, {
            pick: this.columns.map(x => x.field),
            count,
            startIndex,
            totalResults: this._pageCount < 1,
            sort: this.sort(),
        });
        if (error)
            log.prod.error(__filename, error);
        return { data: result, totalResults: meta?.totalResults ?? undefined };
    };
}
