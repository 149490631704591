import { services } from '@fbc/core/services';
import { filters } from '@fbc/core/utils';
import { get } from 'lodash';
import { DateTime } from 'luxon';
export function formatCellValue(entity, fieldName, formatter, defaultValue = '') {
    const value = get(entity, fieldName.toString().replace('/', '.'));
    if (formatter)
        return formatter(value, entity);
    if (value === null || value === undefined)
        return defaultValue;
    switch (value.constructor) {
        case DateTime:
            return filters.formatLocal(value, 'dd.MM.yyyy');
        case Number:
            return value.toLocaleString();
        case Boolean:
            return services.i18n.resource('global', value ? 'yes' : 'no');
        default:
            return value;
    }
}
