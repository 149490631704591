import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = ["for"];
const _hoisted_2 = ["id", "placeholder", "readonly", "disabled", "required", "rows", "value"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(_ctx.mergeClass(_ctx.rootClasses, _ctx.$attrs.class, _ctx.name)),
        style: _normalizeStyle(_ctx.$attrs.style)
    }, [
        (!_ctx.noLabel)
            ? (_openBlock(), _createElementBlock("label", {
                key: 0,
                class: _normalizeClass(_ctx.labelClasses),
                for: _ctx.id
            }, _toDisplayString(_ctx.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_1))
            : _createCommentVNode("v-if", true),
        _createElementVNode("textarea", _mergeProps({
            id: _ctx.id,
            placeholder: _ctx.placeholder,
            readonly: _ctx.readonly,
            disabled: _ctx.disabled,
            required: _ctx.required,
            rows: _ctx.mutableRows && _ctx.maxRows && _ctx.mutableRows > Number(_ctx.maxRows) ? _ctx.maxRows : _ctx.mutableRows,
            dir: "auto"
        }, _ctx.attributes, { value: _ctx.mutableValue }), null, 16 /* FULL_PROPS */, _hoisted_2),
        (_ctx.counter)
            ? (_openBlock(), _createElementBlock("label", {
                key: 1,
                "aria-hidden": "true",
                class: _normalizeClass(_ctx.name + '__counter')
            }, _toDisplayString(_ctx.counterText), 3 /* TEXT, CLASS */))
            : _createCommentVNode("v-if", true)
    ], 6 /* CLASS, STYLE */));
}
