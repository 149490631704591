/** Только одна ветка дерева будет открыта за раз, при открытии другой первая - закрывается */
export const openOnlyOneNode = (node) => {
    node.opened = !node.opened;
    if (!node.opened)
        return;
    for (const childs of node.parent?.childs.filter(x => x.opened && x !== node) ?? []) {
        childs.opened = false;
    }
};
/** Будут отображаться около n узлов.
 * При попытке открытия узла, после достижения лимита какой-нибудь другой узел закроется.
 */
export const openOnlyNumNodes = (node, n) => {
    node.opened = !node.opened;
    if (!node.opened)
        return;
    let opened = node.visibleChilds.length;
    const visitedUpNodes = new Set();
    visitedUpNodes.add(node);
    node.traversal(item => {
        opened += item.visibleChilds.length;
        visitedUpNodes.add(item);
        item.traversal(x => {
            if (visitedUpNodes.has(x))
                return;
            if (opened > n)
                x.opened = false;
            if (x.opened)
                opened += x.visibleChilds.length;
        }, 'breadth');
    }, 'up');
};
