import { DateTime } from 'luxon';
/**
 * Конвертирует строку с датой, которая пришла из OData в объект DateTime
 * @param value строка вида "\/Date(1587105301983)\/"
 */
export function dateTimeFromODataDate(value) {
    if (value === undefined)
        return undefined;
    if (!value)
        return null;
    if (typeof value !== 'string')
        return value;
    return DateTime.fromISO(value).toUTC();
}
export const getErrorMessage = (e) => e
    ? (typeof e === 'object' ? (e.innerException ? getErrorMessage(e.innerException) : e.message) : e) || e.message
    : 'No error';
/**
 * Вычисляет очень примерный размер объекта в JS
 * @param object Объект
 */
export function sizeOfObject(object) {
    const objectList = [];
    const stack = [object];
    let bytes = 0;
    while (stack.length) {
        const value = stack.pop();
        if (typeof value === 'boolean') {
            bytes += 4;
        }
        else if (typeof value === 'string') {
            bytes += value.length * 2;
        }
        else if (typeof value === 'number') {
            bytes += 8;
        }
        else if (typeof value === 'object' && !objectList.includes(value)) {
            objectList.push(value);
            for (const i in value) {
                stack.push(value[i]);
            }
        }
    }
    return bytes;
}
