export function useSlimLocker() {
    const promises = [];
    const callbacks = [];
    return {
        /** Ожидает, когда локер будет разблокирован. После чего блокирует его и продолжает выполнение функции */
        wait() {
            const promise = new Promise(resolve => callbacks.push(resolve));
            promises.push(promise);
            return promises.length < 2 ? Promise.resolve() : promises[promises.length - 2];
        },
        /** Освобождает локер */
        release() {
            const callback = callbacks.shift();
            if (callback) {
                callback();
                promises.shift();
            }
        },
    };
}
/** Оборачивает функцию, чтобы она выполнялась последовательно */
export function useSlimFnLocker(callback) {
    const locker = useSlimLocker();
    return async () => {
        await locker.wait();
        const result = await callback();
        locker.release();
        return result;
    };
}
