import { services } from '@fbc/core/services';
import { isNotNullOrUndefined } from '@fbc/core/utils';
import { computed, ref } from 'vue';
export const addLoading = (context) => {
    const { filters } = context;
    const typesWithValue = new Set(['=', '!=', 'starts', 'ends', 'contains']);
    const typesWithOutValue = new Set(['empty', 'not empty']);
    const isFilterValid = (x) => {
        switch (x.type) {
            case 'number':
            case 'dateTime':
                return isNotNullOrUndefined(x.to) || isNotNullOrUndefined(x.from);
            case 'notInList':
            case 'list':
                return Boolean(x.list?.length);
            case 'text':
                return x.value ? typesWithValue.has(x.op) : typesWithOutValue.has(x.op);
            case 'equal':
                return isNotNullOrUndefined(x.value);
            case 'notEqual':
                return isNotNullOrUndefined(x.value);
        }
    };
    const validFilters = computed(() => filters.value.filter(isFilterValid));
    const loadParameters = {
        filters: validFilters,
        sort: ref([]),
        pick: ref([]),
    };
    const load = createLoadFunction(context, loadParameters);
    load();
    return { ...context, load, loadParameters };
};
const createLoadFunction = (context, parameters) => {
    const { dataProvider, columns, emit, pageSize, loading, pagesCount, currentPage, countChanged, filtersChanged, items, totalResults, } = context;
    const { toast } = services;
    const logResult = (rest) => {
        if (rest?.features) {
            if (!rest.features.pagination) {
                log.dev.error(__filename, 'Пагинация не настроена в апи для ' + dataProvider.value.methodName);
                if (process.env.NODE_ENV === 'development')
                    toast.error('Пагинация не настроена в апи для ' + dataProvider.value.methodName);
            }
            if (!rest.features.sort) {
                log.dev.error(__filename, 'Сортировка не настроена в апи для ' + dataProvider.value.methodName);
                if (process.env.NODE_ENV === 'development')
                    toast.error('Сортировка не настроена в апи для ' + dataProvider.value.methodName);
            }
            if (!rest.features.filters) {
                log.dev.error(__filename, 'Фильтры не настроены в апи для ' + dataProvider.value.methodName);
                if (process.env.NODE_ENV === 'development')
                    toast.error('Фильтры не настроены в апи для ' + dataProvider.value.methodName);
            }
        }
    };
    return async () => {
        if (loading.isLocked.value)
            return;
        loading.lock();
        parameters.sort.value = columns.value
            .filter(x => x.sortOrder)
            .sort((a, b) => (b.sortIndex ?? 0) - (a.sortIndex ?? 0))
            .map(x => ({ fieldName: (x.fieldPrefix ?? '') + x.field, sortOrder: x.sortOrder ?? 'asc' }));
        parameters.pick.value = columns.value.map(x => x.field);
        const [error, data = [], rest] = await dataProvider.value.getData({
            page: {
                size: pageSize,
                count: pagesCount.value,
                num: currentPage.value,
                totalResults: countChanged.value || filtersChanged.value,
            },
            filters: parameters.filters.value,
            sort: parameters.sort.value,
            pick: parameters.pick.value,
        });
        logResult(rest);
        if (error)
            log.prod.error(__filename, error);
        items.value = data;
        if (rest && isNotNullOrUndefined(rest.totalResults)) {
            totalResults.value = rest.totalResults;
            pagesCount.value = Math.ceil(rest.totalResults / pageSize);
        }
        loading.unlock();
        filtersChanged.value = false;
        countChanged.value = false;
        emit('dataLoaded', items.value);
    };
};
