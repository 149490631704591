import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_s_data_table = _resolveComponent("s-data-table");
    const _component_s_button = _resolveComponent("s-button");
    const _component_s_modal = _resolveComponent("s-modal");
    return (_openBlock(), _createBlock(_component_s_modal, {
        modelValue: _ctx.visible,
        caption: _ctx.caption,
        width: 500,
        centralize: "",
        mobileFullscreen: "",
        style: { "--modal-footer-button-min-width": "100px" },
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('update:visible', $event)))
    }, {
        footer: _withCtx(() => [
            _createVNode(_component_s_button, {
                loading: _ctx.tabsSettingModule.loading.isLocked,
                disabled: _ctx.tabsSettingModule.loading.isLocked,
                success: "",
                onClick: _ctx.okClick
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$i18n.resource('global', 'ok')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
            }, 8 /* PROPS */, ["loading", "disabled", "onClick"]),
            _createVNode(_component_s_button, {
                disabled: _ctx.tabsSettingModule.loading.isLocked,
                error: "",
                onClick: _ctx.resetClick
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.local('reset')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
            }, 8 /* PROPS */, ["disabled", "onClick"]),
            _createVNode(_component_s_button, { onClick: _ctx.cancelClick }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$i18n.resource('global', 'cancel')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"])
        ]),
        default: _withCtx(() => [
            _createVNode(_component_s_data_table, {
                items: _ctx.sortedSettings,
                columns: _ctx.settingsColumns,
                idField: "id",
                style: { "--body-max-height": "calc(100dvh - 190px)" }
            }, null, 8 /* PROPS */, ["items", "columns"])
        ]),
        _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "caption"]));
}
