import { authFetch } from './authenticate-fetch';
function toResult(response) {
    return response.status === 204
        ? [undefined, null]
        : response
            .json()
            .then(result => response.ok ? [undefined, result] : [result, undefined]);
}
export const getCurrentUser = () => authFetch(window['fb-base-api-path'] + '/api/CurrentUser', {
    method: 'GET',
    headers: [['content-type', 'application/json']],
    credentials: 'include',
})
    .catch(reason => [reason, undefined])
    .then(response => (response instanceof Response ? toResult(response) : response))
    .catch(reason => [reason, undefined]);
export const getSecurityProfile = (seccodeId) => authFetch(window['fb-base-api-path'] + '/api/CurrentUser/securityProfile?seccodeId=' + seccodeId, {
    method: 'GET',
    headers: [['content-type', 'application/json']],
    credentials: 'include',
})
    .catch(reason => [reason, undefined])
    .then(response => (response instanceof Response ? toResult(response) : response))
    .catch(reason => [reason, undefined]);
