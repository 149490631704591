async function loadScript(url) {
    await new Promise((resolve, reject) => {
        const element = document.createElement('script');
        element.src = url;
        element.type = 'text/javascript';
        element.async = true;
        element.onload = () => {
            element?.parentElement?.removeChild(element);
            resolve();
        };
        element.onerror = error => {
            element?.parentElement?.removeChild(element);
            reject(error);
        };
        document.head.appendChild(element);
    });
    const module = window.fb_module;
    window.fb_module = undefined;
    return module;
}
export async function loadWebpackModule(url, module = 'default') {
    await __webpack_init_sharing__('default');
    const container = await loadScript(url);
    await container.init(__webpack_share_scopes__.default);
    const factory = await container.get(module);
    return factory();
}
