import { services } from '@fbc/core/services';
import { useSemaphore } from '@fbc/core/storage';
import { parseJson } from '@fbc/core/utils';
import { isEqual } from 'lodash';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
const useDragAndDrop = (settings, save) => {
    const draggedTab = ref();
    const draggedTabElement = ref();
    const draggedTabSetting = computed(() => settings.value?.[draggedTab.value?.id ?? '']);
    const draggedTabSize = computed(() => {
        const rect = draggedTabElement.value?.getBoundingClientRect();
        return {
            width: (rect?.width ?? 0).toString() + 'px',
            height: (rect?.height ?? 0).toString() + 'px',
        };
    });
    const draggedOverTab = ref();
    const draggedOverTabSetting = computed(() => settings.value?.[draggedOverTab.value?.id ?? '']);
    const isDragging = ref(false);
    const onDragStart = (event, tab) => {
        isDragging.value = true;
        draggedTab.value = tab;
        event.stopPropagation();
        const target = event.target;
        target.style.opacity = '0.5';
        draggedTabElement.value = target;
        const dragImage = new Image();
        dragImage.src = '';
        event.dataTransfer?.setDragImage(dragImage, 0, 0);
    };
    const isDraggingOverLeftSide = ref(false);
    const isDraggingOverRightSide = ref(false);
    const isNumberInInterval = (value, [leftBorder, rightBorder]) => leftBorder <= value && value <= rightBorder;
    const onDragOver = (event, tab) => {
        event.preventDefault();
        const target = event.target;
        if (target.dataset.tabType === 'shadow')
            return;
        draggedOverTab.value = tab;
        const targetRect = target.getBoundingClientRect();
        const targetLeftSide = [targetRect.left, targetRect.left + targetRect.width / 2];
        const targetRightSide = [targetRect.right - targetRect.width / 2, targetRect.right];
        isDraggingOverLeftSide.value = isNumberInInterval(event.x, targetLeftSide);
        isDraggingOverRightSide.value = isNumberInInterval(event.x, targetRightSide);
    };
    const onDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (!draggedTab.value ||
            !draggedOverTab.value ||
            !settings.value ||
            draggedTab.value.id === draggedOverTab.value.id ||
            (!isDroppingBack.value && !isDroppingForward.value))
            return;
        const sortedTabNames = Object.keys(settings.value).sort((a, b) => (settings.value?.[a]?.order ?? 0) - (settings.value?.[b]?.order ?? 0));
        const draggedTabName = draggedTab.value.id;
        const draggedOverTabName = draggedOverTab.value.id;
        const [splicedDraggedTab] = sortedTabNames.splice(sortedTabNames.indexOf(draggedTabName), 1);
        const draggedOverTabIndex = sortedTabNames.indexOf(draggedOverTabName);
        sortedTabNames.splice(isDroppingBack.value ? draggedOverTabIndex : draggedOverTabIndex + 1, 0, splicedDraggedTab);
        for (const [index, tabName] of sortedTabNames.entries()) {
            settings.value[tabName].order = index;
        }
    };
    const onDragEnd = () => {
        if (draggedTab.value && draggedOverTab.value && draggedTab.value.id !== draggedOverTab.value.id)
            save();
        draggedTab.value = undefined;
        draggedOverTab.value = undefined;
        isDraggingOverLeftSide.value = false;
        isDraggingOverRightSide.value = false;
        isDragging.value = false;
        if (draggedTabElement.value)
            draggedTabElement.value.style.removeProperty('opacity');
    };
    const isDroppingBack = computed(() => isDragging.value &&
        isDraggingOverLeftSide.value &&
        (draggedTabSetting.value.order > draggedOverTabSetting.value.order ||
            draggedOverTabSetting.value.order - draggedTabSetting.value.order > 1));
    const isDroppingForward = computed(() => isDragging.value &&
        isDraggingOverRightSide.value &&
        (draggedTabSetting.value.order < draggedOverTabSetting.value.order ||
            draggedTabSetting.value.order - draggedOverTabSetting.value.order > 1));
    return {
        draggedTab,
        draggedTabSetting,
        draggedTabSize,
        draggedOverTab,
        draggedOverTabSetting,
        isDragging,
        onDragStart,
        onDragOver,
        onDrop,
        onDragEnd,
        isDroppingBack,
        isDroppingForward,
    };
};
export const useTabsSetting = (scope, entityName) => defineStore(`tabs-settings-${scope}`, () => {
    const settings = ref({});
    const defaultSettings = ref({});
    const tabTitles = ref({});
    const loading = useSemaphore();
    const { store } = services;
    const load = async (tabs) => {
        if (!entityName.value) {
            settings.value = mapTabsToSettings(tabs);
            return;
        }
        loading.lock();
        const tabsSettingsUserOption = await store.userOptions.load('TABS_SETTINGS', entityName.value, __filename, true);
        loading.unlock();
        tabTitles.value = tabs.reduce((acc, tab) => Object.assign(acc, { [tab.id]: tab.title }), {});
        defaultSettings.value = mapTabsToSettings(tabs);
        if (!tabsSettingsUserOption) {
            settings.value = mapTabsToSettings(tabs);
            return;
        }
        const parsedTabsSettingsUserOptions = parseJson(tabsSettingsUserOption?.value, (data) => typeof data === 'object' &&
            (Object.keys(data ?? {}).length === 0 ||
                (!!data && 'order' in data[Object.keys(data)[0]] && 'visible' in data[Object.keys(data)[0]]))) ?? {};
        settings.value = mapTabsToSettings(tabs, parsedTabsSettingsUserOptions);
    };
    const mapTabsToSettings = (tabs, existingTabsSettings = null) => tabs.reduce((settingsMap, tab, index) => Object.assign(settingsMap, {
        [tab.id]: existingTabsSettings?.[tab.id] ?? {
            visible: true,
            order: index,
        },
    }), {});
    const save = async () => {
        if (!settings.value)
            return;
        loading.lock();
        if (isEqual(settings.value, defaultSettings.value)) {
            await store.userOptions.delete({
                category: 'TABS_SETTINGS',
                name: entityName.value,
            }, __filename);
        }
        else {
            await store.userOptions.update({
                category: 'TABS_SETTINGS',
                name: entityName.value,
                value: JSON.stringify(settings.value),
            }, __filename);
        }
        loading.unlock();
    };
    return {
        settings,
        defaultSettings,
        tabTitles,
        load,
        loading,
        save,
        ...useDragAndDrop(settings, save),
    };
});
