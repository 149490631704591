import { renderSlot as _renderSlot, mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = { class: "s-checkbox__clickable-area" };
const _hoisted_2 = ["id", "readonly", "disabled", "value"];
const _hoisted_3 = ["for", "textContent"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["s-checkbox", _ctx.mergeClass(_ctx.rootClasses, _ctx.$attrs.class)]),
        style: _normalizeStyle(_ctx.$attrs.style)
    }, [
        _renderSlot(_ctx.$slots, "left"),
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("i", {
                class: _normalizeClass(["s-checkbox__icon", _ctx.checkboxClasses])
            }, [
                _createElementVNode("input", _mergeProps({
                    id: _ctx.id,
                    type: "checkbox",
                    readonly: _ctx.readonly || _ctx.disabled,
                    disabled: _ctx.readonly || _ctx.disabled
                }, _ctx.attributes, { value: _ctx.modelValue }), null, 16 /* FULL_PROPS */, _hoisted_2)
            ], 2 /* CLASS */)
        ]),
        _renderSlot(_ctx.$slots, "default", {}, () => [
            (_ctx.label)
                ? (_openBlock(), _createElementBlock("label", {
                    key: 0,
                    class: "s-checkbox__label",
                    for: _ctx.id,
                    textContent: _toDisplayString(_ctx.label)
                }, null, 8 /* PROPS */, _hoisted_3))
                : _createCommentVNode("v-if", true)
        ])
    ], 6 /* CLASS, STYLE */));
}
