import { defineStore } from 'pinia';
import { h } from 'vue';
export const getModalBody = (icon, iconColor, fillColor, cfg) => h('div', {
    class: cfg.innerClasses,
    style: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        whiteSpace: 'pre-wrap',
    },
}, [
    h('i', {
        style: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 'var(--icon-xl)',
            width: 'var(--icon-xl)',
            background: fillColor,
            borderRadius: 'var(--sm)',
        },
    }, [
        h('i', {
            class: icon,
            style: { fontSize: 'var(--icon-md)', color: iconColor, position: 'relative' },
        }),
    ]),
    h('div', {
        style: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 'var(--md)',
            fontFamily: 'var(--font-regular)',
            textAlign: 'center',
            whiteSpace: 'pre-wrap',
        },
    }, [
        h('div', {
            style: {
                fontSize: cfg.caption && cfg.body ? 'var(--font-lg)' : 'var(--font-md)',
                lineHeight: 1.5,
                overflowWrap: 'anywhere',
            },
            innerHTML: cfg.caption ?? cfg.body,
        }),
        cfg.caption && cfg.body
            ? h('div', {
                style: {
                    fontSize: 'var(--font-md)',
                    paddingTop: 'var(--md)',
                    display: 'flex',
                    alignItems: 'center',
                    overflowX: 'auto',
                    lineHeight: 1.5,
                },
                innerHTML: cfg.body,
            })
            : undefined,
        cfg.additional
            ? h('div', {
                style: { fontSize: 'var(--font-sm)', paddingTop: 'var(--xs)', lineHeight: 1.5 },
                innerHTML: cfg.additional,
            })
            : undefined,
    ]),
]);
export const typeMap = (type) => {
    const { icon, iconColor, fillColor } = { ...typeMapper[type] };
    return { icon, iconColor, fillColor };
};
export const typeMapper = {
    warning: {
        icon: 'fb-common-info',
        iconColor: 'var(--on-warning)',
        fillColor: 'var(--warning)',
    },
    delete: {
        icon: 'fb-common-recycle-bin',
        iconColor: 'var(--on-error)',
        fillColor: 'var(--error-container)',
    },
    error: {
        icon: 'fb-common-close',
        iconColor: 'var(--on-error)',
        fillColor: 'var(--error-container)',
    },
    success: {
        icon: 'fb-common-done',
        iconColor: 'var(--on-success)',
        fillColor: 'var(--success-container)',
    },
};
export const useDialogsStore = defineStore({
    id: 'dialogs',
    state: () => ({
        alerts: [],
        confirms: [],
    }),
    actions: {
        addAlert(alert) {
            let resolve;
            const promise = new Promise(p => {
                resolve = p;
            });
            this.alerts.push({ alert, resolve: resolve, promise });
            return promise;
        },
        removeAlert(alert) {
            const record = this.alerts.find(x => x.alert === alert);
            record?.resolve(alert);
            this.alerts = this.alerts.filter(x => x !== record);
        },
        addConfirm(confirm) {
            let resolve;
            const promise = new Promise(p => {
                resolve = p;
            });
            this.confirms.push({ confirm, resolve: resolve, promise });
            return promise;
        },
        removeConfirm(confirm, result) {
            const record = this.confirms.find(x => x.confirm === confirm);
            record?.resolve(result ?? false);
            this.confirms = this.confirms.filter(x => x !== record);
        },
    },
});
