import { createFocusTrap } from 'focus-trap';
import { ref } from 'vue';
export const useFocusTrap = () => {
    const focusTrap = ref();
    function activateFocusTrap(element) {
        if (!element)
            return;
        try {
            if (!focusTrap.value)
                focusTrap.value = createFocusTrap(element, {
                    escapeDeactivates: false,
                    allowOutsideClick: () => true,
                });
            focusTrap.value.activate();
        }
        catch {
            log.dev.warn(__filename, 'Focus trap cannot be activated while element has no focusable childs. Add focusable child to template');
        }
    }
    function deactivateFocusTrap() {
        try {
            focusTrap.value?.deactivate();
        }
        catch (error) {
            if (error instanceof Error && !error.message.includes('at least one tabbable node'))
                log.prod.error(__filename, error);
        }
    }
    return { activateFocusTrap, deactivateFocusTrap };
};
