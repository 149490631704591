import { authFetch } from './api/authenticate-fetch';
/**
 * FBConsult Web API
 */
export class WebApi {
    path;
    headers = {
        'content-type': 'application/json',
        'cache-control': 'no-cache, no-store, must-revalidate',
    };
    /**
     * @constructor
     * @param path Путь к Web API
     */
    constructor(path = window['fb-base-api-path'] + '/api/') {
        this.path = path;
    }
    /**
     * Вызывает GET-запрос в FbConsult.Web.Api. Типы заполнять в {@link GetMethods}
     * @param path Название метода, например system/urlEncode1251
     * @param parameters Список Параметров
     * @param [fileName] название файла для логгирования, с его помощью можно фильтровать запросы в консоли
     * @param [returnResponse] Вернуть ли Response не обработанным, например, для получения файлов
     * @param [ulrProxy] Сервис переправит запрос по этому адресу
     */
    get(path, parameters, fileName, returnResponse = false, ulrProxy) {
        return this.call(ulrProxy ? 'proxy/get' : path, 'get', ulrProxy
            ? Object.assign(parameters, { fbUrl: encodeURIComponent(ulrProxy) })
            : parameters, fileName, returnResponse);
    }
    /**
     * Вызывает Post-запрос в FbConsult.Web.Api. Типы заполнять в {@link PostMethods}
     * @param path Название метода, например system/log
     * @param parameters Список Параметров
     * @param [fileName] название файла для логгирования, с его помощью можно фильтровать запросы в консоли
     * @param [returnResponse] Вернуть ли Response не обработанным, например, для получения файлов
     * @param [ulrProxy] Сервис переправит запрос по этому адресу
     */
    post(path, parameters, fileName, returnResponse = false, ulrProxy) {
        return this.call(ulrProxy ? 'proxy/post?fbUrl=' + encodeURIComponent(ulrProxy) : path, 'post', parameters, fileName, returnResponse);
    }
    /**
     * Вызывает Put-запрос в FbConsult.Web.Api. Типы заполнять в {@link PutMethods}
     * @param path Название метода, например account/updateStatusReason
     * @param parameters Список Параметров
     * @param [fileName] название файла для логгирования, с его помощью можно фильтровать запросы в консоли
     * @param [returnResponse] Вернуть ли Response не обработанным, например, для получения файлов
     * @param [ulrProxy] Сервис переправит запрос по этому адресу
     */
    put(path, parameters, fileName, returnResponse = false, ulrProxy) {
        return this.call(ulrProxy ? 'proxy/put?fbUrl=' + encodeURIComponent(ulrProxy) : path, 'put', parameters, fileName, returnResponse);
    }
    /**
     * Вызывает Delete-запрос в FbConsult.Web.Api. Типы заполнять в {@link DeleteMethods}
     * @param path Название метода, например group/clearCurrentGroupCache
     * @param parameters Список Параметров
     * @param [fileName] название файла для логгирования, с его помощью можно фильтровать запросы в консоли
     * @param [ulrProxy] Сервис переправит запрос по этому адресу
     */
    delete(path, parameters, fileName, ulrProxy) {
        return this.call(ulrProxy ? 'proxy/delete?fbUrl=' + encodeURIComponent(ulrProxy) : path, 'delete', parameters, fileName);
    }
    async call(path, method, parameters, fileName, returnResponse = false) {
        const response = await authFetch(`${this.path}${path}${method === 'get' && parameters ? `?${formatGetParameters(parameters)}` : ''}`, {
            credentials: 'include',
            headers: parameters instanceof FormData ? {} : this.headers,
            body: method === 'get' || !parameters
                ? undefined
                : parameters instanceof FormData
                    ? parameters
                    : JSON.stringify(parameters),
            method,
            mode: 'cors',
        });
        const result = returnResponse
            ? response.ok
                ? response
                : (await response.json())
            : response.status !== 204 && response.statusText !== 'No Content' && response.status !== 404
                ? (await response.json())
                : null;
        if (process.env.NODE_ENV === 'development' && response.status === 500)
            log.dev.error(fileName, path, method, parameters, response.bodyUsed ? result : await response.text());
        if (process.env.NODE_ENV === 'development' && fileName !== 'never' && localStorage['fora.debug.api'])
            log.dev.debug(fileName, path, parameters, result);
        return response.ok ? [undefined, result] : [result, undefined];
    }
}
const formatGetParameters = (parameters) => Object.entries(parameters)
    .flatMap(x => (Array.isArray(x[1]) ? x[1].map((y) => `${x[0]}=${y}`) : [x.join('=')]))
    .join('&');
