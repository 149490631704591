import { Duration } from 'luxon';
import { isNotNullOrUndefined } from './guards';
/**
 * Схлопывает стэк обновлений, удаляя промежуточные(A->B->C превращается в A->C)
 * @param updateStack стэк обновлений
 */
export function compressStack(updateStack) {
    const props = new Set(updateStack.map(x => x[0]));
    for (const prop of props) {
        const stack = updateStack.filter(x => x[0] === prop);
        if (compare(stack[0][2], stack[stack.length - 1][1])) {
            updateStack = updateStack.filter(x => !stack.includes(x));
        }
    }
    return updateStack;
}
function compare(a, b) {
    if (a instanceof Duration && b instanceof Duration)
        return a.as('milliseconds') === b.as('milliseconds');
    if (typeof a === 'object' && a && 'equals' in a && typeof a.equals === 'function')
        return isNotNullOrUndefined(b) && a.equals(b);
    return a === b;
}
/**
 * Получает объект для сохранения содержащий только измененные св-ва из {@link updateStack}
 * @param updateStack список изменений
 * @param id ид сущности
 */
export const getChangedProperties = (updateStack, id) => {
    const entityToSave = { id };
    // Сохраняем только измененные свойства
    for (const [key, value] of updateStack)
        entityToSave[key] = value;
    return entityToSave;
};
