import { isNotNullOrUndefined } from './guards';
/** Позволяет забиндить фильтры как для запроса в DataApi, так и для фильтрации результата запроса на стороне клиента
 * !! покрывает далеко не все кейсы на текущий момент
 * @param equalFilters - проверяет поле на равенство
 * @param dateFilters - проверяет поле на принадлежность диапазону
 * @example useFilterDataApiBinding({ field: 123 }, { dateFrom: DateTime.now() })
 */
export const useFilterDataApiBinding = (equalFilters, dateFilters) => {
    const getEqualFilters = () => Object.entries(equalFilters).filter(x => isNotNullOrUndefined(x[1]));
    const getDateFilters = () => Object.entries(dateFilters).filter(x => x[1].dateFrom ?? x[1].dateTo);
    const toRowFilter = () => {
        const filters = getEqualFilters()
            .map(([key, value]) => (row) => row[key] === value)
            .concat(getDateFilters().map(([key, value]) => (row) => ((value.dateFrom && row[key] > value.dateFrom) ?? !value.dateFrom) &&
            ((value.dateTo && row[key] < value.dateTo) ?? !value.dateTo)));
        return row => filters.every(x => x(row));
    };
    const toRemoteFilter = () => {
        const filters = getEqualFilters().map(([key, value]) => ({
            type: 'equal',
            fieldName: key,
            value: value,
        }));
        const dateFilters = getDateFilters().map(([key, value]) => ({
            type: 'dateTime',
            fieldName: key,
            from: value.dateFrom ?? undefined,
            to: value.dateTo ?? undefined,
        }));
        return filters.concat(dateFilters);
    };
    return { toRowFilter, toRemoteFilter };
};
