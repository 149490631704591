import { omit } from 'lodash';
import { resolveDirective, withDirectives } from 'vue';
import { isTruthy } from './guards';
const arrayToObject = (array) => array.reduce((p, c) => ((p[c] = true), p), {});
const vueClassToObject = (base) => Array.isArray(base)
    ? arrayToObject(base)
    : typeof base === 'object'
        ? { ...base }
        : typeof base === 'string'
            ? { [base]: true }
            : {};
export function mergeClass(base, ...other) {
    if (!base || !other.some(isTruthy))
        return base;
    return other.reduce((p, c) => Object.assign(p, vueClassToObject(c)), vueClassToObject(base));
}
export const excludeVue2Attributes = (attrs) => omit(attrs, 'class', 'style');
export function defineFunctionalComponent(props, component, emits, displayName, inheritAttributes = false) {
    component.props = props;
    component.emits = emits;
    component.displayName = displayName;
    component.inheritAttrs = inheritAttributes;
    return component;
}
export const withTooltip = (node, label, options = {}) => withDirectives(node, [
    [
        resolveDirective('tooltip'),
        { label, showOnlyIfOverflownSelector: options.showOnlyIfOverflownSelector },
        options.tooltipClass ?? '',
        {
            top: options.top ?? false,
            bottom: options.bottom ?? false,
            left: options.left ?? false,
            right: options.right ?? false,
            showOnlyIfOverflown: options.showOnlyIfOverflown ?? false,
            useDangerousInnerHtml: options.useDangerousInnerHtml ?? false,
            pre: options.pre ?? false,
            enabledOnTouch: options.enabledOnTouch ?? false,
        },
    ],
]);
