import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_s_toggle = _resolveComponent("s-toggle");
    const _component_s_button = _resolveComponent("s-button");
    const _component_s_modal = _resolveComponent("s-modal");
    const _directive_tooltip = _resolveDirective("tooltip");
    return (_openBlock(), _createBlock(_component_s_modal, {
        modelValue: _ctx.visible,
        centralize: "",
        width: 600,
        caption: _ctx.$i18n.global('search')
    }, {
        footer: _withCtx(() => [
            _createVNode(_component_s_button, {
                success: "",
                tabindex: "1",
                disabled: _ctx.module.loading.isLoading('lookup-result').value,
                onClick: _ctx.find
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$i18n.global('find')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
            }, 8 /* PROPS */, ["disabled", "onClick"]),
            _createVNode(_component_s_button, {
                tabindex: "2",
                disabled: _ctx.module.loading.isLoading('lookup-result').value,
                onClick: _ctx.hide
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$i18n.global('cancel')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
            }, 8 /* PROPS */, ["disabled", "onClick"])
        ]),
        default: _withCtx(() => [
            _createElementVNode("div", {
                class: _normalizeClass(_ctx.$options.name + '__content')
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$options.name + '__filters')
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters, (filter, index) => {
                        return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.filterTemplate), {
                            key: index,
                            filter: filter
                        }, null, 8 /* PROPS */, ["filter"]));
                    }), 128 /* KEYED_FRAGMENT */))
                ], 2 /* CLASS */),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$options.name + '__parameters')
                }, [
                    (!_ctx.isStaticCurrentGroup)
                        ? _withDirectives((_openBlock(), _createBlock(_component_s_toggle, {
                            key: 0,
                            modelValue: _ctx.enableExistsConditions,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.enableExistsConditions) = $event)),
                            leftLabel: _ctx.local('enable-exists-conditions'),
                            readonly: _ctx.module.loading.isLoading('lookup-result').value
                        }, null, 8 /* PROPS */, ["modelValue", "leftLabel", "readonly"])), [
                            [_directive_tooltip, _ctx.local('enable-exists-conditions-hint')]
                        ])
                        : _createCommentVNode("v-if", true),
                    _createVNode(_component_s_button, {
                        readonly: _ctx.module.loading.isLoading('lookup-result').value,
                        action: "fb-common-plus",
                        style: { "margin-left": "auto" },
                        onClick: _ctx.addFilter
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.local('add-condition')), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["readonly", "onClick"])
                ], 2 /* CLASS */)
            ], 2 /* CLASS */)
        ]),
        _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "caption"]));
}
