import { services } from '@fbc/core/services';
import { onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
const isGroup = (group) => typeof group === 'object' &&
    group !== null &&
    'id' in group &&
    typeof group.id === 'string' &&
    'displayName' in group &&
    typeof group.displayName === 'string' &&
    'description' in group &&
    (group.description === null ||
        typeof group.description === 'string');
export default ({ module, updateTabsSettings, }) => {
    const { bus, moduleDialog, store } = services;
    const route = useRoute();
    const subscriptions = [];
    const allowCreateGroup = store.user.hasAccess('List/Add');
    const allowEditGroup = store.user.hasAccess('List/Edit');
    const open = (parameters) => {
        if (!module.value.moduleName)
            throw new Error('EntityName not defined in manifest');
        if (!route.meta.listViewer?.tableName)
            throw new Error('TableName not defined in manifest');
        moduleDialog('list-viewer-builder', {
            moduleName: module.value.moduleName,
            table: route.meta.listViewer.tableName,
            entityId: parameters?.groupId,
            saveAsNewGroup: parameters?.saveAsNewGroup,
        });
    };
    const onCreateGroup = (group) => {
        if (!isGroup(group))
            return;
        const newGroup = {
            id: group.id,
            displayName: group.displayName,
            description: group.description,
            canEditDeleteShare: true,
            isStatic: false,
        };
        module.value.groups.push(newGroup);
        updateTabsSettings();
        module.value.currentGroupId = newGroup.id;
    };
    const onUpdateGroup = (group) => {
        if (!isGroup(group))
            return;
        const existsGroup = module.value.groups.find(x => x.id === group.id);
        if (!existsGroup)
            return;
        existsGroup.displayName = group.displayName;
        existsGroup.description = group.description;
        if (module.value.currentGroupId === existsGroup.id)
            module.value.refresh();
        updateTabsSettings();
    };
    onMounted(() => {
        if (allowCreateGroup.value)
            subscriptions.push(bus.on('entity-group-created', onCreateGroup));
        if (allowEditGroup.value)
            subscriptions.push(bus.on('entity-group-updated', onUpdateGroup));
    });
    onUnmounted(() => {
        while (subscriptions.length) {
            subscriptions.pop()?.();
        }
    });
    return { open, allowCreateGroup, allowEditGroup };
};
