import { services } from '@fbc/core/services';
import { computed, ref, watch } from 'vue';
import { isNotNullOrUndefined } from './../../../../core/utils/guards';
import localization from './s-list-viewer.json';
export default (module) => {
    const { toast, i18n } = services;
    let lastSelectedRecordIndex = 0;
    const selectedEntities = computed(() => {
        const { records, selectedRecordIndexes, idFields } = module.value;
        if (!selectedRecordIndexes?.length || !idFields?.length)
            return;
        return records.filter(entity => selectedRecordIndexes.includes(entity.$num));
    });
    const onSelect = (selectedRecord, { ctrlKey = false, shiftKey = false }) => {
        if (module.value.loading.isLoading('selection').value)
            return;
        let selectedRecordIndexes = module.value.selectedRecordIndexes ?? [];
        if (selectedRecordIndexes.includes(selectedRecord.$num)) {
            selectedRecordIndexes = ctrlKey
                ? selectedRecordIndexes.filter(x => x !== selectedRecord.$num)
                : [selectedRecord.$num];
        }
        else if (shiftKey) {
            const min = Math.min(selectedRecord.$num, lastSelectedRecordIndex);
            const max = Math.max(selectedRecord.$num, lastSelectedRecordIndex);
            module.value.selectedRecordIndexes = Array.from({ length: max - min + 1 }, (_, i) => min + i);
            return;
        }
        else if (ctrlKey) {
            selectedRecordIndexes.push(selectedRecord.$num);
        }
        else {
            selectedRecordIndexes = [selectedRecord.$num];
        }
        lastSelectedRecordIndex = selectedRecord.$num;
        module.value.selectedRecordIndexes = selectedRecordIndexes;
    };
    const onClickSelectAll = (value) => {
        module.value.selectedRecordIndexes = value
            ? Array.from({ length: module.value.loader.totalResults }, (_, i) => i)
            : undefined;
    };
    const selectedAll = computed(() => module.value.selectedRecordIndexes?.length === module.value.loader.totalResults);
    const loaderToast = ref();
    watch(() => module.value.selectedRecordIndexes, selectedRecordIndexes => {
        if (!isNotNullOrUndefined(selectedRecordIndexes))
            lastSelectedRecordIndex = 0;
    });
    watch(() => module.value.loading.isLoading('selection').value, newValue => {
        if (!newValue) {
            loaderToast.value?.({ close: true });
            loaderToast.value = undefined;
            return;
        }
        loaderToast.value = toast({
            body: i18n.extract(localization, 'getting-selection'),
            dismissible: false,
            delay: 0,
        });
    });
    return {
        selectedEntities,
        onSelect,
        onClickSelectAll,
        selectedAll,
    };
};
