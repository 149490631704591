import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "-data-table-filter-text" };
const _hoisted_2 = { class: "s-data-table-filter-text__items-container" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_s_input = _resolveComponent("s-input");
    const _component_s_data_table_filter_base = _resolveComponent("s-data-table-filter-base");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_s_data_table_filter_base, {
            "is-selected": _ctx.isSelected,
            caption: _ctx.caption ?? '',
            "menu-class": "s-data-table-filter-list",
            onFilter: _ctx.filter,
            onClear: _ctx.clear,
            onShow: _ctx.show
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_s_input, {
                        modelValue: _ctx.value,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.value) = $event)),
                        placeholder: _ctx.local('placeholder-filter')
                    }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
                ])
            ]),
            _: 1 /* STABLE */
        }, 8 /* PROPS */, ["is-selected", "caption", "onFilter", "onClear", "onShow"])
    ]));
}
