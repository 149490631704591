import { isNotNullOrUndefined } from '@fbc/core/utils';
import { computed, nextTick, ref } from 'vue';
/** Выделение элементов, не расположенных на текущей странице */
export function addSelecting(context) {
    const { currentPage, dataProvider, pageSize, pagesCount, loadParameters, items, emit, selectingLimit, totalResults, modelValue, } = context;
    const lastPage = ref();
    const lastIndex = ref();
    const entities = ref([]);
    const isSelectedAll = computed(() => modelValue.value.length === totalResults.value);
    const selected = async (row, options) => {
        const index = items.value.indexOf(row);
        const page = currentPage.value + 1;
        const entity = items.value.at(index);
        if (options.shiftKey && lastPage.value && lastPage.value !== page && isNotNullOrUndefined(lastIndex.value)) {
            let fromIndex;
            let fromPage;
            let toIndex;
            let toPage;
            if (page > lastPage.value) {
                fromIndex = lastIndex.value;
                fromPage = lastPage.value;
                toIndex = index;
                toPage = page;
            }
            else {
                toIndex = lastIndex.value;
                toPage = lastPage.value;
                fromIndex = index;
                fromPage = page;
            }
            const bufferedEntities = [...entities.value];
            const records = await load(fromPage - 1, toPage - 1);
            const selected = records.filter((_, i) => i > fromIndex && i < pageSize * (toPage - fromPage) + toIndex);
            if (entity)
                selected.push(entity);
            emit('update:modelValue', [...bufferedEntities, ...selected]);
        }
        lastIndex.value = index;
        lastPage.value = page;
    };
    const clickedSelectAll = async (value) => {
        if (!value)
            return;
        const allRecords = await load(0, pagesCount.value - 1);
        emit('update:modelValue', allRecords);
    };
    const modelUpdated = async (update) => {
        await nextTick();
        entities.value = update;
        emit('update:modelValue', entities.value);
    };
    const load = async (from, to) => {
        const promises = [];
        const limitedFrom = Math.max(to - Math.floor(selectingLimit / pageSize), from);
        for (let i = limitedFrom; i <= to; i++)
            promises.push(loadChunk(i));
        return (await Promise.all(promises)).flat();
    };
    const loadChunk = async (page) => {
        const [error, data = []] = await dataProvider.value.getData({
            page: {
                size: pageSize,
                count: pagesCount.value,
                num: page,
            },
            filters: loadParameters.filters.value,
            sort: loadParameters.sort.value,
            pick: loadParameters.pick.value,
        });
        if (error)
            log.prod.error(__filename, error);
        return data;
    };
    return { ...context, selected, clickedSelectAll, modelUpdated, isSelectedAll };
}
