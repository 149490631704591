import { defineFunctionalComponent, isTruthy } from '@fbc/core/utils';
import { computed, h, resolveComponent } from 'vue';
export const useDataTableButtons = (props, emit, showDetail, getId) => {
    const addButtonTemplate = computed(() => defineFunctionalComponent({
        entity: Object,
        column: Object,
        index: Number,
    }, ({ entity, column, index }) => [
        h('div', {
            style: 'display: flex; justify-content: center;',
        }, h(resolveComponent('s-button'), {
            icon: 'fb-common-plus',
            success: true,
            style: '--button-size: 24px;',
            ...(typeof props.allowAdd === 'object' ? props.allowAdd : {}),
            onClick: () => emit('click:add', entity, column, index),
        })),
    ]));
    const editDeleteButtonsTemplate = computed(() => defineFunctionalComponent({
        entity: Object,
        column: Object,
        index: Number,
    }, ({ entity, column, index }) => [
        props.allowShowDetail
            ? h(resolveComponent('s-button'), {
                icon: props.detailsId === getId(entity) ? 'fb-common-top' : 'fb-common-bottom',
                class: 's-data-table__switch-details-button',
                ...(typeof props.allowShowDetail === 'function' ? props.allowShowDetail(entity) : {}),
                onClick: (e) => {
                    e.stopPropagation();
                    showDetail(entity);
                    emit('click:showDetail', entity, column, index);
                },
            })
            : undefined,
        checkIsTruthy(props.allowEdit, entity)
            ? h(resolveComponent('s-button'), {
                icon: 'fb-common-edit',
                class: 's-data-table__edit-button',
                ...(typeof props.allowEdit === 'function' ? props.allowEdit(entity) : {}),
                onClick: (e) => {
                    e.stopPropagation();
                    emit('click:edit', entity, column, index);
                },
            })
            : undefined,
        checkIsTruthy(props.allowDelete, entity)
            ? h(resolveComponent('s-button'), {
                icon: 'fb-common-close',
                error: true,
                class: 's-data-table__delete-button',
                ...(typeof props.allowDelete === 'function' ? props.allowDelete(entity) : {}),
                onClick: (e) => {
                    e.stopPropagation();
                    emit('click:delete', entity, column, index);
                },
            })
            : undefined,
    ]));
    const checkIsTruthy = (allowValue, entity) => {
        const isFunction = typeof allowValue === 'function';
        return (isFunction && !!allowValue(entity)) || (!isFunction && !!allowValue);
    };
    const buttonsColumn = computed(() => {
        const existingButtonsColumn = props.columns.find(x => x.field === '$buttons');
        return {
            field: '$buttons',
            headerTemplate: props.allowAdd ? (existingButtonsColumn?.headerTemplate ?? addButtonTemplate.value) : undefined,
            cellTemplate: existingButtonsColumn?.cellTemplate ?? editDeleteButtonsTemplate.value,
            width: existingButtonsColumn?.width ??
                [props.allowDelete, props.allowEdit, props.allowShowDetail].filter(isTruthy).length * 33,
            align: existingButtonsColumn?.align ?? { horizontal: 'center', vertical: 'center' },
            classes: [`s-data-table__cell--buttons`, existingButtonsColumn?.classes].filter(isTruthy).join(' '),
        };
    });
    return {
        buttonsColumn,
    };
};
