import { services } from '@fbc/core/services';
import { omit } from 'lodash';
import { computed, defineComponent, h, ref, resolveComponent, watch } from 'vue';
import props from './types/Properties';
const PicklistLookupComponent = defineComponent({
    props: {
        ...omit(props, 'idField', 'columns'),
        picklistName: { type: String, required: true },
        columns: { type: Map },
        placeholder: { type: Object },
    },
    emits: ['update:modelValue', 'update:columns', 'cache:picklist:reload'],
    setup(props, ctx) {
        const { store } = services;
        const loading = store.picklists.loading(props.picklistName);
        const items = computed(() => store.picklists.get(props.picklistName)?.items ?? []);
        watch(() => props.picklistName, async (name) => {
            await services.store.picklists.load(name, __filename);
        }, { immediate: true });
        const originalColumns = ref([
            { field: 'text', caption: services.i18n.global('picklist-item'), isDisplayName: true, filter: 'text' },
            { field: 'code', caption: services.i18n.global('picklist-item-code'), filter: 'text' },
            { field: 'shortText', caption: services.i18n.global('picklist-short-text'), filter: 'text', visible: false },
            { field: 'id', visible: false },
            { field: 'level', visible: false },
            { field: 'parentId', visible: false },
        ]);
        const columns = computed(() => props.columns?.size
            ? originalColumns.value
                .filter(c => props.columns?.has(c.field))
                .map(x => Object.assign(x, props.columns?.get(x.field) ?? {}))
            : originalColumns.value);
        watch(() => store.picklists.get(props.picklistName), (newPicklist, oldPicklist) => {
            if (!oldPicklist && newPicklist)
                return;
            if (JSON.stringify(newPicklist?.items.sort()) !== JSON.stringify(oldPicklist?.items.sort()))
                return ctx.emit('cache:picklist:reload');
        });
        return () => loading.value
            ? h(resolveComponent('s-placeholder'), { style: 'height: 32px', ...props.placeholder })
            : h(resolveComponent('s-static-lookup'), {
                ...props,
                data: items.value,
                columns: columns.value,
                idField: 'id',
                'onUpdate:modelValue': (event) => ctx.emit('update:modelValue', event),
                'onUpdate:columns': (event) => (originalColumns.value = event),
            }, { default: ctx.slots.default });
    },
});
export default PicklistLookupComponent;
