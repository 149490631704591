import { defineFunctionalComponent } from '@fbc/core/utils';
import { h, mergeProps } from 'vue';
import DataTableFilterDateTimeComponent from './s-data-table-filter-date-time.vue';
import DataTableFilterListComponent from './s-data-table-filter-list.vue';
import DataTableFilterNumberComponent from './s-data-table-filter-number.vue';
import DataTableFilterTextComponent from './s-data-table-filter-text.vue';
const getFilterByType = (type) => {
    switch (type) {
        case 'DateTime':
            return DataTableFilterDateTimeComponent;
        case 'List':
            return DataTableFilterListComponent;
        case 'Text':
            return DataTableFilterTextComponent;
        case 'Number':
            return DataTableFilterNumberComponent;
        default: {
            const exhaustiveCheck = type;
            throw new Error('Unhandled filter type: ' + String(exhaustiveCheck));
        }
    }
};
export default defineFunctionalComponent({
    entities: { type: [Array, Object], required: true },
    fieldName: { type: String, required: true },
    config: { type: Object, required: true },
}, (props, context) => h(getFilterByType(props.config.type), mergeProps({ class: 's-data-table-filter' }, props, context.attrs)));
