import { services } from '@fbc/core/services';
import { isNotNullOrUndefined } from '@fbc/core/utils';
import { nextTick, reactive, ref, watch } from 'vue';
export const useSearch = (props, filtersChanged, hiddenFiltersChanged, validFilters, onSearchError) => {
    const loaded = ref(false);
    const loading = ref(false);
    const centralize = ref(true);
    const feature = reactive({ pagination: true, sort: true, filters: true });
    const page = reactive({ num: 0, size: 14, count: 0, totalResults: 0 });
    const items = ref([]);
    const search = async () => {
        if (loading.value)
            return;
        loading.value = true;
        const sort = props.columns
            .filter(x => x.sortOrder)
            .sort((a, b) => (a.sortIndex ?? 0) - (b.sortIndex ?? 0))
            .map(x => ({ fieldName: x.field, sortOrder: x.sortOrder ?? 'asc' }));
        const [error, data = [], rest] = await props.dataProvider.getData({
            page,
            validFilters: validFilters.value,
            sort,
            loaded: loaded.value,
            filtersChanged: filtersChanged.value,
            pick: props.columns.map(x => x.field),
        });
        if (error) {
            log.prod.error(__filename, error);
            services.toast.error(error);
            loading.value = false;
            onSearchError();
            return;
        }
        items.value = data;
        if (rest) {
            if (isNotNullOrUndefined(rest.totalResults)) {
                page.totalResults = rest.totalResults;
                page.count = Math.ceil(rest.totalResults / page.size);
            }
            checkFeatures(feature, rest.features);
        }
        filtersChanged.value = false;
        hiddenFiltersChanged.value = false;
        loading.value = false;
        loaded.value = true;
    };
    watch(loading, async () => {
        centralize.value = false;
        await nextTick();
        centralize.value = true;
    });
    return { search, loaded, loading, feature, page, items, centralize };
};
// Проверяет, что API поддерживает нужные для лукапа фичи и отключает их, если это не так
function checkFeatures(feature, features) {
    if (!features)
        return;
    if (!features.pagination) {
        if (feature.pagination)
            log.dev.warn(__filename, 'Пагинация не настроена в апи');
        feature.pagination = false;
    }
    if (!features.sort) {
        if (feature.sort)
            log.dev.warn(__filename, 'Сортировка не настроена в апи');
        feature.sort = false;
    }
    if (!features.filters) {
        if (feature.filters)
            log.dev.warn(__filename, 'Фильтры не настроены в апи');
        feature.filters = false;
    }
}
