import { services } from '@fbc/core/services';
const loadingMap = new Map();
/**
 * Функция для проверки доступа ко вкладке модуля
 * @param api апи модуля
 * @param tab вкладка
 * @param id ид сущности
 * @returns доступна ли вкладка
 */
export const securedMethodRouterTab = async (tab, id) => {
    const { store } = services;
    if (tab.meta?.securedAction && !store.user.hasAccess(tab.meta.securedAction).value)
        return false;
    if (!tab.meta?.securedModule)
        return true;
    let securedMethod;
    if (loadingMap.has(tab.meta.securedModule))
        securedMethod = loadingMap.get(tab.meta.securedModule);
    else {
        securedMethod = await services.store.app.moduleRegistry
            .load(tab.meta.securedModule)
            .then(x => x?.securedMethod);
        if (securedMethod)
            loadingMap.set(tab.meta.securedModule, securedMethod);
    }
    if (!securedMethod || !id)
        return false;
    return securedMethod(id);
};
export const securedMethodRouterModuleTabs = async (module, id) => {
    const tabs = module?.children;
    if (!tabs?.length)
        return [];
    const accessTab = await Promise.all(tabs.map(x => securedMethodRouterTab(x, id)));
    return tabs.filter((_, i) => accessTab[i]);
};
export default securedMethodRouterModuleTabs;
