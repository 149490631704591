import { DateTime } from 'luxon';
/**Проверяет значение на принадлежность к DateTime */
export const isDateTime = (arg) => arg instanceof DateTime && 'ts' in arg;
export const isDateTimeOrEmpty = (x) => x === null || x === undefined || isDateTime(x);
/**Проверяет значение на принадлежность к string */
export const isString = (x) => typeof x === 'string' || x instanceof String;
/** Проверяет является ли значение правдивым(truthy) */
export const isTruthy = Boolean;
/** Проверяет НЕ является ли значение null | undefined  */
export const isNotNullOrUndefined = (x) => x !== null && x !== undefined;
/** Проверяет является ли свойство объекта(и сам объект) null | undefined*/
export const isPropertyNotNullOrUndefined = (property) => (x) => isNotNullOrUndefined(x?.[property]);
export const isLogParameter = (e) => e instanceof Error || isString(e) || Boolean(e && typeof e === 'object' && ('message' in e || 'Message' in e));
const idFirstLetters = new Set([
    'Q',
    'A' /*ACCOUNT */,
    'C' /*CONTACT */,
    'H' /*HISTORY */,
    'O' /*OPPORTUNITY */,
    'V' /*ACTIVITY */,
    'n' /*USERNOTIFICATION */,
    'I' /*SECRIGHTS */,
    'Y' /*PRODUCT */,
    'a' /*ADDRESS */,
    'k' /*PICKLIST */,
]);
export const isInforIdLike = (x) => isString(x) && x.length === 12 && idFirstLetters.has(x[0]);
