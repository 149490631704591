import { defineComponent, h, nextTick, ref, resolveComponent } from 'vue';
const renderAlert = (alert, remove) => {
    const modelValue = ref(true);
    const removeAlert = () => {
        modelValue.value = false;
        return nextTick(() => remove(alert));
    };
    return () => h(resolveComponent('s-modal'), {
        modelValue: modelValue.value,
        caption: alert.caption,
        class: alert.classes,
        width: alert.width,
        height: alert.height,
        centralize: alert.centralize ?? true,
        role: 'alert',
        onHidden: removeAlert,
    }, {
        header: () => (alert.header ? h(alert.header, { remove: removeAlert }) : undefined),
        default: () => typeof alert.body === 'string'
            ? h('p', {
                class: alert.innerClasses,
                textContent: alert.body,
                style: { whiteSpace: 'pre-wrap', lineHeight: 1.5, overflowWrap: 'anywhere' },
            })
            : h(alert.body, { config: alert, remove: removeAlert }),
        footer: () => (alert.footer ? h(alert.footer, { remove: removeAlert }) : undefined),
    });
};
const renderConfirm = (confirm, remove) => {
    const modelValue = ref(true);
    const removeConfirm = (result) => {
        modelValue.value = false;
        return nextTick(() => remove(confirm, result));
    };
    return () => h(resolveComponent('s-modal'), {
        modelValue: modelValue.value,
        showOk: true,
        caption: confirm.caption,
        class: confirm.classes,
        width: confirm.width,
        height: confirm.height,
        centralize: confirm.centralize ?? true,
        role: 'alert',
        'onClick:ok': () => removeConfirm(true),
        'onClick:cancel': () => removeConfirm(false),
        onHidden: () => removeConfirm(false),
    }, {
        header: () => (confirm.header ? h(confirm.header, { remove: removeConfirm }) : undefined),
        default: () => typeof confirm.body === 'string'
            ? h('p', { class: confirm.innerClasses, textContent: confirm.body })
            : h(confirm.body, {
                config: confirm,
                remove: removeConfirm,
            }),
        footer: () => (confirm.footer ? h(confirm.footer, { remove: removeConfirm }) : undefined),
    });
};
export const DialogsContainerComponent = defineComponent({
    name: 's-dialogs-container',
    render() {
        return h('div', { class: 's-alerts-container', 'data-alerts': this.$store.dialogs.alerts.length }, [
            ...this.$store.dialogs.alerts.map(({ alert }) => h(renderAlert(alert, this.$store.dialogs.removeAlert))),
            ...this.$store.dialogs.confirms.map(({ confirm }) => h(renderConfirm(confirm, this.$store.dialogs.removeConfirm))),
        ]);
    },
});
