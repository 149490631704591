import { defineComponent, h, mergeProps, resolveDirective, withDirectives } from 'vue';
import SpinnerComponent from '../s-spinner.vue';
import './s-button.scss';
export default defineComponent({
    inheritAttrs: false,
    name: 's-button',
    props: {
        disabled: { type: Boolean },
        loading: { type: Boolean },
        success: { type: Boolean },
        error: { type: Boolean },
        warning: { type: Boolean },
        secondary: { type: Boolean },
        filled: { type: Boolean },
        raised: { type: Boolean },
        action: { type: String },
        icon: { type: String },
    },
    setup(props, context) {
        return () => withDirectives(h('button', mergeProps({
            class: [
                's-button',
                ...Object.keys(props)
                    .filter(x => props[x] !== undefined && props[x] !== false)
                    .map(x => `s-button--${x}`),
            ],
            type: 'button',
            disabled: props.disabled,
        }, context.attrs), (props.icon?.length ?? props.action?.length)
            ? [h('i', { class: props.icon ?? props.action, 'aria-hidden': true }), context.slots.default?.()]
            : props.loading
                ? [context.slots.default?.(), h(SpinnerComponent)]
                : context.slots), [[resolveDirective('waves')]]);
    },
});
