import variables from '../services/variables';
export function toRandomColorFromString(str, options) {
    const sum = weightedSum(str, options?.coefficient);
    return `hsl(${sum % 361}deg ${options?.saturate ?? 100}% ${options?.lightness ?? 42 + (sum % 16)}% / ${options?.alpha ?? 0.2} )`;
}
export function toChartColorFromString(str, options) {
    const sum = weightedSum(str, options?.coefficient ?? 1);
    return variables[('chart-' + ((sum % variables['chart-colors-count']) + 1))];
}
export function toChartBackgroundColorFromString(str, options) {
    const sum = weightedSum(str, options?.coefficient ?? 1);
    return variables[('chart-' + ((sum % variables['chart-colors-count']) + 1) + '-background')];
}
const weightedSum = (str, coefficient = 4.5) => Array.from({ length: str.length })
    .map((_, i) => str.charCodeAt(i))
    .reduce((p, c) => p + c * coefficient, 0);
