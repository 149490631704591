import { services } from '@fbc/core/services';
import { excludeVue2Attributes, mergeClass } from '@fbc/core/utils';
import { isEqual, isNil, sortBy } from 'lodash';
import { computed, defineComponent, reactive, ref, watch } from 'vue';
import { updatePosition, useUpdatePositionOnScroll } from '../use/use-update-position';
const arraify = (item) => Array.isArray(item) || isNil(item) ? (item?.slice() ?? []) : [item];
function scrollIntoView(item) {
    if (!item)
        return;
    item.scrollIntoView();
}
function usePopup() {
    const visible = ref(false);
    const position = reactive({ top: 0, left: 0 });
    return { visible, position };
}
/** https://gitlab.fbconsult.ru/fora/documentation/-/wikis/select/ */
export default defineComponent({
    name: 's-select',
    inheritAttrs: false,
    emits: [
        'update:modelValue',
        'update:localValue',
        'update:isMenuVisible',
        'update:valid',
        'focus',
        'blur',
        'click',
        'keyup',
        'hide',
        'input',
        'clear',
    ],
    props: {
        modelValue: { type: [Object, String, Number, Array] },
        /** Если SelectItem, то обязательно указывать. При отображении берет св-во с названием labelField */
        labelField: { type: String },
        label: { type: String },
        /** Если allowMultiSelect указан, то используется для склейки значений при отображении */
        joiner: { type: String, default: ', ' },
        disabled: { type: Boolean },
        readonly: { type: Boolean },
        bordered: { type: Boolean, default: false },
        allowMultiSelect: { type: Boolean, default: false },
        notShowOnInputClick: { type: Boolean, default: false },
        showClearButton: { type: Boolean, default: false },
        /** Возможность вводить текст в поле ввода. Адекватно работает если список - строки, в противном случае надо будет самому разобраться что пришло на update:modelValue. Добавляет событие input */
        inputable: { type: Boolean, default: false },
        searchable: { type: Boolean, default: false },
        showSelectAll: { type: Boolean, default: false }, //отображать пункт "Выбрать всё". Работает вместе с allowMultiSelect = true
        showCheckbox: { type: Boolean, default: false }, //отображать чекбоксы у элементов
        maxItems: { type: Number, default: Number.MAX_SAFE_INTEGER },
        menuClass: { type: String, default: '' },
        options: { type: [Array, Promise], default: () => [] },
        equalityComparer: {
            /**
             * @param a - массив выбранных значений
             * @param b - искомое значение
             * @param change - идет ли сравнение для emit change | при рендере undefined\
             */
            type: Function,
            default: (a, b) => a && b && a.includes(b),
        },
        menuHeight: { type: Number },
        /** Используется для автоскрола в меню при его появлении. По умолчанию скролит к активному элементу */
        scrollIndex: { type: Number, default: -1 },
        /** Вместе с событием update:isMenuVisible позволяет извне управлять показом меню со списком */
        isMenuVisible: { type: Boolean, default: false },
        /** Функция для фильтрации элементов для searchable */
        filterFunction: {
            type: Function,
            default: ({ option, labelField, filter }) => String((option !== null && option !== undefined && labelField
                ? option[labelField]
                : option) ?? '')
                .toLowerCase()
                .includes(filter.toLowerCase()),
        },
    },
    setup(props, { emit }) {
        const root = ref();
        const menuRef = ref();
        const filter = ref();
        const menuWidth = ref('auto');
        const isValid = ref(true);
        const { visible, position } = usePopup();
        const valueAsArray = ref();
        watch(() => props.modelValue, newValue => (valueAsArray.value = arraify(newValue)), { immediate: true });
        const buttons = computed(() => {
            if (props.disabled || props.readonly)
                return [];
            const result = [];
            if (props.showClearButton && (Array.isArray(props.modelValue) ? props.modelValue.length : props.modelValue))
                result.push({
                    id: 1,
                    attributes: { class: 's-select__clear-button fb-common-close', onClick: clear },
                });
            result.push({
                id: 0,
                attributes: {
                    'data-visible': String(visible.value),
                    onClick: switchMenuVisible,
                    class: 's-select__show-button fb-common-bottom' + (visible.value ? ' s-select__show-button--menu-visible' : ''),
                },
            });
            return result;
        });
        const filteredOptions = computed(() => {
            if (!props.searchable || !filter.value)
                return props.options;
            return props.options.filter(option => props.filterFunction({ option, labelField: props.labelField, filter: filter.value }));
        });
        const displayName = computed(() => {
            if (props.searchable && !props.inputable && !props.disabled && !props.readonly && filter.value !== undefined)
                return filter.value;
            if (!props.allowMultiSelect && valueAsArray.value?.length === 1)
                return getLabel(valueAsArray.value[0]);
            if (valueAsArray.value?.length) {
                return valueAsArray.value.map(getLabel).join(props.joiner);
            }
            return undefined;
        });
        const selectionStatus = computed(() => {
            const count = filteredOptions.value.filter(x => props.equalityComparer(valueAsArray.value, x)).length;
            return count === filteredOptions.value.length ? 'all' : count === 0 ? 'none' : 'some';
        });
        async function show() {
            if (props.disabled || props.readonly)
                return;
            await updatePosition(root, position, menuWidth);
            if (!props.isMenuVisible)
                emit('update:isMenuVisible', true);
            visible.value = true;
        }
        function hide() {
            visible.value = false;
            emit('update:isMenuVisible', false);
            emit('hide');
            filter.value = undefined;
        }
        function getLabel(option) {
            if (!option)
                return '';
            return props.labelField ? option[props.labelField] : String(option);
        }
        function clear(event) {
            event.preventDefault();
            if (props.disabled || props.readonly)
                return;
            if (props.allowMultiSelect) {
                if (valueAsArray.value?.length) {
                    emit('update:modelValue', []);
                    emit('update:localValue', []);
                }
            }
            else {
                if (valueAsArray.value) {
                    emit('update:modelValue', null);
                    emit('update:localValue', null);
                }
            }
            emit('clear');
            filter.value = undefined;
        }
        function onMenuMounted(el) {
            // ! Лучше пока нет. Не повторять.
            scrollIntoView(el.querySelector(props.scrollIndex >= 0 && props.scrollIndex < props.options.length
                ? `li:nth-child(${props.scrollIndex + 1})`
                : '.s-select__item--active'));
        }
        function changeValue() {
            if (!props.modelValue && !valueAsArray.value?.length)
                return;
            if (props.allowMultiSelect) {
                if (isEqual(sortBy(valueAsArray.value), sortBy(Object.assign([], props.modelValue))))
                    return;
                emit('update:modelValue', valueAsArray.value ?? []);
            }
            else {
                if (isEqual(valueAsArray.value?.[0], props.modelValue))
                    return;
                emit('update:modelValue', valueAsArray.value?.[0]);
            }
        }
        function conditionalShow() {
            if (!props.notShowOnInputClick && (!props.inputable || services.store.app.screen.device === 'desktop'))
                switchMenuVisible();
            emit('click');
        }
        function selectNext(direction) {
            if (props.disabled || props.readonly)
                return;
            show();
            if (props.allowMultiSelect)
                return;
            const firstValue = valueAsArray.value?.at(0);
            const valueIndex = filteredOptions.value.indexOf(firstValue) + direction;
            const value = firstValue && valueIndex >= 0 && valueIndex < filteredOptions.value.length
                ? filteredOptions.value[valueIndex]
                : filteredOptions.value[~direction ? 0 : filteredOptions.value.length - 1];
            if (!props.equalityComparer([value], firstValue, true))
                emit('update:modelValue', value);
        }
        function textInput(event) {
            if (props.inputable) {
                emit('input', event);
            }
            if (props.searchable) {
                filter.value = event;
                show();
            }
        }
        function select(option) {
            if (props.allowMultiSelect) {
                if (props.showSelectAll && option === '_all') {
                    if (filteredOptions.value.every(x => props.equalityComparer(valueAsArray.value, x)))
                        filteredOptions.value.forEach(x => valueAsArray.value?.splice(valueAsArray.value.findIndex(value => props.equalityComparer([value], x)), 1));
                    else if ((valueAsArray.value?.length ?? 0) < props.maxItems)
                        filteredOptions.value
                            .filter(x => !props.equalityComparer(valueAsArray.value, x))
                            .forEach(x => (valueAsArray.value?.length ?? 0) < props.maxItems && valueAsArray.value?.push(x));
                    emit('update:localValue', valueAsArray.value ?? []);
                    return;
                }
                if (props.equalityComparer(valueAsArray.value, option, true))
                    valueAsArray.value?.splice(valueAsArray.value.findIndex(value => props.equalityComparer([value], option)), 1);
                else if ((valueAsArray.value?.length ?? 0) < props.maxItems)
                    valueAsArray.value?.push(option);
                emit('update:localValue', valueAsArray.value ?? []);
                return;
            }
            else if (!props.equalityComparer(valueAsArray.value, option, true)) {
                valueAsArray.value = [option];
                emit('update:localValue', option);
            }
            hide();
        }
        const switchMenuVisible = (event) => {
            event?.preventDefault();
            if (visible.value)
                hide();
            else
                show();
        };
        useUpdatePositionOnScroll(() => updatePosition(root, position, menuWidth), visible);
        watch(() => props.isMenuVisible, (a, b) => (a !== b ? (props.isMenuVisible ? show() : hide()) : undefined));
        return {
            menuWidth,
            buttons,
            filter,
            filteredOptions,
            selectionStatus,
            displayName,
            visible,
            position,
            isValid,
            root,
            menuRef,
            valueAsArray,
            select,
            changeValue,
            selectNext,
            textInput,
            conditionalShow,
            onMenuMounted,
            switchMenuVisible,
            show,
            hide,
            getLabel,
            clear,
            mergeClass,
            excludeVue2Attributes,
        };
    },
});
