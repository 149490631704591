import { h, mergeProps } from 'vue';
import { saveMultiplyOn100 } from './s-input/save-multiply-on-100';
export function render(x) {
    return `<div class="s-progressbar" role="progressbar">
    <div style="width: ${saveMultiplyOn100(x)}%"></div>
    <div>${saveMultiplyOn100(x)} %</div>
</div>`;
}
const ProgressbarComponent = ({ value }, context) => h('div', mergeProps({ class: 's-progressbar', role: 'progressbar' }, context.attrs), [
    h('div', {
        style: {
            width: saveMultiplyOn100(value || 0) + '%',
        },
    }),
    h('div', { textContent: Number(saveMultiplyOn100(value)).toFixed(0) + ' %' }),
]);
ProgressbarComponent.props = { value: { type: Number, required: true } };
export default ProgressbarComponent;
