import { isNotNullOrUndefined } from '@fbc/core/utils';
export const compare = (a, b, key, type, sortOrder = 'asc') => {
    const first = a.data[key];
    const second = b.data[key];
    if (!sortOrder)
        sortOrder = 'asc';
    if ((!isNotNullOrUndefined(first) && !isNotNullOrUndefined(second)) || first === second)
        return 0;
    if (!isNotNullOrUndefined(first))
        return sortOrder === 'asc' ? 1 : -1;
    if (!isNotNullOrUndefined(second))
        return sortOrder === 'asc' ? 1 : -1;
    switch (type) {
        case 'string':
            return sortOrder === 'asc'
                ? first.localeCompare(second)
                : second.localeCompare(first);
        case 'number':
        case 'DateTime': {
            const f = first;
            const s = second;
            return sortOrder === 'asc' ? f - s : s - f;
        }
        default:
            throw new Error('Incomparable types');
    }
};
