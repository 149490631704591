import { nextTick, ref, watch } from 'vue';
export async function updatePosition(root, position, menuWidth = ref('auto'), offset = {}) {
    let rect = root.value?.getBoundingClientRect();
    if (!rect || (rect.x === 0 && rect.y === 0)) {
        await nextTick();
        rect = root.value?.getBoundingClientRect();
    }
    if (!rect)
        return;
    const width = String(rect.width) + 'px';
    if (menuWidth.value !== width)
        menuWidth.value = width;
    if ('top' in position && position.top !== rect.bottom)
        position.top = rect.bottom + (offset.top ?? 0);
    if ('bottom' in position && position.bottom !== rect.top)
        position.bottom = rect.top + (offset.bottom ?? 0);
    if ('left' in position && position.left !== rect.left)
        position.left = rect.left + (offset.left ?? 0);
    if ('right' in position && position.right !== rect.right)
        position.right = rect.right + (offset.right ?? 0);
}
export function useUpdatePositionOnScroll(update, visible) {
    let abortScroll = new AbortController();
    watch(visible, value => {
        if (value)
            document.addEventListener('scroll', update, {
                passive: true,
                capture: true,
                signal: abortScroll.signal,
            });
        else {
            abortScroll.abort();
            abortScroll = new AbortController();
        }
    });
}
