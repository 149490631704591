import { services } from '@fbc/core/services';
export const useSubscriptions = (module) => {
    const { bus } = services;
    const subscriptions = [];
    const subscribe = (events) => {
        unsubscribe();
        if (!events?.length)
            return;
        for (const event of events)
            subscriptions.push(bus.on(event, refreshData));
    };
    const unsubscribe = () => {
        while (subscriptions.length) {
            subscriptions.pop()?.();
        }
    };
    const refreshData = () => {
        module.value.loader.clearCache();
        module.value.records = [];
        module.value.loader.canAffectTotalResults = true;
        module.value.loadData({ shouldGetGroupSettings: false, shouldApplyFilters: true, shouldApplySorting: true });
    };
    return { subscribe, unsubscribe };
};
