const filterTypes = new Set(['notInList']);
export const typesGuard = (filter) => filterTypes.has(filter.type);
export function map(filter) {
    if (filter.type === 'notInList') {
        const values = filter.list;
        if (!values.length)
            return '';
        if (values.length === 1)
            return `${filter.fieldName} ne '${values[0]}'`;
        const list = values.map(x => `'${x}'`).join(', ');
        return `not (${filter.fieldName} in (${list}))`;
    }
    return undefined;
}
