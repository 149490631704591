import { services } from '@fbc/core/services';
import { debounce } from 'lodash';
export function addListeners(context) {
    const { load, refreshConfig: { entityType, events }, countChanged, } = context;
    const { bus } = services;
    function createListeners() {
        const debouncedLoad = debounce(load, 200);
        const createEntityEvent = (event) => `/entity/${entityType}/${event}`;
        const changeCountAndLoad = () => {
            countChanged.value = true;
            return debouncedLoad();
        };
        return [
            ...['created', 'deleted'].map(x => bus.on(createEntityEvent(x), changeCountAndLoad)),
            ...[...events, createEntityEvent('updated')].map(x => bus.on(x, load)),
        ];
    }
    const listeners = createListeners();
    return { ...context, listeners };
}
