import { TransitionGroup, defineComponent, h } from 'vue';
const ToastComponent = ({ classes, guid, body, styles, onClick, onRemove, }) => h('div', { key: guid, class: ['s-toast', classes], style: styles, role: 'alert', onClick }, typeof body === 'string' || !body
    ? h('div', { class: 'toast-body', onClick, innerHTML: body })
    : h(body, { guid, onRemove }));
export const ToastContainerComponent = defineComponent({
    name: 's-toast-container',
    render() {
        const toasts = this.$store.toast.toasts;
        const click = (toast) => {
            if (toast.on.click)
                toast.on.click(toast, () => this.$store.toast.remove(toast));
            if (toast.dismissible && !toast.on.click)
                this.$store.toast.remove(toast);
        };
        return h('div', { class: 's-toast-container', style: 'z-index: 1100' }, h(TransitionGroup, { name: 'toast-fade', tag: 'div' }, () => toasts.map(toast => h(ToastComponent, {
            ref: toast.guid,
            key: toast.guid,
            styles: toast.style ?? '',
            guid: toast.guid,
            body: toast.body,
            classes: toast.classes,
            onClick: () => click(toast),
            onRemove: () => this.$store.toast.remove(toast),
        }))));
    },
});
