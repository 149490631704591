import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = ["href", "textContent"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_s_input = _resolveComponent("s-input");
    const _component_s_button = _resolveComponent("s-button");
    const _component_s_modal = _resolveComponent("s-modal");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["s-web-address", _ctx.$attrs.class]),
        style: _normalizeStyle(_ctx.$attrs.style)
    }, [
        _renderSlot(_ctx.$slots, "default", {
            show: _ctx.show,
            clear: _ctx.clear
        }, () => [
            (_ctx.noDialog)
                ? (_openBlock(), _createBlock(_component_s_input, _mergeProps({
                    key: 0,
                    modelValue: _ctx.mutableValue,
                    class: "s-web-address__input",
                    label: _ctx.placeholder,
                    inputmode: "url"
                }, _ctx.$attrs, {
                    validation: _ctx.validation,
                    disabled: _ctx.disabled,
                    readonly: _ctx.readonly,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => {
                        _ctx.mutableValue = $event;
                        _ctx.updateValue($event);
                    }),
                    onInput: _cache[1] || (_cache[1] = ($event) => {
                        _ctx.mutableValue = $event;
                        _ctx.updateValue($event);
                    })
                }), null, 16 /* FULL_PROPS */, ["modelValue", "label", "validation", "disabled", "readonly"]))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createElementVNode("a", {
                        href: _ctx.sanitizeUrl(_ctx.isUrlStartsWithProtocol ? _ctx.modelValue : 'https://' + _ctx.modelValue),
                        target: "_blank",
                        rel: "noopener noreferrer",
                        textContent: _toDisplayString(_ctx.modelValue)
                    }, null, 8 /* PROPS */, _hoisted_1),
                    (!_ctx.disabled && !_ctx.readonly && _ctx.showClearButton && _ctx.modelValue)
                        ? (_openBlock(), _createBlock(_component_s_button, {
                            key: 0,
                            icon: "fb-common-close",
                            "aria-label": _ctx.$i18n.global('clear') + ' ' + _ctx.$i18n.global('url'),
                            onClick: _withModifiers(_ctx.clear, ["stop", "prevent"])
                        }, null, 8 /* PROPS */, ["aria-label", "onClick"]))
                        : _createCommentVNode("v-if", true),
                    (!_ctx.disabled && !_ctx.readonly)
                        ? (_openBlock(), _createBlock(_component_s_button, {
                            key: 1,
                            "aria-label": _ctx.$i18n.global('url-caption'),
                            icon: "fb-common-edit",
                            onClick: _withModifiers(_ctx.show, ["stop", "prevent"])
                        }, null, 8 /* PROPS */, ["aria-label", "onClick"]))
                        : _createCommentVNode("v-if", true)
                ], 64 /* STABLE_FRAGMENT */))
        ]),
        _createVNode(_component_s_modal, {
            modelValue: _ctx.isModalVisible,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.isModalVisible) = $event)),
            class: "s-web-address__modal",
            centralize: "",
            width: 300,
            caption: _ctx.$i18n.global('url-caption')
        }, {
            footer: _withCtx(() => [
                (_ctx.isMutableValueValid)
                    ? (_openBlock(), _createBlock(_component_s_button, {
                        key: 0,
                        success: "",
                        onClick: _ctx.okClick
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$i18n.global('ok')), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["onClick"]))
                    : _createCommentVNode("v-if", true),
                _createVNode(_component_s_button, { onClick: _ctx.cancelClick }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$i18n.global('cancel')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"])
            ]),
            default: _withCtx(() => [
                _createVNode(_component_s_input, {
                    modelValue: _ctx.modelValue,
                    label: _ctx.$i18n.global('old-value'),
                    readonly: "",
                    tabindex: "-1"
                }, null, 8 /* PROPS */, ["modelValue", "label"]),
                _createVNode(_component_s_input, _mergeProps({
                    modelValue: _ctx.mutableValue,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.mutableValue) = $event)),
                    label: _ctx.$i18n.global('new-value'),
                    inputmode: "url"
                }, _ctx.$attrs, {
                    onInput: _cache[3] || (_cache[3] = ($event) => (_ctx.mutableValue = $event))
                }), null, 16 /* FULL_PROPS */, ["modelValue", "label"])
            ]),
            _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "caption"])
    ], 6 /* CLASS, STYLE */));
}
