import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "s-data-table-filter-date-time" };
const _hoisted_2 = { class: "row" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_s_date_time_picker = _resolveComponent("s-date-time-picker");
    const _component_s_button = _resolveComponent("s-button");
    const _component_s_data_table_filter_base = _resolveComponent("s-data-table-filter-base");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_s_data_table_filter_base, {
            "is-selected": _ctx.isSelected,
            caption: _ctx.caption ?? '',
            "menu-class": "s-data-table-filter-list",
            onFilter: _ctx.filter,
            onClear: _ctx.clear,
            onShow: _ctx.show
        }, {
            default: _withCtx(() => [
                _createVNode(_component_s_date_time_picker, {
                    modelValue: _ctx.startDate,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.startDate) = $event)),
                    placeholder: _ctx.$i18n.resource('global.date', 'from'),
                    "show-clear-button": "",
                    "show-on-input-click": ""
                }, null, 8 /* PROPS */, ["modelValue", "placeholder"]),
                _createVNode(_component_s_date_time_picker, {
                    modelValue: _ctx.endDate,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.endDate) = $event)),
                    placeholder: _ctx.$i18n.resource('global.date', 'to'),
                    "show-clear-button": "",
                    "show-on-input-click": ""
                }, null, 8 /* PROPS */, ["modelValue", "placeholder"]),
                _createElementVNode("div", _hoisted_2, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sizes, (size) => {
                        return (_openBlock(), _createElementBlock(_Fragment, null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.periods, (period) => {
                                return (_openBlock(), _createBlock(_component_s_button, {
                                    key: `${period}-${size}`,
                                    icon: "",
                                    class: _normalizeClass(["m-0-i b-x2s-i border--white pr-0-i pl-0-i s-data-table-filter-date-time__period-button", `col-${_ctx.periods.length === 2 ? 6 : 4}`]),
                                    onClick: ($event) => (_ctx.setDateFilter(period, size))
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$i18n.resource('global.date', `${period}-${size}`)), 1 /* TEXT */)
                                    ]),
                                    _: 2 /* DYNAMIC */
                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "onClick"]));
                            }), 128 /* KEYED_FRAGMENT */))
                        ], 64 /* STABLE_FRAGMENT */));
                    }), 256 /* UNKEYED_FRAGMENT */))
                ])
            ]),
            _: 1 /* STABLE */
        }, 8 /* PROPS */, ["is-selected", "caption", "onFilter", "onClear", "onShow"])
    ]));
}
