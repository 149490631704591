import { computed, ref } from 'vue';
export function useShowIfSwitch(props, key, value = false) {
    const rendered = ref(value);
    const directiveIf = computed(() => {
        if (rendered.value)
            return true;
        return props[key];
    });
    const directiveShow = computed(() => {
        if (!rendered.value)
            return true;
        return props[key];
    });
    return { rendered, directiveIf, directiveShow };
}
