import { debounce, isString } from 'lodash';
import { defineComponent, h, nextTick, reactive, ref, resolveComponent, watch } from 'vue';
import './s-endless-data-table.scss';
export default defineComponent({
    name: 's-endless-data-table',
    emits: ['update:page', 'mounted:body'],
    props: {
        modelValue: { type: Array, default: () => [] },
        /** Отображаемые элементы (текущая, предыдущая и последующая страницы) */
        items: { type: Array, required: true },
        columns: { type: Array, required: true },
        rowsCountOnPage: { type: Number, required: true },
        totalRows: { type: Number, required: true },
        idField: { type: String, required: true },
        page: { type: Number, default: 1, validator: (page) => page > 0 },
        allowResize: { type: Boolean, default: false },
        allowSort: { type: Boolean, default: false },
        selectionMode: { type: String, default: null },
        detailsId: { type: String },
        loading: { type: Boolean, default: false },
        preventRowClick: { type: Boolean, default: false },
        scrollToDetails: { type: Boolean, default: true },
        showHeader: { type: Boolean, default: true },
        unfixedWidth: { type: Boolean, default: false },
        clearFilters: { type: Boolean, default: false },
        highlightedRows: { type: WeakSet },
        showSelectAllCheckbox: { type: Boolean, default: false }, // Нужно указывать свою логику для selectedAll, onUpdate:selectedAll, onClick:selectAll, onUpdate:modelValue, onSelect
    },
    setup(props, { attrs, emit, slots }) {
        let firstRowOffset = 0;
        let lastRowOffset = 0;
        const mutableCurrentPage = ref(1);
        const mutableItems = reactive({
            prev: [],
            curr: [],
            next: [],
        });
        const el = ref();
        const onBodyMounted = async (body) => {
            emit('mounted:body', body);
            if (!body.el)
                return;
            updatePage(props.page);
            setItems(props.items);
            await nextTick();
            scrollToPage();
            scrollToSelectedEntity();
            body.el.addEventListener('scroll', onScroll);
        };
        const getItemHeight = () => el.value?.$el.querySelector('tbody > tr.s-data-table__row')?.clientHeight ?? 48;
        const onScroll = debounce(() => {
            const body = el.value?.$el.querySelector('tbody');
            if (!body)
                return;
            const newPage = Math.min(Math.ceil((body.scrollTop + body.clientHeight) / (props.rowsCountOnPage * getItemHeight())), Math.ceil(props.totalRows / props.rowsCountOnPage));
            if (newPage !== mutableCurrentPage.value && newPage !== 0) {
                mutableCurrentPage.value = newPage;
                emit('update:page', newPage);
            }
        }, 200);
        const calcOffsets = () => {
            firstRowOffset =
                (mutableCurrentPage.value - 2 < 0 ? 0 : mutableCurrentPage.value - 2) * getItemHeight() * props.rowsCountOnPage;
            lastRowOffset =
                props.totalRows * getItemHeight() - (mutableCurrentPage.value + 1) * getItemHeight() * props.rowsCountOnPage;
        };
        const updatePage = (page) => {
            if (page === mutableCurrentPage.value || page > Math.ceil(props.totalRows / props.rowsCountOnPage) || page === 0)
                return;
            mutableCurrentPage.value = page;
        };
        const scrollToSelectedEntity = () => {
            const [selectedEntity = null] = props.modelValue;
            if (!selectedEntity)
                return;
            const body = el.value?.$el.querySelector('tbody');
            if (!body)
                return;
            const id = selectedEntity[props.idField];
            const selectedRow = el.value?.$el.querySelector(`tbody > tr.s-data-table__row[data-id="${isString(id) ? id : ''}"]`);
            if (selectedRow && selectedRow instanceof HTMLElement)
                body.scrollTop = selectedRow.offsetTop;
        };
        const scrollToPage = () => {
            const body = el.value?.$el.querySelector('tbody');
            if (!body)
                return;
            body.scrollTop = (mutableCurrentPage.value - 1) * props.rowsCountOnPage * getItemHeight();
        };
        const setItems = (items) => {
            calcOffsets();
            const isFirstPage = mutableCurrentPage.value === 1;
            mutableItems.prev = isFirstPage ? [] : items.slice(0, props.rowsCountOnPage);
            mutableItems.curr = items.slice(isFirstPage ? 0 : props.rowsCountOnPage, props.rowsCountOnPage * (isFirstPage ? 1 : 2));
            mutableItems.next = items.slice(isFirstPage ? props.rowsCountOnPage : props.rowsCountOnPage * 2, props.rowsCountOnPage * (isFirstPage ? 2 : 3));
        };
        watch(() => props.items, setItems);
        watch(() => props.page, page => {
            const currentPage = mutableCurrentPage.value;
            updatePage(page);
            if (currentPage !== page)
                scrollToPage();
        });
        return () => h(resolveComponent('s-data-table'), {
            ...attrs,
            modelValue: props.modelValue,
            items: [...mutableItems.prev, ...mutableItems.curr, ...mutableItems.next],
            columns: props.columns,
            idField: props.idField,
            allowSort: props.allowSort,
            allowResize: props.allowResize,
            ref: el,
            selectionMode: props.selectionMode,
            detailsId: props.detailsId,
            loading: props.loading,
            preventRowClick: props.preventRowClick,
            scrollToDetails: props.scrollToDetails,
            showHeader: props.showHeader,
            unfixedWidth: props.unfixedWidth,
            clearFilters: props.clearFilters,
            highlightedRows: props.highlightedRows,
            showSelectAllCheckbox: props.showSelectAllCheckbox,
            'onMounted:body': onBodyMounted,
        }, {
            'body-before-rows': () => firstRowOffset > getItemHeight()
                ? h('tr', {
                    style: { height: `${firstRowOffset.toString()}px` },
                    class: 's-endless-data-table__first-row',
                }, h('td', h(resolveComponent('s-loader'))))
                : undefined,
            'body-after-rows': () => lastRowOffset > getItemHeight()
                ? h('tr', {
                    style: { height: `${lastRowOffset.toString()}px` },
                    class: 's-endless-data-table__last-row',
                }, h('td', h(resolveComponent('s-loader'))))
                : undefined,
            ...slots,
        });
    },
});
