import { services } from '@fbc/core/services';
import { useMultiSemaphore } from '@fbc/core/storage';
import { DataCache } from '@fbc/core/use-cases';
import { isString, logAccess } from '@fbc/core/utils';
import { pick } from 'lodash';
import { defineStore } from 'pinia';
import { capitalize, computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { RawEndlessDataTableLoader } from '../..';
import { listViewerApi } from '../services/api';
import getCustomSorting from '../use-cases/get-custom-sorting';
import getEntityId from '../use-cases/get-entity-id';
import { useOperatorsLocalization } from '../use-cases/operators-localization';
import createLookupResultResource from '../ui/s-create-lookup-result/s-create-lookup-result.json';
const pageSize = 50;
const cacheSize = 30;
export const useListViewer = (currentModuleName) => defineStore('list-viewer-' + currentModuleName, () => {
    const { store, toast, i18n, router } = services;
    const local = (value) => i18n.extract(createLookupResultResource, value);
    const operatorsLocalization = useOperatorsLocalization(local);
    const route = useRoute();
    const routeMeta = computed(() => route.matched.at(1)?.meta);
    const moduleName = currentModuleName;
    const isSearchResultId = (id) => id.startsWith('LookupResults:');
    const loader = new RawEndlessDataTableLoader(pageSize, async (startIndex, count, parameters) => {
        if (!currentGroupId.value)
            return { data: [], totalResults: 0 };
        const { shouldGetGroupSettings = false, shouldApplyFilters = true, shouldApplySorting = true, } = parameters ?? {};
        loading.lock('data');
        const [error, result] = await listViewerApi.getData(currentGroupId.value, {
            startIndex,
            count,
            needGroupSettings: shouldGetGroupSettings,
            needTotalCount: loader.canAffectTotalResults,
            sortingColumns: shouldApplySorting ? getCustomSorting(columns.value) : undefined,
            filters: shouldApplyFilters
                ? filters.value.filter(filter => filter.isApply).map(x => pick(x, ['id', 'value']))
                : undefined,
        });
        const hasAlreadyVisited = visitedGroupIds.value.has(currentGroupId.value);
        currentGroupId.value && visitedGroupIds.value.add(currentGroupId.value);
        if (!hasAlreadyVisited && result && !isSearchResultId(currentGroupId.value)) {
            const groupDisplayName = groups.value.find(g => g.id === currentGroupId.value)?.displayName;
            logAccess({
                entityId: currentGroupId.value ?? '',
                type: routeMeta.value?.title
                    ? i18n.extractFromFlatResource(routeMeta.value?.title)
                    : (routeMeta.value?.entityName ?? moduleName),
                isListView: true,
                isTab: false,
                entityType: capitalize(moduleName),
                description: `ГСП ${groupDisplayName ?? ''}`,
            });
        }
        if (error) {
            log.prod.error(__filename, error);
            toast.error(error.message);
            idFields.value = undefined;
            columns.value = [];
        }
        if (shouldGetGroupSettings && result?.groupSettings)
            setGroupSettings(result.groupSettings);
        loading.unlock('data');
        return {
            data: result?.data.map((record, index) => ({
                ...record,
                $num: index + startIndex,
            })) ?? [],
            totalResults: loader.canAffectTotalResults ? (result?.totalCount ?? 0) : undefined,
        };
    }, new DataCache(cacheSize));
    const loading = useMultiSemaphore([
        'first-load',
        'data',
        'lookup-result',
        'removing-entities-from-static-group',
        'selection',
    ]);
    const groups = ref([]);
    const currentGroupId = ref();
    const defaultGroupId = ref();
    /**
     * Отображаемые строки в таблице\
     * Предыдущая страница, текущая и следующая
     */
    const records = ref([]);
    const selectedRecordIndexes = ref();
    const lookupResultId = computed(() => ['LookupResults', store.user.user?.id, moduleName].join(':'));
    const filters = ref([]);
    const columns = ref([]);
    const idFields = ref();
    const visitedGroupIds = ref(new Set());
    const firstLoad = async () => {
        loading.lock('first-load');
        const groupId = isString(route.query.groupId) ? route.query.groupId : undefined;
        const [error, result] = await listViewerApi.firstLoad(moduleName, { pageSize, groupId });
        loading.unlock('first-load');
        if (error) {
            log.prod.error(__filename, error);
            toast.error(i18n.global('error'));
            return;
        }
        if (!result)
            return;
        skipLoadOnGroupChange.value = true;
        groups.value = result.groups ?? [];
        currentGroupId.value = result.selectedGroupId;
        defaultGroupId.value = result.defaultGroupId ?? undefined;
        const hasAlreadyVisited = visitedGroupIds.value.has(currentGroupId.value);
        currentGroupId.value && visitedGroupIds.value.add(currentGroupId.value);
        const groupName = result.groups.find(g => g.id === result.selectedGroupId)?.displayName;
        if (!hasAlreadyVisited && !isSearchResultId(currentGroupId.value))
            logAccess({
                entityId: currentGroupId.value ?? '',
                type: routeMeta.value?.title
                    ? i18n.extractFromFlatResource(routeMeta.value?.title)
                    : (routeMeta.value?.entityName ?? moduleName),
                isListView: true,
                isTab: false,
                entityType: capitalize(moduleName),
                description: `ГСП ${groupName ?? ''}`,
            });
        setGroupSettings(result.data.groupSettings);
        const mappedRecords = result.data.data.map((record, index) => ({
            ...record,
            $num: index,
        }));
        records.value = mappedRecords;
        loader.init(mappedRecords, result.data.totalCount);
    };
    const setGroupSettings = (groupSettings) => {
        idFields.value = groupSettings.idFields;
        columns.value = groupSettings.columns;
        filters.value =
            groupSettings.filters?.map(filter => ({
                ...filter,
                isApply: false,
            })) ?? [];
    };
    const loadData = async (parameters) => {
        records.value = await loader.getData(parameters);
    };
    const refresh = () => {
        loader.clearCache();
        records.value = [];
        selectedRecordIndexes.value = undefined;
        loader.currentPage = 1;
        loader.canAffectTotalResults = true;
        loadData({ shouldGetGroupSettings: true, shouldApplyFilters: false, shouldApplySorting: false });
    };
    const setDefaultGroup = async (group) => {
        const [error] = await listViewerApi.setDefaultGroup(moduleName, group.id);
        if (error)
            return log.prod.error(__filename, error);
        defaultGroupId.value = group.id;
    };
    const deleteGroup = async (group) => {
        const isDeletingCurrentGroup = currentGroupId.value === group.id;
        if (isDeletingCurrentGroup)
            currentGroupId.value = groups.value.at(0)?.id;
        groups.value = groups.value.filter(x => x.id !== group.id);
        const [error] = await listViewerApi.deleteGroup(group.id);
        if (error) {
            log.prod.error(__filename, error);
            groups.value.push(group);
            if (isDeletingCurrentGroup)
                currentGroupId.value = group.id;
            return false;
        }
        return true;
    };
    const createLookupResult = async (filters, disableExistsConditions) => {
        loading.lock('lookup-result');
        const [error] = await listViewerApi.createLookupResult(currentGroupId.value ?? null, filters, disableExistsConditions);
        loading.unlock('lookup-result');
        if (error) {
            log.prod.error(__filename, error);
            return false;
        }
        if (currentGroupId.value === lookupResultId.value) {
            refresh();
        }
        else
            currentGroupId.value = lookupResultId.value;
        currentGroupId.value && visitedGroupIds.value.add(currentGroupId.value);
        const description = produceLookupResultDescription(filters, columns.value, operatorsLocalization, disableExistsConditions, local, i18n.global);
        logAccess({
            entityId: isSearchResultId(currentGroupId.value) ? 'SEARCHRESULT' : currentGroupId.value,
            type: routeMeta.value?.title
                ? i18n.extractFromFlatResource(routeMeta.value?.title)
                : (routeMeta.value?.entityName ?? moduleName),
            isListView: true,
            isTab: false,
            entityType: capitalize(moduleName),
            description: description,
        });
        return true;
    };
    const createStaticGroup = async (basedGroupId, displayName) => {
        const entityIds = (await getSelectedEntitiesIds())?.map(x => x.join(';'));
        if (!entityIds?.length)
            return;
        loading.lock('data');
        const [error, newStaticGroup] = await listViewerApi.createStaticGroup({
            groupId: basedGroupId,
            displayName,
            entityIds,
        });
        loading.unlock('data');
        if (error) {
            log.prod.error(__filename, error);
            toast.error(i18n.global('error'));
            return;
        }
        groups.value.push(newStaticGroup);
        currentGroupId.value = newStaticGroup.id;
    };
    const addEntitiesIntoStaticGroup = async (groupId) => {
        const entityIds = (await getSelectedEntitiesIds())?.map(x => x.join(';'));
        if (!entityIds?.length)
            return;
        loading.lock('data');
        const [error] = await listViewerApi.addEntities(groupId, entityIds);
        loading.unlock('data');
        if (error) {
            log.prod.error(__filename, error);
            toast.error(i18n.global('error'));
            return;
        }
        currentGroupId.value = groupId;
    };
    const removeEntitiesFromStaticGroup = async () => {
        if (!selectedRecordIndexes.value?.length ||
            !currentGroupId.value ||
            loading.isLoading('removing-entities-from-static-group').value)
            return;
        loading.lock('removing-entities-from-static-group');
        const selectedEntitiesIds = await getSelectedEntitiesIds();
        if (!selectedEntitiesIds?.length)
            return;
        const [error] = await listViewerApi.removeEntities(currentGroupId.value, selectedEntitiesIds.map(x => x.join(';')));
        loading.unlock('removing-entities-from-static-group');
        if (error) {
            log.prod.error(__filename, error);
            toast.error(i18n.global('error'));
            return;
        }
        refresh();
    };
    const getSelectedEntitiesIds = async () => {
        if (!currentGroupId.value || !selectedRecordIndexes.value || !idFields.value || !records.value.length)
            return undefined;
        const minSelectedIndex = Math.min(...selectedRecordIndexes.value);
        const maxSelectedIndex = Math.max(...selectedRecordIndexes.value);
        const firstAvailableRecordIndex = records.value[0].$num;
        const lastAvailableRecordIndex = records.value[records.value.length - 1].$num;
        if (firstAvailableRecordIndex <= minSelectedIndex && lastAvailableRecordIndex >= maxSelectedIndex)
            return records.value
                .filter(record => selectedRecordIndexes.value.includes(record.$num))
                .map(record => getEntityId(record, idFields.value));
        loading.lock('selection');
        const [error, result] = await listViewerApi.getIdsByIndexes(currentGroupId.value, {
            indexes: selectedRecordIndexes.value,
            filters: filters.value.filter(filter => filter.isApply).map(x => pick(x, ['id', 'value'])),
            sortingColumns: getCustomSorting(columns.value),
        });
        loading.unlock('selection');
        if (error) {
            log.prod.error(__filename, error);
            toast.error(error.message);
            idFields.value = undefined;
            columns.value = [];
        }
        return result;
    };
    const skipLoadOnGroupChange = ref(false);
    watch(() => currentGroupId.value, () => {
        router.replace({
            query: currentGroupId.value
                ? {
                    groupId: currentGroupId.value,
                }
                : undefined,
        });
        if (skipLoadOnGroupChange.value) {
            skipLoadOnGroupChange.value = false;
            return;
        }
        refresh();
    });
    watch(() => route.query.groupId, newGroupId => {
        if (isString(newGroupId) && currentGroupId.value !== newGroupId)
            currentGroupId.value = newGroupId;
    });
    return {
        moduleName,
        selectedRecordIndexes,
        idFields,
        records,
        columns,
        loader,
        groups,
        loading,
        currentGroupId,
        defaultGroupId,
        lookupResultId,
        refresh,
        loadData,
        deleteGroup,
        setDefaultGroup,
        filters,
        firstLoad,
        createLookupResult,
        createStaticGroup,
        addEntitiesIntoStaticGroup,
        removeEntitiesFromStaticGroup,
        getSelectedEntitiesIds,
    };
});
const produceLookupResultDescription = (filters, columns, operatorsLocalization, disableExistsConditions, local, global) => {
    const searchCriteriaList = filters.map(f => {
        const column = columns.find(v => v.id === f.columnId);
        const value = (f.value && typeof f.value === 'string') || f.value === 'number' || f.value === 'boolean'
            ? `"${f.value}"`
            : Array.isArray(f.value)
                ? f.value.join(', ')
                : '';
        return `[${column?.displaySettings.caption ?? ''}] ${operatorsLocalization[f.operator] ?? ''} ${value}`;
    });
    const searchCriteria = searchCriteriaList.join(';\n');
    return `${global('lookup-results')}:${!searchCriteria ? ` ${global('no-filters')}` : '\n' + searchCriteria}${!disableExistsConditions ? `\n${local('enable-exists-conditions')}` : ''}`;
};
