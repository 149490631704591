export const getCompositeKey = (key) => Object.entries(key)
    .filter(([, value]) => typeof value !== 'function')
    .map(([key, value]) => `${key}='${value}'`)
    .join(',');
export const getEntityOdataKey = (key) => typeof key === 'object' ? getCompositeKey(key) : `'${key}'`;
export const getEntityBusinessRulesKey = (key) => Array.isArray(key)
    ? 'entityIds=' + key.join('&entityIds=')
    : typeof key === 'object'
        ? getEntityBusinessRulesKey(Object.values(key))
        : 'entityId=' + key;
