import { services } from '@fbc/core/services';
async function wrapForaApi(f, map = x => x) {
    const response = await f();
    const result = response.status !== 204 && response.statusText !== 'No Content'
        ? (await response.json())
        : null;
    return response.ok ? [undefined, map(result)] : [result, undefined];
}
const formatGetParameters = (parameters) => Object.entries(parameters)
    .flatMap(x => (Array.isArray(x[1]) ? x[1].map((y) => `${x[0]}=${y}`) : [x.join('=')]))
    .join('&');
export class ListViewerApi {
    path;
    fetch = services.authentication.fetch;
    /**
     * @constructor
     * @param path Путь к Web API
     */
    constructor(path = '/api/') {
        this.path = path;
    }
    getGroupList = (moduleName) => wrapForaApi(() => this.fetch(this.path + `listViewer/${moduleName}/groupList`, {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
    }));
    getDefaultGroupName = async (moduleName) => {
        const response = await this.fetch(this.path + `listViewer/${moduleName}/defaultGroup`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
        });
        return response.ok
            ? [undefined, response.status !== 204 && response.statusText !== 'No Content' ? await response.text() : null]
            : [(await response.json())[0], undefined];
    };
    setDefaultGroup = (moduleName, groupId) => wrapForaApi(() => this.fetch(this.path + `listViewer/${moduleName}/defaultGroup`, {
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify(groupId),
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
    }));
    getData = (groupId, parameters) => wrapForaApi(() => this.fetch(this.path + `listViewer/${groupId}/data`, {
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify(parameters),
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
    }));
    deleteGroup = (groupId) => wrapForaApi(() => this.fetch(this.path + 'listViewer/' + groupId, {
        method: 'DELETE',
        mode: 'cors',
        credentials: 'include',
    }));
    shareGroup = (groupId, roles) => wrapForaApi(() => this.fetch(this.path + `listViewer/${groupId}/share`, {
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify(roles),
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
    }));
    createLookupResult = (groupId, filters, disableExistsConditions) => wrapForaApi(() => this.fetch(this.path + `listViewer/createLookupResult`, {
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify({
            groupId,
            filters,
            disableExistsConditions,
        }),
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
    }));
    createStaticGroup = (parameters) => wrapForaApi(() => this.fetch(this.path + `listViewer/createStaticGroup`, {
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify(parameters),
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
    }));
    addEntities = (groupId, entityIds) => wrapForaApi(() => this.fetch(this.path + `listViewer/${groupId}/addEntities`, {
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify(entityIds),
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
    }));
    removeEntities = (groupId, entityIds) => wrapForaApi(() => this.fetch(this.path + `listViewer/${groupId}/removeEntities`, {
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify(entityIds),
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
    }));
    getIdsByIndexes = (groupId, parameters) => wrapForaApi(() => this.fetch(this.path + `listViewer/${groupId}/idsByIndexes`, {
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify(parameters),
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
    }));
    firstLoad = (moduleName, parameters) => wrapForaApi(() => this.fetch(this.path + `listViewer/${moduleName}/firstLoad?` + formatGetParameters(parameters), {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
    }));
    getFiltersParameters = (groupId, filterId) => wrapForaApi(() => this.fetch(this.path + `listViewer/${groupId}/filters/${filterId}/parameters`, {
        headers: {
            'content-type': 'application/json',
        },
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
    }));
}
