import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_s_input = _resolveComponent("s-input");
    const _component_s_calendar = _resolveComponent("s-calendar");
    const _component_s_popup = _resolveComponent("s-popup");
    return (_openBlock(), _createElementBlock("div", {
        ref: "root",
        class: _normalizeClass(["s-date-time-picker", _ctx.$attrs.class]),
        style: _normalizeStyle(_ctx.$attrs.style)
    }, [
        _renderSlot(_ctx.$slots, "default", {
            value: _ctx.modelValue,
            show: _ctx.show
        }, () => [
            _createVNode(_component_s_input, {
                label: _ctx.label,
                readonly: _ctx.readonly,
                disabled: _ctx.disabled,
                buttons: _ctx.buttons,
                "model-value": _ctx.dateTimeView,
                placeholder: _ctx.placeholder,
                detail: _ctx.detail,
                bordered: _ctx.bordered,
                required: _ctx.required,
                type: "mask",
                mask: _ctx.dateOnly ? '##.##.####' : '##.##.#### ##:##',
                onInput: _ctx.onInput,
                onPaste: _ctx.onInput,
                onFocus: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('focus', $event))),
                onKeypress: _withKeys(_ctx.onApply, ["enter"])
            }, null, 8 /* PROPS */, ["label", "readonly", "disabled", "buttons", "model-value", "placeholder", "detail", "bordered", "required", "mask", "onInput", "onPaste", "onKeypress"])
        ]),
        _createVNode(_component_s_popup, {
            visible: _ctx.visible,
            "onUpdate:visible": _cache[3] || (_cache[3] = ($event) => ((_ctx.visible) = $event)),
            position: _ctx.position,
            "transition-name": "s-date-time-picker__menu-transition",
            class: _normalizeClass(_ctx.popupClass ?? 's-date-time-picker__popup'),
            centralizeOnMobile: "",
            backdrop: _ctx.$store.app.screen.device !== 'desktop',
            excludedElements: { btn: _ctx.root?.querySelector('.fb-common-calendar') }
        }, {
            default: _withCtx(() => [
                _createVNode(_component_s_calendar, {
                    modelValue: _ctx.calendarSelectedValue,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.calendarSelectedValue) = $event)),
                    max: _ctx.max,
                    min: _ctx.min,
                    initial: _ctx.initial,
                    monthSelectClass: _ctx.monthSelectClass,
                    dateOnly: _ctx.dateOnly,
                    timepickerMenuClass: _ctx.timepickerMenuClass,
                    "onClick:cancel": _cache[2] || (_cache[2] = ($event) => (_ctx.setPopupVisible(false))),
                    "onClick:apply": _ctx.onApply
                }, null, 8 /* PROPS */, ["modelValue", "max", "min", "initial", "monthSelectClass", "dateOnly", "timepickerMenuClass", "onClick:apply"])
            ]),
            _: 1 /* STABLE */
        }, 8 /* PROPS */, ["visible", "position", "class", "backdrop", "excludedElements"])
    ], 6 /* CLASS, STYLE */));
}
