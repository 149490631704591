import { services } from '@fbc/core/services';
import { checkIsLoginPage, initClientAuth, registerDefaultBusEventHandlers } from '@fbc/core/utils';
import { DialogsContainerComponent, ModuleDialogContainerComponent, PopupContainerComponent, ToastContainerComponent, } from '@fbc/ui/components';
import { defineComponent, h, ref, resolveComponent, watch } from 'vue';
import { RouterView, useRoute } from 'vue-router';
import './App.scss';
import { showLoginMessage } from './show-login-message';
registerDefaultBusEventHandlers();
function useMeta() {
    const { store, i18n } = services;
    const route = useRoute();
    watch(() => String(route.name) + route.fullPath, () => {
        // Переходы внутри ОЭФ, не будут менять название вкладки браузера
        if (route.matched.some(x => x.meta.moduleType === 'EntityPage' && route.params.id))
            return;
        store.title = [...store.defaultTitle];
        store.description = i18n.extractFromFlatResource(route.meta.description ?? {});
        setTitle();
        setDescription();
    });
    watch(() => store.title, setTitle);
    watch(() => store.description, setDescription);
    const keys = new Set(['route', 'base']);
    function setTitle() {
        const values = {
            route: i18n.extractFromFlatResource(route.meta.title ?? {}),
            base: store.app.title.base,
        };
        document.title = store.title
            .map(x => x && (values[x] ?? (keys.has(x) ? '' : x)))
            .filter(Boolean)
            .join(store.app.title.divider);
    }
    const metaDescription = document.head.querySelector('meta[name="description"]');
    function setDescription() {
        if (!metaDescription)
            return;
        metaDescription.content = (store.description || i18n.extractFromFlatResource(route.meta.description ?? {})) ?? '';
    }
}
const loaderStyle = ref();
const loader = () => h('div', { id: 'global-loader' }, [
    loaderStyle.value ? h('div', { style: loaderStyle.value }) : undefined,
    h('span'),
    h('span'),
    h('span'),
]);
export const AppComponent = defineComponent({
    name: 'fora-crm',
    props: { manifest: { type: Object, required: true } },
    setup(props) {
        loaderStyle.value = props.manifest.app.loader;
        const loading = ref(true);
        const { store, router } = services;
        store.app.registerBase(props.manifest);
        if (process.env.NODE_ENV === 'development') {
            global.serviceProvider = services;
            log.dev.debug(__filename, 'window.serviceProvider has been set', global.serviceProvider);
        }
        const isLoginPage = checkIsLoginPage();
        if (isLoginPage)
            loading.value = false;
        else
            initClientAuth(store, router).then(() => (loading.value = false));
        useMeta();
        return () => loading.value
            ? loader()
            : [
                h('app', {
                    id: 'app',
                    key: [store.app.language ?? store.app.defaultLanguage, store.app.isDarkTheme].join(':'),
                    class: { 'navbar--collapsed': store.app.isNavbarCollapsed },
                }, h(RouterView)),
                store.loading.isLocked ? loader() : undefined,
                h(PopupContainerComponent),
                h(ToastContainerComponent),
                h(DialogsContainerComponent),
            ];
    },
});
export const MainPageComponent = defineComponent({
    name: 's-main-page',
    setup() {
        const loading = ref(true);
        async function onLoaded() {
            const navbarPromise = services.store.app.moduleRegistry.load('navbar');
            const headerPromise = services.store.app.moduleRegistry.load('header');
            const preload = services.store.app.manifest.app.preload?.map(services.store.app.moduleRegistry.load) ?? [];
            await Promise.all([navbarPromise, headerPromise, ...preload]);
            showLoginMessage();
            services.bus.on('auth/accessToken/recalled', async () => {
                if (!(await services.authentication.isTokenViable()))
                    services.router.replace({ name: 'logoff' });
            });
            loading.value = false;
        }
        onLoaded();
        return () => loading.value
            ? loader()
            : [
                h(resolveComponent('s-page'), {
                    id: 'header',
                    name: 'header',
                    placeholder: { props: { id: 'header', surface: true } },
                }),
                h(resolveComponent('s-page'), {
                    id: 'navbar',
                    name: 'navbar',
                    placeholder: { props: { id: 'navbar', surface: true } },
                }),
                h('main', { id: 'main' }, h(RouterView)),
                h(ModuleDialogContainerComponent),
            ];
    },
});
