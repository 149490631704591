import { services } from '@fbc/core/services';
import { createUniqueId } from '@fbc/core/utils';
import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
function timeFilter(n) {
    return n < 10000 ? `${Math.round(n)} ms` : `${(n / 1000).toFixed(1)} s`;
}
function isLighthouse() {
    if (isLighthouse.isLighthouse === undefined)
        isLighthouse.isLighthouse = window.navigator.userAgent.includes('Lighthouse');
    return isLighthouse.isLighthouse;
}
isLighthouse.isLighthouse = undefined;
/**
 * Метрики собираются через браузерный механизм performance и их можно посмотреть через него, если стор умрет или недоступен
 */
export const usePerformanceStore = defineStore({
    id: 'performance',
    state: () => ({
        navigationTiming: performance.getEntriesByType('navigation').at(0),
        metrics: [],
        memory: performance.memory,
        intervalId: undefined,
    }),
    getters: {
        coreMetrics: state => state.metrics
            .filter(x => x.type === 'core')
            .map(x => ({
            name: x.measure.name,
            durationText: timeFilter(x.measure.duration),
            duration: x.measure.duration,
        }))
            .sort((a, b) => b.duration - a.duration),
        view: state => state.metrics.map(x => ({
            id: createUniqueId(),
            type: x.type,
            name: x.measure.name,
            duration: x.measure.duration,
            startTime: x.measure.startTime,
            data: JSON.stringify(x.data),
        })),
    },
    actions: {
        addMetric(...metrics) {
            this.metrics.push(...metrics);
            if (this.metrics.length > 1000)
                this.metrics = this.metrics.slice(500);
        },
        init() {
            if (isLighthouse())
                return;
            this.navigationTiming = performance.getEntriesByType('navigation').at(0);
            if (!this.navigationTiming.loadEventEnd)
                return setTimeout(this.init, 1000);
            this.addMetric({
                type: 'tech',
                measure: performance.measure('Время установки соединения TCP', {
                    start: this.navigationTiming.connectStart,
                    end: this.navigationTiming.connectStart,
                }),
            }, {
                type: 'tech',
                measure: performance.measure('Время установки безопасного подключения', {
                    start: this.navigationTiming.secureConnectionStart,
                    end: this.navigationTiming.requestStart,
                }),
            }, {
                type: 'tech',
                measure: performance.measure('Время поиска домена', {
                    start: this.navigationTiming.domainLookupStart,
                    end: this.navigationTiming.domainLookupEnd,
                }),
            }, {
                type: 'core',
                measure: performance.measure('Время первого отклика сервера', {
                    start: this.navigationTiming.requestStart,
                    end: this.navigationTiming.responseStart,
                }),
            }, {
                type: 'core',
                measure: performance.measure('Время получения страницы', {
                    start: this.navigationTiming.responseStart,
                    end: this.navigationTiming.responseEnd,
                }),
            }, {
                type: 'tech',
                measure: performance.measure('Время выполнения скриптов DOMContentLoaded', {
                    start: this.navigationTiming.domContentLoadedEventStart,
                    end: this.navigationTiming.domContentLoadedEventEnd,
                }),
            }, {
                type: 'core',
                measure: performance.measure('Парсер HTML завершил работу над основным документом', {
                    start: this.navigationTiming.domContentLoadedEventEnd,
                    end: this.navigationTiming.domComplete,
                }),
            }, {
                type: 'tech',
                measure: performance.measure('Обработка события load', {
                    start: this.navigationTiming.loadEventStart,
                    end: this.navigationTiming.loadEventEnd,
                }),
            }, {
                type: 'core',
                measure: performance.measure('Время от начала установки соединения до завершения события load страницы', {
                    start: this.navigationTiming.connectStart,
                    end: this.navigationTiming.loadEventEnd,
                }),
            });
        },
        /** Начало отсчета метрики, если не будет вызван метод end с тем же label, то ничего не запишется в итоге */
        start(label) {
            if (isLighthouse())
                return;
            performance.mark(label + ':start');
        },
        /** Конец отсчета метрик, label должен быть равен label из start */
        end(label, name, type, data) {
            if (isLighthouse())
                return;
            performance.mark(label + ':end');
            try {
                const measure = performance.measure(name, label + ':start', label + ':end');
                const metric = { type, measure, data };
                this.addMetric(metric);
                performance.clearMarks(label + ':start');
                performance.clearMarks(label + ':end');
            }
            catch {
                performance.clearMarks(label + ':end');
            }
        },
        /** Вызывается в роутере для страниц автоматический */
        startPageLoad() {
            if (isLighthouse())
                return;
            performance.clearMarks('page load:start');
            performance.mark('page load:start');
        },
        /** Необходимо вызывать, когда примерно страница загружена. Для ОЭФ, использующих useEntityStore вызывается там, для кастомных страниц надо вызывать самому */
        endPageLoad() {
            const route = services.router.currentRoute.value;
            this.end('page load', `Загрузка страницы ${route.fullPath}`, 'core');
        },
        getMetricsOver(limitMs) {
            return this.metrics
                .filter(x => x.measure.duration > limitMs)
                .sort((a, b) => b.measure.duration - a.measure.duration);
        },
        /** Набор бессмысленных операций примерно на 200-280мс для хрома, можно переписать потом. В FF почему-то работает в 2-3 раза быстрее*/
        testPerformance() {
            if (isLighthouse())
                return;
            this.start('test');
            const v = Array.from({ length: 1000000 })
                .map((_, i) => Math.pow(i, Math.PI))
                .reduce((p, c) => (p > c ? p : c), 0);
            this.end('test', 'Тест производительности', 'core', { chrome: '200-280мс', firefox: '60-90мс' });
            return v;
        },
        getAvgCpuPerformance(num = 3) {
            const start = performance.now();
            for (let i = 0; i < num; i++)
                this.testPerformance();
            const performanceMetrics = this.metrics
                .filter(x => x.measure.name === 'Тест производительности' && x.measure.startTime >= start)
                .map(x => 240 / x.measure.duration);
            return Number(((performanceMetrics.reduce((p, c) => p + c, 0) / Math.max(performanceMetrics.length, 1)) * 100).toFixed(2));
        },
        async getClientData() {
            const { architecture, platform, platformVersion, fullVersionList, bitness } = (await window.navigator.userAgentData?.getHighEntropyValues([
                'architecture',
                'platform',
                'platformVersion',
                'fullVersionList',
                'bitness',
            ])) ?? {};
            const clientData = {
                userAgent: window.navigator.userAgentData && fullVersionList?.length && platform
                    ? undefined
                    : window.navigator.userAgent,
                platform: platform
                    ? `${platform} ${platformVersion ?? 'unknown'}`
                    : (window.navigator.oscpu ?? window.navigator.platform),
                bitness,
                architecture,
                brands: fullVersionList
                    ?.filter(x => x.brand !== 'Not)A;Brand' &&
                    x.brand !== 'Not;A=Brand' &&
                    x.brand !== 'Not-A.Brand' &&
                    x.brand !== 'Not/A)Brand')
                    .map(x => `${x.brand}@${x.version}`)
                    .join('\n'),
                downlink: window.navigator.connection?.downlink,
                connectionEffectiveType: window.navigator.connection?.effectiveType,
                rtt: window.navigator.connection?.rtt,
                minDeviceMemory: window.navigator.deviceMemory,
                screen: `${screen.width}x${screen.height}`,
                documentSize: `${window.innerWidth}x${window.innerHeight}`,
                language: window.navigator.language,
            };
            return Object.fromEntries(Object.entries(clientData).filter(x => x[1] !== undefined));
        },
        /** Получает браузерные метрики по запросам */
        getRequestsPerformanceTimings() {
            return performance
                .getEntriesByType('resource')
                .filter((x) => x.initiatorType === 'fetch' ||
                x.initiatorType === 'xmlhttprequest')
                .map(x => ({
                duration: x.duration,
                name: x.name.replace(services.store.app.api, '').replace(location.origin, ''),
                start: x.fetchStart,
                end: x.responseEnd,
            }))
                .sort((a, b) => b.duration - a.duration);
        },
        startPollMemory(pollInterval = 500) {
            this.intervalId = window.setInterval(() => (this.memory = performance.memory), pollInterval);
        },
        stopPollMemory() {
            window.clearInterval(this.intervalId);
        },
        async export() {
            this.memory = performance.memory;
            const [{ utils, writeFile }, clientData] = await Promise.all([services['import:xlsx'], this.getClientData()]);
            const metrics = utils.json_to_sheet([...this.view]
                .sort((a, b) => a.startTime - b.startTime)
                .map(x => Object.fromEntries(Object.entries(x)
                .filter(x => x[0] !== 'id')
                .map(x => {
                switch (x[0]) {
                    case 'startTime':
                    case 'duration':
                        return [x[0], { v: Math.round(x[1] * 10) / 10, t: 's' }];
                }
                return [x[0], x[1]];
            }))));
            metrics['!cols'] = [{ width: 20 }, { width: 100 }, { width: 30 }, { width: 30 }, { width: 100 }];
            writeFile({
                Sheets: {
                    metrics,
                    memory: utils.json_to_sheet([
                        {
                            used: this.memory?.usedJSHeapSize,
                            allocated: this.memory?.totalJSHeapSize,
                            limit: this.memory?.jsHeapSizeLimit,
                        },
                    ]),
                    client: utils.json_to_sheet([clientData]),
                },
                SheetNames: ['metrics', 'memory'],
            }, `performance-${DateTime.local().toFormat('dd.MM.yyyy hh:mm')}.xlsx`);
        },
    },
});
