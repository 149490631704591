import { services } from '@fbc/core/services';
import { throttle } from 'lodash';
import { reactive, ref, watch } from 'vue';
const oneMinute = 60_000;
const minIdleMinutes = 5;
const events = ['mousemove', 'mousedown', 'resize', 'keydown', 'touchstart', 'wheel'];
function useIdle(timeout = oneMinute) {
    const isIdle = ref(false);
    let timer;
    const start = () => {
        for (const event of events)
            window.addEventListener(event, onEvent, { passive: true });
        reset();
    };
    const reset = () => {
        isIdle.value = false;
        clearTimeout(timer);
        timer = window.setTimeout(() => {
            isIdle.value = true;
        }, timeout);
    };
    const clear = () => {
        for (const event of events)
            window.removeEventListener(event, onEvent, false);
        clearTimeout(timer);
    };
    const onEvent = throttle(reset, 50);
    return {
        isIdle,
        start,
        reset,
        clear,
    };
}
export function useForaIdle() {
    let isInitialized = false;
    const idle = ref(useIdle(minIdleMinutes * oneMinute));
    async function initialize(customSettings) {
        if (isInitialized)
            return;
        let savedTimeout = sessionStorage.getItem('fora.logoff');
        if (savedTimeout === null) {
            const userTimeout = await customSettings.load('Logoff', 'Timeout', __filename);
            sessionStorage.setItem('fora.logoff', userTimeout?.value ?? '-1');
            savedTimeout = sessionStorage.getItem('fora.logoff');
        }
        if (Number.isNaN(savedTimeout) || Number(savedTimeout) < minIdleMinutes)
            return;
        idle.value.clear();
        idle.value = reactive(savedTimeout ? useIdle(Number(savedTimeout) * oneMinute) : useIdle(minIdleMinutes * oneMinute));
        idle.value.start();
        watch(() => idle.value.isIdle, (newIdle) => {
            if (!newIdle)
                return;
            services.toast({ body: services.i18n.global('on-inactive-logoff'), delay: -1 });
            services.router.push({ name: 'logoff' });
        });
        isInitialized = true;
    }
    function clear() {
        isInitialized = false;
        idle.value.clear();
    }
    return { initialize, clear };
}
