import { services } from '@fbc/core/services';
import { defineComponent, nextTick, reactive, ref, watch } from 'vue';
import { useDrag } from '../use/use-drag';
import { useFocusTrap } from '../use/use-focus-trap';
export default defineComponent({
    name: 's-modal',
    emits: ['click:ok', 'click:cancel', 'update:centralize', 'update:modelValue', 'hidden', 'shown'],
    props: {
        modelValue: { type: Boolean, required: true },
        caption: { type: String },
        showOk: { type: Boolean },
        showClose: { type: Boolean },
        centralize: { type: Boolean },
        width: { type: Number },
        height: { type: Number },
        draggable: { type: Boolean, default: () => services.store.app.screen.device === 'desktop' },
        position: { type: Object },
        mobileFullscreen: { type: Boolean },
    },
    slots: Object,
    setup(props, { emit }) {
        const { variables } = services;
        const visible = ref(false);
        const modalRef = ref();
        const rootClasses = reactive({
            's-modal--shown': false,
        });
        const { left, top, drag } = useDrag(window.innerWidth * 0.2, window.innerHeight * 0.15);
        const { activateFocusTrap, deactivateFocusTrap } = useFocusTrap();
        function toCenter() {
            if (!modalRef.value)
                return;
            left.value = (window.innerWidth - modalRef.value.clientWidth) * 0.5;
            top.value = (window.innerHeight - modalRef.value.clientHeight) * 0.5;
        }
        function show() {
            left.value =
                props.position?.left ??
                    (!props.width ? window.innerWidth * 0.2 : Math.max((window.innerWidth - props.width) * 0.5, 0));
            top.value =
                props.position?.top ??
                    (!props.height ? window.innerHeight * 0.15 : Math.max((window.innerHeight - props.height) * 0.5, 0));
            visible.value = true;
            nextTick(() => activateFocusTrap(modalRef.value));
            emit('update:modelValue', true);
            setTimeout(() => {
                rootClasses['s-modal--shown'] = true;
                emit('shown');
                const height = modalRef.value?.clientHeight;
                if (height && height + top.value > window.innerHeight)
                    top.value = Math.max(0, window.innerHeight - height - variables.sm);
                onCentralize();
            }, 250);
        }
        function onCentralize() {
            if (!props.centralize || !props.modelValue)
                return;
            nextTick(() => {
                toCenter();
                nextTick(toCenter);
                emit('update:centralize', false);
            });
        }
        function hide() {
            if (!visible.value)
                return;
            rootClasses['s-modal--shown'] = false;
            deactivateFocusTrap();
            emit('update:modelValue', false);
            setTimeout(() => {
                visible.value = false;
                emit('hidden');
            }, 100);
        }
        function okClick() {
            hide();
            emit('click:ok');
        }
        function cancelClick() {
            hide();
            emit('click:cancel');
        }
        watch(() => props.centralize, onCentralize, { immediate: true });
        watch(() => props.position, (newValue, oldValue) => {
            if (newValue?.top === oldValue?.top && newValue?.left === oldValue?.left)
                return;
            left.value = newValue?.left ?? left.value;
            top.value = newValue?.top ?? top.value;
        });
        watch(() => props.modelValue, (newValue, oldValue) => {
            if (newValue === oldValue)
                return;
            if (props.modelValue)
                show();
            else
                hide();
        }, { immediate: true });
        return {
            visible,
            modalRef,
            left,
            top,
            rootClasses,
            drag,
            okClick,
            cancelClick,
            mouseDownOnHeader: (e) => {
                if (props.draggable)
                    drag(modalRef.value, e);
                return false;
            },
        };
    },
});
