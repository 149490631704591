<template>
	<div class="s-spinner" />
</template>

<style lang="scss">
.s-spinner {
	--spinner-size: 16px;
	--spinner-stroke-width: calc(var(--spinner-size) / 6);
	--spinner-color: currentColor;
	--spinner-animation-timing-function: linear;
	--spinner-animation-duration: 1s;
	width: var(--spinner-size);
	height: var(--spinner-size);
	border-width: var(--spinner-stroke-width);
	border-style: solid;
	border-color: var(--spinner-color) var(--spinner-color) var(--spinner-color) transparent;
	border-radius: 50%;
	transform: rotate(0deg);
	animation: var(--spinner-animation-timing-function) var(--spinner-animation-duration) infinite s-spinner-animation;
	flex-shrink: 0;
}

@keyframes s-spinner-animation {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}
</style>
