import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "s-data-table-filter" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_s_button = _resolveComponent("s-button");
    const _component_s_modal = _resolveComponent("s-modal");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_s_button, {
            ref: "button",
            class: _normalizeClass(["s-data-table-filter__button-filter pl-0-i pr-0-i", { 's-data-table-filter__button-filter--selected': _ctx.isSelected }]),
            icon: "fb-common-filter",
            "aria-label": _ctx.$i18n.global('show-filter', { column: _ctx.caption ?? '' }),
            onClick: _withModifiers(_ctx.show, ["stop"])
        }, null, 8 /* PROPS */, ["aria-label", "class", "onClick"]),
        _createVNode(_component_s_modal, {
            ref: "modal",
            modelValue: _ctx.visible,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.visible) = $event)),
            caption: _ctx.caption,
            draggable: false,
            noTransition: "",
            position: _ctx.position,
            class: _normalizeClass(["s-data-table-filter__menu s-modal--rounded", _ctx.menuClass]),
            width: _ctx.modalWidth,
            onShown: _cache[1] || (_cache[1] = ($event) => (_ctx.$nextTick(_ctx.recalculatePosition))),
            onChange: _cache[2] || (_cache[2] = ($event) => ($event !== _ctx.visible && _ctx.$emit('update:visible', $event)))
        }, {
            footer: _withCtx(() => [
                _createVNode(_component_s_button, {
                    success: "",
                    onClick: _ctx.clickOk
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$i18n.global('ok')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"]),
                _createVNode(_component_s_button, { onClick: _ctx.clickCancel }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$i18n.global('cancel')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"]),
                _createVNode(_component_s_button, {
                    error: "",
                    onClick: _ctx.clickClear
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$i18n.global('clear')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"])
            ]),
            default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3 /* FORWARDED */
        }, 8 /* PROPS */, ["modelValue", "caption", "position", "class", "width"])
    ]));
}
