import { parseJson } from '@fbc/core/utils';
import { defineStore } from 'pinia';
import { reactive } from 'vue';
import { deleteUserOption, getUserOption, setUserOption } from './../../services/api/common';
const isUserOption = (x) => Boolean(typeof x === 'object' &&
    x &&
    'name' in x &&
    typeof x.name === 'string' &&
    'category' in x &&
    typeof x.category === 'string' &&
    'userId' in x &&
    typeof x.userId === 'string');
export const useUserOptionStore = (cache, app, user) => defineStore('user-option', () => {
    const loadingMap = reactive(new Map());
    function add(option, useSessionStorage = false) {
        const key = `fora.user-options:${option.userId}:${option.category}:${option.name}`;
        if (useSessionStorage)
            sessionStorage.setItem(key, JSON.stringify(option));
        else
            cache.set(key, option, app.manifest.app.optionCacheDuration);
    }
    function get(category, name, useSessionStorage = false) {
        const key = `fora.user-options:${user.user?.id ?? 'unknown'}:${category}:${name}`;
        return useSessionStorage ? parseJson(sessionStorage.getItem(key), isUserOption) : cache.get(key, isUserOption);
    }
    function load(category, name, fileName, useSessionStorage = false) {
        const exist = get(category, name, useSessionStorage);
        if (exist) {
            log.dev.debug(fileName, `get user option from cache: `, exist);
            return exist;
        }
        const key = category + '|' + name;
        if (loadingMap.has(key))
            return loadingMap.get(key);
        if (process.env.NODE_ENV === 'development' && fileName !== 'never')
            log.dev.debug(fileName, `load user option: `, category, name);
        const promise = getUserOption({ category, name }, fileName).then(([error, setting]) => {
            if (error) {
                loadingMap.delete(key);
                log.prod.error(fileName, error);
                return null;
            }
            loadingMap.delete(key);
            if (!setting) {
                if (user.user?.id)
                    add({ name, category, value: null, userId: user.user.id }, useSessionStorage);
                return null;
            }
            add(setting, useSessionStorage);
            return setting;
        });
        loadingMap.set(key, promise);
        return promise;
    }
    async function update(option, fileName) {
        const error = await setUserOption(option, fileName);
        if (error !== true)
            log.prod.error(fileName, error);
        removeFromCache(option.category, option.name);
        load(option.category, option.name, fileName);
        return error === true;
    }
    async function deleteOption(option, fileName) {
        const error = await deleteUserOption(option, fileName);
        if (error !== true)
            log.prod.error(fileName, error);
        removeFromCache(option.category, option.name);
        return error === true;
    }
    function removeFromCache(category, name) {
        const key = `fora.user-options:${user.user?.id ?? 'unknown'}:${category}:${name}`;
        sessionStorage.removeItem(key);
        cache.remove(key);
    }
    return { load, update, delete: deleteOption, removeFromCache };
})();
