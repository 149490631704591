import { services } from '@fbc/core/services';
import { computed } from 'vue';
export function getCachedModule(name, initFn) {
    const { store } = services;
    const unregister = () => {
        if (store.dynamic.has(name)) {
            const currentStore = store.dynamic[name];
            currentStore.$dispose();
            currentStore.$reset();
            if (currentStore.unregister)
                currentStore.unregister();
        }
        store.dynamic.delete(name);
    };
    if (store.dynamic.has(name))
        return { module: store.dynamic[name], unregister };
    const module = initFn();
    store.dynamic.register(name, module);
    return { module, unregister };
}
export function getStoreDynamicModule(name) {
    const { store } = services;
    if (store.dynamic.has(name))
        return store.dynamic[name];
}
export const useModelBinding = (key, props, emit) => computed({
    get() {
        return props[key];
    },
    set(newValue) {
        emit(`update:${key}`, newValue);
    },
});
