export const isI18nRecord = (x) => Boolean(typeof x === 'object' && x && 'default' in x && Object.values(x).every(y => typeof y === 'string'));
export const isMenuBase = (x) => Boolean(typeof x === 'object' &&
    x &&
    (!('securedAction' in x) || typeof x.securedAction === 'string') &&
    'text' in x &&
    isI18nRecord(x.text) &&
    (!('tooltip' in x) || isI18nRecord(x.tooltip)) &&
    (!('order' in x) || typeof x.order === 'number'));
export const isMenuLink = (x) => isMenuBase(x) &&
    (!('icon' in x) || isIcon(x.icon)) &&
    (!('link' in x) || isLink(x.link)) &&
    (!('group' in x) ||
        typeof x.group === 'string' ||
        Boolean(typeof x.group === 'object' && x.group && 'id' in x.group)) &&
    (!('items' in x) || (Array.isArray(x.items) && x.items.every(isMenuItem)));
export const isMenuItem = (x) => isMenuLink(x);
// Из конфигов ссылки и иконки могут приходить как строки
const isLink = (x) => Boolean(typeof x === 'object' && x && 'path' in x && typeof x.path === 'string') || typeof x === 'string';
const isIcon = (x) => Boolean(typeof x === 'object' && x && 'data' in x && typeof x.data === 'string') || typeof x === 'string';
