import { services } from '@fbc/core/services';
import { useSemaphore } from '@fbc/core/storage';
import { enrichItem } from '@fbc/core/use-cases';
import { isNotNullOrUndefined } from '@fbc/core/utils';
import { computed } from 'vue';
const localization = {
    default: {
        'default-group': 'Set as default group',
        share: 'Share',
        'delete-confirm': 'Are you sure you want to delete the "{group}" group?',
        'add-entities-into-static-group': 'Add into group',
        'add-entities-into-static-group--description': 'Select the necessary records for addition to a static group',
        'remove-entities-from-group': 'Remove from group',
    },
    'ru-ru': {
        'default-group': 'Сделать группой по умолчанию',
        share: 'Поделиться',
        'delete-confirm': 'Вы уверены, что хотите удалить группу "{group}"?',
        'add-entities-into-static-group': 'Добавить в группу',
        'add-entities-into-static-group--description': 'Выберите необходимые записи для добавления в статичную группу',
        'remove-entities-from-group': 'Удалить из группы',
    },
};
export default ({ module, builder, updateTabsSettings, showGroupShare, addingIntoStaticGroupVisible, }) => {
    const { i18n, confirm, toast, store } = services;
    const contextMenuLoading = useSemaphore();
    const listViewerActions = computed(() => services.store.app.getModulesByPurpose('list-viewer-action')
        .filter(actionModule => {
        if (actionModule.securedAction && !store.user.hasAccess(actionModule.securedAction).value)
            return false;
        return actionModule.parameters?.moduleNames?.includes(module.value.moduleName) ?? true;
    })
        .sort((a, b) => (b.parameters?.order ?? 0) - (a.parameters?.order ?? 0)));
    const getContext = (parameters) => {
        switch (parameters.type) {
            case 'group':
                return getGroupContext(parameters.context);
            case 'action':
                return getActionContext();
            default:
                throw new Error('Parameter "type" not defined');
        }
    };
    const getGroupContext = (group) => [
        {
            visible: group.id !== module.value.lookupResultId,
            icon: 'fb-common-bookmark',
            text: i18n.extract(localization, 'default-group'),
            action: () => module.value.setDefaultGroup(group),
        },
        {
            visible: group.canEditDeleteShare,
            text: i18n.extract(localization, 'share'),
            icon: 'fb-common-globe',
            action: () => showGroupShare(group.id),
        },
        {
            visible: builder.allowEditGroup.value && group.canEditDeleteShare,
            text: i18n.global('edit'),
            icon: 'fb-common-edit',
            action: () => builder.open({ groupId: group.id }),
        },
        {
            visible: builder.allowCreateGroup.value && !group.isStatic,
            text: i18n.global('copy'),
            icon: 'fb-common-copy',
            action: () => builder.open({ groupId: group.id, saveAsNewGroup: true }),
        },
        {
            visible: group.canEditDeleteShare,
            text: i18n.global('delete'),
            icon: 'fb-common-recycle-bin',
            action: async () => {
                if (!(await confirm.delete(i18n.extract(localization, 'delete-confirm', { group: group.displayName }))))
                    return;
                if (!(await module.value.deleteGroup(group)))
                    toast.error(i18n.global('error-delete'));
                else
                    updateTabsSettings();
            },
        },
    ].filter(x => x.visible);
    const getActionContext = () => [
        { $type: 'group', items: getDefaultActionContext().map(x => enrichItem(x)), groupBehavior: 'inline', text: {} },
        ...listViewerActions.value.map(actionModule => ({
            module: actionModule.name,
            text: (actionModule.title && i18n.extractFromFlatResource(actionModule.title)) ?? actionModule.name,
            icon: actionModule.parameters?.icon,
            tooltip: actionModule.description && i18n.extractFromFlatResource(actionModule.description),
            disabled: contextMenuLoading.isLocked.value ||
                module.value.loading.isLoadingSome ||
                getDisabled(actionModule.parameters?.selectedEntities?.min, actionModule.parameters?.selectedEntities?.max),
            action: () => {
                contextMenuLoading.lock();
                onListViewerToolbarActionClick(actionModule).finally(() => contextMenuLoading.unlock());
            },
        })),
    ];
    const getDefaultActionContext = () => {
        const currentGroup = module.value.groups.find(group => group.id === module.value.currentGroupId);
        return [
            {
                text: i18n.extract(localization, 'add-entities-into-static-group'),
                icon: 'fb-common-plus',
                disabled: contextMenuLoading.isLocked.value || module.value.loading.isLoadingSome || getDisabled(1),
                tooltip: i18n.extract(localization, 'add-entities-into-static-group--description'),
                action: () => (addingIntoStaticGroupVisible.value = true),
            },
            currentGroup?.isStatic
                ? {
                    text: i18n.extract(localization, 'remove-entities-from-group'),
                    icon: 'fb-common-close',
                    disabled: contextMenuLoading.isLocked.value ||
                        module.value.loading.isLoading('removing-entities-from-static-group').value ||
                        getDisabled(1),
                    action: () => {
                        contextMenuLoading.lock();
                        module.value.removeEntitiesFromStaticGroup().finally(() => contextMenuLoading.unlock());
                    },
                }
                : undefined,
        ].filter(isNotNullOrUndefined);
    };
    const onListViewerToolbarActionClick = async (actionModule) => {
        const handler = (await store.app.moduleRegistry.load(actionModule.name))
            ?.default;
        if (!handler) {
            toast.error(i18n.global('error'));
            return;
        }
        const selectedEntitiesIds = isNotNullOrUndefined(actionModule.parameters?.selectedEntities?.min)
            ? await module.value.getSelectedEntitiesIds()
            : undefined;
        await handler({
            moduleName: module.value.moduleName,
            selectedEntityIds: selectedEntitiesIds,
        });
    };
    const getDisabled = (min, max) => (!!max || !!min) &&
        ((!!min && (module.value.selectedRecordIndexes?.length ?? 0) < min) ||
            (!!max && (module.value.selectedRecordIndexes?.length ?? 0) > max));
    return getContext;
};
