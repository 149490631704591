import { pick } from 'lodash';
import { markRaw, toRaw } from 'vue';
import { Selection, strategies, switchSelection, TreeCollection } from './index';
import { TraversalOperations } from './traversal-operations';
/**
 * Хранение ссылки на родителя в оригинальном объекте приводит к циклическим зависимостям
 * на которых падает Vue с Maximum call stack size exceeded.
 * Все работает, т.к. там есть защита, но сильно лагает.
 * Для фикса этого здесь карта слабых ссылок + получения родителя в геттере с toRaw.
 * Без toRaw не будет находить, т.к. this становится ссылкой на прокси при попадании в систему реактивности
 * any т.к. не получается без сильных костылей разрешить типизацию
 */
const parentsMap = markRaw(new WeakMap());
/**
 * Класс для использования в компоненте дерева
 * В принципе, можно использовать и сам по себе, но если возникла такая нужда, то мб вынести общий для деревьев код в другой класс, а этот отнаследовать от него
 */
export default class Tree extends TraversalOperations {
    _id;
    label;
    selected = Selection.UnSelected;
    visible = true;
    opened = false;
    selectable = true;
    disabled = false;
    _level = 0;
    _data;
    _childs = [];
    parentSelectionStrategy;
    childsSelectionStrategy;
    sortStrategy;
    /** id узла, используется для ключей элементов */
    get id() {
        return this._id;
    }
    get level() {
        return this._level;
    }
    get data() {
        return this._data;
    }
    /** см. parentsMap */
    get parent() {
        return parentsMap.get(toRaw(this)) ?? null;
    }
    get childs() {
        return this._childs;
    }
    get visibleChilds() {
        return this.childs.filter(x => x.visible);
    }
    constructor({ item, key, label, }, { parentSelectionStrategy = strategies.selection.parent.select, childsSelectionStrategy = strategies.selection.childs.all, sortStrategy = strategies.sort.deep, } = {}) {
        super();
        this.parentSelectionStrategy = parentSelectionStrategy;
        this.childsSelectionStrategy = childsSelectionStrategy;
        this.sortStrategy = sortStrategy;
        this._id = typeof key === 'function' ? key(item) : key;
        this.label = label(item);
        this._data = item;
    }
    /**
     * Создает дерево из массива
     * @param key функция для получения ключа
     * @param getParentId функция для получения ид предка
     * @param label функция для получения текст элемента
     * @param parentSelectionStrategy стратегия выбора предков
     * @param childsSelectionStrategy стратегия выбора потомков
     * @param sortStrategy стратегия сортировки
     * @returns дерево
     */
    static fromArray({ items, key, getParentId, label, }, strategies = {}) {
        const tree = new TreeCollection();
        const map = new Map();
        for (const item of items) {
            const id = key(item);
            map.set(id, new Tree({ item, key: id, label }, strategies));
        }
        for (const element of map.values()) {
            const parentId = getParentId(element.data);
            const parent = map.get(parentId);
            if (parent)
                parent.addChild(element);
            else if (!parentId)
                tree.push(element);
        }
        return tree;
    }
    /**
     * Добавляет потомка текущему элементу дерева
     * @param item потомок
     * @returns this
     */
    addChild(item) {
        this._childs.push(item);
        item.setParent(this);
        return this;
    }
    /**
     * Добавляет потомков текущему элементу дерева
     * @param item потомок
     * @returns this
     */
    addChilds(childs) {
        for (const child of childs)
            this.addChild(child);
        return this;
    }
    /**
     * Устанавливает родителя текущему элементу дерева
     * @param parent родитель
     * @returns this
     */
    setParent(parent) {
        if (parent)
            parentsMap.set(this, parent);
        else
            parentsMap.delete(this);
        this._level = this.parent ? this.parent.level + 1 : 0;
        this.traversal(item => {
            if (!item.parent)
                return;
            // Чтобы при попытке создания циклического дерева не падал RangeError: Maximum call stack size exceeded
            if (item.parent.parent && item.parent.level < item.parent.parent.level)
                return false;
            item._level = item.parent.level + 1;
        }, 'pre');
        return this;
    }
    /** Конвертирует поддерево в сериализуемый объект */
    toObject(keys) {
        return {
            ...pick(this, ...(keys ?? ['id', 'label', 'selected', 'selectable', 'visible', 'disabled', 'opened', 'level', 'data'])),
            childs: this.childs.map(x => x.toObject(keys)),
        };
    }
    /** Возвращает массив с данными дерева */
    toArray(strategy) {
        return this.all(() => true, strategy).map(x => x.data);
    }
    /** Конвертирует поддерево в JSON */
    toJSON() {
        return JSON.stringify(this.toObject());
    }
    /**
     * Обходит дерево и вызывает action для каждого элемента
     * @param action для каждого элемента
     * @param strategy стратегия обхода. По дефолту pre, подробнее в traverse-strategies.ts
     * @returns this
     */
    traversal(action, strategy = 'pre') {
        strategies.traversal[strategy](action, this);
        return this;
    }
    /**
     * Изменяет свойство selected элемента и его предков/потомков согласно стратегиям
     * @returns this
     */
    select() {
        this.selected = switchSelection(this.selected);
        if (this.parentSelectionStrategy)
            this.parentSelectionStrategy(this);
        if (this.childsSelectionStrategy)
            this.childsSelectionStrategy(this);
        return this;
    }
    /**
     * Изменяет opened для всех элементов
     * @param isOpen открыть или закрыть все элементы
     * @returns this
     */
    openDeep(isOpen = true) {
        return this.traversal(item => (item.opened = isOpen));
    }
    /** Возвращает корень дерева из текущего элемента */
    getRoot() {
        if (!this.parent)
            return this;
        let root;
        this.traversal(item => {
            root = item;
        }, 'up');
        return root ?? this;
    }
    /** Возвращает путь до корня дерева из текущего элемента */
    getPath() {
        const path = [this];
        this.traversal(item => {
            path.unshift(item);
        }, 'up');
        return path;
    }
    /**
     * Сортирует элементы в дереве
     * @param comparator функция сравнения элементов
     * @returns this
     */
    sort(comparator) {
        this.sortStrategy(this, comparator);
        return this;
    }
    /**
     * Удаляет текущий элемент из дерева
     * @returns текущий элемент
     */
    remove() {
        if (this.parent)
            this.parent._childs = this.parent._childs.filter(x => x !== this);
        return this.setParent(null);
    }
}
