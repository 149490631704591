import { mergeProps as _mergeProps, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = { class: "s-input-control-inner" };
const _hoisted_2 = { class: "s-input-control-inner" };
const _hoisted_3 = ["id", "type", "readonly", "disabled", "inputmode", "value", "autocomplete"];
const _hoisted_4 = ["for"];
const _hoisted_5 = ["textContent"];
const _hoisted_6 = { class: "s-input__button-container" };
const _hoisted_7 = {
    key: 0,
    class: "s-input-details"
};
const _hoisted_8 = { class: "s-input-details-inner" };
const _hoisted_9 = { class: "s-input-details-inner" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _directive_tooltip = _resolveDirective("tooltip");
    const _directive_click_outside = _resolveDirective("click-outside");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["s-input", _ctx.mergeClass(_ctx.rootClasses, _ctx.$attrs.class)]),
        style: _normalizeStyle(_ctx.$attrs.style)
    }, [
        _withDirectives((_openBlock(), _createElementBlock("div", {
            class: "s-input-control",
            onClick: _cache[0] || (_cache[0] =
                //@ts-ignore
                (...args) => (_ctx.focus && _ctx.focus(...args)))
        }, [
            _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _withDirectives(_createElementVNode("input", _mergeProps({
                        id: _ctx.id,
                        ref: "input",
                        type: _ctx.type === 'password' ? 'password' : 'text',
                        readonly: _ctx.readonly,
                        disabled: _ctx.disabled,
                        dir: "auto",
                        inputmode: _ctx.type === 'integer' || _ctx.type === 'positive'
                            ? 'numeric'
                            : _ctx.type === 'double' || _ctx.type === 'percent'
                                ? 'decimal'
                                : undefined
                    }, _ctx.attributes, {
                        value: _ctx.valueWrapper,
                        autocomplete: typeof _ctx.autocomplete === 'string' ? _ctx.autocomplete : _ctx.autocomplete ? 'on' : 'off'
                    }), null, 16 /* FULL_PROPS */, _hoisted_3), [
                        [
                            _directive_tooltip,
                            _ctx.valueWrapper,
                            void 0,
                            {
                                top: true,
                                showOnlyIfOverflown: true
                            }
                        ]
                    ]),
                    (!_ctx.noLabel)
                        ? (_openBlock(), _createElementBlock("label", {
                            key: 0,
                            class: _normalizeClass(["s-label", _ctx.labelClasses]),
                            for: _ctx.id
                        }, [
                            _createElementVNode("span", {
                                textContent: _toDisplayString(_ctx.labelValue)
                            }, null, 8 /* PROPS */, _hoisted_5)
                        ], 10 /* CLASS, PROPS */, _hoisted_4))
                        : _createCommentVNode("v-if", true)
                ]),
                _createElementVNode("div", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (button) => {
                        return _withDirectives((_openBlock(), _createElementBlock("i", _mergeProps({
                            key: button.id,
                            "aria-hidden": "true",
                            class: "s-input__button",
                            ref_for: true
                        }, button.attributes), [
                            _createTextVNode(_toDisplayString(button.text), 1 /* TEXT */)
                        ], 16 /* FULL_PROPS */)), [
                            [
                                _directive_tooltip,
                                button.tooltip,
                                void 0,
                                { useDangerousInnerHtml: true }
                            ]
                        ]);
                    }), 128 /* KEYED_FRAGMENT */))
                ])
            ]),
            ((_ctx.detailMessage || _ctx.detail) && !_ctx.bordered)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_9, [
                            _createTextVNode(_toDisplayString(_ctx.detailMessage || _ctx.detail), 1 /* TEXT */)
                        ])), [
                            [
                                _directive_tooltip,
                                _ctx.detailMessage || _ctx.detail,
                                void 0,
                                {
                                    showOnlyIfOverflown: true,
                                    left: true
                                }
                            ]
                        ])
                    ])
                ]))
                : _createCommentVNode("v-if", true)
        ])), [
            [_directive_click_outside, _ctx.unfocus]
        ])
    ], 6 /* CLASS, STYLE */));
}
