import { defineFunctionalComponent } from '@fbc/core/utils';
import { h, inject, resolveComponent } from 'vue';
import { services } from '.';
import { getModalBody, typeMap } from '../storage/global/dialogs';
function confirm(cfg) {
    if (!cfg || !cfg.body)
        cfg = { body: cfg };
    const confirm = cfg;
    if (Array.isArray(confirm.classes))
        confirm.classes.push('s-confirm');
    else
        confirm.classes = confirm.classes ? 's-confirm ' + confirm.classes : 's-confirm';
    const store = services.store ?? inject('store');
    return store.dialogs.addConfirm(confirm);
}
function confirmWithIcon(type, cfg) {
    const confirmStyles = typeMap(type);
    return confirm({
        body: () => getModalBody(confirmStyles.icon, confirmStyles.iconColor, confirmStyles.fillColor, cfg),
        footer: confirmButtons(type),
        width: cfg.width,
        classes: cfg.classes,
    });
}
const confirmButtons = (type) => defineFunctionalComponent({ remove: { type: Function, required: true } }, ({ remove }) => [
    h(resolveComponent('s-button'), {
        onClick: () => remove(true),
        filled: true,
        error: type === 'delete',
        warning: type === 'warning',
        style: { margin: 'auto' },
    }, () => services.i18n.global(type === 'delete' ? 'delete' : 'ok')),
    h(resolveComponent('s-button'), { onClick: () => remove(false), tabindex: 1, warning: type === 'warning', style: { margin: 'auto' } }, () => services.i18n.global('cancel')),
]);
confirm.delete = (body, width = 440, caption, additional) => confirmWithIcon('delete', { body, width, caption, additional, classes: 's-confirm--delete' });
confirm.warning = (body, width = 440, caption, additional) => confirmWithIcon('warning', { body, width, caption, additional, classes: 's-confirm--warning' });
export default confirm;
