import { defineComponent, h, mergeProps, resolveComponent } from 'vue';
export default defineComponent({
    name: 's-property',
    props: {
        loading: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
        contrast: { type: Boolean, default: false },
    },
    slots: Object,
    setup(props, { slots, attrs }) {
        const attributes = mergeProps({ class: 's-property' }, attrs);
        const renderLabel = () => (slots.label ? h('span', { class: 's-property__label' }, [slots.label()]) : null);
        const renderReadonly = () => slots.readonly ? h('span', { class: 's-property__readonly' }, [slots.readonly()]) : null;
        const renderInput = () => (slots.default ? slots.default() : null);
        const renderPlaceholder = () => h('div', attributes, [renderLabel(), h(resolveComponent('s-placeholder'), { contrast: !!props.contrast })]);
        const render = () => h(props.readonly ? 'div' : 'label', attributes, [
            renderLabel(),
            props.readonly ? renderReadonly() : renderInput(),
        ]);
        return () => (props.loading ? renderPlaceholder() : render());
    },
});
