const listeners = new Map();
function onOutsideClick(event) {
    listeners.forEach(fn => fn(event));
}
onOutsideClick.listeners = listeners;
export default {
    priority: 700,
    beforeMount: function (el, binding) {
        if (listeners.size === 0)
            document.body.addEventListener('click', onOutsideClick);
        listeners.set(el, function (event) {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value(event, el);
            }
        });
    },
    unmounted: function (el) {
        listeners.delete(el);
        if (listeners.size === 0)
            document.body.removeEventListener('click', onOutsideClick);
    },
};
