import { renderSlot as _renderSlot, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["href", "target"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_router_link = _resolveComponent("router-link");
    return (_ctx.link.$type === 'external')
        ? (_openBlock(), _createElementBlock("a", _mergeProps({
            key: 0,
            href: _ctx.link.path,
            rel: "noopener noreferrer",
            target: _ctx.link.openInNewTab ? '_blank' : ''
        }, _ctx.$attrs), [
            _renderSlot(_ctx.$slots, "default")
        ], 16 /* FULL_PROPS */, _hoisted_1))
        : (_ctx.link.$type === 'dialog')
            ? (_openBlock(), _createElementBlock("span", _mergeProps({
                key: 1,
                style: { "cursor": "pointer" }
            }, _ctx.$attrs, {
                onClick: _cache[0] || (_cache[0] =
                    //@ts-ignore
                    (...args) => (_ctx.openDialog && _ctx.openDialog(...args)))
            }), [
                _renderSlot(_ctx.$slots, "default")
            ], 16 /* FULL_PROPS */))
            : (_openBlock(), _createBlock(_component_router_link, _mergeProps({
                key: 2,
                to: _ctx.link.path,
                target: _ctx.link.openInNewTab ? '_blank' : ''
            }, _ctx.$attrs), {
                default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "default")
                ]),
                _: 3 /* FORWARDED */
            }, 16 /* FULL_PROPS */, ["to", "target"]));
}
