import { isDateTime, isNotNullOrUndefined } from '@fbc/core/utils';
import { Duration } from 'luxon';
export function dataTableSort(items, allowSort, columns, sortFn) {
    if (!allowSort)
        return items;
    return [...items].sort((a, b) => {
        for (const col of columns
            .filter(x => x.sortIndex >= 0 && x.sortOrder != null && x.field)
            .sort((a, b) => a.sortIndex - b.sortIndex)) {
            const result = sortFn(a, b, col);
            if (!result)
                continue;
            return result;
        }
        return 0;
    });
}
export function sort(a, b, col) {
    const field = col.field;
    const isDateTimeOrDuration = (v) => isDateTime(v) || v instanceof Duration;
    const aValue = col.formatter && !isDateTimeOrDuration(a[field]) ? col.formatter(a[field], a) : a[field];
    const bValue = col.formatter && !isDateTimeOrDuration(b[field]) ? col.formatter(b[field], b) : b[field];
    if (aValue === bValue)
        return 0;
    const direction = col.sortOrder === 'desc' ? -1 : 1;
    if (!isNotNullOrUndefined(aValue) && !isNotNullOrUndefined(bValue))
        return 0;
    if (!isNotNullOrUndefined(aValue))
        return direction;
    if (!isNotNullOrUndefined(bValue))
        return -direction;
    return aValue > bValue ? direction : aValue < bValue ? -direction : 0;
}
