import { services } from '..';
import globalDate from './global.date.json';
import globalJob from './global.job.json';
import global from './global.json';
// Component -> Locale -> Key -> Value
const i18nDictionary = {
    global: global,
    'global.date': globalDate,
    'global.job': globalJob,
};
const localeMap = new Map([
    ['ru', 'ru-ru'],
    ['be', 'ru-ru'],
    ['be-by', 'ru-ru'],
    ['kk', 'ru-ru'],
    ['kk-kz', 'ru-ru'],
    ['uk', 'ru-ru'],
    ['uk-ua', 'ru-ru'],
    ['en', 'en-us'],
    ['en-gb', 'en-us'],
]);
export default {
    /**
     * Весь словарь с локализацией собранный из ./src/i18n
     */
    get dictionary() {
        return i18nDictionary;
    },
    /**
     * Текущая выбранная локаль
     */
    get locale() {
        const locale = 
        // Тут должно быть ||, чтобы пустую строку не пропустить
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        window.localStorage.getItem('fora.language')?.toLowerCase() ||
            navigator.language.toLowerCase() ||
            document.documentElement.lang ||
            services.store.app.defaultLanguage;
        return localeMap.get(locale) ?? locale;
    },
    /**
     * Получает ресурс текущей локали из словаря локализации и заполняет плэйсхолдеры
     * @param componentName Название словаря локализации
     * @param key Ключ в словаре локализации
     * @param map Карта значений для заполнения в локализации, пример локализации: "test":"Test {param1}, {param2} ...".
     */
    resource(componentName, key, map) {
        let result = this.findResource(componentName, key);
        if (!map || Object.keys(map).length === 0)
            return result;
        for (const key in map)
            result = result?.replace(`{${key}}`, map[key]);
        return result;
    },
    /**
     * Возвращает значение из глобального словаря
     * @param key Ключ в словаре локализации
     * @param map Карта значений для заполнения в локализации, пример локализации: "test":"Test {param1}, {param2} ...".
     */
    global(key, map) {
        return this.resource('global', key, map);
    },
    /**
     * Получает из объекта локализации значение с текущей локалью
     * @param i18n Объект локализации
     * @param key Ключ в словаре локализации
     */
    extract(i18n, key, map) {
        let locale = this.locale;
        if (!i18n)
            return process.env.NODE_ENV === 'development' ? `components locale object not found` : '';
        if (!i18n[locale])
            locale = 'default';
        let result = i18n[locale][key] ?? i18n.default[key];
        if (!map || Object.keys(map).length === 0)
            return result;
        for (const key in map)
            result = result?.replace(`{${key}}`, map[key]);
        return result;
    },
    /**
     * Получает полностью текущую локаль выбранного словаря локализации
     * @param componentName Название словаря, например global
     */
    getFullLocale(componentName) {
        const locale = this.locale;
        if (!this.dictionary[componentName])
            return process.env.NODE_ENV === 'development' ? `locale file ${componentName} not found` : '';
        if (!this.dictionary[componentName][locale])
            return this.dictionary[componentName].default;
        return this.dictionary[componentName][locale];
    },
    register(key, map) {
        if (i18nDictionary[key])
            return new Error(`i18n with key ${key} already registered`);
        i18nDictionary[key] = map;
    },
    findResource(componentName, key) {
        let locale = this.locale;
        if (!i18nDictionary[componentName])
            return process.env.NODE_ENV === 'development' ? `locale file ${componentName} not found` : '';
        if (!i18nDictionary[componentName][locale])
            locale = 'default';
        if (!i18nDictionary[componentName][locale][key])
            return i18nDictionary[componentName].default[key];
        return i18nDictionary[componentName][locale][key];
    },
    extractFromFlatResource(resource) {
        return resource[this.locale] ?? resource.default ?? '';
    },
};
