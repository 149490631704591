const containers = new Set([undefined, 'card', 'strip', 'background', 'contrast', 'filters', 'info']);
function applyClasses(el, binding) {
    if (binding.value === false)
        return;
    el.classList.add('v-container');
    if (!containers.has(binding.arg))
        return log.dev.error(__filename, `Недопустимый аргумент ${binding.arg ?? ''}`);
    el.classList.add(`v-container--${binding.arg ?? 'default'}`);
    if (el.tagName.toLowerCase() === 'ul' && binding.arg === 'contrast') {
        el.classList.add('v-container--contrast-ul');
    }
}
export default {
    beforeMount: applyClasses,
    updated: applyClasses, // Чтобы при использовании классов извне не перетирались эти
};
