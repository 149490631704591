import { filters, isNotNullOrUndefined } from '@fbc/core/utils';
import alert from './alert';
import { AttachmentsApi } from './api/attachments';
import { authFetch, authenticate, authenticateLdap, getSystemInfo, isTokenExpired, isTokenViable, logoff, } from './api/authenticate-fetch';
import { deleteUserOption, getUserOption, setUserOption } from './api/common';
import { getCurrentUser, getSecurityProfile } from './api/get-current-user';
import { EventBus } from './bus';
import confirm from './confirm';
import { DataApi } from './data-api';
import { LookupDataApiProvider } from './data-providers/lookup-data-api-provider';
import { LookupODataProvider } from './data-providers/lookup-odata-provider';
import { LookupStaticProvider } from './data-providers/lookup-static-provider';
import { createTableCacheProvider } from './data-providers/table-cache-provider';
import { TableDataApiProvider } from './data-providers/table-data-api-provider';
import { TableODataProvider } from './data-providers/table-odata-provider';
import * as helpers from './helpers';
import i18n from './i18n';
import { ListViewerApi } from './list-viewer-api';
import log from './log';
import moduleDialog from './module-dialog';
import * as modules from './modules';
import { ODataEntitiesApi } from './odata-entities-api';
import toast from './toast';
import variables from './variables';
import { WebApi } from './web-api';
import { WebSocketLogger } from './websocket-logger';
const bus = new EventBus();
const dependencies = {
    'import:xlsx': {
        value: () => un.store.app.moduleRegistry.load('xlsx'),
        type: 'factory',
    },
    log: { value: log, type: 'value' },
    bus: { value: bus, type: 'value' },
    entitiesApi: { value: ODataEntitiesApi, type: 'value' },
    webApi: { value: WebApi, type: 'value' },
    dataApi: { value: DataApi, type: 'value' },
    attachmentsApi: { value: AttachmentsApi, type: 'value' },
    authentication: {
        value: { authenticate, authenticateLdap, getSystemInfo, isTokenExpired, fetch: authFetch, logoff, isTokenViable },
        type: 'value',
    },
    user: {
        value: { getUserOption, setUserOption, deleteUserOption, getCurrentUser, getSecurityProfile },
        type: 'value',
    },
    i18n: { value: i18n, type: 'value' },
    variables: { value: variables, type: 'value' },
    store: { value: undefined, type: 'value' },
    pinia: { value: undefined, type: 'value' },
    moduleDialog: { value: moduleDialog, type: 'value' },
    filters: { value: filters, type: 'value' },
    modules: { value: modules, type: 'value' },
    router: { value: undefined, type: 'value' },
    helpers: { value: helpers, type: 'value' },
    webSocketLogger: { value: WebSocketLogger, type: 'value' },
    toast: { value: toast, type: 'value' },
    confirm: { value: confirm, type: 'value' },
    alert: { value: alert, type: 'value' },
    tableDataApiProvider: { value: TableDataApiProvider, type: 'value' },
    tableODataProvider: { value: TableODataProvider, type: 'value' },
    tableCacheProvider: { value: createTableCacheProvider, type: 'value' },
    lookupDataApiProvider: { value: LookupDataApiProvider, type: 'value' },
    lookupODataProvider: { value: LookupODataProvider, type: 'value' },
    lookupStaticProvider: { value: LookupStaticProvider, type: 'value' },
    listViewerApi: { value: ListViewerApi, type: 'value' },
};
export const services = new Proxy({}, {
    get: (_, dependencyName) => {
        const dependency = dependencies[dependencyName];
        if (dependency.type === 'factory')
            return dependency.value();
        return dependencies[dependencyName].value;
    },
    set: (_, dependencyName, value) => {
        const dependency = dependencies[dependencyName];
        if (isNotNullOrUndefined(dependency.value))
            return false;
        Object.assign(dependency, { value });
        return true;
    },
});
const un = services; // Нужен чтобы в serviceProvider ссылаться на самого себя, но при этом работали типы
