const isPromise = (x) => (typeof x === 'object' || typeof x === 'function') &&
    x !== null &&
    'then' in x &&
    typeof x.then === 'function' &&
    'catch' in x &&
    typeof x.catch === 'function';
export class EventBus {
    subscriptions = {};
    global = [];
    onGlobalMessage(message) {
        if (!message.data || typeof message.data !== 'object' || !message.data.event)
            return;
        this.emit(message.data.event, message.data.payload);
    }
    all(subscription) {
        if (typeof subscription !== 'function')
            throw new Error('subscription not a function');
        this.global.push(subscription);
        return () => (this.global = this.global.filter(f => f !== subscription));
    }
    emit(event, payload) {
        log.dev.debug('event.' + event, payload);
        return Promise.all([
            ...(this.subscriptions[event] ?? []).map((f) => {
                try {
                    const result = f(payload);
                    return isPromise(result) ? result.catch(x => x) : result;
                }
                catch (error) {
                    return error;
                }
            }),
            ...this.global.map(f => {
                try {
                    const result = f(event, payload);
                    return isPromise(result) ? result.catch(x => x) : result;
                }
                catch (error) {
                    return error;
                }
            }),
        ]);
    }
    on(event, subscription) {
        if (typeof subscription !== 'function')
            throw new Error('subscription not a function');
        if (!this.subscriptions[event])
            this.subscriptions[event] = [];
        this.subscriptions[event]?.push(subscription);
        return () => this.off(event, subscription);
    }
    off(event, subscription) {
        this.subscriptions[event] = this.subscriptions[event]?.filter(f => f !== subscription);
    }
}
