import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "s-page-no-access" };
const _hoisted_2 = ["textContent"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h1", {
            textContent: _toDisplayString(_ctx.local('title'))
        }, null, 8 /* PROPS */, _hoisted_2),
        _createElementVNode("p", null, [
            _createTextVNode(_toDisplayString(_ctx.local('body')) + " ", 1 /* TEXT */),
            _createElementVNode("button", {
                class: "link",
                onClick: _cache[0] || (_cache[0] =
                    //@ts-ignore
                    (...args) => (_ctx.redirectToHome && _ctx.redirectToHome(...args)))
            }, _toDisplayString(_ctx.local('main')), 1 /* TEXT */),
            _createTextVNode(" " + _toDisplayString(_ctx.local('or')) + " ", 1 /* TEXT */),
            _createElementVNode("button", {
                class: "link",
                onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.$router.back()))
            }, _toDisplayString(_ctx.local('back')), 1 /* TEXT */)
        ])
    ]));
}
