import { createUniqueId } from '@fbc/core/utils';
import { defineStore } from 'pinia';
export const useModuleDialogStore = defineStore({
    id: 'moduleDialog',
    state: () => ({
        moduleDialogs: [],
    }),
    actions: {
        clear() {
            this.moduleDialogs = [];
        },
        add(name, parameters) {
            this.moduleDialogs.push({ name, parameters, scope: createUniqueId() });
        },
        remove(name, scope) {
            this.moduleDialogs = this.moduleDialogs.filter(x => !(x.name === name && x.scope === scope));
        },
    },
});
