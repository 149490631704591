import { services } from '@fbc/core/services';
import { defineFunctionalComponent, getStoreDynamicModule, isNotNullOrUndefined, isString, withTooltip, } from '@fbc/core/utils';
import { DateTime } from 'luxon';
import { computed, defineComponent, h, ref, resolveComponent, watch, } from 'vue';
import operatorsByDataType from '../../use-cases/operators-by-data-type';
import resource from './s-create-lookup-result.json';
import { useOperatorsLocalization } from '../../use-cases/operators-localization';
export default defineComponent({
    name: 's-create-lookup-result',
    emits: ['update:visible', 'ok-click'],
    props: {
        visible: { type: Boolean, required: true },
        storeName: { type: String, required: true },
    },
    setup(props, { emit }) {
        const { i18n, toast } = services;
        const local = (value) => i18n.extract(resource, value);
        const module = ref(getStoreDynamicModule(props.storeName));
        const filters = ref([]);
        const enableExistsConditions = ref(false);
        const operatorsLocalization = useOperatorsLocalization(local);
        const isStaticCurrentGroup = computed(() => !!module.value.currentGroupId &&
            !!module.value.groups.find(group => group.id === module.value.currentGroupId)?.isStatic);
        const columnOptions = computed(() => module.value.columns.map(column => ({ caption: column.displaySettings.caption, column })));
        const filterTemplate = defineFunctionalComponent({
            filter: { type: Object, required: true },
        }, ({ filter }) => {
            const operators = filter.column?.dataType &&
                operatorsByDataType(filter.column.dataType).map(operator => ({
                    text: operatorsLocalization[operator],
                    value: operator,
                }));
            return [
                h(resolveComponent('s-select'), {
                    modelValue: columnOptions.value.find(option => option.column === filter.column),
                    bordered: true,
                    placeholder: local('column'),
                    labelField: 'caption',
                    options: columnOptions.value,
                    readonly: module.value.loading.isLoading('lookup-result').value,
                    required: true,
                    searchable: true,
                    style: 'grid-column: 1;',
                    'onUpdate:modelValue': (value) => {
                        if (filter.column?.dataType !== value?.column.dataType) {
                            filter.operator = null;
                            filter.value = null;
                        }
                        filter.column = value?.column ?? null;
                    },
                }),
                h(resolveComponent('s-select'), {
                    modelValue: operators?.find(operator => operator.value === filter.operator),
                    bordered: true,
                    placeholder: local('operator'),
                    labelField: 'text',
                    options: operators,
                    readonly: !filter.column || module.value.loading.isLoading('lookup-result').value,
                    required: true,
                    style: 'grid-column: 2;',
                    'onUpdate:modelValue': (option) => {
                        filter.operator = option?.value ?? null;
                        if (option && ['IsNull', 'IsNotNull'].includes(option.value)) {
                            filter.value = null;
                        }
                    },
                }),
                filter.operator && ['IsNotNull', 'IsNull'].includes(filter.operator) ? undefined : valueTemplate(filter),
                filters.value.length > 1
                    ? withTooltip(h(resolveComponent('s-button'), {
                        icon: 'fb-common-close',
                        readonly: module.value.loading.isLoading('lookup-result').value,
                        secondary: true,
                        style: 'grid-column: 4;',
                        onClick: () => deleteFilter(filter),
                    }), i18n.global('delete'))
                    : undefined,
            ];
        });
        const valueTemplate = (filter) => {
            if (filter.column?.dataType === 'DateTime')
                return [
                    h(resolveComponent('s-date-time-picker'), {
                        modelValue: filter.value ? DateTime.fromISO(filter.value) : null,
                        bordered: true,
                        placeholder: local('value'),
                        readonly: module.value.loading.isLoading('lookup-result').value,
                        required: true,
                        style: 'grid-column: 3;',
                        'onUpdate:modelValue': (value) => {
                            filter.value = value?.toISO() ?? null;
                        },
                    }),
                ];
            return [
                h(resolveComponent('s-input'), {
                    modelValue: filter.operator === 'In' && Array.isArray(filter.value) ? filter.value.join(';') : filter.value,
                    bordered: true,
                    placeholder: local('value'),
                    readonly: !filter.column || module.value.loading.isLoading('lookup-result').value,
                    required: true,
                    style: 'grid-column: 3;',
                    type: filter.column?.dataType === 'Integer'
                        ? 'integer'
                        : filter.column?.dataType === 'Decimal'
                            ? 'double'
                            : 'text',
                    'onUpdate:modelValue': (value) => {
                        if (isString(value) && filter.operator === 'In') {
                            filter.value = value
                                .split(';')
                                .map(x => x.trim())
                                .filter(isNotNullOrUndefined);
                            return;
                        }
                        filter.value =
                            value &&
                                filter.column &&
                                (['Integer', 'Decimal'].includes(filter.column.dataType) ? Number(value) : value);
                    },
                }),
            ];
        };
        const addFilter = () => {
            if (!columnOptions.value.length)
                return;
            const column = columnOptions.value.at(0)?.column;
            filters.value.push({
                column: column ?? null,
                operator: (column && operatorsByDataType(column.dataType).at(0)) ?? null,
                value: null,
            });
        };
        const deleteFilter = (filter) => {
            filters.value = filters.value.filter(existsFilter => existsFilter !== filter);
        };
        const buildFilters = () => filters.value
            .filter((filter) => !!(filter.column &&
            filter.operator &&
            (['IsNull', 'IsNotNull'].includes(filter.operator) ||
                (isNotNullOrUndefined(filter.value) && filter.value !== ''))))
            .map(({ column, operator, value }) => ({
            columnId: column.id,
            operator,
            value,
        }));
        const find = async () => {
            const isSuccess = await module.value.createLookupResult(buildFilters(), !enableExistsConditions.value);
            if (!isSuccess)
                toast.error(i18n.global('error'));
            emit('ok-click');
            hide();
        };
        const hide = () => {
            emit('update:visible', false);
        };
        watch(() => props.visible, visible => {
            if (!visible)
                return;
            filters.value = [];
            enableExistsConditions.value = false;
            addFilter();
        });
        watch(() => props.storeName, () => {
            module.value = getStoreDynamicModule(props.storeName);
        });
        return {
            isStaticCurrentGroup,
            enableExistsConditions,
            filters,
            filterTemplate,
            module,
            addFilter,
            find,
            hide,
            local,
        };
    },
});
