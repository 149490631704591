import { computed, ref } from 'vue';
export function useSemaphore() {
    const counter = ref(0);
    const isLocked = computed(() => counter.value > 0);
    const lock = () => counter.value++;
    const clear = () => (counter.value = 0);
    const unlock = () => (counter.value = counter.value > 0 ? counter.value - 1 : 0);
    return { isLocked, lock, unlock, clear };
}
export class Semaphore {
    _counter = 0;
    get isLocked() {
        return this._counter > 0;
    }
    lock() {
        this._counter++;
    }
    unlock() {
        if (this._counter > 0)
            this._counter--;
        else
            this._counter = 0;
    }
}
