import { services } from '@fbc/core/services';
import { defineStore } from 'pinia';
import { computed } from 'vue';
export const useUserStore = defineStore({
    id: 'user',
    state: () => ({
        user: null,
        promise: null,
        profile: null,
        adminId: 'ADMIN0000001',
    }),
    getters: {
        isAdmin: state => state.user?.id === state.adminId,
        filteredSecuredActions: state => new Set([
            ...(state.profile?.actions.filter(x => x.action === 'Add').map(x => x.securedAction) ?? []),
            ...[...(state.user?.securedActions ?? [])].filter(x => !state.profile?.actions.some(y => y.securedAction === x && y.action === 'Delete')),
        ]),
    },
    actions: {
        setUser(user, clearSecuredActions = false) {
            this.user = mapUser(user, clearSecuredActions);
        },
        hasAccess(action) {
            return computed(() => {
                if (!this.user)
                    return false;
                if (this.isAdmin)
                    return true;
                return this.filteredSecuredActions.has(action?.toLowerCase()) ?? false;
            });
        },
        async loadProfile(seccodeId) {
            const { user: { getSecurityProfile }, } = services;
            const [error, profile] = await getSecurityProfile(seccodeId);
            if (error)
                log.prod.error(__filename, error);
            if (!profile) {
                this.profile = null;
                return;
            }
            this.profile = Object.assign(profile, {
                actions: profile?.actions.map(x => Object.assign(x, { securedAction: x.securedAction.toLowerCase().trim() })),
            });
        },
        unloadProfile() {
            this.profile = null;
        },
    },
});
const mapUser = (user, clearSecuredActions = false) => {
    if (!user)
        return null;
    const securedActions = clearSecuredActions
        ? user.securedActions.map(x => x.toLowerCase().trim())
        : user.securedActions;
    return Object.assign(user, {
        roles: new Set(user.roles),
        securedActions: new Set(securedActions),
    });
};
