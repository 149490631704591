import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "s-tab-container" };
const _hoisted_2 = { class: "s-tab-container__tablist-container" };
const _hoisted_3 = { class: "s-tab-container__toolbar-left" };
const _hoisted_4 = {
    class: "s-tab-container__tablist",
    role: "tablist"
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = { class: "s-tab-container__toolbar-right" };
const _hoisted_7 = {
    class: "s-tab-container__tab-detail",
    role: "tabpanel"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _directive_waves = _resolveDirective("waves");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "left")
            ]),
            _createElementVNode("ul", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
                    return _withDirectives((_openBlock(), _createElementBlock("li", {
                        key: index,
                        class: _normalizeClass(["s-tab-container__tab", {
                                's-tab-container__tab--active': tab.key === _ctx.modelValue?.key,
                                's-tab-container__tab--required': tab.required,
                            }]),
                        role: "tab",
                        onClick: ($event) => (_ctx.$emit('update:modelValue', tab))
                    }, [
                        _createElementVNode("span", null, _toDisplayString(tab.title), 1 /* TEXT */)
                    ], 10 /* CLASS, PROPS */, _hoisted_5)), [
                        [_directive_waves]
                    ]);
                }), 128 /* KEYED_FRAGMENT */))
            ]),
            _createElementVNode("div", _hoisted_6, [
                _renderSlot(_ctx.$slots, "right")
            ])
        ]),
        _createElementVNode("div", _hoisted_7, [
            (!_ctx.noTransition)
                ? (_openBlock(), _createBlock(_Transition, {
                    key: 0,
                    name: _ctx.tabTransition
                }, {
                    default: _withCtx(() => [
                        (_ctx.modelValue)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: _ctx.modelValue.key,
                                class: "s-tab s-tab--transition"
                            }, [
                                (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.modelValue.component), _mergeProps({
                                    key: _ctx.modelValue.key
                                }, _ctx.modelValue.props), null, 16 /* FULL_PROPS */))
                            ]))
                            : _createCommentVNode("v-if", true)
                    ]),
                    _: 1 /* STABLE */
                }, 8 /* PROPS */, ["name"]))
                : (_ctx.modelValue)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: _ctx.modelValue.key,
                        class: "s-tab"
                    }, [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.modelValue.component), _mergeProps({
                            key: _ctx.modelValue.key
                        }, _ctx.modelValue.props), null, 16 /* FULL_PROPS */))
                    ]))
                    : _createCommentVNode("v-if", true)
        ])
    ]));
}
