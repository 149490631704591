import { escapeSpecialODataCharacters, isNotNullOrUndefined } from '@fbc/core/utils';
export function getText(isLower, value) {
    return isLower
        ? { register: 'tolower', value: value?.toLowerCase() }
        : { register: 'toupper', value: value?.toUpperCase() };
}
export function setFluentFilter(filters, query) {
    if (!filters?.length)
        return query;
    for (const filter of filters) {
        switch (filter.type) {
            case 'equal': {
                query = query.filter(x => x[filter.fieldName].equals(escapeSpecialODataCharacters(String(filter.value))));
                break;
            }
            case 'notEqual': {
                query = query.filter(x => x[filter.fieldName]
                    .equals(escapeSpecialODataCharacters(String(filter.value)))
                    .not());
                break;
            }
            case 'number': {
                query = setNumberFilter(filter, query);
                break;
            }
            case 'dateTime': {
                query = setDateTimeFilter(filter, query);
                break;
            }
            case 'list': {
                query = query.filter(x => x[filter.fieldName].in(filter.list.map(escapeSpecialODataCharacters)));
                break;
            }
            case 'notInList': {
                query = query.filter(x => x[filter.fieldName].in(filter.list.map(escapeSpecialODataCharacters)).not());
                break;
            }
            case 'text': {
                query = setTextFilter(filter, query);
                break;
            }
        }
    }
    return query;
}
function setNumberFilter(filter, query) {
    const hasFrom = isNotNullOrUndefined(filter.from) || filter.from === '';
    const hasTo = isNotNullOrUndefined(filter.to) || filter.to === '';
    if (hasFrom && !hasTo)
        query = query.filter(x => x[filter.fieldName].biggerThanOrEqual(filter.from));
    else if (!hasFrom && hasTo)
        query = query.filter(x => x[filter.fieldName].lessThanOrEqual(filter.to));
    else if (filter.from === filter.to)
        query = query.filter(x => x[filter.fieldName].equals(filter.to));
    else
        query = query.filter(x => x[filter.fieldName]
            .biggerThanOrEqual(filter.from)
            .and(x[filter.fieldName].lessThanOrEqual(filter.to)));
    return query;
}
function setDateTimeFilter(filter, query) {
    const hasFrom = isNotNullOrUndefined(filter.from) || filter.from === '';
    const hasTo = isNotNullOrUndefined(filter.to) || filter.to === '';
    if (hasFrom && !hasTo)
        query = query.filter(x => x[filter.fieldName].isAfterOrEqual(filter.from.toJSDate()));
    else if (!hasFrom && hasTo)
        query = query.filter(x => x[filter.fieldName].isBeforeOrEqual(filter.to.toJSDate()));
    else if (filter.from === filter.to)
        query = query.filter(x => x[filter.fieldName].isSame(filter.to.toJSDate()));
    else
        query = query.filter(x => x[filter.fieldName]
            .isAfterOrEqual(filter.from.toJSDate())
            .and(x[filter.fieldName].isBeforeOrEqual(filter.to.toJSDate())));
    return query;
}
function setTextFilter(filter, query) {
    const { register, value } = getText(filter.isLower, filter.value);
    const escapedFilterValue = escapeSpecialODataCharacters(filter.value);
    const escapedValue = escapeSpecialODataCharacters(value);
    switch (filter.op) {
        case '=': {
            query = query.filter(x => x[filter.fieldName].equals(escapedFilterValue));
            break;
        }
        case '!=': {
            query = query.filter(x => x[filter.fieldName].equals(escapedFilterValue).not());
            break;
        }
        case 'starts': {
            query = query.filter(x => x[filter.fieldName][register]().startsWith(escapedValue));
            break;
        }
        case 'ends': {
            query = query.filter(x => x[filter.fieldName][register]().endsWith(escapedValue));
            break;
        }
        case 'contains': {
            query = query.filter(x => x[filter.fieldName][register]().contains(escapedValue));
            break;
        }
        case 'empty': {
            query = query.filter(x => x[filter.fieldName].equals(null));
            break;
        }
        case 'not empty': {
            query = query.filter(x => x[filter.fieldName].equals(null).not());
            break;
        }
    }
    return query;
}
