import { defineComponent, h, mergeProps, resolveComponent, watch } from 'vue';
export default defineComponent({
    name: 's-statebutton',
    inheritAttrs: false,
    emits: ['update:modelValue', 'click'],
    props: {
        delay: { type: [String, Number] },
        modelValue: {
            type: String,
            default: 'ready',
            validator: (x) => ['ready', 'loading', 'successful', 'failed'].includes(x),
        },
    },
    setup(props, { emit, slots, attrs }) {
        async function onState() {
            if (props.modelValue === 'loading' || props.modelValue === 'ready')
                return;
            await new Promise(resolve => setTimeout(resolve, props.delay && Number(props.delay) > 0 ? Number(props.delay) : 2000));
            if (props.modelValue !== 'loading')
                emit('update:modelValue', 'ready');
        }
        watch(() => props.modelValue, () => {
            onState();
        }, { immediate: true });
        return () => {
            switch (props.modelValue) {
                case 'loading':
                    return (slots.loading?.() ??
                        h(resolveComponent('s-loader'), mergeProps({
                            class: 'state-loading',
                            count: 5,
                        })));
                case 'successful':
                    return slots.successful?.() ?? h('i', { class: 'fb-common-checked-circle state-successful' });
                case 'failed':
                    return slots.failed?.() ?? h('i', { class: 'fb-common-close state-failed' });
                default:
                    return (slots.ready?.() ??
                        h(resolveComponent('s-button'), mergeProps({
                            class: 'state-ready',
                            ...attrs,
                            onClick(event) {
                                emit('click', event);
                            },
                        }), { default: slots.default }));
            }
        };
    },
});
