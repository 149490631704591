import { services } from '@fbc/core/services';
import { useModelBinding } from '@fbc/core/utils';
import { computed, defineComponent, watch } from 'vue';
import resources from './s-progress-modal.json';
export default defineComponent({
    name: 's-progress-modal',
    emits: ['update:modelValue', 'hidden', 'click:ok'],
    props: {
        modelValue: { type: Boolean, default: false },
        caption: { type: String, default: null },
        current: { type: Number, required: true },
        total: { type: Number, required: true },
        phase: {
            type: String,
            default: 'execution',
            validator: (x) => ['waiting', 'execution', 'completion', 'completed'].includes(x),
        },
        subphase: { type: String, default: null },
        closeDelay: { type: Number, default: 500 },
        showOk: { type: Boolean },
        showPercentage: { type: Boolean },
    },
    slots: Object,
    setup(props, { emit }) {
        const local = (value, map) => services.i18n.extract(resources, value, map);
        const visible = useModelBinding('modelValue', props, emit);
        function okClick() {
            emit('update:modelValue', !props.modelValue);
            emit('click:ok');
        }
        const processText = computed(() => `${local(props.phase, { current: props.current.toString(), total: props.total.toString() })}${props.showPercentage && props.current && props.total
            ? ` (${Math.round((props.current / props.total) * 100)}%)`
            : ''}`);
        watch(() => props.current, value => {
            if (!props.showOk && value === props.total)
                setTimeout(okClick, props.closeDelay);
        });
        return {
            visible,
            processText,
            okClick,
        };
    },
});
