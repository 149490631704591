import { h } from 'vue';
import { RouterLink } from 'vue-router';
import { defineFunctionalComponent } from '../render';
export const entityLinkCellFactory = (entityName, idFieldName = 'id', { tabPath, customNameFormatter, } = {}) => defineFunctionalComponent({
    entity: { type: Object, required: true },
    column: Object,
    index: Number,
}, ({ entity, column }) => entity[idFieldName]
    ? h(RouterLink, {
        to: `/${entityName}/${entity[idFieldName]}${tabPath ?? ''}`,
    }, { default: () => (customNameFormatter ? customNameFormatter(entity) : (entity[column.field] ?? '---')) })
    : (entity[column.field] ?? ''), undefined, 'entity-link-cell', false);
