import { renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createBlock(_Transition, {
        name: "s-transition-collapse-height",
        onBeforeEnter: _ctx.beforeEnter,
        onEnter: _ctx.enter,
        onAfterEnter: _ctx.afterEnter,
        onBeforeLeave: _ctx.beforeLeave,
        onLeave: _ctx.leave,
        onAfterLeave: _ctx.afterLeave
    }, {
        default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["onBeforeEnter", "onEnter", "onAfterEnter", "onBeforeLeave", "onLeave", "onAfterLeave"]));
}
