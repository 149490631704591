import { services } from '@fbc/core/services';
import { defineStore } from 'pinia';
import { computed, reactive } from 'vue';
import { useSemaphore } from './use-semaphore';
const cacheDelay = 1000;
const allCacheDelay = 2000;
const isClearCache = (x) => Boolean(x && typeof x == 'object' && 'name' in x);
const fullJitter = (delay) => {
    const jitteredDelay = Math.random() * delay;
    return Math.round(jitteredDelay);
};
export const usePicklistStore = defineStore('picklist', () => {
    const { bus } = services;
    const cacheLock = useSemaphore();
    const picklists = reactive(new Map());
    const loadingMap = reactive(new Map());
    const cacheInvalidated = reactive(new Set());
    bus.on('/entity/picklist/invalidated', (payload) => onClearCache(payload, 'never'));
    bus.on('/entity/picklists/invalidated', () => onClearAllCache('never'));
    const api = new services.webApi();
    const delay = (ms) => new Promise(resolve => {
        setTimeout(resolve, fullJitter(ms));
    });
    const loading = (name) => computed(() => loadingMap.has(name));
    const get = (name) => picklists.get(name);
    const load = (name, fileName) => {
        if (!cacheInvalidated.has(name) && picklists.has(name))
            return picklists.get(name);
        if (loadingMap.has(name))
            return loadingMap.get(name);
        if (process.env.NODE_ENV === 'development' && fileName !== 'never' && localStorage['fora.debug.api'])
            log.dev.debug(fileName, `get picklist: `, name);
        const promise = api.get('picklist', { names: [name] }, fileName).then(([error, [picklistData] = [null]]) => {
            if (error) {
                loadingMap.delete(name);
                log.prod.error(fileName, error);
                return null;
            }
            if (!picklistData)
                return loadingMap.delete(name), null;
            cacheInvalidated.has(name)
                ? Object.assign(picklists.get(name), picklistData)
                : picklists.set(name, picklistData);
            loadingMap.delete(name);
            return picklists.get(name) ?? null;
        });
        loadingMap.set(name, promise);
        return promise;
    };
    const loadMultiple = (names, fileName) => {
        const namesToLoad = names.filter(x => (cacheInvalidated.has(x) || !picklists.has(x)) && !loadingMap.has(x));
        if (namesToLoad.length) {
            if (process.env.NODE_ENV === 'development' && fileName !== 'never')
                log.dev.debug(fileName, `get picklists: `, namesToLoad);
            const promise = api.get('picklist', { names: namesToLoad }, fileName).then(([error, picklistData = []]) => {
                if (error) {
                    namesToLoad.forEach(name => loadingMap.delete(name));
                    log.prod.error(fileName, error);
                    return null;
                }
                if (!picklistData.length)
                    return namesToLoad.forEach(name => loadingMap.delete(name)), null;
                for (const picklist of picklistData) {
                    cacheInvalidated.has(picklist.name)
                        ? Object.assign(picklists.get(picklist.name), picklist)
                        : picklists.set(picklist.name, picklist);
                    loadingMap.delete(picklist.name);
                }
            });
            for (const name of namesToLoad.filter(x => !loadingMap.has(x))) {
                loadingMap.set(name, promise.then(() => picklists.get(name) ?? null));
            }
            return promise.then(() => names.map(x => picklists.get(x)));
        }
        return names.map(x => picklists.get(x));
    };
    const onClearCache = async (payload, fileName) => {
        if (!isClearCache(payload))
            return;
        if (cacheInvalidated.has(payload.name))
            return;
        cacheInvalidated.add(payload.name);
        const isExists = picklists.has(payload.name) || loadingMap.has(payload.name);
        if (!isExists)
            return;
        loadingMap.delete(payload.name);
        await delay(cacheDelay);
        await load(payload.name, fileName);
        cacheInvalidated.delete(payload.name);
    };
    const onClearAllCache = async (fileName) => {
        if (cacheLock.isLocked.value)
            return;
        cacheLock.lock();
        const names = [...new Set(...picklists.keys(), ...loadingMap.keys())];
        if (names.length === 0)
            return;
        loadingMap.clear();
        for (const name of names)
            cacheInvalidated.add(name);
        await delay(allCacheDelay);
        await loadMultiple(names, fileName);
        for (const name of names)
            cacheInvalidated.delete(name);
        cacheLock.unlock();
    };
    return {
        picklists,
        loadingMap,
        cacheInvalidated,
        loading,
        get,
        load,
        loadMultiple,
        onClearCache,
        onClearAllCache,
    };
});
