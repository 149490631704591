import { parseJson } from '@fbc/core/utils';
import { DateTime } from 'luxon';
import { services } from '..';
export async function authenticate(login, password, hash) {
    const result = await fetch(window['fb-base-api-path'] + '/auth/AuthenticateWithPassword', {
        headers: {
            accept: 'text/plain',
            'content-type': 'application/json',
        },
        body: JSON.stringify({ login, password, clientProvidedHash: hash }),
        method: 'POST',
    })
        .then(async (x) => ({ success: x.ok, body: await x.text() }))
        .catch((x) => ({ success: false, body: x }));
    return result.success ? [undefined, parseAuthResult(result.body)] : [parseError(result.body), undefined];
}
const parseError = (result) => parseJson(String(result), (x) => Boolean(typeof x === 'object' && x && 'status' in x && 'title' in x));
export const authenticateLdap = async () => {
    const result = await fetch(window['fb-base-api-path'] + '/auth/AuthenticateLDAP', {
        headers: {
            accept: 'text/plain',
            'content-type': 'application/json',
        },
        body: null,
        method: 'POST',
    })
        .then(async (x) => ({ success: x.ok, body: await x.text() }))
        .catch((x) => ({ success: false, body: x }));
    return result.success ? [undefined, parseAuthResult(result.body)] : [parseError(result.body), undefined];
};
export const getSystemInfo = async () => {
    const response = await fetch(window['fb-base-api-path'] + '/auth/getSystemInfo', {
        headers: {
            accept: 'text/plain',
            'content-type': 'application/json',
        },
        body: null,
        method: 'GET',
    });
    const result = response.status !== 204 && response.statusText !== 'No Content' ? await response.text() : null;
    return response.ok ? parseGetSystemInfoResponse(result ?? '') : parseGetSystemInfoResponseError(result ?? '');
};
const parseAuthResult = (o) => {
    const parsed = parseJson(o, (data) => Boolean(typeof data === 'object' && data !== null) &&
        'lastSuccessfulLogin' in data &&
        'failedAttempts' in data);
    return {
        ...parsed,
        lastSuccessfulLogin: parsed?.lastSuccessfulLogin
            ? DateTime.fromISO(parsed.lastSuccessfulLogin, { zone: 'UTC' })
            : undefined,
    };
};
const parseGetSystemInfoResponse = (o) => {
    const result = parseJson(o, (data) => Boolean(typeof data === 'object' && data !== null) && checkSystemInfoFields(data));
    if (result)
        return [undefined, result];
    return ['parsing error', undefined];
};
const parseGetSystemInfoResponseError = (o) => [
    parseJson(o, (data) => Boolean(typeof data === 'object' && data !== null && 'message' in data)),
    undefined,
];
const checkSystemInfoFields = (data) => 'authenticationType' in data &&
    'loginPasswordForbidden' in data &&
    'ldapForbidden' in data &&
    'isLDAPConfigured' in data;
const getRandTimeoutTime = () => 24_000 + (Math.random() * 10_000 - 5_000);
let reauthenticateTimeout;
function setAutoReauthenticate() {
    const timeout = (getExpirationDate() ?? 0) - Date.now() - getRandTimeoutTime();
    if (timeout <= 0)
        return;
    if (reauthenticateTimeout)
        window.clearTimeout(reauthenticateTimeout);
    log.dev.debug(__filename, `Next token will be received in ${(timeout / 60_000).toFixed(2)} minutes`);
    reauthenticateTimeout = window.setTimeout(() => {
        if (!isTokenExpired())
            return setAutoReauthenticate();
        if (authPromise)
            return;
        authPromise = reauthenticate();
        debugExpiration('Token updated by timeout');
        authPromise.then(() => (authPromise = undefined));
    }, timeout);
}
function debugExpiration(reason) {
    if (process.env.NODE_ENV !== 'development')
        return;
    authPromise?.then(() => log.dev.debug(__filename, `${reason}. ${(((getExpirationDate() ?? 0) - Date.now()) / 60_000).toFixed(2)} minutes left until token expiration`));
}
setAutoReauthenticate();
async function reauthenticate() {
    if (location.protocol !== 'https:')
        return reauthenticateInner();
    await navigator.locks.request('fora.reauthenticate', async () => {
        try {
            await reauthenticateInner();
        }
        catch (e) {
            log.prod.error(__filename, e);
        }
    });
}
async function reauthenticateInner() {
    const x = await fetch(window['fb-base-api-path'] + '/auth/RefreshAccessToken', {
        headers: {
            accept: 'text/plain',
            'content-type': 'application/json',
        },
        body: JSON.stringify({}),
        method: 'POST',
    });
    const parseResult = x.ok ? parseAuthResult(await x.text()) : undefined;
    if ((x.status === 401 || parseResult?.hasExpiredPassword) &&
        services.router.currentRoute.value.name !== 'login' &&
        services.router.currentRoute.value.name !== 'logoff' &&
        services.router.currentRoute.value.name !== undefined)
        services.router.push({ name: 'logoff' });
    else if (x.status !== 409)
        setAutoReauthenticate();
    return x;
}
let authPromise;
export async function authFetch(input, init) {
    if (authPromise)
        await authPromise;
    if (isTokenExpired()) {
        authPromise = reauthenticate();
        debugExpiration('Token updated before query');
        try {
            await authPromise;
        }
        finally {
            authPromise = undefined;
        }
    }
    const response = await fetch(input, init);
    if (response.status === 401) {
        throw new Error('Unauthorized');
    }
    return response;
}
function getExpirationDate() {
    const cts = document.cookie
        .split(';')
        .map(x => x.trim())
        .find(x => x.startsWith('_fora_access_cookie_expiration_timestamp_'))
        ?.split('=')[1];
    if (!cts)
        return;
    const timestamp = Number(cts);
    if (Number.isNaN(cts))
        return;
    return timestamp;
}
export function isTokenExpired() {
    const expirationDate = getExpirationDate();
    if (!expirationDate)
        return true;
    return expirationDate - Date.now() < 24000;
}
export async function isTokenViable() {
    const x = await fetch('/auth/checkSessionStatus', {
        headers: {
            accept: 'text/plain',
            'content-type': 'application/json',
        },
        credentials: 'include',
        mode: 'cors',
        method: 'GET',
    });
    return x.ok;
}
export function logoff() {
    return authFetch('/auth/LogOut', {
        headers: {
            'content-type': 'application/json',
        },
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
    });
}
