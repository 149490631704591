import { filters as coreFilters, escapeSpecialODataCharacters, isNotNullOrUndefined } from '@fbc/core/utils';
import { isNumber } from 'lodash';
import { getText } from '../set-fluent-filter';
const filterTypes = new Set(['text', 'equal', 'number', 'dateTime', 'list', 'notEqual']);
export const typesGuard = (filter) => filterTypes.has(filter.type);
export function map(rawFilter) {
    const dataFilter = {
        ...rawFilter,
        fieldName: coreFilters.capitalize(rawFilter?.fieldName, { onlyFirstLetter: true }), //! пока так, ибо поля в odata с заглавной
    };
    switch (dataFilter.type) {
        case 'text':
            return getTextFilter(dataFilter);
        case 'equal':
            return getEqualFilter(dataFilter, 'eq');
        case 'notEqual':
            return getEqualFilter(dataFilter, 'ne');
        case 'number':
            return getNumberFilter(dataFilter);
        case 'dateTime':
            return getDateTimeFilter(dataFilter);
        case 'list':
            return getListFilter(dataFilter);
    }
}
function getTextFilter(filter) {
    const { register, value } = getText(filter.isLower, filter.value);
    const textValue = escapeSpecialODataCharacters(value ?? 'null');
    const fieldName = `${register}(${filter.fieldName})`;
    switch (filter.op) {
        case '=':
            return { fieldName, operator: 'eq', value: `'${textValue}'` };
        case '!=':
            return { fieldName, operator: 'ne', value: `'${textValue}'` };
        case 'starts':
        case 'ends':
        case 'contains':
            return {
                fieldName,
                operator: 'like',
                expression: getLikeValue(fieldName, textValue, filter.op),
            };
        case 'empty':
            return { fieldName, operator: 'eq', value: textValue };
        case 'not empty':
            return { fieldName, operator: 'ne', value: textValue };
    }
}
function getEqualFilter(filter, operator) {
    const fieldName = filter.fieldName.includes(' ')
        ? filter.fieldName.substring(0, filter.fieldName.indexOf(' '))
        : filter.fieldName;
    const value = filter.value
        ? isNumber(filter.value)
            ? filter.value
            : escapeSpecialODataCharacters(filter.value)
        : 'null';
    return { fieldName, operator, value: value };
}
function getNumberFilter(filter) {
    const hasFrom = isNotNullOrUndefined(filter.from) || filter.from === '';
    const hasTo = isNotNullOrUndefined(filter.to) || filter.to === '';
    if (hasFrom && !hasTo)
        return { fieldName: filter.fieldName, operator: 'ge', value: filter.from };
    if (!hasFrom && hasTo)
        return { fieldName: filter.fieldName, operator: 'le', value: filter.to };
    if (filter.from === filter.to)
        return { fieldName: filter.fieldName, operator: 'eq', value: filter.to };
    return {
        fieldName: filter.fieldName,
        operator: 'between',
        value: `${filter.from} and ${filter.to}`,
    };
}
function getDateTimeFilter(filter) {
    if (filter.from && !filter.to)
        return { fieldName: filter.fieldName, operator: 'ge', value: filter.from };
    if (!filter.from && filter.to)
        return { fieldName: filter.fieldName, operator: 'le', value: filter.to };
    if (filter.from === filter.to && filter.to)
        return { fieldName: filter.fieldName, operator: 'eq', value: filter.to };
    return [
        { fieldName: filter.fieldName, operator: 'ge', value: filter.from },
        { fieldName: filter.fieldName, operator: 'le', value: filter.to },
    ];
}
function getListFilter(filter) {
    if (filter.list.length === 1) {
        return { fieldName: filter.fieldName, operator: 'eq', value: `'${filter.list[0]}'` };
    }
    const separator = `', '`;
    return { fieldName: filter.fieldName, operator: 'in', value: `('${filter.list.join(separator)}')` };
}
function getLikeValue(fieldName, value, op) {
    return {
        starts: `startswith(${fieldName},'${value}')`,
        ends: `endswith(${fieldName},'${value}')`,
        contains: `contains(${fieldName},'${value}')`,
    }[op];
}
