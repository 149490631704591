import { services } from '@fbc/core/services';
import { HttpTransportType, HubConnectionBuilder } from '@microsoft/signalr';
export async function useWebSockets() {
    const { store, router } = services;
    await store.user.promise;
    if (!store.user.user?.id)
        return;
    try {
        const connection = new HubConnectionBuilder()
            .withUrl(store.app.websocketServiceUrl + 'notificationsHub', {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets,
        })
            .withAutomaticReconnect()
            .configureLogging(new services.webSocketLogger())
            .build();
        connection.on('NotificationReceived', (notification) => {
            services.bus.emit(notification.notificationName, notification.payload);
        });
        // выносить после загрузок нет смысла, т.к. он не занимает  слот запроса
        await connection.start();
        useEntityPagesObservability(store.user.user.id, connection, router);
    }
    catch {
        //no catch
    }
}
const getEntityUrlFromRoute = (route) => {
    const entityPageRoute = route.matched.findLast(x => x.meta.moduleType === 'EntityPage');
    if (!entityPageRoute?.meta.entityName)
        return '';
    return `${entityPageRoute.meta.entityName}/${route.params.id.toString()}`;
};
const useEntityPagesObservability = (userId, connection, router) => {
    connection.on('EntityOpened', ({ userId, url }) => {
        services.bus.emit(`entity/${url}/opened`, userId);
    });
    connection.on('EntityClosed', ({ userId, url }) => {
        services.bus.emit(`entity/${url}/closed`, userId);
    });
    const isEntityPage = (route) => route.meta.moduleType === 'EntityPage' && Boolean(route.params.id);
    const isTab = (route) => route.meta.moduleType !== 'EntityPage' &&
        route.matched.some(x => x.meta.moduleType === 'EntityPage') &&
        Boolean(route.params.id);
    /*
        [-] EntityPage -> Tab
        [+] EntityPage -> not EntityPage
        [+] EntityPage -> another EntityPage
        [-] Tab -> Tab (same entity)
        [+] Tab -> Tab (different entityName)
        [+] Tab -> not EntityPage
        [+] Tab -> another EntityPage
        [-] not EntityPage -> not EntityPage
        [+] not EntityPage -> EntityPage
        [+] not EntityPage -> Tab
        
        + еще учитывать что сама страница ГСП со списками - это тоже EntityPage
    */
    router.afterEach((to, from) => {
        if ((isEntityPage(from) && isTab(to)) ||
            (isTab(from) && isTab(to) && from.meta.entityName === to.meta.entityName) ||
            (!isEntityPage(from) && !isTab(from) && !(isEntityPage(to) || isTab(to))))
            return;
        /*
            В 90% случаев мы сначала переходим на ОЭФ и только потом автоматически редиректит на первую табу
            Но если находясь на табе ОЭФ перейти куда-то и потом нажать кнопку браузера "назад",
            то мы также попадем в хук afterEach, но сразу на табу, а не на ОЭФ
        */
        if (isEntityPage(to) || isTab(to))
            connection.invoke('EntityOpened', getEntityUrlFromRoute(to), userId);
        if (isEntityPage(from) || isTab(from))
            connection.invoke('EntityClosed', getEntityUrlFromRoute(from), userId);
        return true;
    });
    // Если закрыли вкладку
    window.addEventListener('beforeunload', () => {
        const route = router.currentRoute.value;
        if (!isEntityPage(route) && !isTab(route))
            return;
        connection.invoke('EntityClosed', getEntityUrlFromRoute(route), userId);
    });
    // Сразу эмитим событие посещения ОЭФ если открыли напрямую по url
    const onLoad = () => {
        const route = router.currentRoute.value;
        if (!isEntityPage(route) && !isTab(route))
            return;
        connection.invoke('EntityOpened', getEntityUrlFromRoute(route), userId);
    };
    onLoad();
};
