import { services } from '@fbc/core/services';
import { defineComponent, h, provide, resolveComponent } from 'vue';
const loadingMap = new Map();
export const PageComponent = defineComponent({
    name: 's-page',
    inheritAttrs: false,
    props: {
        name: {
            type: String,
            required: true,
        },
        properties: { type: Object, default: () => ({}) },
        placeholder: {
            type: Object,
        },
        optional: { type: Boolean },
    },
    emits: ['module:loaded'],
    setup(props) {
        provide('nameModule', props.name);
    },
    render() {
        const { store, modules, toast } = services;
        const config = store.app.modules[this.name];
        // optional компоненты могут не существовать
        if (!config && this.optional)
            return this.$slots.fallback?.();
        const Runtime = modules.getComponent(this.name, this);
        if (loadingMap.has(this.name)) {
            loadingMap.get(this.name)?.then(() => this.$forceUpdate());
        }
        else if (!Runtime) {
            const promise = store.app.moduleRegistry
                .load(this.name)
                .then(result => {
                const module = result?.default;
                if (isVueComponent(module)) {
                    modules.addComponent(this.name, module, this);
                    this.$forceUpdate();
                    loadingMap.delete(this.name);
                }
                else if (module) {
                    module(services, config?.parameters).then(x => {
                        modules.addComponent(this.name, x, this);
                        this.$forceUpdate();
                        loadingMap.delete(this.name);
                    });
                }
                this.$emit('module:loaded');
            })
                .catch(r => {
                toast.error(`Не удалось загрузить модуль ${this.name}. Вернитесь обратно, может он потом будет доступен.`);
                loadingMap.delete(this.name);
                throw r;
            });
            loadingMap.set(this.name, promise);
        }
        return Runtime
            ? h(Runtime, { ...(config?.parameters ?? {}), ...this.$attrs, ...this.properties, 'data-module': this.name }, this.$slots)
            : (this.$slots.fallback?.() ??
                (this.placeholder?.props
                    ? h(resolveComponent(this.placeholder.component ?? 's-placeholder'), {
                        'data-module': this.name,
                        ...this.placeholder.props,
                    })
                    : undefined));
        // Lighthouse испытывает лютую ненависть к плейсхолдеру компонента и рисует CLS 0.5(2/25),
        // поэтому рендерим его только если установлены какие-нибудь пропы
    },
});
const isVueComponent = (x) => typeof x === 'object' && Boolean(x);
