import { DataApi } from '../data-api';
/**
 * Data API Provider
 */
export class TableDataApiProvider {
    method;
    parameters;
    /**
     * @constructor
     * @param method Название метода в Data API
     * @param parameters Передаваемые параметры в Data API
     */
    constructor(method, parameters) {
        this.method = method;
        this.parameters = parameters;
    }
    get methodName() {
        return this.method;
    }
    getData({ page, filters, sort, pick, } = {}) {
        const dataService = new DataApi();
        return dataService.get(this.method, this.parameters, __filename, {
            count: page?.size,
            startIndex: page ? page.num * page.size : 0,
            filters: filters,
            sort: sort?.length ? sort : undefined,
            totalResults: page?.totalResults ?? true,
            pick: pick,
        });
    }
}
