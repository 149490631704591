import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
    class: "s-radio-group",
    role: "radiogroup"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_s_radio_button = _resolveComponent("s-radio-button");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
            return (_openBlock(), _createBlock(_component_s_radio_button, {
                key: option[_ctx.idField],
                "model-value": _ctx.modelValue,
                item: option,
                idField: _ctx.idField,
                labelField: _ctx.labelField,
                readonly: _ctx.readonly,
                theme: _ctx.theme,
                disabled: typeof _ctx.disabled === 'boolean' ? _ctx.disabled : _ctx.disabled.has(option[_ctx.idField]),
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('update:modelValue', $event)))
            }, {
                default: _withCtx(({ item, htmlDomId }) => [
                    _renderSlot(_ctx.$slots, "default", {
                        item: item,
                        htmlDomId: htmlDomId
                    })
                ]),
                _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["model-value", "item", "idField", "labelField", "readonly", "theme", "disabled"]));
        }), 128 /* KEYED_FRAGMENT */))
    ]));
}
