import { services } from '@fbc/core/services';
import { logAccess } from '@fbc/core/utils';
import { PageComponent, PageNotFound } from '@fbc/ui/components';
import { h } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
export const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/login',
            name: 'login',
            component: h(PageComponent, {
                name: 'login',
                key: 'login',
            }),
        },
        {
            path: '/logoff',
            name: 'logoff',
            component: h(PageComponent, {
                name: 'logoff',
                key: 'logoff',
            }),
        },
        {
            path: '/:pathMatch(.*)*',
            name: 'page-not-found',
            component: h(PageNotFound),
        },
    ],
});
router.beforeEach(() => {
    // Этот гвард должен идти перед всеми beforeEach
    const { store } = services;
    store.performance.start('guards');
    return true;
});
router.beforeEach(() => {
    const { store } = services;
    if (!store?.isDirty)
        return true;
    // eslint-disable-next-line no-alert
    const shouldRedirect = confirm('Возможно, внесенные изменения не сохранятся.');
    if (shouldRedirect)
        store.clearDirtyStatus();
    return shouldRedirect;
});
router.beforeEach(async (to, from) => {
    if (!to.meta.securedAction)
        return true; // Нет проверки
    const { store: { user }, } = services;
    await user.promise; // При заходе на страницу без загруженного SPA
    if (user.hasAccess(to.meta.securedAction).value)
        return true; // Пройдена
    if (from.name === 'login')
        return { path: '/' }; // Если проверка со страницы входа
    const isUserAuthorized = !!user.user;
    if (isUserAuthorized) {
        if (to.matched[to.matched.length - 1].name === to.name)
            return { name: 'page-no-access', replace: true }; // Если проверка для страницы
        if (from.path !== '/')
            services.toast.error(services.i18n.global('no-access')); // Если проверка для закладки
    }
    return false; // Если проверка для закладки
});
router.beforeEach(to => {
    // Этот гвард должен идти после всех beforeEach
    const { store } = services;
    store.performance.start('navigate');
    store.performance.end('guards', 'Отработаны гварды на роуте', 'core', { path: to.fullPath });
    return true;
});
router.afterEach((to, from) => {
    // Этот гвард должен идти перед всеми afterEach
    const { store } = services;
    store.performance.end('navigate', 'Навигация', 'core', { from: from.fullPath, to: to.fullPath });
    store.performance.startPageLoad();
    return true;
});
router.afterEach(to => {
    const isEntityTab = Boolean(to.meta.entityName && to.meta.moduleType === 'Page');
    const entityId = to.params?.id ? String(to.params.id) : null;
    logAccess({
        entityId,
        entityType: to.meta.entityName ?? '',
        description: to.meta.description?.['ru-ru'] ?? '',
        type: to.meta.title?.['ru-ru'] ?? to.meta.title?.default ?? to.fullPath,
        isTab: isEntityTab,
    });
});
router.afterEach(to => {
    if (typeof to.query.dialog !== 'string')
        return;
    services.moduleDialog(to.query.dialog, extractDialogParameters(to));
    router.replace({ query: extractUnusedQueryParameters(to) });
});
router.afterEach(async (to, from) => {
    const { store } = services;
    if (from.name === undefined && to.name !== 'login')
        await store.idle.initialize(store.customSettings);
    if (to.name === 'logoff')
        store.idle.clear();
    return true;
});
function extractDialogParameters(to) {
    return Object.keys(to.query)
        .filter(x => x.startsWith('dialog-'))
        .reduce((p, c) => ((p[c.replace('dialog-', '')] = to.query[c]), p), {});
}
function extractUnusedQueryParameters(to) {
    return Object.keys(to.query)
        .filter(x => !x.startsWith('dialog-') && x !== 'dialog')
        .reduce((p, c) => ((p[c] = to.query[c]), p), {});
}
