import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_s_button = _resolveComponent("s-button");
    return _renderSlot(_ctx.$slots, "default", {
        generateFile: _ctx.generateFile,
        download: _ctx.download
    }, () => [
        _createVNode(_component_s_button, {
            icon: "fb-common-excel-doc",
            onClick: _cache[0] || (_cache[0] =
                () => {
                    _ctx.generateFile();
                    _ctx.download();
                })
        })
    ]);
}
