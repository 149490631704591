import { services } from '@fbc/core/services';
import { createUniqueId, isTruthy } from '@fbc/core/utils';
import { computed, reactive } from 'vue';
import { isMenuItem } from './guards';
export const useScreen = () => {
    const viewport = reactive({
        width: window.innerWidth,
        height: window.innerHeight,
        orientation: screen.orientation.type,
    });
    const device = computed(() => {
        if ((viewport.width <= 500 && viewport.orientation.startsWith('portrait')) ||
            (viewport.height <= 500 && viewport.orientation.startsWith('landscape')))
            return 'mobile';
        if ((viewport.width > 500 && viewport.width <= 1280 && viewport.orientation.startsWith('portrait')) ||
            (viewport.height > 500 && viewport.height <= 700 && viewport.orientation.startsWith('landscape')))
            return 'tablet';
        return 'desktop';
    });
    function handleResize() {
        viewport.width = window.innerWidth;
        viewport.height = window.innerHeight;
    }
    function handleRotation() {
        viewport.orientation = screen.orientation.type;
    }
    screen.orientation.addEventListener('change', handleRotation, { passive: true });
    window.addEventListener('resize', handleResize, { passive: true });
    function unregister() {
        screen.orientation.removeEventListener('change', handleRotation);
        window.removeEventListener('resize', handleResize);
    }
    return { viewport, device, unregister };
};
export function filterAccess(items, hasAccess, isDevelopment) {
    return filterEmptyGroups(items
        ?.filter(x => ('securedAction' in x && x.securedAction ? hasAccess(x.securedAction).value : true) &&
        !(!isDevelopment && x.development))
        .map(x => 'items' in x ? { ...x, items: filterAccess(x.items, hasAccess, isDevelopment) ?? [] } : x));
}
// Группы фильтруем потом, т.к. filterAccess идет от корня
export function filterEmptyGroups(items) {
    return items
        ?.filter(x => x.link ?? x.items.length)
        .map(x => ('items' in x ? { ...x, items: filterEmptyGroups(x.items) ?? [] } : x));
}
const isMenuItemOrArray = (x) => Array.isArray(x) ? x.every(isMenuItem) : isMenuItem(x);
export function extractMenuItemsFromModules(property, modules, groups) {
    const items = [];
    // Если не указаны группы, то формируем меню из верхних итемов.  group может быть указан, но если предка для него нет, то он считается верхнеуровневым
    const rootItems = new Set();
    const map = new Map();
    addGroupsToMap(groups, map);
    addItemsFromModules(modules, property, map, items, rootItems);
    formGroups(items, map, rootItems);
    // Сортируем
    // В результате полное валидное дерево итемов навбара. Фильтра по ДБ нет, т.к. ДБ может меняться по ходу работы
    return sortItems([...groups, ...rootItems]);
}
const sortItems = (items) => {
    // При одинаковом order элементы сортируется в лексикографическом порядке
    items.sort((a, b) => 'text' in a && 'text' in b
        ? services.i18n
            .extractFromFlatResource(a.text)
            .localeCompare(services.i18n.extractFromFlatResource(b.text))
        : 0);
    items.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    for (const item of items)
        if (item.items)
            sortItems(item.items);
    return items;
};
function formGroups(items, map, rootItems) {
    for (const item of items) {
        if (!('group' in item) || !item.group)
            continue;
        if (typeof item.group === 'string') {
            setParent(map.get(item.group), item);
        }
        else {
            const parents = (Array.isArray(item.group.id) ? item.group.id : [item.group.id])
                .map(x => map.get(x))
                .filter(isTruthy);
            if (!parents.length)
                setParent(typeof item.group.fallback === 'string'
                    ? map.get(item.group.fallback)
                    : map.get(item.group.fallback?.find(x => map.has(x)) ?? ''), item);
            else
                parents.forEach(x => setParent(x, item));
        }
    }
    function setParent(parent, item) {
        if (!parent)
            return;
        if (!parent.items)
            parent.items = [];
        parent.items.push(item);
        rootItems.delete(item);
        parent.$type = 'group';
    }
}
function addItemsFromModules(modules, property, map, items, rootItems) {
    // Не цепочкой, потому что есть побочные эффекты
    for (const module of modules) {
        const { name, [property]: elements } = module;
        // Фильтруем подходящие по типу
        if (!elements)
            continue;
        if (!isMenuItemOrArray(elements)) {
            logUnexpectedType(elements);
            continue;
        }
        // Обогащаем
        const enrichedItems = (Array.isArray(elements) ? elements : [elements]).map((x, i) => enrichItem(x, Array.isArray(elements) ? `${property}_${name}_${i + 1}` : `${property}_${name}`, name));
        // Вливаем в существующие
        for (const element of enrichedItems) {
            map.set(element.id, element);
            items.push(element);
            rootItems.add(element);
        }
    }
}
export const enrichItem = (x, idFallback, module) => ({
    ...x,
    $type: x.$type ?? (x.items?.length ? 'group' : x.action ? 'action' : 'link'),
    id: x.id ?? idFallback ?? createUniqueId(),
    module: x.module ?? module,
    link: typeof x.link === 'string' ? { $type: 'internal', path: x.link } : x.link,
    icon: typeof x.icon === 'string' ? { $type: 'icon', data: x.icon } : x.icon,
    items: x.items ?? [],
    order: x.order ?? 0,
    text: typeof x.text === 'string' ? { default: x.text } : x.text,
    tooltip: typeof x.tooltip === 'string' ? { default: x.tooltip } : x.tooltip,
});
function addGroupsToMap(groups, map) {
    for (const group of groups) {
        group.$type ??= 'group';
        group.id = group.id || `nav_group_${createUniqueId()}`;
        group.items ??= [];
        group.order ??= 0;
        map.set(group.id, group);
        addGroupsToMap(group.items, map);
    }
}
function logUnexpectedType(navbar) {
    if (!services.store.app.isDevelopment)
        return;
    const item = {
        id: 'string',
        securedAction: 'string?',
        text: { default: 'string', 'ru-ru': 'string' },
        tooltip: { default: 'string', 'ru-ru': 'string' },
        order: 0,
        icon: { $type: 'icon', data: 'fb-common-location' },
        link: { $type: 'internal', path: '/account' },
        group: 'string?|{ id: string | string[]; fallback?: string | string[] }',
        items: [],
    };
    log.prod.warn(__filename, `Unexpected navbar type, expect:
${JSON.stringify(item, undefined, '\t')}
got:
${JSON.stringify(navbar, undefined, '\t')}`);
}
