import { defineFunctionalComponent } from '@fbc/core/utils';
import { h, nextTick, ref } from 'vue';
const scrollZone = 50; // Ширина зоны, при попадании в которую начинается прокрутка
const scrollSpeed = 10; // Скорость прокрутки в пикселях за интервал
const scrollIntervalTime = 10; // Время в мс для интервала между прокрутками
export function useDataTableDraggableRow(tableId, emit) {
    const ghost = ref();
    const hoveredElement = ref();
    const isDragging = ref(false);
    const scrollInterval = ref();
    const draggableEntity = ref();
    const direction = ref();
    const onPointerDown = (event, entity) => {
        isDragging.value = true;
        draggableEntity.value = entity;
        document.addEventListener('pointermove', onDocumentPointerMove);
        document.addEventListener('pointerup', onDocumentPointerUp);
        document.addEventListener('pointercancel', onDocumentPointerUp);
        document.body.classList.add('s-data-table__dragged');
        nextTick(() => updateGhostPosition(event));
        if (event.target instanceof HTMLElement)
            event.target.releasePointerCapture(event.pointerId);
    };
    const onDocumentPointerMove = (event) => {
        updateGhostPosition(event);
        findScrollableContainerAndScroll(event.clientX, event.clientY);
        if (hoveredElement.value) {
            hoveredElement.value.style.removeProperty('border-top');
            hoveredElement.value.style.removeProperty('border-bottom');
        }
        hoveredElement.value = findRow(event.clientX, event.clientY);
        if (!hoveredElement.value)
            return;
        const target = hoveredElement.value.getBoundingClientRect();
        if (target.y + target.height / 2 >= event.clientY) {
            hoveredElement.value.style.borderTop = '2px solid var(--divider)';
            hoveredElement.value.style.removeProperty('border-bottom');
            direction.value = 'before';
        }
        else {
            hoveredElement.value.style.removeProperty('border-top');
            hoveredElement.value.style.borderBottom = '2px solid var(--divider)';
            direction.value = 'after';
        }
    };
    const onPointerUp = (entity) => {
        if (!isDragging.value || !draggableEntity.value || !direction.value)
            return;
        emit('row:dragAndDrop', { draggedEntity: draggableEntity.value, targetEntity: entity, direction: direction.value });
    };
    const onDocumentPointerUp = (event) => {
        log.dev.debug(__filename, event.type, event);
        if (!isDragging.value)
            return;
        document.removeEventListener('pointermove', onDocumentPointerMove);
        document.removeEventListener('pointerup', onDocumentPointerUp);
        document.removeEventListener('pointercancel', onDocumentPointerUp);
        isDragging.value = false;
        direction.value = undefined;
        draggableEntity.value = undefined;
        if (hoveredElement.value) {
            hoveredElement.value.style.removeProperty('border-top');
            hoveredElement.value.style.removeProperty('border-bottom');
            hoveredElement.value = undefined;
        }
        document.body.classList.remove('s-data-table__dragged');
        window.clearInterval(scrollInterval.value);
        scrollInterval.value = undefined;
    };
    const updateGhostPosition = (event) => {
        if (!ghost.value)
            return;
        ghost.value.style.top = event.pointerType === 'touch' ? '' : event.clientY + 'px';
        ghost.value.style.bottom = event.pointerType === 'touch' ? window.innerHeight - event.clientY + 'px' : '';
        ghost.value.style.left = event.clientX + 'px';
    };
    const findRow = (x, y) => {
        const elements = document.elementsFromPoint(x, y);
        return elements.find((element) => element instanceof HTMLElement &&
            element.matches(`#${tableId} > .s-data-table__table > .s-data-table__body > .s-data-table__row`));
    };
    const findScrollableContainerAndScroll = (x, y) => {
        window.clearInterval(scrollInterval.value);
        scrollInterval.value = window.setInterval(() => {
            const elements = document.elementsFromPoint(x, y);
            for (const element of elements) {
                if (element.scrollHeight === element.clientHeight)
                    continue;
                const elementRect = element.getBoundingClientRect();
                if (y <= elementRect.top + scrollZone) {
                    if (element.scrollTop === 0)
                        continue;
                    element.scrollTop -= scrollSpeed;
                    return;
                }
                else if (y >= elementRect.bottom - scrollZone) {
                    if (element.scrollTop + element.clientHeight === element.scrollHeight)
                        continue;
                    element.scrollTop += scrollSpeed;
                    return;
                }
                window.clearInterval(scrollInterval.value);
                scrollInterval.value = undefined;
            }
        }, scrollIntervalTime);
    };
    const column = {
        field: 'dragAndDrop',
        sortable: false,
        filterable: false,
        width: 20,
        cellTemplate: defineFunctionalComponent({
            entity: { type: Object, required: true },
            column: Object,
            index: Number,
        }, ({ entity }) => h('i', {
            class: 'fb-common-drag',
            style: {
                cursor: 'grab',
            },
            onPointerdown: (event) => onPointerDown(event, entity),
        })),
        classes: 's-data-table__touch-column',
    };
    return {
        column,
        ghost,
        draggableEntity,
        isDragging,
        handlers: { onPointerUp },
    };
}
