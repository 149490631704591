/**
 * Создает генератор последовательности чисел, который может возвращать числа в заданной алфавитом системе счисления
 * @param alphabet Алфавит, например для бинарных чисел 01
 * @param version Прибавляется в начале. Ид для DOM Node или классы css, например, должны начинаться с нечислового символа
 * @param start Начальное значение счетчика, по дефолту 0
 */
export function useSequence(alphabet, version, start = 0) {
    let counter = start;
    /** Возвращает текстовое представление счетчика в системе счисления, заданной алфавитом */
    function createStringId() {
        return getStringRepresentation(counter++);
    }
    /** Возвращает следующее значение счетчика в виде числа */
    function createNumberId() {
        return counter++;
    }
    /** Конвертирует числовое представление счетчика в систему счисления, заданную алфавитом */
    function getStringRepresentation(number) {
        let id = '';
        let num = number;
        do {
            const position = num % alphabet.length;
            id = alphabet[position] + id;
            num = (num - (num % alphabet.length)) / alphabet.length;
        } while (num > 0);
        return version + id;
    }
    return { createStringId, createNumberId, alphabet, version, getStringRepresentation };
}
export const { createStringId: createUniqueId, alphabet, version, } = useSequence('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ', 'v1');
