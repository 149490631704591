import { childsSelectionStrategies } from './strategies/childs-selection';
import { parentSelectionStrategies } from './strategies/parent-selection';
import { sortStrategies } from './strategies/sort';
import { traverseStrategies } from './strategies/traverse';
import Tree from './tree';
export { openOnlyNumNodes, openOnlyOneNode } from './open';
export { SelectionComponent } from './s-tree-view-selection';
export { Selection, selectOnlyOneNode, switchSelection } from './selection';
export { compare } from './sort';
export { default as TreeCollection } from './tree-array';
export default Tree;
export const strategies = {
    traversal: traverseStrategies,
    selection: {
        parent: parentSelectionStrategies,
        childs: childsSelectionStrategies,
    },
    sort: sortStrategies,
};
