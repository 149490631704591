import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = ["aria-label"];
const _hoisted_2 = { class: "s-modal__content" };
const _hoisted_3 = { class: "s-modal__footer" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_s_button = _resolveComponent("s-button");
    const _component_s_popup = _resolveComponent("s-popup");
    return (_openBlock(), _createBlock(_component_s_popup, {
        visible: _ctx.visible,
        "onUpdate:visible": _cache[3] || (_cache[3] = ($event) => ((_ctx.visible) = $event)),
        class: _normalizeClass(["s-modal", [_ctx.rootClasses, { 's-modal--mobile-fullscreen': _ctx.mobileFullscreen }]]),
        preventClickOutsideHide: ""
    }, {
        default: _withCtx(() => [
            _createElementVNode("section", {
                ref: "modalRef",
                role: "dialog",
                "aria-modal": "true",
                "aria-label": _ctx.caption,
                class: "s-modal__modal",
                style: _normalizeStyle({ left: _ctx.left + 'px', top: _ctx.top + 'px', width: _ctx.width + 'px', height: _ctx.height + 'px' })
            }, [
                _createElementVNode("header", {
                    class: _normalizeClass(["s-modal__header", { 's-modal__header--draggable': _ctx.draggable }]),
                    onMousedown: _cache[1] || (_cache[1] = _withModifiers(
                    //@ts-ignore
                    (...args) => (_ctx.mouseDownOnHeader && _ctx.mouseDownOnHeader(...args)), ["exact"])),
                    onTouchstartPassive: _cache[2] || (_cache[2] = ($event) => (_ctx.draggable ? _ctx.drag(_ctx.modalRef, $event) : undefined))
                }, [
                    _renderSlot(_ctx.$slots, "header", {
                        drag: (x) => _ctx.drag(_ctx.modalRef, x)
                    }, () => [
                        _createTextVNode(_toDisplayString(_ctx.caption), 1 /* TEXT */)
                    ]),
                    (_ctx.showClose)
                        ? (_openBlock(), _createBlock(_component_s_button, {
                            key: 0,
                            class: "s-modal__close-button",
                            tabindex: "-1",
                            icon: "fb-common-close",
                            onClick: _ctx.cancelClick,
                            onMousedown: _cache[0] || (_cache[0] = _withModifiers(() => { }, ["stop"]))
                        }, null, 8 /* PROPS */, ["onClick"]))
                        : _createCommentVNode("v-if", true)
                ], 34 /* CLASS, NEED_HYDRATION */),
                _createElementVNode("main", _hoisted_2, [
                    _renderSlot(_ctx.$slots, "default")
                ]),
                _createElementVNode("footer", _hoisted_3, [
                    _renderSlot(_ctx.$slots, "footer", {}, () => [
                        _createVNode(_component_s_button, {
                            success: "",
                            class: "s-modal__s-button-ok",
                            onClick: _ctx.okClick
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$i18n.resource('global', 'ok')), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["onClick"]),
                        (_ctx.showOk)
                            ? (_openBlock(), _createBlock(_component_s_button, {
                                key: 0,
                                class: "s-modal__s-button-cancel",
                                onClick: _ctx.cancelClick
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$i18n.resource('global', 'cancel')), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["onClick"]))
                            : _createCommentVNode("v-if", true)
                    ])
                ])
            ], 12 /* STYLE, PROPS */, _hoisted_1)
        ]),
        _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["visible", "class"]));
}
