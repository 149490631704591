export function recalculatePosition(el, top, left) {
    if (!el)
        return { top: 0, left: 0 };
    top = Number.parseFloat(top);
    left = Number.parseFloat(left);
    if (Number.isNaN(top))
        top = 0;
    if (Number.isNaN(left))
        left = 0;
    const rect = el.getBoundingClientRect();
    if (rect.height + top > window.innerHeight)
        top = window.innerHeight - rect.height - 2;
    if (rect.width + left > window.innerWidth)
        left = window.innerWidth - rect.width - 2;
    if (top < 0)
        top = 0;
    if (left < 0)
        left = 0;
    return { top, left };
}
