import { services } from '@fbc/core/services';
import { isTruthy, parseJson } from '@fbc/core/utils';
import { DateTime } from 'luxon';
const isLoginMessageRaw = (x) => typeof x === 'object' &&
    x !== null &&
    (!('lastSuccessfulLogin' in x) || typeof x.lastSuccessfulLogin === 'string') &&
    (!('failedAttempts' in x) || typeof x.failedAttempts === 'number');
const toLoginMessage = (value) => {
    const raw = parseJson(value, isLoginMessageRaw);
    const lastSuccessfulLogin = raw?.lastSuccessfulLogin ? DateTime.fromISO(raw.lastSuccessfulLogin) : undefined;
    return { ...(raw ?? {}), lastSuccessfulLogin: lastSuccessfulLogin?.isValid ? lastSuccessfulLogin : undefined };
};
const showDefaultLoginToast = (message) => services.toast({
    body: [
        message.lastSuccessfulLogin &&
            services.i18n.global('previous-login-time', {
                previousLogin: message.lastSuccessfulLogin.toFormat('dd.MM.yyyy HH:mm ZZZZZ'),
            }),
        message.failedAttempts &&
            services.i18n.global('previous-login-attempts', { failedAttempts: message.failedAttempts }),
    ]
        .filter(isTruthy)
        .join('\n'),
    classes: message.failedAttempts ? 'warning' : undefined,
    delay: message.failedAttempts ? 30000 : 6000,
});
export function showLoginMessage() {
    const message = toLoginMessage(localStorage.getItem('fora.login.message'));
    localStorage.removeItem('fora.login.message');
    if (message.lastSuccessfulLogin || message.failedAttempts) {
        const module = services.store.app.modules['login-message'];
        if (module?.type === 'Dialog') {
            services.moduleDialog('login-message', message);
        }
        else
            showDefaultLoginToast(message);
    }
}
