import { services } from '@fbc/core/services';
import { defineComponent, h, resolveComponent } from 'vue';
import { TooltipComponent } from '../directives/tooltip';
export const PopupContainerComponent = defineComponent({
    name: 's-popup-container',
    setup() {
        return () => h('div', { class: 's-popup-container' }, [
            h(TooltipComponent),
            h(resolveComponent('s-navigation-context-menu'), {
                ...services.store.contextMenu.lastState,
                ...(services.store.contextMenu.menu ?? {}),
            }),
        ]);
    },
});
