import { services } from '@fbc/core/services';
import { useGlobalStore } from '@fbc/core/storage';
import { filters } from '@fbc/core/utils';
import { pinia } from '.';
import { router } from './routes';
const { alert, toast, i18n, confirm } = services;
export const global = {
    install(app) {
        const store = useGlobalStore();
        services.store = store;
        services.pinia = pinia;
        services.router = router;
        app.config.globalProperties.$store = store;
        app.config.globalProperties.$alert = alert;
        app.config.globalProperties.$confirm = confirm;
        app.config.globalProperties.$i18n = i18n;
        app.config.globalProperties.$filters = filters;
        app.provide('store', store);
        app.provide('toast', toast);
        app.provide('alert', alert);
        app.provide('confirm', confirm);
        app.provide('i18n', i18n);
        app.provide('filters', filters);
        app.provide('pinia', pinia);
    },
};
