export default {
    mounted(el, binding) {
        if (typeof binding.value !== 'function')
            throw new Error('Binding value is not a function');
        let touchstartX = 0;
        let touchstartY = 0;
        let touchendX = 0;
        let touchendY = 0;
        function handleGesture(touchstartX, touchstartY, touchendX, touchendY, event) {
            const deltaX = touchendX - touchstartX;
            const deltaY = touchendY - touchstartY;
            binding.value({ deltaX, deltaY, touchstartX, touchstartY, touchendX, touchendY, event });
        }
        el.addEventListener('touchstart', function (event) {
            touchstartX = event.changedTouches[0].screenX;
            touchstartY = event.changedTouches[0].screenY;
        }, false);
        el.addEventListener('touchend', function (event) {
            touchendX = event.changedTouches[0].screenX;
            touchendY = event.changedTouches[0].screenY;
            handleGesture(touchstartX, touchstartY, touchendX, touchendY, event);
        }, false);
    },
};
