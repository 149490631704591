import { dateTimeFromODataDate } from '@fbc/core/utils';
export class ODataMapper {
    converters;
    constructor(converters) {
        this.converters = converters;
    }
    toClientViewArray(key, relation) {
        return relation?.value?.map(x => this.toClientView(key, x)) ?? null;
    }
    toCamelCase(obj) {
        if (!obj || typeof obj !== 'object')
            return obj;
        if (Array.isArray(obj))
            return obj.map(x => this.toCamelCase(x));
        return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key.charAt(0).toLowerCase() + key.slice(1), this.toCamelCase(value)]));
    }
    toClientView(key, entity) {
        const lowerEntity = this.toCamelCase(entity);
        return Object.assign(lowerEntity, {
            id: lowerEntity.id,
            createDate: dateTimeFromODataDate(lowerEntity.createDate),
            modifyDate: dateTimeFromODataDate(lowerEntity.modifyDate),
        }, this.converters[key]?.from?.(lowerEntity, this) ?? {});
    }
    toServerView(key, entity) {
        if (!entity)
            return {};
        const convertedEntity = Object.assign({}, entity, {
            Id: entity?.id ?? undefined,
        }, this.converters[key]?.to?.(entity, this) ?? {});
        delete convertedEntity.id; // фиктивное св-во
        return convertedEntity;
    }
    toClientDateTime(value) {
        return dateTimeFromODataDate(value);
    }
}
