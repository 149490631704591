import { isEqual } from 'lodash';
/** Обертка для кэширования результатов загрузки */
export function createTableCacheProvider(provider, cache) {
    const parameters = {};
    return {
        methodName: provider.methodName,
        async getData({ page, filters, sort, pick }) {
            if (!isEqual(filters, parameters.filters) ||
                !isEqual(sort, parameters.sort) ||
                !isEqual(pick, parameters.pick) ||
                page?.totalResults)
                cache.clear();
            parameters.filters = filters;
            parameters.sort = sort;
            parameters.pick = pick;
            if (parameters.rest && cache.has(page?.num ?? 0)) {
                const items = cache.get(page?.num ?? 0)?.value ?? [];
                return [undefined, items, parameters.rest];
            }
            const result = await provider.getData({ page, filters, sort, pick });
            parameters.rest = result[2];
            cache.set(page?.num ?? 0, result[1] ?? []);
            return result;
        },
    };
}
