export default (dataType) => {
    switch (dataType) {
        case 'String':
            return ['Contains', 'Equal', 'StartsWith', 'NotEqual', 'IsNull', 'IsNotNull', 'In'];
        case 'Integer':
        case 'Decimal':
        case 'DateTime':
            return ['Equal', 'NotEqual', 'LessThan', 'LessOrEqual', 'MoreOrEqual', 'MoreThan', 'IsNull', 'IsNotNull'];
        case 'Clob':
            return ['Contains', 'Equal', 'StartsWith', 'NotEqual', 'IsNull', 'IsNotNull'];
    }
};
