import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, vShow as _vShow, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = ["data-filter"];
const _hoisted_2 = { class: "s-wrapper-filter__title" };
const _hoisted_3 = ["textContent"];
const _hoisted_4 = { key: 0 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_s_button = _resolveComponent("s-button");
    const _component_s_transition_collapse_height = _resolveComponent("s-transition-collapse-height");
    const _directive_tooltip = _resolveDirective("tooltip");
    return (_ctx.filterComponent)
        ? (_openBlock(), _createElementBlock("section", {
            key: 0,
            class: "s-wrapper-filter",
            "data-filter": _ctx.filter.id
        }, [
            _createElementVNode("h1", _hoisted_2, [
                _createElementVNode("label", {
                    class: "s-wrapper-filter__label",
                    onClick: _cache[0] || (_cache[0] = ($event) => {
                        _ctx.hasBeenVisible = true;
                        _ctx.opened = !_ctx.opened;
                    })
                }, [
                    _createElementVNode("i", {
                        class: _normalizeClass(_ctx.opened ? 'fb-common-bottom' : 'fb-common-right')
                    }, null, 2 /* CLASS */),
                    _withDirectives(_createElementVNode("span", {
                        class: "text-ellipsis",
                        textContent: _toDisplayString(_ctx.filter.displaySettings.caption)
                    }, null, 8 /* PROPS */, _hoisted_3), [
                        [
                            _directive_tooltip,
                            _ctx.filter.displaySettings.caption,
                            void 0,
                            {
                                top: true,
                                showOnlyIfOverflown: true
                            }
                        ]
                    ])
                ]),
                (_ctx.filter.isApply)
                    ? (_openBlock(), _createBlock(_component_s_button, {
                        key: 0,
                        "aria-label": _ctx.$i18n.global('clear'),
                        disabled: _ctx.disabled || _ctx.filterLoading,
                        icon: "fb-common-close",
                        style: { "--button-size": "20px" },
                        onClick: _ctx.onClear
                    }, null, 8 /* PROPS */, ["aria-label", "disabled", "onClick"]))
                    : _createCommentVNode("v-if", true)
            ]),
            _createVNode(_component_s_transition_collapse_height, null, {
                default: _withCtx(() => [
                    (_ctx.hasBeenVisible)
                        ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
                            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.filterComponent), {
                                filter: _ctx.filter,
                                groupId: _ctx.currentGroupId,
                                disabled: _ctx.disabled,
                                onIsLoading: _cache[1] || (_cache[1] = ($event) => (_ctx.filterLoading = $event)),
                                "onUpdate:filter": _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('update:filter', $event)))
                            }, null, 40 /* PROPS, NEED_HYDRATION */, ["filter", "groupId", "disabled"]))
                        ], 512 /* NEED_PATCH */)), [
                            [_vShow, _ctx.opened]
                        ])
                        : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
            })
        ], 8 /* PROPS */, _hoisted_1))
        : _createCommentVNode("v-if", true);
}
