import { Selection } from '..';
/** Выбирается Selection.Partial если не все дети выбраны. Иначе select */
const partial = (tree) => tree.traversal(item => item.childs.every(x => x.selected === Selection.Selected)
    ? ((item.selected = Selection.Selected), true)
    : item.childs.some(x => x.selected === Selection.Selected || x.selected === Selection.Partial)
        ? ((item.selected = Selection.Partial), true)
        : ((item.selected = Selection.UnSelected), true), 'up');
/** Выбираются родители только если все дети выбраны */
const select = (tree) => tree.traversal(item => item.childs.some(x => x.selected === Selection.Selected)
    ? ((item.selected = Selection.Selected), true)
    : ((item.selected = Selection.UnSelected), true), 'up');
export const parentSelectionStrategies = {
    /** Выбирается Selection.Partial если не все дети выбраны. Иначе select */
    partial,
    /** Выбираются родители только если все дети выбраны */
    select,
};
