export const useOperatorsLocalization = (local) => ({
    StartsWith: local('start-with'),
    Contains: local('contains'),
    Equal: local('equal'),
    NotEqual: local('not-equal'),
    LessThan: local('less-than'),
    MoreThan: local('more-than'),
    LessOrEqual: local('less-or-equal'),
    MoreOrEqual: local('more-or-equal'),
    In: local('in'),
    IsNull: local('is-null'),
    IsNotNull: local('is-not-null'),
});
