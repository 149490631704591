export default {
    columns: { type: Array, required: true },
    idField: { type: String, required: true },
    modelValue: { type: Object, default: () => null },
    /* Необязательные св-ва */
    additionalButtons: { type: Array, default: () => [] },
    allowClear: Boolean,
    allowMultiple: Boolean,
    preview: Boolean,
    caption: String,
    inputClick: Function,
    hiddenFilters: { type: Array, default: () => [] },
    label: String,
    modalClass: String,
    readonly: Boolean,
};
