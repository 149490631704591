const requireJson = require.context('../icons', false, /\.(svg)$/);
const iconDictionary = {};
for (const fileName of requireJson.keys()) {
    const componentConfig = requireJson(fileName);
    const componentName = fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '');
    iconDictionary[componentName] = componentConfig;
}
export default iconDictionary;
