import { enrichItem } from '@fbc/core/use-cases';
import { createGuid } from '@fbc/core/utils';
import { reactive, ref, watch } from 'vue';
export function useContextMenu() {
    const menu = ref();
    function show(items, position, options) {
        const { promise, resolve } = Promise.withResolvers();
        menu.value = {
            visible: true,
            items: items.map(x => enrichItem(x, createGuid())),
            position,
            showBack: false,
            ...(options ?? {}),
            'onUpdate:visible': (x) => {
                if (menu.value)
                    menu.value.visible = x;
                options?.['onUpdate:visible']?.(x);
                if (x)
                    return;
                resolve(undefined);
            },
            onSelect: resolve,
        };
        return promise;
    }
    watch(() => menu.value?.visible, x => {
        if (x)
            return;
        menu.value = undefined;
    });
    // При скрытии меню скрывается с анимацией. Если затереть состояние, то vue обновит меню во время анимации на пустое меню
    const lastState = reactive({});
    watch(() => menu.value?.position, x => x && (lastState.position = x), { deep: true });
    watch(() => menu.value?.items, x => x && (lastState.items = x), { deep: true });
    watch(() => menu.value?.showBack, x => x !== undefined && (lastState.showBack = x));
    function hide() {
        if (!menu.value)
            return;
        menu.value.visible = false;
    }
    return { menu, lastState, show, hide };
}
