import { computed, watch } from 'vue';
import { useSemaphore } from './use-semaphore';
const isModuleWithDirtyStatus = (x) => Boolean(x && typeof x === 'object' && 'isDirty' in x && typeof x.isDirty === 'boolean');
export function useDirty(dynamic) {
    const dirty = useSemaphore();
    const isDynamicModuleDirty = computed(() => [...dynamic.ctx.values()].some(module => isModuleWithDirtyStatus(module) && module.isDirty));
    const isDirty = computed(() => dirty.isLocked.value || isDynamicModuleDirty.value);
    function warnHasChanges(event) {
        event.preventDefault();
        return (event.returnValue = true);
    }
    watch(() => isDirty.value, (value, old) => {
        if (value === old)
            return;
        if (value)
            window.addEventListener('beforeunload', warnHasChanges, { capture: true });
        else
            window.removeEventListener('beforeunload', warnHasChanges, { capture: true });
    });
    return {
        isDirty,
        markDirty: dirty.lock,
        unmarkDirty: dirty.unlock,
        clearDirtyStatus: dirty.clear,
        isDynamicModuleDirty,
    };
}
