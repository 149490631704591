import { debounce } from 'lodash';
import { defineComponent, h, nextTick, reactive, ref, Transition, watch, } from 'vue';
const isOverflown = ({ clientWidth, clientHeight, scrollWidth, scrollHeight, }) => Math.abs(scrollHeight - clientHeight) > 1 || scrollWidth > clientWidth;
function parseArguments(binding) {
    const value = typeof binding.value == 'string' ? { label: binding.value } : binding.value;
    let direction = 'bottom';
    if (binding.modifiers.top)
        direction = 'top';
    if (binding.modifiers.bottom)
        direction = 'bottom';
    if (binding.modifiers.left)
        direction = 'left';
    if (binding.modifiers.right)
        direction = 'right';
    const popupClass = ['s-tooltip', ...(binding.arg ?? '').split(':')];
    if (binding.modifiers.pre)
        popupClass.push('s-tooltip--pre');
    if (value?.class)
        popupClass.push(value.class);
    return {
        label: value?.label ?? '',
        direction,
        showOnlyIfOverflownSelector: value?.showOnlyIfOverflownSelector,
        showOnlyIfOverflown: binding.modifiers.showOnlyIfOverflown,
        popupClass,
        offsetX: value?.offsetX ?? 0,
        offsetY: value?.offsetY ?? 0,
        openDelay: value?.offsetY ?? 500,
        closeDelay: value?.offsetY ?? 500,
        enabled: value?.enabled ?? true,
        enabledOnTouch: binding.modifiers.enabledOnTouch,
        useDangerousInnerHtml: binding.modifiers.useDangerousInnerHtml,
    };
}
const globalTooltip = ref();
export default (() => {
    document.body.addEventListener('scroll', () => globalTooltip.value?.delayedUpdatePosition(), {
        passive: true,
        capture: true,
    });
    document.body.addEventListener('pointermove', x => globalTooltip.value?.delayedIsMouseOver(x), {
        passive: true,
        capture: true,
    });
    document.body.addEventListener('pointerdown', x => globalTooltip.value?.delayedIsMouseOver(x), {
        passive: true,
        capture: true,
    });
    return {
        priority: 700,
        mounted(el, binding) {
            const tooltip = reactive(new Tooltip(el));
            Tooltip.state.set(el, tooltip);
            tooltip.update(parseArguments(binding));
            el.addEventListener('pointermove', event => tooltip.show(event), { passive: true, capture: true });
            el.addEventListener('pointerdown', event => tooltip.show(event), { passive: true, capture: true });
        },
        beforeUpdate(el, binding) {
            const tooltip = Tooltip.state.get(el);
            if (!tooltip)
                return;
            tooltip.update(parseArguments(binding));
        },
        unmounted(el) {
            if (globalTooltip.value?.target === el)
                globalTooltip.value = undefined;
            Tooltip.state.delete(el);
        },
    };
})();
function getTooltipPosition(rect, popupRect, { direction, offsetX, offsetY }) {
    if (Object.values(rect.toJSON()).every(x => x === 0))
        return;
    const { width = 0, height = 0 } = popupRect;
    let top = 0;
    let left = 0;
    switch (direction) {
        case 'top':
            left = rect.left + rect.width / 2 - width / 2 + offsetX;
            top = rect.top - height - 10 + offsetY;
            break;
        case 'left':
            left = rect.left - width - 10 + offsetX;
            top = rect.top - height / 2 + rect.height / 2 + offsetY;
            break;
        case 'right':
            left = rect.right + 10 + offsetX;
            top = rect.top - height / 2 + rect.height / 2 + offsetY;
            break;
        case 'bottom':
            left = rect.left + rect.width / 2 - width / 2 + offsetX;
            top = rect.bottom + 10 + offsetY;
            break;
    }
    // Компенсация выхода из окна
    if (height + top > window.innerHeight)
        top = window.innerHeight - height - 2;
    if (width + left > window.innerWidth)
        left = window.innerWidth - width - 2;
    if (left < 0)
        left = 0;
    if (top < 0)
        top = 0;
    return { left, top };
}
class Tooltip {
    target;
    static state = new WeakMap();
    visible = true;
    enabled;
    enabledOnTouch;
    overflow;
    tooltip;
    position = {};
    transition;
    class;
    style;
    label = '';
    openDelay = 500;
    closeDelay = 500;
    offsetX = 0;
    offsetY = 0;
    useDangerousInnerHtml;
    direction = 'bottom';
    constructor(target) {
        this.target = target;
    }
    updatePosition() {
        if (!this.tooltip)
            return;
        const positionNew = getTooltipPosition(this.target.getBoundingClientRect(), this.tooltip.getBoundingClientRect() ?? {}, { direction: this.direction, offsetX: this.offsetX, offsetY: this.offsetY });
        if (positionNew)
            this.position = positionNew;
        else
            globalTooltip.value = undefined;
    }
    // На скролл не рекомендуется вешать изменение DOM без задержек для производительности
    delayedUpdatePosition = debounce(this.updatePosition, 5);
    async show(event) {
        if (!this.label ||
            !this.enabled ||
            (!this.enabledOnTouch && event.pointerType !== 'mouse') ||
            (this.overflow &&
                !isOverflown(typeof this.overflow === 'string'
                    ? (this.target.querySelector(this.overflow) ?? {})
                    : this.target)) ||
            (this.visible && globalTooltip.value === this))
            return;
        if (globalTooltip.value?.target !== this.target) {
            globalTooltip.value?.hide();
            await nextTick();
        }
        this.visible = true;
        globalTooltip.value = this;
        // Ждем пока появится тултип, чтобы обновить позицию с учетом его размеров
        // В ответ на появление в s-popup-container не обновляет корректно
        nextTick(() => this.updatePosition());
    }
    update(args) {
        this.transition = `s-tooltip-transition--${args.direction}`;
        this.class = args.popupClass.join(' ');
        this.label = args.label;
        this.useDangerousInnerHtml = args.useDangerousInnerHtml;
        this.openDelay = args.openDelay;
        this.closeDelay = args.closeDelay;
        this.overflow = args.showOnlyIfOverflown ? args.showOnlyIfOverflown || args.showOnlyIfOverflownSelector : false;
        this.enabled = args.enabled;
        this.direction = args.direction;
        this.offsetX = args.offsetX;
        this.offsetY = args.offsetY;
        this.enabledOnTouch = args.enabledOnTouch;
        // Чтобы тултип не появлялся в 0:0
        const position = getTooltipPosition(this.target.getBoundingClientRect(), this.tooltip?.getBoundingClientRect() ?? {}, args);
        if (!position)
            return;
        this.position = position;
    }
    hide() {
        globalTooltip.value = undefined;
    }
    delayedIsMouseOver = debounce(this.isMouseOver, 10);
    timeout;
    timeoutCloseDelay;
    isMouseOver(event) {
        const { pageX: x, pageY: y } = event;
        const rect = this.target.getBoundingClientRect();
        const rectTooltip = this.tooltip?.getBoundingClientRect();
        // Тупо пока мышь над целевым элементом проверяем координаты
        if (isInRect(x, y, rect) || (rectTooltip && isInRect(x, y, rectTooltip))) {
            this.visible = true;
            if (this.timeout)
                window.clearTimeout(this.timeout);
            if (this.timeoutCloseDelay)
                window.clearTimeout(this.timeoutCloseDelay);
            return;
        }
        const transitionDuration = 200;
        // closeDelay не через css переменные, чтобы отменять начало скрытия тултипа если мышь вышла за его пределы и вернулась за t < closeDelay
        this.timeoutCloseDelay = window.setTimeout(() => {
            this.visible = false;
            this.timeout = window.setTimeout(() => {
                if (globalTooltip.value?.target !== this.target)
                    return;
                this.hide();
            }, transitionDuration);
        }, this.closeDelay);
    }
}
const isInRect = (x, y, { left, top, width, height }) => x >= left && x <= left + width && y >= top && y <= top + height;
export const TooltipComponent = defineComponent({
    setup() {
        const tooltipComponent = ref();
        watch(tooltipComponent, x => {
            if (!globalTooltip.value || !x)
                return;
            globalTooltip.value.tooltip = x;
            globalTooltip.value.updatePosition();
        }, { immediate: true });
        return () => {
            const tooltip = globalTooltip.value;
            return h(Transition, { name: tooltip?.transition }, () => tooltip?.visible
                ? h('div', {
                    ref: tooltipComponent,
                    role: 'tooltip',
                    class: tooltip.class,
                    style: [
                        Object.entries(tooltip.position)
                            .filter(x => x[1])
                            .map(x => `${x[0]}:${x[1]}px;`)
                            .join(' '),
                        `--open-delay: ${tooltip.openDelay}`,
                        tooltip.style,
                    ],
                    textContent: typeof tooltip.label === 'string' && !tooltip.useDangerousInnerHtml ? tooltip.label : undefined,
                    innerHTML: typeof tooltip.label === 'string' && tooltip.useDangerousInnerHtml ? tooltip.label : undefined,
                }, typeof tooltip.label !== 'string' ? h(tooltip.label, { hide: () => tooltip.hide() }) : undefined)
                : undefined);
        };
    },
});
