import { authFetch } from './api/authenticate-fetch';
import { formatODataQueryParameters } from './api/odata';
/**
 * Spec: http://docs.oasis-open.org/odata/odata-json-format/v4.01/csprd03/odata-json-format-v4.01-csprd03.html#sec_BatchRequestsandResponses
 */
export default class ODataBatchBuilder {
    filename;
    api;
    isTransactional;
    requests = [];
    constructor(filename, api, isTransactional = false) {
        this.filename = filename;
        this.api = api;
        this.isTransactional = isTransactional;
    }
    create(type, entity, id = this.requests.length.toString(), config = {}, batchParameters = {}) {
        this.requests.push({
            id,
            url: `${this.api.path}/${type}?${formatODataQueryParameters(config)}`,
            method: 'POST',
            type,
            body: this.api.mapper.toServerView(type, entity),
            ...batchParameters,
        });
        return this;
    }
    read(type, key, id = this.requests.length.toString(), config = {}, batchParameters = {}) {
        this.requests.push({
            id,
            url: `${this.api.path}/${type}${key ? `('${key}')` : ''}?${formatODataQueryParameters(config)}`,
            method: 'GET',
            type,
            ...batchParameters,
        });
        return this;
    }
    update(type, key, entity, id = this.requests.length.toString(), config = {}, batchParameters = {}) {
        this.requests.push({
            id,
            url: `${this.api.path}/${type}('${key}')?${formatODataQueryParameters(config)}`,
            method: 'PATCH',
            type,
            body: this.api.mapper.toServerView(type, entity),
            ...batchParameters,
        });
        return this;
    }
    createOrUpdate(type, entity, id = this.requests.length.toString(), config = {}, batchParameters = {}) {
        return entity.id
            ? this.update(type, entity.id, entity, id, config, batchParameters)
            : this.create(type, entity, id, config, batchParameters);
    }
    delete(type, key, id = this.requests.length.toString(), batchParameters = {}) {
        this.requests.push({
            id,
            url: `${this.api.path}/${type}('${key}')`,
            method: 'DELETE',
            type,
            ...batchParameters,
        });
        return this;
    }
    async execute() {
        if (!this.requests.length)
            return [];
        const headers = {
            'content-type': 'application/json',
            'cache-control': 'no-cache, no-store, must-revalidate',
            Prefer: 'return=representation',
            Accept: 'application/json',
        };
        if (this.isTransactional)
            headers.Isolation = 'snapshot';
        const response = await authFetch(`${this.api.path}/$batch`, {
            credentials: 'include',
            method: 'POST',
            mode: 'cors',
            headers: headers,
            body: JSON.stringify({
                requests: this.requests.map(x => ({
                    id: x.id,
                    method: x.method,
                    url: x.url,
                    body: x.body,
                    atomicityGroup: x.atomicityGroup,
                    dependsOn: x.dependsOn,
                    headers: {
                        'content-type': 'application/json; odata.metadata=minimal; odata.streaming=true',
                        'Accept-Encoding': 'deflate',
                    },
                })),
            }),
        });
        try {
            const { responses } = (await response.json());
            const isSuccessResponse = (status) => status >= 200 && status < 300;
            return this.requests.map(request => {
                const response = responses.find(r => r.id === request.id) ?? {
                    id: request.id,
                    status: 404,
                    headers: {},
                    body: { message: `Result with id ${request.id} not exist in response` },
                };
                return isSuccessResponse(response.status)
                    ? [
                        undefined,
                        request.method === 'DELETE'
                            ? undefined
                            : this.api.mapper.toClientView(request.type, response.body),
                        request.id,
                    ]
                    : [response.body ?? { message: this.mapStatusCodeToErrorMsg(response.status) }, undefined];
            });
        }
        catch (error) {
            return Array.from({ length: this.requests.length }).map(() => {
                if (error instanceof Error)
                    return [error, undefined];
                if (typeof error === 'string')
                    return [{ message: error }, undefined];
                return [{ message: 'unknown error' }, undefined];
            });
        }
    }
    mapStatusCodeToErrorMsg(statusCode) {
        switch (statusCode) {
            case 404:
                return `can't find entity`;
            default:
                return 'unknown error';
        }
    }
}
