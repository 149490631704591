import { services } from '@fbc/core/services';
import { cutToRange, isNotNullOrUndefined, validateNumber } from '@fbc/core/utils';
import { computed, ref } from 'vue';
import { saveMultiplyOn100 } from './save-multiply-on-100';
export const numberTypes = ['integer', 'positive', 'double', 'percent'];
const isNumberType = (x) => numberTypes.includes(x);
export const useNumberInput = (props, { attrs, emit }, mutableRootClasses, validate) => {
    if (!isNumberType(props.type))
        return { attributes: ref({}) };
    const separators = ['.', ','];
    const groupSeparator = ' ';
    const separatorRegexp = new RegExp('[' + separators.join('') + ']', 'g');
    const stringBeginning = new Set(['-', ...separators, ...separators.map(x => '-' + x)]);
    const { i18n } = services;
    const valueWrapper = computed({
        get() {
            if (props.modelValue === null || props.modelValue === undefined)
                return null;
            if (!props.modelValue)
                return props.modelValue;
            if (mutableRootClasses.value['s-input--focused']) {
                return setDecimalView(String(props.type === 'percent' && typeof props.modelValue === 'number'
                    ? saveMultiplyOn100(props.modelValue)
                    : props.modelValue), separatorRegexp, props.decimalPlaces);
            }
            return (Number.parseFloat(String(props.modelValue).replace(separatorRegexp, '.')).toLocaleString(i18n.locale, {
                style: props.type === 'percent' ? 'percent' : 'decimal',
                maximumFractionDigits: 2,
                ...props.numberFormatOptions,
            }) + props.measureStr);
        },
        set(value) {
            emit('update:modelValue', !value ? null : Number.parseFloat(String(value).replace(groupSeparator, '')));
        },
    });
    const attributes = computed(() => ({
        onInput: (event) => {
            const target = event.target;
            if (!target)
                return;
            emit('input', target.value, event);
        },
        onChange: (event) => {
            const target = event.target;
            if (!target)
                return;
            if (stringBeginning.has(target.value))
                target.value = '';
            target.value =
                target.value === '' || target.value === null || target.value === undefined
                    ? ''
                    : String(cutToRange(Number(target.value.replace(separatorRegexp, '.')), Number(props.min ?? Number.MIN_SAFE_INTEGER), Number(props.max ?? Number.MAX_SAFE_INTEGER)));
            if (props.type === 'percent') {
                const percentModel = setPercentModel(target.value, props.type, separatorRegexp);
                if (target.value === percentModel)
                    return;
                emit('update:modelValue', target.value ? percentModel : null, event);
                return;
            }
            valueWrapper.value = setDecimalView(setPercentView(target.value, 1, props.type, separatorRegexp, props.numberFormatOptions), separatorRegexp, props.decimalPlaces);
        },
        onKeypress: (event) => preventKeyPress(event, props, separators),
        onPaste: (event) => {
            if (props.readonly || props.disabled || props.preventPaste || !isNumberType(props.type)) {
                event.preventDefault();
                return;
            }
            const target = event.target;
            if (!target)
                return;
            const clipdata = event.clipboardData?.getData('Text').replace(/[^\d,.]/g, '');
            if (!clipdata)
                return;
            target.value =
                target.value.slice(0, target.selectionStart) + clipdata + target.value.slice(target.selectionEnd);
            if (attrs.maxLength)
                target.value = target.value.slice(0, Number(attrs.maxLength));
            if (validateNumber(props.regExp || props.type, target.value, Number(props.min ?? Number.MIN_SAFE_INTEGER), Number(props.max ?? Number.MAX_SAFE_INTEGER)) === undefined) {
                event.preventDefault();
                return false;
            }
            validate(target.value);
            setInputValue(target, props, separatorRegexp);
            event.preventDefault();
            emit('update:modelValue', Number(target.value.replace(separatorRegexp, '.')));
        },
    }));
    return { attributes, valueWrapper };
};
function setInputValue(target, props, separatorRegexp) {
    if (!isNumberType(props.type))
        return;
    const validatedValue = validateNumber(props.regExp || props.type, target.value) === undefined ? target.defaultValue : target.value;
    target.value = setDecimalView(validatedValue, separatorRegexp, props.decimalPlaces) ?? '';
    target.defaultValue = target.value;
}
function preventKeyPress($event, props, separators) {
    if (!$event.target)
        return;
    const key = $event.key;
    const selectionStart = $event.target.selectionStart;
    let value = props.modelValue;
    if (typeof value === 'number')
        value = String(value);
    switch (props.type) {
        case 'integer':
            if (((key < '0' || key > '9') && key !== '-') ||
                (!!value && key === '-' && (value.includes('-') || selectionStart !== 0))) {
                $event.preventDefault();
            }
            break;
        case 'positive':
            if (key < '0' || key > '9') {
                $event.preventDefault();
            }
            break;
        case 'double':
            if (((key < '0' || key > '9') && !separators.includes(key) && key !== '-') ||
                (!!value && key === '-' && (value.includes('-') || selectionStart !== 0)) ||
                (!!value && separators.includes(key) && value.includes(key))) {
                $event.preventDefault();
            }
            break;
        case 'percent':
            if (((key < '0' || key > '9') && !separators.includes(key) && key !== '-' && key !== '%') ||
                (!!value && key === '-' && (value.includes('-') || selectionStart !== 0)) ||
                (!!value && separators.includes(key) && value.includes(key)) ||
                (!!value && key === '%' && value.includes('%'))) {
                $event.preventDefault();
            }
            break;
    }
}
function setDecimalView(value, separatorRegexp, decimalPlaces) {
    if (typeof value === 'number')
        value = String(value);
    if (!isNotNullOrUndefined(value))
        return value;
    if (decimalPlaces > 20 ||
        decimalPlaces < 0 ||
        !isNotNullOrUndefined(decimalPlaces) ||
        Number.isNaN(Number(decimalPlaces)))
        return value;
    const lastIndex = value.replace(separatorRegexp, '.').lastIndexOf('.');
    if (lastIndex < 0)
        return value;
    const sumIndex = lastIndex + Number(decimalPlaces) + 1;
    return value.substring(0, Math.min(sumIndex, value.length));
}
function setPercentView(value, multiplier, type, separatorRegexp, numberFormatOptions) {
    if (type !== 'percent' || value === null || value === undefined)
        return value;
    const number = Number.parseFloat(String(value).replace(separatorRegexp, '.'));
    if (Number.isNaN(number))
        return '0';
    // Исправление ошибки 1.11*100
    return String(Number(number * multiplier).toFixed(numberFormatOptions.minimumFractionDigits ?? 10));
}
function setPercentModel(value, type, separatorRegexp) {
    if (value === null || value === undefined || value === '')
        return null;
    if (type !== 'percent' || value == null)
        return value;
    const float = Number.parseFloat(String(value)
        .replace(separatorRegexp, '.')
        .replace(/[^\d.-]/g, ''));
    if (!value || !String(value) || Number.isNaN(float))
        return 0;
    return float / 100;
}
