import { services } from '@fbc/core/services';
import { loadWebpackModule } from '@fbc/core/utils';
import { defineStore } from 'pinia';
export const useModuleRegistry = defineStore({
    id: 'module-registry',
    state: () => ({
        modules: new Map(),
    }),
    actions: {
        load(name) {
            if (this.modules.has(name))
                return this.modules.get(name);
            const { store: { app }, } = services;
            const moduleConfig = app.modules[name];
            if (!moduleConfig)
                log.prod.warn(__filename, `Trying to load unknown module ${name}`);
            const defaultUrl = `/modules/${name}/remoteEntry.js?${name}@${moduleConfig?.version ?? '1.0.0'}`;
            const url = moduleConfig?.modulePath?.includes('/')
                ? moduleConfig.modulePath
                : moduleConfig?.modulePath
                    ? `/modules/${moduleConfig.modulePath}/remoteEntry.js?${name}@${moduleConfig?.version ?? '1.0.0'}`
                    : defaultUrl;
            const promise = loadWebpackModule(url);
            this.modules.set(name, promise);
            promise.catch(() => this.unload(name));
            return promise;
        },
        unload(name) {
            this.modules.delete(name);
        },
    },
});
