export class DataCache {
    capacity;
    /**
     * @constructor
     * @param capacity Объем кэша. При переполнении самые старые данные будут удаляться.
     * Не определять, если не нужна автоматическая очистка кэша
     */
    constructor(capacity = 0) {
        this.capacity = capacity;
    }
    dataMap = [];
    set(key, value) {
        if (this.has(key)) {
            this.dataMap[this.dataMap.findIndex(x => x.key === key)].value = value;
            return;
        }
        this.dataMap.push({ key, value });
        if (this.capacity > 0 && this.dataMap.length > this.capacity)
            this.dataMap.shift();
    }
    get(key) {
        return this.dataMap.find(x => x.key === key);
    }
    has(key) {
        return this.dataMap.some(x => x.key === key);
    }
    clear() {
        this.dataMap = [];
    }
}
