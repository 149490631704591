import { useSemaphore } from '@fbc/core/storage';
import { omit } from 'lodash';
import { computed, defineComponent, h, onUnmounted, ref, resolveComponent } from 'vue';
import { sort } from '../use-data-table-sort';
import './s-pagination-data-table.scss';
import { addListeners } from './use-listeners';
import { addLoading } from './use-loading';
import { addSelecting } from './use-selecting';
import { registerWatchers } from './use-watchers';
export default defineComponent({
    name: 's-pagination-data-table',
    props: {
        modelValue: { type: Array, default: () => [] },
        entityType: { type: String, required: true },
        updateEvents: { type: Array, default: () => [] },
        pageSize: { type: Number, required: true },
        totalVisible: { type: Number, default: 10 },
        externalSort: { type: Boolean, default: false },
        dataProvider: {
            type: Object,
            required: true,
        },
        hiddenFilters: { type: Array, default: () => [] },
        sort: { type: Function, default: sort },
        allowSort: { type: Boolean, default: false },
        columns: { type: Array, required: true },
        noResetCurrentPage: { type: Boolean, default: false },
    },
    emits: ['dataLoaded', 'update:modelValue'],
    setup(props, { attrs, emit, slots }) {
        const { items, loading, load, listeners, pagesCount, currentPage, selected, clickedSelectAll, modelUpdated, isSelectedAll, } = addListeners(registerWatchers(addSelecting(addLoading({
            items: ref([]),
            columns: computed(() => props.columns),
            filters: computed(() => props.hiddenFilters),
            dataProvider: computed(() => props.dataProvider),
            modelValue: computed(() => props.modelValue),
            loading: useSemaphore(),
            emit: emit,
            filtersChanged: ref(false),
            countChanged: ref(true),
            pagesCount: ref(0),
            currentPage: ref(0),
            totalResults: ref(0),
            pageSize: props.pageSize > 0 ? props.pageSize : 15,
            refreshConfig: {
                entityType: props.entityType,
                events: props.updateEvents,
                noResetCurrentPage: props.noResetCurrentPage,
            },
            selectingLimit: 500,
        }))));
        onUnmounted(() => listeners.forEach(unsubscribe => unsubscribe()));
        return () => h(resolveComponent('s-data-table'), {
            ...attrs,
            ...omit(props, ['dataProvider', 'hiddenFilters']),
            externalSort: true,
            items: items.value,
            loading: loading.isLocked.value,
            class: 's-pagination-data-table',
            onSelect: selected,
            selectedAll: isSelectedAll?.value,
            'onClick:selectAll': clickedSelectAll,
            'onUpdate:modelValue': modelUpdated,
        }, {
            footer() {
                return pagesCount.value > 1 || currentPage.value > 0
                    ? h(resolveComponent('s-pagination'), {
                        length: pagesCount.value,
                        totalVisible: props.totalVisible,
                        modelValue: currentPage.value + 1,
                        'onUpdate:modelValue': (value) => {
                            currentPage.value = value - 1;
                            load();
                        },
                    })
                    : null;
            },
            ...slots,
        });
    },
});
