import { mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["role"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
        (_ctx.backdrop && _ctx.visible)
            ? (_openBlock(), _createElementBlock("div", _mergeProps({
                key: 0,
                class: "s-popup-backdrop"
            }, _ctx.backdropAttrs, {
                onClick: _cache[0] || (_cache[0] = ($event) => (!_ctx.preventClickOutsideHide && _ctx.hide($event)))
            }), null, 16 /* FULL_PROPS */))
            : _createCommentVNode("v-if", true),
        _createVNode(_Transition, { name: _ctx.transitionName }, {
            default: _withCtx(() => [
                (_ctx.directiveIf)
                    ? _withDirectives((_openBlock(), _createElementBlock("div", _mergeProps({
                        key: 0,
                        ref: "root",
                        class: ["s-popup", { 's-popup--centralize-on-mobile': _ctx.centralizeOnMobile }],
                        role: _ctx.backdrop ? 'dialog' : undefined
                    }, _ctx.$attrs, {
                        onVnodeMounted: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('menu:mounted', _ctx.$refs.root)))
                    }), [
                        _renderSlot(_ctx.$slots, "default")
                    ], 16 /* FULL_PROPS */, _hoisted_1)), [
                        [_vShow, _ctx.directiveShow]
                    ])
                    : _createCommentVNode("v-if", true)
            ]),
            _: 3 /* FORWARDED */
        }, 8 /* PROPS */, ["name"])
    ]));
}
