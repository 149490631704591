import { services } from '@fbc/core/services';
const showToastOnBusEvent = () => {
    const isToastMessage = (x) => Boolean(typeof x === 'object' &&
        x &&
        'message' in x &&
        typeof x.message === 'string' &&
        'type' in x &&
        typeof x.type === 'string');
    const onToastMessage = (message) => {
        if (!isToastMessage(message))
            return;
        const toast = services.toast;
        (toast[message.type] ?? toast)(message.message);
    };
    return services.bus.on('toast', onToastMessage);
};
export function registerDefaultBusEventHandlers() {
    showToastOnBusEvent();
}
