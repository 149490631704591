import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withKeys as _withKeys, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = ["value", "onClick"];
const _hoisted_2 = {
    key: 0,
    class: "s-select__no-data"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_s_input = _resolveComponent("s-input");
    const _component_s_checkbox = _resolveComponent("s-checkbox");
    const _component_s_popup = _resolveComponent("s-popup");
    return (_openBlock(), _createElementBlock("div", {
        ref: "root",
        class: _normalizeClass(["s-select", _ctx.mergeClass({ 's-select--menu-opened': _ctx.visible, 's-select--disabled': _ctx.disabled || _ctx.readonly }, _ctx.$attrs.class)]),
        style: _normalizeStyle(_ctx.$attrs.style)
    }, [
        _renderSlot(_ctx.$slots, "input", {
            label: _ctx.label,
            input: _ctx.textInput,
            readonly: _ctx.readonly,
            disabled: _ctx.disabled,
            text: _ctx.displayName,
            modelValue: (_ctx.allowMultiSelect || !_ctx.valueAsArray ? _ctx.valueAsArray : _ctx.valueAsArray[0]) || undefined,
            conditionalShow: _ctx.conditionalShow,
            switchMenuVisible: _ctx.switchMenuVisible,
            selectNext: _ctx.selectNext,
            buttons: _ctx.buttons,
            attrs: _ctx.excludeVue2Attributes(_ctx.$attrs),
            hide: _ctx.hide,
            inputable: _ctx.inputable
        }, () => [
            _createVNode(_component_s_input, _mergeProps({
                label: _ctx.label,
                readonly: (!_ctx.searchable && !_ctx.inputable) || _ctx.readonly,
                disabled: _ctx.disabled,
                "model-value": _ctx.displayName,
                bordered: _ctx.bordered,
                class: "s-select__input",
                buttons: _ctx.buttons
            }, _ctx.excludeVue2Attributes(_ctx.$attrs), {
                onInput: _cache[0] || (_cache[0] = ($event) => (_ctx.textInput($event))),
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => (_ctx.inputable && _ctx.$emit('update:modelValue', $event))),
                onBlur: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('blur', $event))),
                onClick: _ctx.conditionalShow,
                onKeyup: [
                    _withKeys(_ctx.switchMenuVisible, ["enter"]),
                    _cache[3] || (_cache[3] = _withKeys(($event) => (_ctx.selectNext(1)), ["down"])),
                    _cache[4] || (_cache[4] = _withKeys(($event) => (_ctx.selectNext(-1)), ["up"])),
                    _cache[5] || (_cache[5] = ($event) => (_ctx.$emit('keyup', $event)))
                ],
                onFocus: _cache[6] || (_cache[6] = ($event) => {
                    _ctx.filter = '';
                    _ctx.$emit('focus', $event);
                }),
                "onUpdate:valid": _cache[7] || (_cache[7] = ($event) => (_ctx.isValid = $event))
            }), null, 16 /* FULL_PROPS */, ["label", "readonly", "disabled", "model-value", "bordered", "buttons", "onClick", "onKeyup"])
        ]),
        _createVNode(_component_s_popup, {
            visible: _ctx.visible,
            "onUpdate:visible": _cache[9] || (_cache[9] = ($event) => ((_ctx.visible) = $event)),
            position: _ctx.position,
            "transition-name": "s-select__menu-transition",
            class: _normalizeClass(["s-select__menu", _ctx.menuClass]),
            excludedElements: { root: _ctx.root, menuRef: _ctx.menuRef },
            centralizeOnMobile: "",
            backdrop: _ctx.$store.app.screen.device !== 'desktop',
            onHide: _cache[10] || (_cache[10] = ($event) => {
                _ctx.changeValue();
                _ctx.hide();
            }),
            "onMenu:mounted": _ctx.onMenuMounted
        }, {
            default: _withCtx(() => [
                _createElementVNode("ul", {
                    ref: "menuRef",
                    class: "s-select__menu-content",
                    style: _normalizeStyle({ 'max-height': _ctx.menuHeight ? _ctx.menuHeight + 'px' : '370px', width: _ctx.menuWidth }),
                    role: "menu"
                }, [
                    _renderSlot(_ctx.$slots, "selectAll", {
                        allowMultiSelect: _ctx.allowMultiSelect,
                        showSelectAll: _ctx.showSelectAll,
                        filteredOptions: _ctx.filteredOptions,
                        select: _ctx.select,
                        selectionStatus: _ctx.selectionStatus
                    }, () => [
                        (_ctx.allowMultiSelect && _ctx.showSelectAll && _ctx.filteredOptions && _ctx.filteredOptions.length)
                            ? (_openBlock(), _createElementBlock("li", {
                                key: 0,
                                class: _normalizeClass(["s-select__item s-select__item__select-all", {
                                        's-select__item--active': !_ctx.showCheckbox && _ctx.selectionStatus === 'all',
                                        's-select__item--with-checkbox': _ctx.showCheckbox,
                                    }]),
                                onMousedown: _cache[8] || (_cache[8] = _withModifiers(($event) => (_ctx.select('_all')), ["prevent", "stop"]))
                            }, [
                                (_ctx.showCheckbox)
                                    ? (_openBlock(), _createBlock(_component_s_checkbox, {
                                        key: 0,
                                        "model-value": _ctx.selectionStatus === 'all',
                                        class: "s-select__item__checkbox"
                                    }, null, 8 /* PROPS */, ["model-value"]))
                                    : _createCommentVNode("v-if", true),
                                _createElementVNode("span", null, _toDisplayString(_ctx.$i18n.global('select-all')), 1 /* TEXT */)
                            ], 34 /* CLASS, NEED_HYDRATION */))
                            : _createCommentVNode("v-if", true)
                    ]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredOptions, (option, index) => {
                        return (_openBlock(), _createElementBlock("li", {
                            key: index,
                            class: _normalizeClass([{
                                    's-select__item--active': !_ctx.showCheckbox && _ctx.equalityComparer(_ctx.valueAsArray, option),
                                    's-select__item--with-checkbox': _ctx.showCheckbox,
                                }, "s-select__item"]),
                            value: _ctx.getLabel(option),
                            onClick: ($event) => (_ctx.select(option))
                        }, [
                            _renderSlot(_ctx.$slots, "default", {
                                option: option,
                                active: _ctx.equalityComparer(_ctx.valueAsArray, option),
                                index: index,
                                showCheckbox: _ctx.showCheckbox
                            }, () => [
                                (_ctx.showCheckbox)
                                    ? (_openBlock(), _createBlock(_component_s_checkbox, {
                                        key: 0,
                                        "model-value": _ctx.equalityComparer(_ctx.valueAsArray, option),
                                        class: "s-select__item__checkbox"
                                    }, null, 8 /* PROPS */, ["model-value"]))
                                    : _createCommentVNode("v-if", true),
                                _createElementVNode("span", null, _toDisplayString(_ctx.getLabel(option)), 1 /* TEXT */)
                            ])
                        ], 10 /* CLASS, PROPS */, _hoisted_1));
                    }), 128 /* KEYED_FRAGMENT */))
                ], 4 /* STYLE */),
                (!_ctx.filteredOptions || _ctx.filteredOptions.length === 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _renderSlot(_ctx.$slots, "noData", {}, () => [
                            _createTextVNode(_toDisplayString(_ctx.$i18n.global('no-data-to-display')), 1 /* TEXT */)
                        ])
                    ]))
                    : _createCommentVNode("v-if", true)
            ]),
            _: 3 /* FORWARDED */
        }, 8 /* PROPS */, ["visible", "position", "excludedElements", "class", "backdrop", "onMenu:mounted"])
    ], 6 /* CLASS, STYLE */));
}
