export const getUserOption = async ({ category, name }, filename) => {
    const response = await fetch(`/api/currentUser/options?name=${name}&category=${category}`, {
        headers: {
            'content-type': 'application/json',
        },
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
    });
    if (process.env.NODE_ENV === 'development' && localStorage['fora.debug.api'])
        log.dev.debug(filename, 'get user option', response);
    return response.ok && response.status !== 204
        ? [
            undefined,
            (await response.json()),
        ]
        : response.status === 404 || response.status === 204
            ? [undefined, undefined]
            : [(await response.json()), undefined];
};
export const setUserOption = async ({ category, name, value }, filename) => {
    const response = await fetch(`/api/currentUser/options?name=${name}&category=${category}`, {
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify(value),
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
    });
    if (process.env.NODE_ENV === 'development' && localStorage['fora.debug.api'])
        log.dev.debug(filename, 'set user option', response);
    return response.ok ? true : (await response.json());
};
export const deleteUserOption = async ({ category, name }, filename) => {
    const response = await fetch(`/api/currentUser/options?name=${name}&category=${category}`, {
        method: 'DELETE',
        mode: 'cors',
        credentials: 'include',
    });
    if (process.env.NODE_ENV === 'development' && localStorage['fora.debug.api'])
        log.dev.debug(filename, 'delete user option', response);
    return response.ok ? true : (await response.json());
};
