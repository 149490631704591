import { getEntityBusinessRulesKey, getEntityOdataKey } from '@fbc/core/utils';
import odataQuery from 'odata-fluent-query';
import { authFetch } from './api/authenticate-fetch';
import { encodeODataQueryParameters, formatODataQueryParameters, isODataError, mapODataErrorToServerError, } from './api/odata';
import { ODataMapper } from './api/odata-mapper';
import ODataBatchBuilder from './odata-batch-builder';
export class ODataEntitiesApi {
    converters;
    path;
    headers = {
        'content-type': 'application/json',
        'cache-control': 'no-cache, no-store, must-revalidate',
    };
    mapper;
    /**
     * @constructor
     * @param path Путь к API
     */
    constructor(converters, path = window['fb-base-api-path'] + '/api/odata') {
        this.converters = converters;
        this.path = path;
        this.mapper = new ODataMapper(converters);
    }
    get dataConverters() {
        return this.converters;
    }
    mapResult(response, type, result) {
        if (!response.ok)
            return [isODataError(result) ? mapODataErrorToServerError(result) : result, undefined];
        const entity = this.mapper.toClientView(type, result);
        return [undefined, entity];
    }
    /**
     * Получает шаблон сущности с выполненным бизнес-правилом OnCreate
     * @param type Название сущности для OData
     * @returns Кортеж из ошибки или шаблона сущности
     */
    async template(type) {
        const response = await authFetch(`${this.path}/${type}/template`, {
            credentials: 'include',
            headers: this.headers,
            method: 'GET',
            mode: 'cors',
        });
        const result = (await response.json());
        return this.mapResult(response, type, result);
    }
    /**
     * Сохраняет новую сущность в базу, вызываются бизнес-правила OnBeforeInsert и OnAfterInsert
     * @param type Название сущности для OData
     * @param entity Сущность для сохранения, получается посредством вызова template
     * @param [fileName] название файла для логгирования, с его помощью можно фильтровать запросы в консоли
     * @param [config] Конфиг для OData для получения связанных сущностей, может не работать
     * @returns Кортеж из ошибки или созданной сущности с Ид и измененными бизнес-правилами полями
     */
    async create(type, entity, fileName) {
        if (process.env.NODE_ENV === 'development' && localStorage['fora.debug.api'])
            log.dev.debug(fileName, `create entity ${type} `, entity);
        const response = await authFetch(`${this.path}/${type}`, {
            credentials: 'include',
            headers: this.headers,
            body: JSON.stringify(this.mapper.toServerView(type, entity)),
            method: 'POST',
            mode: 'cors',
        });
        if (response.headers.get('Content-Type')?.includes('text'))
            return [{ message: await response.text() }, undefined]; // До тех пор пока с сервера падают строки в качестве ошибок
        if (response.status === 404)
            return [{ message: 'Entity not found' }, undefined];
        const result = (await response.json());
        return this.mapResult(response, type, result);
    }
    /**
     * Получает сущность через OData
     * @param type Название сущности для OData
     * @param key Ид сущности
     * @param [fileName] название файла для логгирования, с его помощью можно фильтровать запросы в консоли
     * @param [config] Конфиг для OData для получения связанных сущностей или колбек odata fluent query
     * @returns Кортеж из ошибки или сущности
     */
    async read(type, key, fileName, config) {
        let time = 0;
        if (process.env.NODE_ENV === 'development' && localStorage['fora.debug.api']) {
            log.dev.debug(fileName, `read entity ${type}`, key);
            time = performance.now();
        }
        const queryParameters = config
            ? typeof config === 'object'
                ? formatODataQueryParameters(config)
                : encodeODataQueryParameters(config(odataQuery()))
            : '';
        const response = await authFetch(`${this.path}/${type}${key ? `('${key}')` : ''}?${queryParameters}`, {
            credentials: 'include',
            headers: this.headers,
            method: 'Get',
            mode: 'cors',
        });
        if (response.headers.get('Content-Type')?.includes('text'))
            return [{ message: await response.text() }, undefined]; // До тех пор пока с сервера падают строки в качестве ошибок
        if (process.env.NODE_ENV === 'development' && localStorage['fora.debug.api'])
            log.dev.debug(fileName, 'read ended', key, performance.now() - time);
        if (response.status === 404)
            return [{ message: 'Entity not found' }, undefined];
        const result = (await response.json());
        return this.mapResult(response, type, result);
    }
    /**
     * Получает массив сущностей через OData по условию, сущности уже сконвертированы toClientView
     * [Доступные функции(см. FullODataConfig)](https://learn.microsoft.com/en-us/azure/search/query-odata-filter-orderby-syntax)
     * @param type Название сущности для OData
     * @param [config] Конфиг для OData для получения связанных сущностей или колбек odata fluent query
     * @param [fileName] название файла для логгирования, с его помощью можно фильтровать запросы в консоли
     * @returns Кортеж из ошибки или сущностей
     */
    async where(type, config, fileName) {
        let time = 0;
        if (process.env.NODE_ENV === 'development' && localStorage['fora.debug.api']) {
            log.dev.debug(fileName ?? 'НЕИЗВЕСТНЫЙ ФАЙЛ, ОПРЕДЕЛИТЬ', `read entities ${type}`, config);
            time = performance.now();
        }
        try {
            const queryParameters = config
                ? typeof config === 'object'
                    ? formatODataQueryParameters(config)
                    : encodeODataQueryParameters(config(odataQuery()))
                : '';
            const response = await authFetch(`${this.path}/${type}?${queryParameters}`, {
                credentials: 'include',
                headers: this.headers,
                method: 'Get',
                mode: 'cors',
            });
            if (response.headers.get('Content-Type')?.includes('text'))
                return [{ message: await response.text() }, undefined, undefined]; // До тех пор пока с сервера падают строки в качестве ошибок
            if (process.env.NODE_ENV === 'development' && localStorage['fora.debug.api'])
                log.dev.debug(fileName, 'read ended', config, performance.now() - time);
            if (response.status === 404)
                return [{ message: 'Entities not found' }, undefined, undefined];
            const json = (await response.json());
            if (!response.ok)
                return [isODataError(json) ? mapODataErrorToServerError(json) : json, undefined, undefined];
            const result = json;
            return [
                undefined,
                result.value.map((x) => this.mapper.toClientView(type, x)),
                { totalResults: result['@odata.count'] },
            ];
        }
        catch (error) {
            if (error instanceof Error)
                return [{ message: error.message, stackTrace: error.stack }, undefined, undefined];
            return [{ message: JSON.stringify(error) }, undefined, undefined];
        }
    }
    /**
     * Сохраняет существующую сущность в базу, вызываются бизнес-правила OnBeforeUpdate и OnAfterUpdate
     * @param type Название сущности для OData
     * @param key Ид сущности
     * @param entity Измененные в сущности поля. Не измененные лучше не отправлять
     * @param [fileName] название файла для логгирования, с его помощью можно фильтровать запросы в консоли
     * @param [config] Конфиг для OData для получения связанных сущностей или колбек odata fluent query
     * @returns Кортеж из ошибки или обновленной сущности
     */
    async update(type, key, entity, fileName, config) {
        if (process.env.NODE_ENV === 'development' && localStorage['fora.debug.api'])
            log.dev.debug(fileName, `update entity ${type}`, key, entity);
        const queryParameters = config
            ? typeof config === 'object'
                ? formatODataQueryParameters(config)
                : encodeODataQueryParameters(config(odataQuery()))
            : '';
        const response = await authFetch(`${this.path}/${type}(${getEntityOdataKey(key)})?${queryParameters}`, {
            credentials: 'include',
            headers: Object.assign(this.headers, {
                Prefer: 'return=representation',
            }),
            body: JSON.stringify(this.mapper.toServerView(type, entity)),
            method: 'PATCH',
            mode: 'cors',
        });
        if (response.headers.get('Content-Type')?.includes('text'))
            return [{ message: await response.text() }, undefined]; // До тех пор пока с сервера падают строки в качестве ошибок
        if (response.status === 404)
            return [{ message: 'Entity not found' }, undefined];
        const result = (await response.json());
        return this.mapResult(response, type, result);
    }
    /**
     * Вызывает {@link EntitiesApi.create} или  {@link EntitiesApi.update} в зависимости от наличия заполненного свойства {@link entity.Id}
     * @param type Название сущности для OData
     * @param entity Измененные в сущности поля. Не измененные лучше не отправлять
     * @param [fileName] название файла для логгирования, с его помощью можно фильтровать запросы в консоли
     * @param [config] Конфиг для OData для получения связанных сущностей или колбек odata fluent query
     * @returns Кортеж из ошибки или сущности
     */
    createOrUpdate(type, entity, fileName, config) {
        return entity.id ? this.update(type, entity.id, entity, fileName, config) : this.create(type, entity, fileName);
    }
    /**
     * Удаляет сущность из БД, вызываются бизнес-правила OnBeforeDelete и OnAfterDelete
     * @param type Название сущности для OData
     * @param key Ид сущности
     * @param [fileName] название файла тип для логгирования, с его помощью можно фильтровать запросы в консоли
     * @returns Возвращает {true} или серверную ошибку
     */
    delete(type, key, fileName) {
        if (process.env.NODE_ENV === 'development' && localStorage['fora.debug.api'])
            log.dev.debug(fileName, `delete entity ${type}`, key);
        return authFetch(`${this.path}/${type}(${getEntityOdataKey(key)})`, {
            credentials: 'include',
            method: 'DELETE',
            mode: 'cors',
        })
            .then(x => (x.ok ? true : x.json()))
            .then(x => (x === true ? true : isODataError(x) ? mapODataErrorToServerError(x) : x));
    }
    /**
     * Массово создает сущности
     * @param type Название сущности для odata, можно посмотреть в Fora.*.EntityModel/{ServiceName}/
     * @param entity Измененные в сущности поля. Не измененные лучше не отправлять
     * @param [fileName] название файла для логгирования, с его помощью можно фильтровать запросы в консоли
     * @returns Кортежи из ошибки или созданной сущности
     */
    batchCreate(type, entities, fileName) {
        if (!entities.length)
            return Promise.resolve([]);
        const batch = new ODataBatchBuilder(fileName, this);
        for (const entity of entities)
            batch.create(type, entity);
        return batch.execute();
    }
    /**
     * Массово обновляет сущности
     * @param type Название сущности для odata, можно посмотреть в Fora.*.EntityModel/{ServiceName}/
     * @param entity Измененные в сущности поля. Не измененные лучше не отправлять
     * @param [fileName] название файла для логгирования, с его помощью можно фильтровать запросы в консоли
     * @returns Кортежи из ошибки или обновленной сущности
     */
    batchUpdate(type, entities, fileName) {
        if (!entities.length)
            return Promise.resolve([]);
        const batch = new ODataBatchBuilder(fileName, this);
        for (const entity of entities)
            batch.update(type, entity.id, entity);
        return batch.execute();
    }
    /**
     * Массово удаляет сущности
     * @param type Название сущности для odata, можно посмотреть в Fora.*.EntityModel/{ServiceName}/
     * @param entity Измененные в сущности поля. Не измененные лучше не отправлять
     * @param [fileName] название файла для логгирования, с его помощью можно фильтровать запросы в консоли
     * @returns Возвращает массив из ({true} или серверной ошибки)
     */
    batchDelete(type, keys, fileName) {
        if (process.env.NODE_ENV === 'development' && localStorage['fora.debug.api'])
            log.dev.debug(fileName ?? 'НЕИЗВЕСТНЫЙ ФАЙЛ, ОПРЕДЕЛИТЬ', `delete entity ${type}`, keys);
        if (!keys.length)
            return Promise.resolve([]);
        const batch = new ODataBatchBuilder(fileName, this);
        for (const key of keys)
            batch.delete(type, key);
        return batch.execute().then(x => x.map(r => r[0] ?? true));
    }
    batch(filename) {
        return new ODataBatchBuilder(filename, this);
    }
    /**
     * Вызывает метод сущности
     * @param entityType Название сущности для odata
     * @param entityId Ид сущности. Может быть композитным ключом, тогда стоит воспользоваться кортежем
     * @param method Название метода в BusinessRules (в lowerCamelCase)
     * @param parameters Список параметров (может быть null, если у метода нет параметров)
     * @param [fileName] название файла для логирования, с его помощью можно фильтровать запросы в консоли
     * @returns Кортежи из ошибки или результата метода
     */
    async invoke(entityType, entityId, method, parameters, filename, converterType) {
        const upperMethod = method.charAt(0).toUpperCase() + method.slice(1);
        const isComposite = typeof entityId !== 'string';
        const response = await authFetch(isComposite
            ? `${this.path.replace('/odata', '/methods')}/${entityType}/executeBusinessMethodComposite?methodName=${upperMethod}&${getEntityBusinessRulesKey(entityId)}`
            : `${this.path.replace('/odata', '/methods')}/${entityType}/${entityId}/${method}`, {
            method: 'POST',
            headers: this.headers,
            credentials: 'include',
            mode: 'cors',
            cache: 'default',
            body: JSON.stringify(parameters ?? {}),
        });
        const json = response.status === 204 ? null : (await response.json());
        if (process.env.NODE_ENV === 'development' && localStorage['fora.debug.api'])
            log.dev.debug(filename ?? 'НЕИЗВЕСТНЫЙ ФАЙЛ, ОПРЕДЕЛИТЬ', entityType, method, parameters, response);
        if (!response.ok)
            return [isODataError(json) ? mapODataErrorToServerError(json) : json, undefined];
        const result = json?.response?.Result ?? json;
        if (converterType) {
            return [undefined, this.mapper.toClientView(converterType, result)];
        }
        return [undefined, result];
    }
    /**
     * Вызывает метод сущности без сущности
     * @param entityType Название сущности для odata
     * @param method Название метода в BusinessRules (в lowerCamelCase)
     * @param parameters Список параметров (может быть null, если у метода нет параметров)
     * @param [fileName] название файла для логирования, с его помощью можно фильтровать запросы в консоли
     * @returns Кортежи из ошибки или результата метода
     */
    async invokeNoEntity(entityType, method, parameters, filename, converterType) {
        const response = await authFetch(`${this.path.replace('/odata', '/methods')}/${entityType}/${method}`, {
            method: 'POST',
            headers: this.headers,
            credentials: 'include',
            mode: 'cors',
            cache: 'default',
            body: JSON.stringify(parameters ?? {}),
        });
        const json = response.status === 204 ? null : (await response.json());
        if (process.env.NODE_ENV === 'development' && localStorage['fora.debug.api'])
            log.dev.debug(filename ?? 'НЕИЗВЕСТНЫЙ ФАЙЛ, ОПРЕДЕЛИТЬ', entityType, method, parameters, response);
        if (!response.ok)
            return [isODataError(json) ? mapODataErrorToServerError(json) : json, undefined];
        const result = json?.response?.Result ?? json;
        if (converterType) {
            return [undefined, this.mapper.toClientView(converterType, result)];
        }
        return [undefined, result];
    }
}
