import { createUniqueId, excludeVue2Attributes, isNotNullOrUndefined, isTruthy, mergeClass } from '@fbc/core/utils';
import { cloneDeep } from 'lodash';
import { computed, defineComponent, h, onMounted, onUnmounted, ref, watch, } from 'vue';
import { formatCellValue } from './format-cell-value';
import DataTableFilterComponent from './s-data-table-filter';
import { useDataTableButtons } from './use-data-table-buttons';
import { useDataTableDraggableRow } from './use-data-table-draggable-row';
import { useDataTableFilters } from './use-data-table-filters';
import { useDataTableResize } from './use-data-table-resize';
import { useDataTableSelection } from './use-data-table-selection';
import { dataTableSort, sort } from './use-data-table-sort';
const StyleComponent = (_, context) => h('style', context.attrs, context.slots);
export default defineComponent({
    name: 's-data-table',
    inheritAttrs: false,
    emits: [
        'update:modelValue',
        'update:detailsId',
        'update:columns',
        'update:sorting',
        'row:mouseenter',
        'row:mouseleave',
        'row:dblclick',
        'row:click',
        'row:contextmenu',
        'update:filters',
        'update:clearFilters',
        'mounted:body',
        'updated:body',
        'click:add',
        'click:edit',
        'click:delete',
        'click:showDetail',
        'row:dragAndDrop',
        'select',
        'update:selectedAll',
        'click:selectAll',
    ],
    slots: Object,
    components: { 'v-style': StyleComponent, 's-data-table-filter': DataTableFilterComponent },
    props: {
        modelValue: { type: Array, default: () => [] },
        idField: {
            type: [String, Function],
            required: true,
        },
        columns: { type: Array, required: true },
        items: { type: Array, required: true },
        selectionMode: { type: String, default: null },
        showSelectionCheckboxes: { type: Boolean, default: false }, // Показывать ли чекбоксы выбора строк. По умолчанию только для selectionMode === multiple.
        showSelectAllCheckbox: { type: Boolean, default: true }, // Показывать ли чекбокс "Выделить всё". Зависит от отображения чекбоксов выделения строк
        selectedAll: { type: Boolean, default: undefined }, // Выделены ли все записи. Значение для чекбокса "Выделить всё"
        detailsId: { type: String },
        loading: { type: Boolean, default: false },
        preventRowClick: { type: Boolean, default: false },
        scrollToDetails: { type: Boolean, default: true },
        showHeader: { type: Boolean, default: true },
        allowResize: { type: Boolean, default: false },
        resizeMode: { type: String, default: 'nextColumn' },
        allowSort: { type: Boolean, default: false },
        allowAdd: { type: [Boolean, Object], default: false },
        allowEdit: { type: [Boolean, Function], default: false },
        allowDelete: { type: [Boolean, Function], default: false },
        allowShowDetail: { type: [Boolean, Function], default: false },
        externalSort: { type: Boolean, default: false }, // сохраняет кликабельность хейдеров, но оффает сортировку результатов.
        unfixedWidth: { type: Boolean, default: false },
        clearFilters: { type: Boolean, default: false },
        sort: { type: Function, default: sort },
        highlightedRows: { type: WeakSet },
        draggableRow: { type: Boolean, default: false },
    },
    setup(props, { emit, attrs, slots }) {
        const tableId = attrs.id ?? createUniqueId();
        const hasScroll = ref(false);
        const body = ref();
        const details = [];
        const columnRef = ref();
        const { allFiltersCleared, setFilter } = useDataTableFilters(props, emit);
        const { column: dragAndDropColumn, ghost, isDragging, draggableEntity, handlers: rowDragHandlers, } = useDataTableDraggableRow(tableId, emit);
        const resizeObserver = new ResizeObserver(([{ target: body }]) => {
            hasScroll.value = body.scrollHeight > body.clientHeight;
        });
        const visibleItems = computed(() => props.externalSort ? props.items : dataTableSort(props.items, props.allowSort, props.columns, props.sort));
        onMounted(() => {
            if (!body.value)
                return;
            hasScroll.value = body.value.scrollHeight > body.value.clientHeight;
            resizeObserver.observe(body.value);
        });
        onUnmounted(() => body.value && resizeObserver.unobserve(body.value));
        const { buttonsColumn } = useDataTableButtons(props, emit, showDetail, getId);
        const { select, selectionColumn, shouldShowSelectionCheckboxes } = useDataTableSelection(props, emit, getId, visibleItems);
        const visibleColumns = computed(() => [
            ...(props.draggableRow ? [dragAndDropColumn] : []),
            ...(shouldShowSelectionCheckboxes.value ? [selectionColumn.value] : []),
            ...props.columns.filter(x => x.visible !== false && x.field !== '$buttons'),
            ...(props.allowAdd || props.allowEdit || props.allowDelete || props.allowShowDetail ? [buttonsColumn.value] : []),
        ]);
        const columnGap = 8;
        const rowPaddingLeft = 16;
        const resizeStart = useDataTableResize(visibleColumns, props, columnRef, props.resizeMode, columnGap, rowPaddingLeft);
        function getId(entity) {
            return !entity
                ? createUniqueId()
                : typeof props.idField === 'function'
                    ? props.idField(entity)
                    : entity[props.idField];
        }
        function getHeaderClasses(column, index) {
            /* eslint-disable @typescript-eslint/naming-convention */
            return {
                's-data-table__header-cell': true,
                's-data-table__header-cell--sortable': column.sortable !== false && props.allowSort,
                's-data-table__header-cell--filterable': column.filterable,
                's-data-table__header-cell--resizable': props.allowResize,
                [`s-data-table__header-cell--${index}`]: true,
            };
            /* eslint-enable @typescript-eslint/naming-convention */
        }
        function rowDblClick(entity) {
            emit('row:dblclick', entity);
        }
        function rowMouseEnter(entity) {
            emit('row:mouseenter', entity);
        }
        function rowMouseLeave(entity) {
            emit('row:mouseleave', entity);
        }
        function showDetail(entity, entityId) {
            const id = entityId ?? getId(entity);
            if (id === props.detailsId || !isNotNullOrUndefined(id))
                emit('update:detailsId', undefined);
            else
                emit('update:detailsId', id);
        }
        function rowClick(entity, event) {
            if (props.preventRowClick) {
                event.preventDefault();
                event.stopPropagation();
            }
            if (slots.details)
                showDetail(entity);
            if (!shouldShowSelectionCheckboxes.value)
                select(entity, event);
            emit('row:click', entity, event);
        }
        function toggleSort(column) {
            if (!props.allowSort || column.sortable === false)
                return;
            const columns = cloneDeep(props.columns);
            for (const col of columns) {
                if (col.field === column.field && column.fieldPrefix === col.fieldPrefix) {
                    col.sortOrder = column.sortOrder === 'asc' ? 'desc' : 'asc';
                    col.sortIndex = 0;
                    const sortingInfo = { fieldName: col.field, sortOrder: col.sortOrder };
                    emit('update:sorting', sortingInfo);
                    continue;
                }
                col.sortOrder = null;
                col.sortIndex = -1;
            }
            emit('update:columns', columns);
            body.value?.scroll(0, 0);
        }
        watch(() => props.detailsId, () => {
            if (!props.scrollToDetails ||
                !body.value ||
                !details[0] ||
                body.value.scrollTop + body.value.offsetHeight >= details[0].offsetTop + details[0].offsetHeight)
                return;
            body.value.scrollTo(0, details[0].offsetTop + details[0].offsetHeight - body.value.offsetHeight);
        });
        const columnsGridTemplate = computed(() => visibleColumns.value
            .map(col => col.width
            ? col.width + 'px'
            : `minmax(${([!!col.filterable || !!col.filter, col.sortable].filter(isTruthy).length + 1) * 50}px, 1fr)`)
            .join(' '));
        return {
            tableId,
            hasScroll,
            visibleItems,
            visibleColumns,
            columnsGridTemplate,
            details,
            body,
            columnRef,
            getId,
            getAlign,
            getHeaderClasses,
            formatCellValue,
            rowClick,
            rowDblClick,
            rowMouseEnter,
            rowMouseLeave,
            showDetail,
            toggleSort,
            resizeStart,
            excludeVue2Attributes,
            mergeClass,
            allFiltersCleared,
            setFilter,
            rowDragHandlers,
            shouldShowSelectionCheckboxes,
            columnGap,
            rowPaddingLeft,
            isDragging,
            draggableEntity,
            ghost,
        };
    },
});
function getAlign(align) {
    switch (align) {
        case 'top':
        case 'left':
            return 'flex-start';
        case 'center':
            return 'center';
        case 'bottom':
        case 'right':
            return 'flex-end';
    }
}
