import { defineComponent, h, resolveComponent } from 'vue';
export const ModuleDialogContainerComponent = defineComponent({
    name: 's-module-dialog-container',
    render() {
        return h('div', { class: 's-module-dialog-container' }, this.$store.moduleDialog.moduleDialogs.map(module => h(resolveComponent('s-page'), {
            name: module.name,
            key: module.scope,
            properties: {
                ...module.parameters,
                visible: true,
                'onUpdate:visible': ($event) => !$event && this.$store.moduleDialog.remove(module.name, module.scope),
                parameters: module.parameters,
                scope: module.scope,
                'data-scope': module.scope,
            },
        })));
    },
});
