import { Duration } from 'luxon';
import { defineStore } from 'pinia';
import { computed, reactive } from 'vue';
export const useInfoCacheMapStore = defineStore('info-cache-map', () => {
    const maps = new Map();
    const get = (category) => {
        let map = maps.get(category);
        if (map)
            return map;
        map = createMap(() => maps.delete(category));
        maps.set(category, map);
        return map;
    };
    return process.env.NODE_ENV === 'development' ? { get, maps: computed(() => maps) } : { get };
});
const createMap = (removeMapCallback) => {
    const map = reactive({});
    let loadingCallback = null;
    let timerId = null;
    let waitPromise = Promise.resolve();
    const tryStartLoadingTimer = () => {
        if (timerId !== null)
            return;
        waitPromise = new Promise(resolve => {
            timerId = window.setTimeout(async () => {
                timerId = null;
                if (!loadingCallback) {
                    resolve();
                    return;
                }
                const keys = [...Object.entries(map)].filter(x => !x[1]?.isLoaded).map(x => x[0]);
                const data = await loadingCallback(keys);
                Object.entries(data).forEach(([key, value]) => {
                    const state = map[key];
                    if (state) {
                        state.isLoaded = true;
                        state.data = value;
                    }
                });
                resolve();
            }, 10);
        });
    };
    const tryClearCacheAfterDelay = (key) => window.setTimeout(() => {
        const state = map[key];
        if (!state)
            return;
        if (state.count) {
            state.timerId = null;
            return;
        }
        delete map[key];
        if (!Object.keys(map).length)
            removeMapCallback();
    }, Duration.fromDurationLike({ minutes: 5 }).toMillis());
    return {
        map,
        onLoading(callback) {
            if (!loadingCallback)
                loadingCallback = callback;
        },
        register(key) {
            const state = map[key];
            if (!state) {
                map[key] = { count: 1, data: null, isLoaded: false, timerId: null };
                tryStartLoadingTimer();
            }
            else {
                state.count += 1;
            }
        },
        refresh(key) {
            const state = map[key];
            if (!state)
                return;
            state.isLoaded = false;
            tryStartLoadingTimer();
        },
        unregister(key) {
            const state = map[key];
            if (!state)
                return;
            state.count -= 1;
            if (state.count || state.timerId)
                return;
            state.timerId = tryClearCacheAfterDelay(key);
        },
        wait: () => waitPromise,
    };
};
