import { getToCenterPosition } from '@fbc/core/utils';
import { ref } from 'vue';
export const useEntityPreview = (props) => {
    const entityId = ref();
    const previewPosition = ref();
    const button = {
        id: 'btn-entity-preview',
        attributes: {
            class: 'fb-common-info',
            onClick: (event) => {
                if (!event || !(event instanceof Event) || !event.target || !(event.target instanceof HTMLElement))
                    return;
                event.stopPropagation();
                const rect = event.target.getBoundingClientRect();
                previewPosition.value = getToCenterPosition(rect, 8);
                entityId.value = props.modelValue[props.idField];
            },
        },
    };
    return { entityId, previewPosition, button };
};
