import { isDateTime } from '../guards';
/**
 * Конвертирует DateTime в текст относительно текущей даты (Вчера, 3 дня назад, 5 секунд назад)
 * @param value Значение DateTime
 * @param isRelativeCalendar Использовать ли ф-ю toRelativeCalendar. Различаются в выводе текста, например toRelativeCalendar - позавчера, toRelative - 1 день назад
 */
export default function dateTimeRelative(value, isRelativeCalendar = false) {
    return isDateTime(value)
        ? ((isRelativeCalendar ? value.toRelativeCalendar() : value.toRelative()) ?? '')
        : value;
}
