/** Приводит к строковом представлению числа * 100, чтобы избежать 0.07*100 */
export function saveMultiplyOn100(value) {
    if (typeof value === 'number')
        value = String(value);
    let index = value.indexOf('.');
    if (index < 0)
        return value + '00';
    let v = `${value.replace('.', '')}00`;
    const isNegative = v.startsWith('-');
    if (isNegative) {
        v = v.slice(1);
        index--;
    }
    v = v.slice(0, index + 2) + '.' + v.slice(index + 2);
    v = v.replace(/^0+/, '').replace(/(?<=[.1-9])0+$/, '');
    if (v.endsWith('.'))
        v = v.slice(0, -1);
    return isNegative ? '-' + v : v;
}
