import { services } from '@fbc/core/services';
import { useSemaphore } from '@fbc/core/storage';
import { defineFunctionalComponent } from '@fbc/core/utils';
import { defineComponent, h, ref, resolveComponent, watch } from 'vue';
import { data, listViewerApi } from '../../services/api';
import resource from './s-list-viewer-share.json';
export default defineComponent({
    name: 's-list-viewer-share',
    emits: ['update:visible'],
    props: {
        visible: { type: Boolean, required: true },
        groupId: { type: String, required: true },
    },
    setup: (props, { emit }) => {
        const roles = ref([]);
        const loading = useSemaphore();
        const { toast, i18n } = services;
        const local = (value) => i18n.extract(resource, value);
        const columns = [
            {
                field: 'name',
                caption: local('role'),
                sortIndex: 1,
                sortOrder: 'asc',
            },
            {
                field: 'hasAccess',
                caption: local('hasAccess'),
                width: 100,
                align: {
                    horizontal: 'center',
                },
                sortable: false,
                cellTemplate: defineFunctionalComponent({
                    entity: { type: Object, required: true },
                    column: Object,
                    index: Number,
                }, ({ entity }) => h(resolveComponent('s-checkbox'), {
                    modelValue: entity.hasAccess,
                    'onUpdate:modelValue': (value) => {
                        entity.hasAccess = value;
                        entity.wasChanged = true;
                    },
                }), undefined, 'group-name-cell', false),
            },
        ];
        const hide = () => {
            emit('update:visible', false);
        };
        const save = async () => {
            const changedRole = roles.value.filter(role => role.wasChanged).map(({ id, hasAccess }) => ({ id, hasAccess }));
            if (!changedRole.length) {
                hide();
                return;
            }
            loading.lock();
            const [error] = await listViewerApi.shareGroup(props.groupId, changedRole);
            if (error) {
                log.prod.error(__filename, error);
                toast.notSaved();
            }
            loading.unlock();
            hide();
        };
        watch(() => props.visible, async (visible) => {
            if (!visible)
                return;
            loading.lock();
            const [error, roleAccess = []] = await data.get('entityGroup/groupAccess', { groupId: props.groupId }, __filename);
            if (error)
                log.prod.error(__filename, error);
            roles.value = roleAccess.map(role => Object.assign(role, { wasChanged: false }));
            loading.unlock();
        }, {
            immediate: true,
        });
        return {
            roles,
            columns,
            loading,
            local,
            i18n,
            hide,
            save,
        };
    },
});
