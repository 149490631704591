import { services } from '@fbc/core/services';
import { filters } from '@fbc/core/utils';
import { computed, ref, watch } from 'vue';
export default (module) => {
    const columns = ref([]);
    const detailModeColumns = computed(() => columns.value.length
        ? [
            {
                ...(columns.value.find(x => x.isDisplayName) ?? columns.value[0]),
                width: 250,
                cellTemplate: undefined,
            },
        ]
        : []);
    const formatters = computed(() => services.store.app.getModulesByPurpose('list-viewer-formatter')
        .filter((formatterModule) => !!formatterModule.parameters &&
        (!formatterModule.parameters.moduleNames ||
            formatterModule.parameters.moduleNames.includes(module.value.moduleName)))
        .map(formatterModule => ({
        name: formatterModule.parameters.name,
        moduleName: formatterModule.name,
    })));
    const onUpdateColumns = (columns) => {
        module.value.columns = module.value.columns.map(groupColumn => {
            const column = columns.find(column => column.field === groupColumn.id);
            if (!column)
                return groupColumn;
            groupColumn.displaySettings.width = column.width ?? null;
            groupColumn.sortIndex = column.sortIndex ?? null;
            groupColumn.sortOrder = column.sortOrder
                ? filters.capitalize(column.sortOrder)
                : null;
            return groupColumn;
        });
        module.value.selectedRecordIndexes = undefined;
        module.value.loader.clearCache();
        module.value.loader.currentPage = 1;
        module.value.loadData();
    };
    const updateColumns = async () => (columns.value = await Promise.all(module.value.columns.map(columnToDataTableColumn)));
    const columnToDataTableColumn = async (column) => {
        const formatterModuleNameModuleName = formatters.value.find(formatter => formatter.name === column.displaySettings.formatter)?.moduleName;
        const formatterMapper = formatterModuleNameModuleName
            ? (await services.store.app.moduleRegistry.load(formatterModuleNameModuleName))?.default
            : undefined;
        return Object.assign({
            field: column.id,
            width: column.displaySettings.width ?? undefined,
            caption: column.displaySettings.caption ?? undefined,
            sortIndex: column.sortIndex,
            sortOrder: column.sortOrder?.toLocaleLowerCase(),
            align: column.displaySettings.align,
            isDisplayName: column.displaySettings.isDisplayName,
        }, await formatterMapper?.(column, module.value.moduleName));
    };
    watch(() => module.value.columns, updateColumns);
    return { columns, detailModeColumns, onUpdateColumns };
};
