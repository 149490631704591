/**
 * ? Для доступа к css переменным из ts, для доступа в разметке/стилях необходимо просто использовать переменные
 */
const style = window.getComputedStyle(document.body);
const variables = new Proxy({}, {
    get: function (_, name) {
        const rawValue = style.getPropertyValue('--' + name).trim();
        return rawValue.endsWith('px') || name === 'chart-colors-count' ? Number.parseFloat(rawValue) : rawValue;
    },
});
export default variables;
