import { services } from '@fbc/core/services';
import { compressStack, useFnPromiseWrapper } from '@fbc/core/utils';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { computed, ref } from 'vue';
import { useSemaphore } from './use-semaphore';
export function useEntityStore(api, key, filename, { odataConfig, maxLengthMap, onPropertyChanged, loadWithEntity = [], onEntityLoaded, getDisplayName, } = {}) {
    const entity = ref();
    const updateStack = ref([]);
    const loading = useSemaphore();
    const { store, i18n, bus, router } = services;
    const updatingFields = ref(new Set());
    const onPropertyChangedLocal = createOnPropertyChangedLocal(entity, updateStack, filename, maxLengthMap, onPropertyChanged);
    const isDirty = computed(() => updateStack.value.length > 0);
    async function loadOriginal(entityId, needToSwitchLoading = true) {
        if (!entityId)
            return false;
        if (needToSwitchLoading)
            loading.lock();
        store.performance.start('load ' + entityId);
        const [[error, response]] = await Promise.all([
            api.read(key, entityId, filename, odataConfig),
            ...(Array.isArray(loadWithEntity) ? loadWithEntity : [loadWithEntity]).map(x => x(entityId)),
        ]);
        if (error) {
            router.replace({ name: 'page-no-access' });
        }
        if (needToSwitchLoading)
            loading.unlock();
        if (error ?? !response)
            return log.prod.error(filename, error), false;
        updateEntity(response);
        if (onEntityLoaded)
            if (Array.isArray(onEntityLoaded))
                await Promise.all(onEntityLoaded.map(x => x(entity.value)));
            else
                await onEntityLoaded(entity.value);
        const currentRoute = router.currentRoute.value;
        store.entityHistory.add({
            displayName: getDisplayName ? getDisplayName(response) : null,
            link: currentRoute.fullPath,
            entityTypeName: currentRoute.meta.listViewer?.entity?.[i18n.locale],
            entityId,
        });
        store.performance.end('load ' + entityId, 'Загружена сущность ' + key, 'core', { entityId });
        store.performance.endPageLoad();
        return Boolean(entity.value?.id);
    }
    const { f: load, promise: loadPromise } = useFnPromiseWrapper(loadOriginal);
    async function save({ relatedProperties = [], needToSwitchLoading = true, } = {}) {
        const entityValue = entity.value;
        if (!entityValue)
            return false;
        if (process.env.NODE_ENV === 'development')
            log.dev.table(__filename, updateStack.value.map(x => ({ Name: x[0], newValue: x[1], oldValue: x[2] })));
        const entityToSave = { id: entityValue.id };
        // Сохраняем только измененные свойства
        for (const [key, value] of updateStack.value)
            entityToSave[key] = value;
        if (Object.keys(entityToSave).length === 1)
            return true; // Не шлем запрос если нет св-в на обновление
        store.performance.start('save ' + (entityValue.id ?? 'new'));
        updatingFields.value = new Set(Object.keys(entityToSave));
        const [error, rawEntity] = await api.createOrUpdate(key, entityToSave, __filename, odataConfig);
        if (relatedProperties.length)
            updateStack.value = updateStack.value.filter(([propertyName]) => !relatedProperties.includes(propertyName));
        store.performance.end('save ' + (entityValue.id ?? 'new'), 'Сохранена сущность ' + key, 'action', {
            entityId: rawEntity?.id ?? 'Ошибка',
        });
        if (error ?? !rawEntity) {
            updatingFields.value.clear();
            log.prod.error(__filename, error);
            return false;
        }
        bus.emit(`/entity/${key}/${entityValue.id ? 'updated' : 'created'}`, rawEntity.id);
        return load(rawEntity.id, needToSwitchLoading).then(x => (updatingFields.value.clear(), x));
    }
    async function partialSave(properties, relatedProperties = []) {
        const entityValue = entity.value;
        if (!entityValue?.id || !properties.length)
            return false;
        const entityToSave = { id: entityValue.id };
        for (const key of properties)
            entityToSave[key] = entityValue[key];
        if (Object.keys(entityToSave).length === 1)
            return true; // Не шлем запрос если нет св-в на обновление
        store.performance.start('partial save ' + entityValue.id);
        updatingFields.value = new Set(Object.keys(entityToSave));
        const [error, rawEntity] = await api.update(key, entityValue.id, entityToSave, __filename);
        updatingFields.value.clear();
        store.performance.end('partial save ' + entityValue.id, 'Сохранена сущность ' + key, 'action', {
            entityId: rawEntity?.id ?? 'Ошибка',
        });
        if (error ?? !rawEntity)
            return log.prod.error(__filename, error), false;
        bus.emit(`/entity/${key}/updated`, rawEntity.id);
        let stack = updateStack.value;
        log.dev.debug(__filename, 'update properties from server response: ', properties);
        for (const key of properties) {
            entityValue[key] = rawEntity[key];
            stack = stack.filter(x => x[0] !== key);
        }
        if (relatedProperties.length)
            stack = stack.filter(([propertyName]) => !relatedProperties.includes(propertyName));
        updateStack.value = stack;
        return true;
    }
    async function deleteEntity() {
        const entityValue = entity.value;
        if (!entityValue?.id)
            return [undefined, false];
        store.performance.start('delete ' + entityValue.id);
        const response = await api.delete(key, entityValue.id, filename);
        store.performance.end('delete ' + entityValue.id, 'Удалена сущность сущность ' + key, 'action', {
            entityId: entityValue.id,
        });
        if (response !== true)
            return [response, false];
        if (entity.value?.id === entityValue.id)
            clear();
        else
            log.dev.warn(filename, 'Во время удаления сущности была загружена другая');
        bus.emit(`/entity/${key}/deleted`, entityValue.id);
        return [undefined, true];
    }
    function clear() {
        entity.value = undefined;
        updateStack.value = [];
    }
    function updateEntity(entityValue) {
        entity.value = createEntityProxy(entityValue, onPropertyChangedLocal);
        updateStack.value = [];
    }
    return {
        entity,
        isDirty,
        maxLengthMap,
        updateStack,
        loading,
        load,
        save,
        clear,
        delete: deleteEntity,
        partialSave,
        loadPromise,
        updatingFields,
    };
}
export function createEntityProxy(entity, onPropertyChanged) {
    if (!entity)
        return undefined;
    return new Proxy(entity, {
        set: function (entity, property, value) {
            for (const onChange of onPropertyChanged) {
                const result = onChange(property, value);
                if (result === false)
                    return onChange === onPropertyChanged[0]; // 0 обработчик - проверка на то, что свойство изменилось
                if (result === true)
                    continue;
                value = result[0];
            }
            entity[property] = value;
            return true;
        },
    });
}
export function createOnPropertyChangedLocal(entityRef, updateStack, filename, maxLengthMap, onPropertyChanged) {
    const onPropertyChangedLocal = [
        /* return on no change */
        (property, value) => !(!entityRef.value ||
            entityRef.value[property] === value ||
            (value instanceof DateTime &&
                entityRef.value[property] instanceof DateTime &&
                value.toMillis() === entityRef.value[property].toMillis())),
    ];
    if (maxLengthMap?.size)
        onPropertyChangedLocal.push((property, value) => {
            const maxLength = maxLengthMap?.get(property);
            if (maxLength && typeof value === 'string' && value.length > maxLength) {
                return [value.substring(0, maxLength)];
            }
            return true;
        });
    if (onPropertyChanged)
        if (Array.isArray(onPropertyChanged))
            onPropertyChangedLocal.push(...onPropertyChanged);
        else
            onPropertyChangedLocal.push(onPropertyChanged);
    onPropertyChangedLocal.push((property, value) => {
        if (!entityRef.value)
            return false;
        updateStack.value.push([property, cloneDeep(value), cloneDeep(entityRef.value)[property]]);
        updateStack.value = compressStack(updateStack.value);
        return true;
    });
    if (process.env.NODE_ENV === 'development')
        onPropertyChangedLocal.push((property, value) => (log.dev.debug(filename, 'Property updated:', property, ' = ', value), true));
    return onPropertyChangedLocal;
}
