export var Selection;
(function (Selection) {
    Selection[Selection["Selected"] = 1] = "Selected";
    Selection[Selection["UnSelected"] = -1] = "UnSelected";
    Selection[Selection["Partial"] = 0] = "Partial";
})(Selection || (Selection = {}));
export const switchSelection = (selection) => {
    switch (selection) {
        case Selection.Selected:
            return Selection.UnSelected;
        case Selection.Partial:
            return Selection.Selected;
        case Selection.UnSelected:
            return Selection.Selected;
    }
};
/** Будет выбрана только одна нода в дереве. Работает только без стратегий выбора предка/потомков */
export const selectOnlyOneNode = (node) => {
    node.selected = switchSelection(node.selected);
    if (node.selected !== Selection.Selected)
        return;
    for (const childs of node.getRoot().all(x => x.selected === Selection.Selected && x !== node)) {
        childs.selected = Selection.UnSelected;
    }
};
