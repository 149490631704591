/**
 * Выводит человекочитаемый размер файла
 * @param value Кол-во байт
 */
const nameSizeByte = { en: ['B', 'KB', 'MB', 'GB', 'TB'], ru: ['Б', 'КБ', 'МБ', 'ГБ', 'ТБ'] };
export default function fileSize(value, lang = 'ru') {
    if (typeof value !== 'number') {
        return '';
    }
    for (const v of nameSizeByte[lang]) {
        if (value < 1024) {
            return value.toPrecision(3) + ' ' + v;
        }
        value /= 1024;
    }
    return value.toPrecision(3) + nameSizeByte[lang][nameSizeByte[lang].length - 1];
}
