import { isNotNullOrUndefined, parseJson } from '@fbc/core/utils';
import { defineStore } from 'pinia';
const storeKey = 'fora.entity.history';
export const useEntityHistoryStore = defineStore({
    id: 'entityHistory',
    state: () => ({ historyItems: [] }),
    getters: {
        asMenuItems: (state) => ({
            order: 0,
            id: 'mnuEntityHistory',
            text: {
                default: 'Recently watched',
                'ru-ru': 'Недавно просмотренные',
            },
            items: state.historyItems.map((x, i) => ({
                order: i,
                id: `entity_history_item_${i.toString()}`,
                link: { $type: 'internal', path: x.link },
                text: { default: `${x.entityTypeName ? `${x.entityTypeName}: ` : ''}${x.displayName ?? ''}` },
                items: [],
            })),
        }),
    },
    actions: {
        clear() {
            sessionStorage.removeItem(storeKey);
            this.historyItems = [];
        },
        add(item) {
            if (!isNotNullOrUndefined(item.displayName))
                return;
            const duplicateIndex = this.historyItems.findIndex(x => x.link === item.link || x.entityId === item.entityId);
            if (duplicateIndex >= 0)
                this.historyItems.splice(duplicateIndex, 1);
            if (this.historyItems.unshift(item) > 10)
                this.historyItems.pop();
            sessionStorage.setItem(storeKey, JSON.stringify(this.historyItems));
        },
        load() {
            this.historyItems =
                parseJson(sessionStorage.getItem(storeKey), (data) => typeof data === 'object' &&
                    data !== null &&
                    Array.isArray(data) &&
                    data.every(x => 'link' in x && 'displayName' in x && 'entityTypeName' in x)) ?? [];
        },
    },
});
