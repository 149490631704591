import { defineFunctionalComponent } from '@fbc/core/utils';
import { h } from 'vue';
import { Selection, switchSelection } from '.';
export const SelectionComponent = defineFunctionalComponent({ modelValue: { type: Number, required: true }, disabled: Boolean }, (props, options) => h('i', {
    class: [
        's-tree-view-selection',
        getSelectionClass(props.modelValue),
        { 's-tree-view-selection--disabled': props.disabled },
    ],
    onClick: () => props.disabled || options.emit('update:modelValue', switchSelection(props.modelValue)),
}), ['update:modelValue'], 'TreeViewItemSelection');
const getSelectionClass = (value) => {
    switch (value) {
        case Selection.Selected:
            return 's-tree-view-selection--selected';
        case Selection.UnSelected:
            return 's-tree-view-selection--un-selected';
        case Selection.Partial:
            return 's-tree-view-selection--partial';
    }
};
