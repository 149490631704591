import { ref } from 'vue';
export function useFnPromiseWrapper(fn) {
    const promise = ref();
    const args = ref();
    function f(...fnArguments) {
        promise.value = fn(...fnArguments);
        args.value = fnArguments;
        return promise.value;
    }
    f.promise = promise;
    f.args = args;
    return { f, promise, args };
}
