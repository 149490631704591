import { createUniqueId, excludeVue2Attributes, mergeClass } from '@fbc/core/utils';
import { computed, defineComponent, reactive } from 'vue';
const name = 's-tags-input';
export default defineComponent({
    name,
    inheritAttrs: false,
    emits: ['update:modelValue', 'input', 'focus', 'blur'],
    props: {
        modelValue: { type: [Array] },
        id: { type: String, default: () => 'input-' + createUniqueId() },
        labelField: { type: String },
        label: { type: String },
        readonly: { type: Boolean },
        disabled: { type: Boolean },
        required: { type: Boolean },
        onlyUnique: { type: Boolean },
        rows: { type: [Number, String], default: 1 },
        maxRows: { type: [Number, String] },
        maxItems: { type: Number },
        counter: { type: [Boolean, Number, String] },
        vertical: {
            type: Boolean,
        },
        equalityComparer: {
            /**
             * @param a - массив выбранных значений
             * @param b - искомое значение
             */
            type: Function,
            default: (a, b) => a && b && a.includes(b),
        },
    },
    setup(props, { emit, attrs }) {
        const noLabel = computed(() => !props.label);
        const counterText = computed(() => props.maxItems ? String(props.modelValue?.length ?? 0) + ' / ' + props.maxItems : (props.modelValue?.length ?? 0));
        const mutableClasses = reactive({
            [`${name}--focused`]: false,
        });
        const rootClasses = computed(() => ({
            [`${name}--focused`]: mutableClasses[`${name}--focused`],
            [`${name}--readonly`]: Boolean(props.readonly),
            [`${name}--disabled`]: Boolean(props.disabled),
            [`${name}--no-label`]: Boolean(noLabel.value),
            [`${name}--vertical`]: Boolean(props.vertical),
        }));
        const labelClasses = computed(() => ({
            [`${name}__label`]: true,
            [`${name}__label--required`]: props.required,
        }));
        const attributes = computed(() => ({
            ...excludeVue2Attributes(attrs),
            'onUpdate:modelValue': (value, event) => {
                if (value && (!props.onlyUnique || !props.equalityComparer(props.modelValue, value))) {
                    addItem(value);
                    event.target.value = '';
                }
            },
            onKeydown: (event) => {
                if (event.shiftKey || event.ctrlKey || event.altKey || event.metaKey)
                    return;
                if (event.key === 'Backspace' && !event.target.value && props.modelValue?.length) {
                    removeItemByIndex(props.modelValue.length - 1);
                }
            },
            onPaste: (event) => {
                const items = event.clipboardData
                    ?.getData('text')
                    .split(/\r?\n/)
                    ?.map(item => item.trim())
                    .filter(Boolean);
                if (!items || items.length <= 1)
                    return;
                event.preventDefault();
                addItem(items);
            },
            onFocus: (event) => {
                mutableClasses[`${name}--focused`] = true;
                emit('focus', event);
            },
            onBlur: (event) => {
                mutableClasses[`${name}--focused`] = false;
                emit('blur', event);
            },
        }));
        const allowAdd = computed(() => !props.readonly && !props.disabled && (!props.maxItems || (props.modelValue?.length ?? 0) < props.maxItems));
        function addItem(item) {
            if (!allowAdd.value)
                return;
            const itemsForAdd = [];
            const itemsArray = Array.isArray(item) ? item : [item];
            for (const item of itemsArray)
                if (!props.onlyUnique || !props.equalityComparer(props.modelValue, item))
                    itemsForAdd.push(item);
            emit('update:modelValue', [...(props.modelValue ?? []), ...itemsForAdd]);
        }
        function removeItemByIndex(itemIndexToRemove) {
            if (!props.modelValue?.length)
                return;
            emit('update:modelValue', props.modelValue.filter((_, index) => index !== itemIndexToRemove));
        }
        function getLabel(item) {
            if (!item)
                return '';
            return props.labelField ? item[props.labelField] : String(item);
        }
        function focusInput() {
            const inputComponent = document.querySelector(`#${props.id}`);
            if (!inputComponent)
                return;
            inputComponent.focus();
        }
        return {
            name,
            noLabel,
            mutableClasses,
            rootClasses,
            labelClasses,
            allowAdd,
            attributes,
            counterText,
            mergeClass,
            getLabel,
            addItem,
            removeItemByIndex,
            focusInput,
        };
    },
});
