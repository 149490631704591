/**
 * Конвертирует строку с json в объект проверенный гвардом
 * @param rawData строка с json
 * @param guard [type guard](https://www.typescriptlang.org/docs/handbook/advanced-types.html)
 * @see
 * ```ts
 * const t = parseJson(
    '{prop:1}',
    (a): a is { prop: number } => typeof a === 'object' && a !== null && 'prop' in a && typeof (a as { prop: unknown }).prop === 'number'
    );
 * ```
 * @returns Распарсеный объект или null, если он не прошел проверку или не является валидным json
 */
export const parseJson = (rawData, guard) => {
    if (!rawData)
        return null;
    try {
        const result = JSON.parse(rawData);
        return guard(result) ? result : null;
    }
    catch {
        return null;
    }
};
