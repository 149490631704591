import { services } from '@fbc/core/services';
import { createGuid, getCachedModule, securedMethodRouterModuleTabs } from '@fbc/core/utils';
import { computed, defineComponent, inject, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { onBeforeRouteUpdate, useRoute, useRouter, } from 'vue-router';
import DraggableTabComponent from '../tabs-settings/s-draggable-tab.vue';
import ModalTabsSettingsComponent from '../tabs-settings/s-modal-tabs-settings.vue';
import { useTabsSetting } from '../tabs-settings/use-tabs-setting';
import { useHorizontalScroll } from '../use-horizontal-scroll';
const lastTabName = ref(); // Вынесено отдельно, чтобы сохранялось при пересоздании компонента
export default defineComponent({
    name: 's-detail-tab-container',
    props: {
        entityId: { type: String },
        entityName: { type: String, required: true },
        entitySubName: { type: String },
        subTabs: { type: Boolean, default: false },
        preventAutoRedirect: { type: Boolean, default: false },
    },
    components: {
        's-modal-tabs-settings': ModalTabsSettingsComponent,
        's-draggable-tab': DraggableTabComponent,
    },
    setup(props) {
        const { i18n } = services;
        const route = useRoute();
        const router = useRouter();
        const nameModule = inject('nameModule');
        const rootRoute = route.matched.find(x => x.name === nameModule);
        const visibleMore = ref(false);
        const getMeta = (tab, target) => i18n.extractFromFlatResource(tab.meta?.[target] ?? {});
        const tabs = ref([]);
        const menuTabs = ref([]);
        const loading = ref(true);
        const entityName = computed(() => props.entitySubName ?? props.entityName);
        const scope = createGuid();
        const { module: tabsSettingModule, unregister } = getCachedModule(`tabs-settings-${scope}`, useTabsSetting(scope, entityName));
        const getTabSetting = (tab) => tabsSettingModule.settings?.[tab.name?.toString() ?? ''];
        const visibleTabs = computed(() => tabs.value
            .filter(tab => getTabSetting(tab).visible ?? true)
            .sort((a, b) => getTabSetting(a).order - getTabSetting(b).order));
        const isModalTabsSettingsVisible = ref(false);
        const showModalTabsSettingClick = () => {
            isModalTabsSettingsVisible.value = true;
        };
        const onTabClicked = async ({ id }) => {
            const tab = tabs.value.find(x => x.name?.toString() === id);
            lastTabName.value = id;
            await router.replace({
                ...tab,
                params: props.entityId ? { id: props.entityId } : undefined,
                query: route.query,
            });
            nextTick(() => scrollToActiveTab());
        };
        const updateTabs = async () => {
            loading.value = true;
            const loadedTabs = await securedMethodRouterModuleTabs(rootRoute, props.entityId);
            const tabsForSettings = loadedTabs
                .map(x => ({
                id: x.name?.toString() ?? '',
                title: getMeta(x, 'title'),
                order: x.meta?.tabContainerOrder ?? 0,
            }))
                .sort((a, b) => a.order - b.order);
            await tabsSettingModule.load(tabsForSettings);
            tabs.value = loadedTabs;
            await redirectToFirstRoute();
            loading.value = false;
        };
        watch(() => props.entityId, updateTabs);
        const hasAccessToCurrentModule = computed(() => {
            const availableRoutes = getTabAndChildrenNames(tabs.value).filter(x => x !== undefined);
            return route.name && availableRoutes.includes(route.name);
        });
        const getTabAndChildrenNames = (tabs) => [
            ...tabs.map(x => x.name),
            ...tabs.filter(x => x.children !== undefined).flatMap(x => getTabAndChildrenNames(x.children ?? [])),
        ];
        watch(() => route.meta.entityName, () => (lastTabName.value = undefined));
        const servicePageNames = new Set(['page-not-found', 'page-no-access']);
        async function redirectToFirstRoute(allowAccessInvisibleTab = true) {
            if ((allowAccessInvisibleTab ? tabs : visibleTabs).value.some(x => x.name === route.name))
                return; // никуда не переходим если уже на доступной закладке
            if (servicePageNames.has(String(route.name)))
                return; //циклическая загрузка вкладок, если сущность не найдена
            const firstTab = visibleTabs.value.find(x => x.name === lastTabName.value) ?? visibleTabs.value.at(0);
            if (props.preventAutoRedirect || !firstTab)
                return;
            lastTabName.value = firstTab.name;
            await router.replace({
                name: firstTab.name,
                params: props.entityId ? { id: props.entityId } : undefined,
                query: route.query,
            });
        }
        const { tabsListElement, isBackButtonVisible, isForwardButtonVisible, scrollToActiveTab, recalculateButtonsVisible, register: registerHorizontalScrollModule, unregister: unregisterHorizontalScrollModule, } = useHorizontalScroll('.router-link-active.router-link-exact-active');
        onBeforeRouteUpdate((to, from) => {
            const isRoot = (route) => route.name === rootRoute?.name;
            if (isRoot(to) && !isRoot(from) && to.meta.entityName === from.meta.entityName && to.params.id === from.params.id)
                return from;
        });
        onMounted(async () => {
            await updateTabs();
            recalculateButtonsVisible();
            nextTick(() => scrollToActiveTab());
            registerHorizontalScrollModule(tabsListElement.value);
        });
        onBeforeUnmount(() => {
            unregister();
            unregisterHorizontalScrollModule(tabsListElement.value);
        });
        const onTabsVisibilityChanged = () => {
            if (!visibleTabs.value.length && rootRoute)
                return router.replace({
                    name: rootRoute.name,
                    params: { id: props.entityId },
                    query: route.query,
                });
            redirectToFirstRoute(false);
        };
        return {
            getMeta,
            tabsListElement,
            isBackButtonVisible,
            isForwardButtonVisible,
            hasAccessToCurrentModule,
            visibleMore,
            menuTabs,
            visibleTabs,
            tabs,
            loading,
            route,
            lastTabName,
            isModalTabsSettingsVisible,
            showModalTabsSettingClick,
            onTabClicked,
            tabsSettingModule,
            scope,
            onTabsVisibilityChanged,
        };
    },
});
