import { services } from '@fbc/core/services';
import { computed, ref } from 'vue';
import { createEntityProxy, createOnPropertyChangedLocal } from './use-entity-store';
import { useSemaphore } from './use-semaphore';
export function useEntityCreation(api, key, filename, { maxLengthMap, } = {}) {
    const entity = ref();
    const updateStack = ref([]);
    const loading = useSemaphore();
    const { store, bus } = services;
    const onPropertyChangedLocal = createOnPropertyChangedLocal(entity, updateStack, filename);
    const isDirty = computed(() => updateStack.value.length > 0);
    async function template() {
        store.performance.start('template ' + key);
        const [error, template] = await api.template(key);
        store.performance.end('template ' + key, 'Загружен шаблон сущности ' + key, 'core');
        if (error ?? !template)
            return log.prod.error(filename, error), false;
        updateEntity(template);
        return true;
    }
    async function save() {
        const entityValue = entity.value;
        if (!entityValue)
            return false;
        if (process.env.NODE_ENV === 'development')
            log.dev.table(__filename, updateStack.value.map(x => ({ Name: x[0], newValue: x[1], oldValue: x[2] })));
        store.performance.start('save ' + (entityValue.id ?? 'new'));
        const [error, rawEntity] = await api.create(key, entityValue, __filename);
        store.performance.end('save ' + (entityValue.id ?? 'new'), 'Сохранена сущность ' + key, 'action', {
            entityId: rawEntity?.id ?? 'Ошибка',
        });
        if (error ?? !rawEntity)
            return log.prod.error(__filename, error), false;
        updateEntity(rawEntity);
        bus.emit(`/entity/${key}/created`, rawEntity.id);
        return true;
    }
    function clear() {
        entity.value = undefined;
        updateStack.value = [];
    }
    function updateEntity(updatedEntity) {
        entity.value = createEntityProxy(updatedEntity, onPropertyChangedLocal);
        updateStack.value = [];
    }
    return { entity, isDirty, updateStack, new: template, loading, save, clear, maxLengthMap };
}
