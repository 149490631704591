import { services } from '@fbc/core/services';
import { defineFunctionalComponent } from '@fbc/core/utils';
import { omit } from 'lodash';
import { h, resolveComponent } from 'vue';
import props from './types/Properties';
const StaticLookupComponent = defineFunctionalComponent({
    ...props,
    data: { type: Array, required: true },
}, (props, options) => h(resolveComponent('s-lookup'), {
    ...omit(props, 'data'),
    ...options.attrs,
    dataProvider: new services.lookupStaticProvider(props.data),
    'onUpdate:modelValue': (event) => options.emit('update:modelValue', event),
    'onUpdate:columns': (event) => options.emit('update:columns', event),
}, { default: options.slots.default }), ['update:modelValue', 'update:columns']);
export default StaticLookupComponent;
