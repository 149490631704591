import { services } from '@fbc/core/services';
import { getStoreDynamicModule } from '@fbc/core/utils';
import { computed, defineComponent, ref, watch } from 'vue';
import resource from './s-add-entity-into-static-group.json';
export default defineComponent({
    name: 's-add-entity-into-static-group',
    emits: ['update:visible'],
    props: {
        visible: { type: Boolean, required: true },
        storeName: { type: String, required: true },
    },
    setup(props, { emit }) {
        const { i18n } = services;
        const local = (value) => i18n.extract(resource, value);
        const module = ref(getStoreDynamicModule(props.storeName));
        const transitionType = ref('tab-transition');
        const mode = ref('exists');
        const groupId = ref();
        const selectedGroup = computed({
            get: () => module.value.groups.find(x => x.id === groupId.value),
            set: newGroup => (groupId.value = newGroup?.id),
        });
        const baseGroupColumns = ref([
            {
                field: 'displayName',
                caption: local('display-name'),
                filter: 'text',
                sortIndex: 1,
                sortOrder: 'asc',
                isDisplayName: true,
            },
        ]);
        const displayName = ref();
        const filter = ref();
        const staticGroups = computed(() => module.value.groups
            .filter(x => x.isStatic &&
            x.id !== module.value.currentGroupId &&
            (!filter.value || x.displayName.includes(filter.value)))
            .sort((x, y) => x.displayName.localeCompare(y.displayName)));
        const hasExistsStaticGroups = computed(() => !!staticGroups.value.length);
        const isValid = computed(() => !!groupId.value && (mode.value === 'exists' || !!displayName.value));
        const hide = () => {
            emit('update:visible', false);
        };
        const okClick = () => {
            if (!groupId.value)
                return;
            if (mode.value === 'exists')
                module.value.addEntitiesIntoStaticGroup(groupId.value);
            else {
                if (!displayName.value)
                    return;
                module.value.createStaticGroup(groupId.value, displayName.value);
            }
            hide();
        };
        const changeMode = (newMode) => {
            mode.value = newMode;
            groupId.value =
                mode.value === 'new' && module.value.currentGroupId !== module.value.lookupResultId
                    ? module.value.currentGroupId
                    : undefined;
        };
        const changeFilter = (newFilter) => {
            filter.value = newFilter;
            if (staticGroups.value.every(x => x.id !== groupId.value))
                groupId.value = undefined;
        };
        watch(() => props.visible, visible => {
            if (!visible)
                return;
            filter.value = undefined;
            displayName.value = undefined;
            changeMode(hasExistsStaticGroups.value ? 'exists' : 'new');
        });
        watch(() => props.storeName, () => {
            module.value = getStoreDynamicModule(props.storeName);
        });
        return {
            local,
            i18n,
            module,
            hide,
            mode,
            transitionType,
            groupId,
            displayName,
            staticGroups,
            hasExistsStaticGroups,
            selectedGroup,
            baseGroupColumns,
            okClick,
            isValid,
            filter,
            changeMode,
            changeFilter,
        };
    },
});
