import { services } from '@fbc/core/services';
import { isNotNullOrUndefined, isPropertyNotNullOrUndefined, isTruthy } from '@fbc/core/utils';
import { isEmpty } from 'lodash';
import { computed, defineComponent, h, ref, resolveComponent, withKeys } from 'vue';
import resource from './s-lookup.json';
const typesWithValue = new Set(['=', '!=', 'starts', 'ends', 'contains']);
const typesWithOutValue = new Set(['empty', 'not empty']);
const isFilterValid = (x) => {
    switch (x.type) {
        case 'number':
        case 'dateTime':
            return isNotNullOrUndefined(x.to) || isNotNullOrUndefined(x.from);
        case 'notInList':
        case 'list':
            return Boolean(x.list?.length);
        case 'text':
            return x.value ? typesWithValue.has(x.op) : typesWithOutValue.has(x.op);
        case 'equal':
            return !isEmpty(x.value);
        case 'notEqual':
            return !isEmpty(x.value);
    }
};
export const useLookupFilters = (props) => {
    const filters = ref([]);
    const filtersChanged = ref(false);
    const hiddenFiltersChanged = ref(false);
    const validFilters = computed(() => [
        ...filters.value.filter(isFilterValid),
        ...props.hiddenFilters.filter(isFilterValid),
    ]);
    const newFilter = () => {
        const col = props.columns.find(isPropertyNotNullOrUndefined('filter'));
        if (!col)
            throw new Error('Хотя бы у одного поля должен быть заполнен фильтр');
        filters.value.push({
            fieldName: col.field,
            type: col.filter,
            ...initFilterParameters(col.filter),
        });
    };
    newFilter();
    const removeFilter = (filter) => {
        filters.value = filters.value.filter(x => x !== filter);
        if (!filters.value.length)
            newFilter();
    };
    const clearFilters = () => {
        filters.value = [];
        newFilter();
    };
    return { filters, validFilters, filtersChanged, hiddenFiltersChanged, newFilter, removeFilter, clearFilters };
};
const getFilterContent = (filter, emit, { bordered }) => {
    switch (filter.type) {
        case 'dateTime':
            return [
                h(resolveComponent('s-date-time-picker'), {
                    modelValue: filter.from,
                    bordered,
                    showClearButton: true,
                    max: filter.to,
                    placeholder: services.i18n.resource('global.date', 'from'),
                    onKeypress: withKeys(() => emit('enter'), ['enter']),
                    'onUpdate:modelValue': (from) => emit('updateParameter', { from }),
                }),
                h(resolveComponent('s-date-time-picker'), {
                    modelValue: filter.to,
                    bordered,
                    showClearButton: true,
                    min: filter.from,
                    placeholder: services.i18n.resource('global.date', 'to'),
                    onKeypress: withKeys(() => emit('enter'), ['enter']),
                    'onUpdate:modelValue': (to) => emit('updateParameter', { to }),
                }),
            ];
        case 'number':
            return [
                h(resolveComponent('s-input'), {
                    modelValue: filter.from,
                    type: 'double',
                    bordered,
                    showClearButton: true,
                    max: filter.to,
                    placeholder: services.i18n.resource('global.date', 'from'),
                    onKeypress: withKeys(() => emit('enter'), ['enter']),
                    'onUpdate:modelValue': (from) => emit('updateParameter', { from }),
                }),
                h(resolveComponent('s-input'), {
                    modelValue: filter.to,
                    type: 'double',
                    bordered,
                    showClearButton: true,
                    min: filter.from,
                    placeholder: services.i18n.resource('global.date', 'to'),
                    onKeypress: withKeys(() => emit('enter'), ['enter']),
                    'onUpdate:modelValue': (to) => emit('updateParameter', { to }),
                }),
            ];
        case 'text': {
            const options = [...typesWithValue, ...typesWithOutValue].map(x => ({
                key: x,
                value: services.i18n.extract(resource, x),
            }));
            return [
                h(resolveComponent('s-select'), {
                    modelValue: options.find(x => x.key === filter.op) ?? null,
                    bordered,
                    options,
                    labelField: 'value',
                    'onUpdate:modelValue': ({ key: op }) => emit('updateParameter', { op }),
                }),
                !typesWithOutValue.has(filter.op)
                    ? h(resolveComponent('s-input'), {
                        modelValue: filter.value,
                        bordered,
                        placeholder: services.i18n.extract(resource, 'text-placeholder'),
                        tabindex: 1,
                        onKeypress: withKeys(() => emit('enter'), ['enter']),
                        onInput: (value) => emit('updateParameter', { value }),
                        'onUpdate:modelValue': (value) => emit('updateParameter', { value }),
                    })
                    : undefined,
            ].filter(isTruthy);
        }
        case 'list':
            return [
                h(resolveComponent('s-textarea'), {
                    modelValue: filter.list?.join('\n') ?? '',
                    rows: 1,
                    maxRows: 6,
                    expandable: true,
                    noLabel: true,
                    clearable: true,
                    style: 'flex: 1 0 auto;height: 100%;display: flex;',
                    placeholder: services.i18n.extract(resource, 'list-placeholder'),
                    'onUpdate:modelValue': (value) => emit('updateParameter', { list: value?.split('\n').filter(isTruthy) }),
                }),
            ];
    }
    return [];
};
const initFilterParameters = (filter) => filter === 'text' ? { op: 'starts' } : {};
export default defineComponent({
    name: 's-lookup-filter',
    props: {
        index: { type: Number, required: true },
        columns: { type: Array, required: true },
        filter: { type: Object, required: true },
        bordered: { type: Boolean, default: true },
    },
    emits: ['new', 'update', 'remove', 'enter', 'updateParameter'],
    render() {
        return h('div', { class: ['s-lookup-filter', 's-lookup-filter--' + this.filter.type] }, [
            h(resolveComponent('s-select'), {
                modelValue: this.columns.find(x => x.field === this.filter.fieldName && x.filter === this.filter.type),
                bordered: this.bordered,
                labelField: 'caption',
                options: this.columns.filter(x => x.filter),
                'onUpdate:modelValue': (column) => {
                    this.$emit('update', {
                        fieldName: column.field,
                        type: column.filter,
                        ...initFilterParameters(column.filter),
                    });
                },
            }),
            ...getFilterContent(this.filter, this.$emit, { bordered: this.bordered }),
            h(resolveComponent('s-button'), {
                icon: this.index === 0 ? 'fb-common-plus' : 'fb-common-close',
                class: 's-lookup-filter__button ' + (this.index === 0 ? '' : 's-lookup-filter__button--delete'),
                onClick: () => (this.index === 0 ? this.$emit('new') : this.$emit('remove', this.filter)),
            }),
        ]);
    },
});
