import { services } from '@fbc/core/services';
import { defineFunctionalComponent } from '@fbc/core/utils';
import { h, resolveComponent } from 'vue';
import props from './types/Properties';
const DataLookupComponent = defineFunctionalComponent({
    ...props,
    parameters: Object,
    method: { type: String, required: true },
}, (props, options) => h(resolveComponent('s-lookup'), {
    ...props,
    dataProvider: new services.lookupDataApiProvider(props.method, props.parameters),
    'onUpdate:modelValue': (event) => options.emit('update:modelValue', event),
    'onUpdate:columns': (event) => options.emit('update:columns', event),
    ...options.attrs,
}, { default: options.slots.default }), ['update:modelValue', 'update:columns']);
export default DataLookupComponent;
