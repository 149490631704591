import { services } from '@fbc/core/services';
import { computed } from 'vue';
export const useInfoCacheMap = (entity, loadingCallback) => {
    const { bus, store: { infoCacheMap }, } = services;
    const cache = infoCacheMap.get(entity);
    cache.onLoading(loadingCallback);
    return {
        entities: computed(() => cache.map),
        register: (id) => {
            cache.register(id);
            const refresh = () => cache.refresh(id);
            const subscriptions = [
                bus.on(`/entity/${entity}/created`, refresh),
                bus.on(`/entity/${entity}/updated`, refresh),
                bus.on(`/entity/${entity}/deleted`, refresh),
            ];
            return () => {
                cache.unregister(id);
                subscriptions.forEach(x => x());
            };
        },
        registerArray: (ids) => {
            const clearCallbacks = ids.map(id => {
                cache.register(id);
                return () => cache.unregister(id);
            });
            return () => clearCallbacks.forEach(callback => callback());
        },
        wait: cache.wait,
    };
};
