import { reactive, toRaw, watch } from 'vue';
export function useSubmenu(props) {
    const submenu = reactive({
        item: undefined,
        visible: false,
        position: undefined,
    });
    const showSubmenu = (item, event, target) => {
        if (!item || !event || !target)
            return;
        if (!item?.items?.length || toRaw(submenu.item) === toRaw(item)) {
            submenu.visible = false;
            submenu.item = undefined;
            return;
        }
        submenu.item = item;
        if (!submenu.item)
            return;
        const rect = target.getBoundingClientRect();
        event.preventDefault();
        event.stopPropagation();
        submenu.position =
            props.submenuPosition === 'left'
                ? { right: window.innerWidth - rect.left + 4, top: rect.top - 8 }
                : { left: rect.right + 4, top: rect.top - 8 };
        submenu.visible = true;
    };
    watch(() => props.visible, x => x || (submenu.visible = false));
    watch(() => submenu.visible, x => x || (submenu.item = undefined));
    return { showSubmenu, submenu };
}
