import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_s_placeholder = _resolveComponent("s-placeholder");
    const _component_s_select = _resolveComponent("s-select");
    return (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_s_placeholder, {
            key: 0,
            style: _normalizeStyle([{ "height": "32px" }, { width: _ctx.placeholderWidth }]),
            contrast: _ctx.placeholderContrast
        }, null, 8 /* PROPS */, ["contrast", "style"]))
        : (_openBlock(), _createBlock(_component_s_select, _mergeProps({
            key: 1,
            "model-value": _ctx.valueField ? _ctx.fullValue(_ctx.modelValue) : _ctx.modelValue
        }, _ctx.attributes, {
            options: _ctx.items,
            "label-field": _ctx.valueField ? 'text' : null
        }), null, 16 /* FULL_PROPS */, ["model-value", "options", "label-field"]));
}
