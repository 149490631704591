import { services } from '@fbc/core/services';
import { createGuid, getCachedModule, isString } from '@fbc/core/utils';
import { isEqual, sortBy } from 'lodash';
import { computed, defineComponent, nextTick, onMounted, onUnmounted, reactive, ref, watch, } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import DraggableTabComponent from '../../tabs-settings/s-draggable-tab.vue';
import ModalTabsSettingsComponent from '../../tabs-settings/s-modal-tabs-settings.vue';
import { useTabsSetting } from '../../tabs-settings/use-tabs-setting';
import { useHorizontalScroll } from '../../use-horizontal-scroll';
import { useListViewer } from '../presenter/use-list-viewer';
import getEntityId from '../use-cases/get-entity-id';
import AddEntityIntoStaticGroupComponent from './s-add-entity-into-static-group/s-add-entity-into-static-group.vue';
import CreateLookupResultComponent from './s-create-lookup-result/s-create-lookup-result.vue';
import ListViewerFiltersComponent from './s-list-viewer-filters/s-list-viewer-filters.vue';
import ListViewerShareComponent from './s-list-viewer-share/s-list-viewer-share.vue';
import resource from './s-list-viewer.json';
import useColumns from './use-columns';
import useContextMenu from './use-context-menu';
import useListViewerBuilder from './use-list-viewer-builder';
import useSelectRows from './use-select-rows';
import { useSubscriptions } from './use-subscriptions';
export default defineComponent({
    name: 's-list-viewer',
    components: {
        's-list-viewer-filters': ListViewerFiltersComponent,
        's-list-viewer-share': ListViewerShareComponent,
        's-modal-tabs-settings': ModalTabsSettingsComponent,
        's-draggable-tab': DraggableTabComponent,
        's-create-lookup-result': CreateLookupResultComponent,
        's-add-entity-into-static-group': AddEntityIntoStaticGroupComponent,
    },
    setup() {
        const { i18n, store } = services;
        const local = (value, map) => i18n.extract(resource, value, map);
        const route = useRoute();
        const router = useRouter();
        // Если переходим с ОЭФ обратно на списочное представление в рамках одного модуля, то обновляем данные
        const removeAfterEachHook = router.afterEach((to, from) => {
            if (isDetail(from) && !isDetail(to) && from.meta.manifestName === to.meta.manifestName)
                module.value.refresh();
            if (isDetail(to) && !isDetail(from))
                module.value.selectedRecordIndexes = undefined;
        });
        const routeMeta = computed(() => route.matched.at(1)?.meta);
        const manifestName = computed(() => routeMeta.value?.manifestName ?? null);
        const moduleUrl = computed(() => {
            const [, moduleUrl] = route.path.split('/');
            return moduleUrl;
        });
        const storeName = computed(() => 'list-viewer-' + (manifestName.value?.toLocaleLowerCase() ?? 'undefined'));
        const { module, unregister } = (() => {
            const store = getCachedModule(storeName.value, useListViewer(manifestName.value ?? 'undefined'));
            return { module: ref(store.module), unregister: ref(store.unregister) };
        })();
        const scope = createGuid();
        const { module: tabsSettingModule, unregister: unregisterTabsSettingModule } = getCachedModule(`tabs-settings-${scope}`, useTabsSetting(scope, computed(() => (manifestName.value ?? '') + '-list-viewer')));
        const updateTabsSettings = () => tabsSettingModule.load(module.value.groups.map((x, index) => ({ id: x.id, title: x.displayName, order: index })));
        const internalRightPanelCollapsed = ref();
        watch(() => store.app.screen.width, (screenWidth, previousScreenWidth) => {
            if (screenWidth <= 1080 && previousScreenWidth > 1080)
                rightPanelCollapsed.value = true;
        });
        const rightPanelCollapsed = computed({
            get: () => internalRightPanelCollapsed.value ??
                (store.app.screen.width <= 1080 ? true : localStorage.getItem('is-right-panel-visible') === 'false') ??
                false,
            set: value => {
                internalRightPanelCollapsed.value = value;
                if (store.app.screen.width > 1080)
                    localStorage.setItem('is-right-panel-visible', (!value).toString());
            },
        });
        const collapseRightPanelOnSmallScreen = () => {
            if (store.app.screen.width <= 1080)
                rightPanelCollapsed.value = true;
        };
        const visibleGroups = computed(() => Object.keys(tabsSettingModule.settings).length
            ? module.value.groups
                .filter(tab => (tabsSettingModule.settings?.[tab.id]?.visible ?? true) ||
                tab.id === module.value.defaultGroupId ||
                tab.id === module.value.currentGroupId)
                .sort((a, b) => (tabsSettingModule.settings?.[a.id]?.order ?? 0) - (tabsSettingModule.settings?.[b.id]?.order ?? 0))
            : []);
        const visibleGroupsWithLookupResults = computed(() => [
            {
                id: module.value.lookupResultId,
                displayName: local('lookup-result'),
                description: null,
                canEditDeleteShare: false,
                isStatic: false,
            },
            ...visibleGroups.value,
        ]);
        const hasGroups = computed(() => module.value.groups.length);
        const onGroupsVisibilityChanged = () => {
            if (!module.value.currentGroupId)
                return;
            const isCurrentGroupVisible = tabsSettingModule.settings?.[module.value.currentGroupId]?.visible;
            if (isCurrentGroupVisible)
                return;
            const visibleGroups = module.value.groups.filter(x => Boolean(tabsSettingModule.settings?.[x.id]?.visible));
            module.value.currentGroupId = module.value.defaultGroupId ?? visibleGroups.at(0)?.id;
        };
        const { columns, detailModeColumns, onUpdateColumns } = useColumns(module);
        const { selectedEntities, onSelect, onClickSelectAll, selectedAll } = useSelectRows(module);
        const { subscribe, unsubscribe } = useSubscriptions(module);
        const builder = useListViewerBuilder({
            module,
            updateTabsSettings,
        });
        const isModalTabsSettingsVisible = ref(false);
        const showModalTabsSettingClick = () => {
            isModalTabsSettingsVisible.value = true;
        };
        const onTabClicked = (groupId) => {
            if (module.value.loading.isLoadingSome)
                return;
            module.value.currentGroupId = groupId;
            nextTick().then(() => scrollToActiveTab());
        };
        const actionsButton = ref();
        const groupShare = reactive({
            visible: false,
            groupId: undefined,
        });
        const addingIntoStaticGroupVisible = ref(false);
        const isCreateLookupResultModalVisible = ref(false);
        const isAvailableCreateLookupResult = computed(() => !!module.value.columns.length &&
            !store.isDirty &&
            !module.value.loading.isLoadingSomeBy(['first-load', 'data', 'lookup-result']).value);
        const getContext = useContextMenu({
            module,
            showGroupShare: (groupId) => {
                groupShare.groupId = groupId;
                groupShare.visible = true;
            },
            builder,
            updateTabsSettings,
            addingIntoStaticGroupVisible,
        });
        const isDetail = (x) => !!x.params.id?.[0];
        const mode = computed(() => (isDetail(route) ? 'detail' : 'list'));
        const currentEntity = computed(() => mode.value === 'detail' && module.value.idFields
            ? module.value.records.find(x => isEqual(sortBy(module.value.idFields.map(idField => x[idField])), sortBy(Array.isArray(route.params.id) ? route.params.id : [route.params.id])))
            : null);
        const { tabsListElement, isBackButtonVisible, isForwardButtonVisible, scrollToActiveTab, recalculateButtonsVisible, register: registerHorizontalScrollModule, unregister: unregisterHorizontalScrollModule, } = useHorizontalScroll('.s-list-viewer__group-item--current');
        const onUpdatePage = (newPage) => {
            module.value.loader.currentPage = newPage;
            module.value.loadData();
        };
        const showActionContextMenu = () => {
            const el = actionsButton.value instanceof HTMLElement
                ? actionsButton.value
                : actionsButton.value?.$el;
            if (!el)
                return;
            const rect = el.getBoundingClientRect();
            showContextMenu({ left: rect.left, top: rect.bottom }, { type: 'action' });
        };
        const showGroupContextMenu = (event, parameters) => {
            const rect = event.target instanceof HTMLElement ? event.target.getBoundingClientRect() : undefined;
            showContextMenu({ left: rect?.left ?? event.pageX, top: rect?.bottom ?? event.pageY }, parameters);
        };
        let lastContextMenuId = null;
        const showContextMenu = ({ left, top }, parameters) => {
            const contextMenuId = getContextMenuId(parameters);
            if (lastContextMenuId === contextMenuId) {
                services.store.contextMenu.hide();
                lastContextMenuId = null;
                return;
            }
            const items = getContext(parameters);
            if (!items.length) {
                lastContextMenuId = null;
                return;
            }
            lastContextMenuId = contextMenuId;
            services.store.contextMenu.show(items, { left, top }).then(() => {
                lastContextMenuId = null;
            });
        };
        const getContextMenuId = (parameters) => parameters.type + ('context' in parameters ? parameters.context.id : '');
        const goToDetail = (record) => {
            if (!module.value.idFields)
                return;
            const ids = module.value.idFields.map(idField => record[idField]).filter(isString);
            if (!ids.length || isEqual(Array.isArray(route.params.id) ? route.params.id : [route.params.id], ids))
                return;
            router.push({ path: `/${moduleUrl.value}/${ids.join('/')}` });
        };
        const getLocalization = (modifier) => {
            const routI18n = route.meta.listViewer?.[modifier ? `entity--${modifier}` : 'entity'];
            return (routI18n?.[i18n.locale] ??
                routI18n?.default ??
                i18n.global(modifier ? `entity--${modifier}` : 'entity') ??
                manifestName.value ??
                '---');
        };
        const title = computed(() => {
            const currentTitle = store.title.join('');
            const defaultTitle = store.defaultTitle.join('');
            return currentTitle === defaultTitle
                ? getLocalization(mode.value === 'detail' ? '' : 'plural')
                : store.title.slice(1).join(' - ');
        });
        onMounted(() => {
            registerHorizontalScrollModule(tabsListElement.value);
        });
        onUnmounted(() => {
            unregister.value();
            unsubscribe();
            unregisterTabsSettingModule();
            unregisterHorizontalScrollModule(tabsListElement.value);
            removeAfterEachHook();
        });
        watch(() => manifestName.value, async (name) => {
            unregister.value();
            if (!name) {
                unsubscribe();
                return;
            }
            subscribe(routeMeta.value?.listViewer?.updateSubscriptions);
            ({ module: module.value, unregister: unregister.value } = getCachedModule(storeName.value, useListViewer(manifestName.value ?? 'undefined')));
            module.value.loading.lock('first-load');
            await module.value.firstLoad();
            await updateTabsSettings();
            module.value.loading.unlock('first-load');
            nextTick()
                .then(() => recalculateButtonsVisible())
                .then(() => scrollToActiveTab());
        }, {
            immediate: true,
        });
        const readonlyIds = computed(() => new Set([module.value.defaultGroupId]));
        return {
            actionsButton,
            addingIntoStaticGroupVisible,
            columns,
            currentEntity,
            detailModeColumns,
            getEntityId,
            goToDetail,
            groupShare,
            i18n,
            isAvailableCreateLookupResult,
            isBackButtonVisible,
            isCreateLookupResultModalVisible,
            isForwardButtonVisible,
            isModalTabsSettingsVisible,
            local,
            manifestName,
            mode,
            module,
            moduleUrl,
            onGroupsVisibilityChanged,
            onSelect,
            onClickSelectAll,
            selectedAll,
            onTabClicked,
            onUpdateColumns,
            onUpdatePage,
            readonlyIds,
            scope,
            scrollToActiveTab,
            selectedEntities,
            showActionContextMenu,
            showGroupContextMenu,
            showModalTabsSettingClick,
            storeName,
            tabsListElement,
            title,
            visibleGroups,
            visibleGroupsWithLookupResults,
            rightPanelCollapsed,
            collapseRightPanelOnSmallScreen,
            hasGroups,
            builder,
        };
    },
});
