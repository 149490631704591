/**
 * Обход дерева по порядку \
    (1)\
    ├─ (2)\
    │  ├─ (3)\
    │  │  ├─ (4)\
    │  ├─ (5)\
    │  │  ├─ (6)\
    │  │  │  ├─ (7)\
    ├─ (8)\
    │  ├─ (9)\
    ├─ (10)\
 * @param action действие для каждого элемента, если возвращает false - обход прекращается
 * @param tree элемент с которого начинается обход
 * @returns был ли прерван обход
 */
const depthFirstPreOrder = (action, tree) => {
    let keepGoing = action(tree);
    for (const child of tree.childs) {
        if (keepGoing === false)
            return false;
        keepGoing = depthFirstPreOrder(action, child);
    }
    return keepGoing;
};
/**
 * Обход дерева в ширину \
    (1)\
    ├─ (2)\
    │  ├─ (5)\
    │  │  ├─ (8)\
    │  ├─ (6)\
    │  │  ├─ (9)\
    │  │  │  ├─ (10)\
    ├─ (3)\
    │  ├─ (7)\
    ├─ (4)\
 * @param action действие для каждого элемента, если возвращает false - обход прекращается
 * @param tree элемент с которого начинается обход
 */
const breadthFirst = (action, tree) => {
    const queue = [tree];
    (function processQueue() {
        if (queue.length === 0)
            return;
        const node = queue.shift();
        if (!node)
            return;
        for (const child of node.childs) {
            queue.push(child);
        }
        if (action.call(tree, node) !== false) {
            processQueue();
        }
    })();
};
/**
 * Обход дерева с листьев вверх \
    (10)\
    ├─ (6)\
    │  ├─ (2)\
    │  │  ├─ (1)\
    │  ├─ (5)\
    │  │  ├─ (4)\
    │  │  │  ├─ (3)\
    ├─ (8)\
    │  ├─ (7)\
    ├─ (9)\
 * @param action действие для каждого элемента, если возвращает false - обход прекращается
 * @param tree элемент с которого начинается обход
 * @returns был ли прерван обход
 */
const depthFirstPostOrder = (action, tree) => {
    let keepGoing;
    for (const child of tree.childs) {
        keepGoing = depthFirstPostOrder(action, child);
        if (keepGoing === false)
            return false;
    }
    keepGoing = action(tree);
    return keepGoing;
};
/**
 *  Переходит из элемента в корень дерева, action не вызывается на самом элементе \
    (3)\
    ├─ (2)\
    │  ├─ unvisited\
    │  │  ├─ unvisited\
    │  ├─ (1)\
    │  │  ├─ (start)\
    │  │  │  ├─ unvisited\
    ├─ unvisited\
    │  ├─ unvisited\
    ├─ unvisited\
 * @param action действие для каждого элемента, если возвращает false - обход прекращается
 * @param tree элемент с которого начинается обход
 * @returns был ли прерван обход
 */
const up = (action, tree) => {
    let keepGoing = false;
    let root = tree;
    while ((root = root.parent)) {
        keepGoing = action(root);
        if (keepGoing === false)
            return false;
    }
    return keepGoing;
};
export const traverseStrategies = {
    /**
     * Обход дерева по порядку \
        (1)\
        ├─ (2)\
        │  ├─ (3)\
        │  │  ├─ (4)\
        │  ├─ (5)\
        │  │  ├─ (6)\
        │  │  │  ├─ (7)\
        ├─ (8)\
        │  ├─ (9)\
        ├─ (10)\
    * @param action действие для каждого элемента, если возвращает false - обход прекращается
    * @param tree элемент с которого начинается обход
    * @returns был ли прерван обход
    */
    pre: depthFirstPreOrder,
    /**
     * Обход дерева с листьев вверх \
        (10)\
        ├─ (6)\
        │  ├─ (2)\
        │  │  ├─ (1)\
        │  ├─ (5)\
        │  │  ├─ (4)\
        │  │  │  ├─ (3)\
        ├─ (8)\
        │  ├─ (7)\
        ├─ (9)\
    * @param action действие для каждого элемента, если возвращает false - обход прекращается
    * @param tree элемент с которого начинается обход
    * @returns был ли прерван обход
    */
    post: depthFirstPostOrder,
    /**
     *  Переходит из элемента в корень дерева, action не вызывается на самом элементе \
        (3)\
        ├─ (2)\
        │  ├─ unvisited\
        │  │  ├─ unvisited\
        │  ├─ (1)\
        │  │  ├─ (start)\
        │  │  │  ├─ unvisited\
        ├─ unvisited\
        │  ├─ unvisited\
        ├─ unvisited\
    * @param action действие для каждого элемента, если возвращает false - обход прекращается
    * @param tree элемент с которого начинается обход
    * @returns был ли прерван обход
    */
    up,
    /**
     * Обход дерева в ширину \
        (1)\
        ├─ (2)\
        │  ├─ (5)\
        │  │  ├─ (8)\
        │  ├─ (6)\
        │  │  ├─ (9)\
        │  │  │  ├─ (10)\
        ├─ (3)\
        │  ├─ (7)\
        ├─ (4)\
    * @param action действие для каждого элемента, если возвращает false - обход прекращается
    * @param tree элемент с которого начинается обход
    */
    breadth: breadthFirst,
};
