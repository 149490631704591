/**
 *  Converts a string into Capitalize
 *
 * 'abc' => 'Abc'
 *
 * @param {Object} options
 */
export default function capitalize(value, { onlyFirstLetter = false } = {}) {
    if (!value)
        return '';
    if (onlyFirstLetter) {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return value
        .toString()
        .toLowerCase()
        .split(' ')
        .map(function (item) {
        return item.charAt(0).toUpperCase() + item.slice(1);
    })
        .join(' ');
}
