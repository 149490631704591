import { isTruthy } from '@fbc/core/utils';
import { computed, ref, watch } from 'vue';
export function useDataTableFilters(props, emit) {
    const filters = ref({});
    const allFiltersCleared = ref(false);
    const appliedFilters = computed(() => Object.values(filters.value).filter(isTruthy));
    function setFilter(key, value) {
        if (allFiltersCleared.value)
            return;
        filters.value[key] = value;
        emit('update:filters', appliedFilters.value);
    }
    function clearAllFilters() {
        filters.value = {};
        allFiltersCleared.value = true;
        emit('update:filters', appliedFilters.value);
        emit('update:clearFilters', false);
    }
    watch(() => props.clearFilters, x => x && clearAllFilters());
    return { allFiltersCleared, appliedFilters, setFilter, clearAllFilters };
}
