import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useAppStore } from './app';
import { useCacheStore } from './cache';
import { useCustomSettingStore } from './custom-setting';
import { useDialogsStore } from './dialogs';
import { useEntityHistoryStore } from './entity-history';
import { useInfoCacheMapStore } from './info-cache-map';
import { useModuleDialogStore } from './module-dialog';
import { usePerformanceStore } from './performance';
import { usePicklistStore } from './picklist';
import { useToastStore } from './toast';
import { useContextMenu } from './use-context-menu';
import { useDirty } from './use-dirty';
import { useDynamicModules } from './use-dynamic-modules';
import { useForaIdle } from './use-fora-idle';
import { Semaphore } from './use-semaphore';
import { useUserStore } from './user';
import { useUserOptionStore } from './user-options';
/*
dynamic modules:
Есть метод wait, в котором можно подождать регистрацию модуля зная его название
dirty status:
Глобальный isDirty получается из того, что руками выставляли глобально и isDirty состояния динамических модулей(isDynamicModuleDirty), если оно есть
При переходе, если пользователь выбрал в confirm перейти глобальное состояние чистится. Состояние модулей надо чистить самому
*/
export const useGlobalStore = defineStore('global', () => {
    const dynamic = useDynamicModules();
    const loading = new Semaphore();
    const app = useAppStore();
    const user = useUserStore();
    const toast = useToastStore();
    const cache = useCacheStore();
    const picklists = usePicklistStore();
    const customSettings = useCustomSettingStore(cache, app);
    const userOptions = useUserOptionStore(cache, app, user);
    const dialogs = useDialogsStore();
    const moduleDialog = useModuleDialogStore();
    const performance = usePerformanceStore();
    const entityHistory = useEntityHistoryStore();
    const infoCacheMap = useInfoCacheMapStore();
    entityHistory.load();
    performance.init();
    const idle = useForaIdle();
    const title = ref(app.title.order);
    const description = ref('');
    const now = ref(DateTime.utc());
    let intervalId = window.setInterval(() => (now.value = DateTime.utc()), app.globalTimeUpdateIntervalMs);
    watch(() => app.globalTimeUpdateIntervalMs, () => {
        window.clearInterval(intervalId);
        intervalId = window.setInterval(() => (now.value = DateTime.utc()), app.globalTimeUpdateIntervalMs);
    });
    return {
        title,
        description,
        defaultTitle: computed(() => app.title.order),
        app,
        user,
        toast,
        cache,
        infoCacheMap,
        picklists,
        customSettings,
        userOptions,
        loading,
        dynamic,
        dialogs,
        moduleDialog,
        performance,
        entityHistory,
        ...useDirty(dynamic),
        now,
        idle,
        contextMenu: useContextMenu(),
    };
});
