<template>
	<svg xmlns="http://www.w3.org/2000/svg" class="s-logo" width="166" viewBox="0 0 480 76">
		<path
			style="stroke-width: 1.13"
			d="M 34.74072,29.53368 C 33.92712,28.80144 32.9508,28.39464 31.81176,28.39464 H 10.90224 C 10.17,28.39464 9.60048,28.23192 9.11232,27.74376 8.62416,27.2556 8.46144,26.68608 8.46144,25.95384 V 11.14632 c 0,-0.73224 0.16272,-1.30176 0.65088,-1.78992 C 9.60048,8.86824 10.17,8.62416 10.90224,8.62416 h 30.1032 c 1.2204,0 2.19672,-0.32544 3.01032,-1.05768 C 44.748,6.9156 45.1548,5.93928 45.1548,4.80024 45.1548,3.6612 44.748,2.68488 44.01576,1.95264 43.20216,1.2204 42.22584,0.8136 41.00544,0.8136 H 4.23072 C 3.01032,0.8136 1.95264,1.2204 1.2204,2.034 0.4068,2.8476 0,3.82392 0,5.04432 v 55.08072 c 0,1.2204 0.4068,2.19672 1.2204,3.01032 0.8136,0.8136 1.78992,1.2204 3.01032,1.2204 1.13904,0 2.19672,-0.4068 3.01032,-1.2204 0.8136,-0.8136 1.2204,-1.78992 1.2204,-3.01032 V 38.72736 c 0,-0.73224 0.16272,-1.30176 0.65088,-1.78992 C 9.60048,36.44928 10.17,36.2052 10.90224,36.2052 h 20.90952 c 1.13904,0 2.11536,-0.32544 2.92896,-1.05768 0.8136,-0.73224 1.2204,-1.6272 1.2204,-2.8476 0,-1.13904 -0.4068,-2.034 -1.2204,-2.76624 z"
		/>

		<path
			d="m 71.98061,64.33576 c -2.16,-0.42 -4.92,-3.6 -5.64,-6.48 -0.6,-2.4 -0.84,-2.64 -3.24,-3.24 -4.8,-1.2 -7.56,-5.64 -6.6,-10.44 1.32,-6.18 8.46,-9.6 21.6,-10.44 6.9,-0.42 6.84,-1.2 -0.12,-1.32 -4.68,-0.06 -11.76,-1.56 -15,-3.12 -9,-4.32 -8.7,-15.3 0.42,-18 1.98,-0.6 2.46,-1.02 2.76,-2.7 0.54,-3 3.66,-6.12 6.9,-6.96 2.52,-0.6 3.06,-0.54 5.7,0.78 5.16,2.58 7.74,8.64 8.46,20.16 0.42,6.84 1.38,7.8 1.38,1.38 0,-7.38 2.58,-16.5 5.52,-19.62 0.72,-0.72 2.46,-1.8 3.84,-2.34 4.98,-1.92 10.08,0.84 11.7,6.3 0.6,1.92 1.08,2.4 3,3 7.44,2.22 9.36,10.86 3.48,15.84 -3.48,3 -10.98,5.16 -18.24,5.22 -5.46,0.12 -8.04,1.08 -3,1.2 8.94,0.12 17.16,2.1 21,5.04 2.52,1.92 4.2,6.12 3.66,9.06 -0.6,3.06 -3.54,6.18 -6.66,6.96 -2.34,0.6 -2.64,0.9 -3.3,3.24 -1.44,5.22 -6.54,8.04 -11.58,6.36 -5.46,-1.8 -9.36,-10.62 -9.42,-21.36 0,-2.28 -0.3,-4.62 -0.6,-5.1 -0.42,-0.66 -0.6,0.06 -0.6,2.52 0,5.04 -1.14,12.24 -2.52,15.9 -2.52,6.78 -6.6,9.36 -12.9,8.16 z"
			style="fill: var(--secondary); stroke-width: 0.06"
		/>
		<path
			style="stroke-width: 1.13"
			d="m 179.82255,60.53184 c 0,-0.89496 -0.32544,-1.78992 -0.8136,-2.68488 L 167.86263,40.11048 a 2.49278,2.49278 0 0 1 -0.24408,-1.87128 2.55832,2.55832 0 0 1 1.05768,-1.54584 c 2.35944,-1.38312 4.31208,-3.2544 5.85792,-5.61384 1.95264,-2.92896 2.92896,-6.34608 2.92896,-10.25136 0,-3.90528 -0.89496,-7.3224 -2.68488,-10.41408 -1.78992,-3.01032 -4.23072,-5.36976 -7.40376,-7.07832 -3.17304,-1.6272 -6.75288,-2.52216 -10.73952,-2.52216 h -14.6448 c -1.2204,0 -2.27808,0.4068 -3.01032,1.2204 -0.8136,0.8136 -1.2204,1.78992 -1.2204,3.01032 v 55.08072 c 0,1.2204 0.4068,2.19672 1.2204,3.01032 0.8136,0.8136 1.78992,1.2204 3.01032,1.2204 1.2204,0 2.19672,-0.4068 3.01032,-1.2204 0.8136,-0.73224 1.2204,-1.78992 1.2204,-3.01032 V 42.3072 c 0,-0.65088 0.16272,-1.2204 0.65088,-1.70856 0.48816,-0.48816 1.05768,-0.73224 1.70856,-0.73224 h 8.136 c 0.89496,0 1.54584,0.4068 2.034,1.2204 l 13.34304,21.23496 c 0.8136,1.38312 2.034,2.034 3.6612,2.034 1.2204,0 2.19672,-0.32544 2.92896,-1.05768 0.73224,-0.73224 1.13904,-1.6272 1.13904,-2.76624 z m -23.1876,-28.476 h -8.05464 c -0.65088,0 -1.2204,-0.16272 -1.70856,-0.65088 -0.48816,-0.48816 -0.65088,-1.05768 -0.65088,-1.70856 V 11.06496 c 0,-0.65088 0.16272,-1.2204 0.65088,-1.70856 0.48816,-0.48816 1.05768,-0.73224 1.70856,-0.73224 h 8.05464 c 3.6612,0 6.67152,1.13904 9.11232,3.41712 2.35944,2.27808 3.57984,5.20704 3.57984,8.78688 0,3.33576 -1.2204,6.02064 -3.57984,8.136 -2.4408,2.11536 -5.45112,3.09168 -9.11232,3.09168 z"
		/>
		<path
			d="m 240.55666,60.2064 c 0,-0.48816 -0.16272,-1.05768 -0.4068,-1.78992 L 217.20521,3.6612 c -0.73224,-1.87128 -2.034,-2.8476 -3.98664,-2.8476 -1.87128,0 -3.17304,0.97632 -3.98664,2.8476 L 186.3709,58.25376 a 4.5765,4.5765 0 0 0 -0.4068,1.87128 c 0,1.2204 0.32544,2.19672 1.13904,3.01032 0.8136,0.8136 1.78992,1.2204 3.01032,1.2204 0.8136,0 1.54584,-0.16272 2.27808,-0.65088 0.65088,-0.48816 1.2204,-1.05768 1.6272,-1.95264 l 3.98664,-9.84456 c 0.4068,-0.97632 1.13904,-1.54584 2.27808,-1.54584 h 25.87248 c 1.13904,0 1.87128,0.56952 2.27808,1.54584 l 3.98664,9.84456 c 0.24408,0.8136 0.8136,1.46448 1.54584,1.95264 0.73224,0.48816 1.46448,0.65088 2.35944,0.65088 1.13904,0 2.19672,-0.4068 3.01032,-1.2204 0.8136,-0.8136 1.2204,-1.78992 1.2204,-2.92896 z M 223.63378,40.02912 c 0.32544,0.8136 0.24408,1.6272 -0.24408,2.27808 -0.48816,0.73224 -1.13904,1.05768 -2.034,1.05768 h -16.272 c -0.89496,0 -1.54584,-0.32544 -2.034,-1.05768 -0.48816,-0.73224 -0.56952,-1.46448 -0.24408,-2.27808 l 8.29872,-20.09592 c 0.4068,-0.97632 1.13904,-1.46448 2.19672,-1.46448 1.13904,0 1.87128,0.48816 2.27808,1.54584 z"
			style="stroke-width: 1.13"
		/>
		<path
			d="M 316.20112,1.6272 C 313.1908,0.56952 309.61096,0 305.54296,0 c -5.85792,0 -11.14632,1.46448 -15.94656,4.39344 -4.80024,2.92896 -8.5428,6.83424 -11.30904,11.7972 -2.76624,4.96296 -4.14936,10.41408 -4.14936,16.35336 0,6.02064 1.38312,11.55312 4.14936,16.51608 2.76624,5.04432 6.5088,8.9496 11.30904,11.7972 4.80024,2.92896 10.08864,4.31208 15.94656,4.31208 7.40376,0 14.07528,-2.35944 19.9332,-7.07832 0.8136,-0.8136 1.30176,-1.87128 1.30176,-3.01032 0,-1.13904 -0.4068,-2.11536 -1.05768,-2.8476 a 3.33576,3.33576 0 0 0 -2.52216,-1.13904 c -0.89496,0 -1.78992,0.32544 -2.68488,0.97632 -4.80024,3.57984 -9.7632,5.36976 -14.97024,5.36976 -4.23072,0 -8.136,-1.05768 -11.71584,-3.33576 -3.57984,-2.19672 -6.34608,-5.20704 -8.46144,-9.03096 -2.11536,-3.74256 -3.09168,-7.97328 -3.09168,-12.52944 0,-4.4748 0.97632,-8.62416 3.09168,-12.44808 2.11536,-3.82392 4.8816,-6.83424 8.46144,-9.11232 3.57984,-2.19672 7.48512,-3.33576 11.71584,-3.33576 3.33576,0 6.102,0.48816 8.21736,1.30176 2.11536,0.8136 4.23072,2.19672 6.5088,4.068 0.8136,0.73224 1.78992,1.05768 2.8476,1.05768 0.97632,0 1.87128,-0.32544 2.68488,-1.05768 0.8136,-0.65088 1.2204,-1.54584 1.2204,-2.68488 0,-0.65088 -0.16272,-1.30176 -0.32544,-1.78992 A 4.39344,4.39344 0 0 0 325.3948,6.99696 c -3.09168,-2.4408 -6.18336,-4.23072 -9.19368,-5.36976 z"
			style="stroke-width: 1.13"
		/>
		<path
			d="m 385.76505,60.53184 c 0,-0.89496 -0.32544,-1.78992 -0.8136,-2.68488 L 373.80513,40.11048 a 2.49278,2.49278 0 0 1 -0.24408,-1.87128 2.55832,2.55832 0 0 1 1.05768,-1.54584 c 2.35944,-1.38312 4.31208,-3.2544 5.85792,-5.61384 1.95264,-2.92896 2.92896,-6.34608 2.92896,-10.25136 0,-3.90528 -0.89496,-7.3224 -2.68488,-10.41408 -1.78992,-3.01032 -4.23072,-5.36976 -7.40376,-7.07832 -3.17304,-1.6272 -6.75288,-2.52216 -10.73952,-2.52216 h -14.6448 c -1.2204,0 -2.27808,0.4068 -3.01032,1.2204 -0.8136,0.8136 -1.2204,1.78992 -1.2204,3.01032 v 55.08072 c 0,1.2204 0.4068,2.19672 1.2204,3.01032 0.8136,0.8136 1.78992,1.2204 3.01032,1.2204 1.2204,0 2.19672,-0.4068 3.01032,-1.2204 0.8136,-0.73224 1.2204,-1.78992 1.2204,-3.01032 V 42.3072 c 0,-0.65088 0.16272,-1.2204 0.65088,-1.70856 0.48816,-0.48816 1.05768,-0.73224 1.70856,-0.73224 h 8.136 c 0.89496,0 1.54584,0.4068 2.034,1.2204 l 13.34304,21.23496 c 0.8136,1.38312 2.034,2.034 3.6612,2.034 1.2204,0 2.19672,-0.32544 2.92896,-1.05768 0.73224,-0.73224 1.13904,-1.6272 1.13904,-2.76624 z m -23.1876,-28.476 h -8.05464 c -0.65088,0 -1.2204,-0.16272 -1.70856,-0.65088 -0.48816,-0.48816 -0.65088,-1.05768 -0.65088,-1.70856 V 11.06496 c 0,-0.65088 0.16272,-1.2204 0.65088,-1.70856 0.48816,-0.48816 1.05768,-0.73224 1.70856,-0.73224 h 8.05464 c 3.6612,0 6.67152,1.13904 9.11232,3.41712 2.35944,2.27808 3.57984,5.20704 3.57984,8.78688 0,3.33576 -1.2204,6.02064 -3.57984,8.136 -2.4408,2.11536 -5.45112,3.09168 -9.11232,3.09168 z"
			style="stroke-width: 1.13"
		/>
		<path
			d="m 460.98011,2.034 c -0.89383,-0.8136 -1.87015,-1.2204 -3.00919,-1.13904 -1.6272,0 -2.8476,0.73224 -3.74256,2.11536 v 0.16272 c -0.0814,0 -0.0814,0.08136 -0.0814,0.08136 l -21.31632,43.85304 c -0.4068,0.89496 -1.13904,1.30176 -2.19672,1.38312 -0.97632,0 -1.70856,-0.48816 -2.19672,-1.38312 L 406.5514,3.17304 406.47,3.09168 C 405.57504,1.6272 404.35464,0.8136 402.8088,0.8136 c -1.2204,0 -2.19672,0.4068 -3.01032,1.2204 -0.8136,0.8136 -1.2204,1.87128 -1.2204,3.01032 v 55.08072 c 0,1.2204 0.4068,2.19672 1.2204,3.01032 0.8136,0.8136 1.78992,1.2204 3.01032,1.2204 1.13904,0 2.19672,-0.4068 3.01032,-1.2204 0.8136,-0.8136 1.2204,-1.78992 1.2204,-3.01032 v -29.2896 c 0,-1.30176 0.56952,-2.034 1.87128,-2.35944 1.2204,-0.32544 2.11536,0.16272 2.76624,1.30176 L 426.4846,59.3928 c 0.8136,1.70856 2.11536,2.52216 3.90528,2.52216 1.46448,0 2.60352,-0.65088 3.49848,-1.95264 0.0814,-0.08136 0.16272,-0.16272 0.16272,-0.24408 l 14.97024,-30.51 c 0.56952,-1.2204 1.54584,-1.6272 2.76624,-1.30176 1.30176,0.24408 1.95264,1.05768 1.95264,2.35944 v 29.85912 c 0,1.2204 0.4068,2.19672 1.2204,3.01032 0.8136,0.8136 1.78992,1.2204 3.01032,1.2204 1.13904,0 2.11536,-0.4068 2.92896,-1.2204 0.8136,-0.8136 1.30176,-1.78992 1.30176,-3.01032 V 5.04432 c 0,-1.13904 -0.4068,-2.19672 -1.2204,-3.01032 z"
			style="stroke-width: 1.13"
		/>
	</svg>
</template>
<style lang="scss">
@layer baseComponents {
	.s-logo {
		user-select: none;
		transition: all 0.15s ease-out;
		> * {
			fill: var(--primary);
			transition: transform 0.7s ease-out;
		}

		&:hover {
			> path {
				&:nth-child(n - 5) {
					transform: scale(1.1) translateX(104px);
				}
				&:nth-child(n + 5) {
					transform: scale(0.5) translateX(490px);
					fill: var(--secondary);
				}
			}
		}
	}
}
</style>
