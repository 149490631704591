import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, Transition as _Transition, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["textContent"];
const _hoisted_2 = { class: "s-calendar__date-picker" };
const _hoisted_3 = { class: "s-calendar__date-picker-header" };
const _hoisted_4 = { class: "s-calendar__day-picker" };
const _hoisted_5 = ["onClick"];
const _hoisted_6 = ["textContent"];
const _hoisted_7 = {
    key: 1,
    class: "s-calendar__control-buttons"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_s_button = _resolveComponent("s-button");
    const _component_s_select = _resolveComponent("s-select");
    const _component_s_timepicker = _resolveComponent("s-timepicker");
    const _directive_swipe = _resolveDirective("swipe");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["s-calendar", { 's-calendar--date-only': _ctx.dateOnly }]),
        onClickPassive: _cache[11] || (_cache[11] =
            //@ts-ignore
            (...args) => (_ctx.closeAllMenu && _ctx.closeAllMenu(...args)))
    }, [
        _createElementVNode("h1", {
            textContent: _toDisplayString(_ctx.focusedDate.toFormat(_ctx.dateOnly ? 'dd.MM.yyyy' : 'dd.MM.yyyy HH:mm'))
        }, null, 8 /* PROPS */, _hoisted_1),
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_s_button, {
                    "aria-label": _ctx.$i18n.resource('global.date', 'previous-year'),
                    disabled: !_ctx.isDateTimeAllowed(_ctx.focusedDate.minus({ years: 1 }).endOf('year')),
                    class: "s-calendar__month-picker-btn",
                    icon: "fb-common-double-left",
                    onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.onYearWheel({ deltaY: -1 })))
                }, null, 8 /* PROPS */, ["aria-label", "disabled"]),
                _createVNode(_component_s_button, {
                    "aria-label": _ctx.$i18n.resource('global.date', 'previous-month'),
                    disabled: !_ctx.isDateTimeAllowed(_ctx.focusedDate.minus({ months: 1 }).endOf('month')),
                    class: "s-calendar__month-picker-btn",
                    icon: "fb-common-left",
                    onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.onMonthWheel({ deltaY: -1 })))
                }, null, 8 /* PROPS */, ["aria-label", "disabled"]),
                _createVNode(_component_s_select, {
                    modelValue: _ctx.month,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.month) = $event)),
                    isMenuVisible: _ctx.isMonthMenuVisible,
                    "onUpdate:isMenuVisible": _cache[3] || (_cache[3] = ($event) => ((_ctx.isMonthMenuVisible) = $event)),
                    "menu-class": `s-calendar__date-picker-header-month-menu ${_ctx.monthSelectClass ?? ''}`,
                    "menu-height": 220,
                    options: _ctx.monthNames
                }, {
                    input: _withCtx(({ switchMenuVisible, text }) => [
                        _createVNode(_component_s_button, {
                            action: "",
                            onClick: _withModifiers(($event) => {
                                _ctx.closeAllMenu();
                                switchMenuVisible();
                            }, ["stop"]),
                            onWheel: _ctx.onMonthWheel
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(text), 1 /* TEXT */)
                            ]),
                            _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick", "onWheel"])
                    ]),
                    _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue", "isMenuVisible", "menu-class", "options"]),
                _createVNode(_component_s_select, {
                    isMenuVisible: _ctx.isYearMenuVisible,
                    "onUpdate:isMenuVisible": _cache[4] || (_cache[4] = ($event) => ((_ctx.isYearMenuVisible) = $event)),
                    modelValue: _ctx.focusedDate.year,
                    options: _ctx.yearValues,
                    "menu-height": 220,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event) => {
                        _ctx.transitionType = $event - _ctx.focusedDate.year < 0 ? 'calendar-reverse-transition' : 'calendar-transition';
                        _ctx.setFocusedDate(_ctx.focusedDate.set({ year: $event }));
                    })
                }, {
                    input: _withCtx(({ switchMenuVisible, text }) => [
                        _createVNode(_component_s_button, {
                            action: "",
                            onClick: _withModifiers(($event) => {
                                _ctx.closeAllMenu();
                                switchMenuVisible();
                            }, ["stop"]),
                            onWheel: _ctx.onYearWheel
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(text), 1 /* TEXT */)
                            ]),
                            _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick", "onWheel"])
                    ]),
                    _: 1 /* STABLE */
                }, 8 /* PROPS */, ["isMenuVisible", "modelValue", "options"]),
                _createVNode(_component_s_button, {
                    "aria-label": _ctx.$i18n.resource('global.date', 'next-month'),
                    disabled: !_ctx.isDateTimeAllowed(_ctx.focusedDate.plus({ months: 1 }).startOf('month')),
                    class: "s-calendar__month-picker-btn",
                    icon: "fb-common-right",
                    onClick: _cache[6] || (_cache[6] = ($event) => (_ctx.onMonthWheel({ deltaY: 1 })))
                }, null, 8 /* PROPS */, ["aria-label", "disabled"]),
                _createVNode(_component_s_button, {
                    "aria-label": _ctx.$i18n.resource('global.date', 'next-year'),
                    disabled: !_ctx.isDateTimeAllowed(_ctx.focusedDate.plus({ years: 1 }).startOf('year')),
                    class: "s-calendar__month-picker-btn",
                    icon: "fb-common-double-right",
                    onClick: _cache[7] || (_cache[7] = ($event) => (_ctx.onYearWheel({ deltaY: 1 })))
                }, null, 8 /* PROPS */, ["aria-label", "disabled"])
            ]),
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dayNames, (day, i) => {
                    return (_openBlock(), _createElementBlock("div", {
                        key: day,
                        class: "s-calendar__day-week-cell"
                    }, [
                        _createElementVNode("span", {
                            class: _normalizeClass(`s-calendar__day-week-cell${Number(i) > 4 ? '--day-off' : ''}`)
                        }, _toDisplayString(day), 3 /* TEXT, CLASS */)
                    ]));
                }), 128 /* KEYED_FRAGMENT */)),
                _createVNode(_Transition, { name: _ctx.transitionType }, {
                    default: _withCtx(() => [
                        (_openBlock(), _createElementBlock("div", {
                            key: `${_ctx.focusedDate.year}-${_ctx.focusedDate.month}`,
                            class: "s-calendar__calendar-grid"
                        }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.focusedDateMonthDays, (day) => {
                                return (_openBlock(), _createElementBlock("div", {
                                    key: day.datetime.valueOf(),
                                    class: "s-calendar__calendar-cell",
                                    onClick: () => {
                                        if (!day.show)
                                            return;
                                        _ctx.setDateTime(day.datetime);
                                        if (_ctx.dateOnly)
                                            _ctx.onClickApply();
                                    }
                                }, [
                                    (day.show)
                                        ? (_openBlock(), _createElementBlock("div", {
                                            key: 0,
                                            class: _normalizeClass(day.class),
                                            textContent: _toDisplayString(day.num)
                                        }, null, 10 /* CLASS, PROPS */, _hoisted_6))
                                        : _createCommentVNode("v-if", true)
                                ], 8 /* PROPS */, _hoisted_5));
                            }), 128 /* KEYED_FRAGMENT */))
                        ]))
                    ]),
                    _: 1 /* STABLE */
                }, 8 /* PROPS */, ["name"])
            ])), [
                [_directive_swipe, _ctx.onCalendarSwipe]
            ])
        ]),
        (!_ctx.dateOnly)
            ? (_openBlock(), _createBlock(_component_s_timepicker, {
                key: 0,
                modelValue: _ctx.focusedDate,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event) => ((_ctx.focusedDate) = $event)),
                isHourMenuVisible: _ctx.isHourMenuVisible,
                isMinuteMenuVisible: _ctx.isMinuteMenuVisible,
                class: "s-calendar__time-picker",
                "min-hour": _ctx.timeLimits.minHour,
                "max-hour": _ctx.timeLimits.maxHour,
                "min-minute": _ctx.timeLimits.minMinute,
                "max-minute": _ctx.timeLimits.maxMinute,
                "onUpdate:isHourMenuVisible": _cache[9] || (_cache[9] = ($event) => {
                    _ctx.closeAllMenu();
                    _ctx.isHourMenuVisible = $event;
                }),
                "onUpdate:isMinuteMenuVisible": _cache[10] || (_cache[10] = ($event) => {
                    _ctx.closeAllMenu();
                    _ctx.isMinuteMenuVisible = $event;
                })
            }, null, 8 /* PROPS */, ["modelValue", "isHourMenuVisible", "isMinuteMenuVisible", "min-hour", "max-hour", "min-minute", "max-minute"]))
            : _createCommentVNode("v-if", true),
        (!(_ctx.disableChangeOnButton ?? _ctx.dateOnly))
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_ctx.isDateTimeAllowed(_ctx.$store.now.toLocal()) && _ctx.$store.app.screen.width > 600)
                    ? (_openBlock(), _createBlock(_component_s_button, {
                        key: 0,
                        class: "s-calendar__now",
                        action: "",
                        onClick: _ctx.setToday
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$i18n.resource('global.date', _ctx.dateOnly ? 'today' : 'now')), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["onClick"]))
                    : _createCommentVNode("v-if", true),
                _createVNode(_component_s_button, {
                    success: "",
                    action: "fb-common-done",
                    class: "s-calendar__apply",
                    onClick: _ctx.onClickApply
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$i18n.global('apply')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"]),
                _createVNode(_component_s_button, {
                    action: "fb-common-close",
                    class: "s-calendar__cancel",
                    onClick: _ctx.onClickCancel
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$i18n.global('cancel')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"])
            ]))
            : _createCommentVNode("v-if", true)
    ], 34 /* CLASS, NEED_HYDRATION */));
}
