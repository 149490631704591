import { h, inject, resolveComponent } from 'vue';
import { services } from '.';
import { getModalBody, typeMap } from '../storage/global/dialogs';
import { defineFunctionalComponent } from '../utils';
/*
Example:
            const {alert} = services;
            alert({
                body: markRaw(
                    defineComponent({
                        props: ['config'],
                        render() {
                            return h(Page, { name: 'v-ui-guide' });
                        },
                    }),
                ),
                width: 1700,
            }).then(x => log.dev.debug('alert', x));
*/
function alert(cfg) {
    if (!cfg || !cfg.body)
        cfg = { body: cfg };
    const alert = cfg;
    if (Array.isArray(alert.classes))
        alert.classes.push('s-alert');
    else
        alert.classes = alert.classes ? 's-alert ' + alert.classes : 's-alert';
    const store = services.store ?? inject('store');
    return store.dialogs.addAlert(alert);
}
const alertButtonCentered = () => defineFunctionalComponent({ remove: { type: Function, required: true } }, ({ remove }) => [
    h(resolveComponent('s-button'), { onClick: () => remove(), style: { margin: 'auto' } }, () => services.i18n.global('ok')),
]);
function alertWithIcon(type, cfg) {
    const alertStyles = typeMap(type);
    return alert({
        body: () => getModalBody(alertStyles.icon, alertStyles.iconColor, alertStyles.fillColor, cfg),
        footer: alertButtonCentered(),
        width: cfg.width,
        classes: cfg.classes,
    });
}
alert.success = (body, width = 440, caption, additional) => alertWithIcon('success', { body, width, caption, additional, classes: 's-alert--success' });
alert.error = (body, width = 440, caption, additional) => alertWithIcon('error', { body, width, caption, additional, classes: 's-alert--error' });
alert.warning = (body, width = 440, caption, additional) => alertWithIcon('warning', { body, width, caption, additional, classes: 's-alert--warning' });
export default alert;
