import { resolveComponent as _resolveComponent, withKeys as _withKeys, mergeProps as _mergeProps, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_s_input = _resolveComponent("s-input");
    const _component_s_button = _resolveComponent("s-button");
    const _component_s_modal = _resolveComponent("s-modal");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_s_input, _mergeProps({
            modelValue: _ctx.mutableTextValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.mutableTextValue) = $event)),
            label: _ctx.label,
            readonly: _ctx.readonly,
            disabled: _ctx.disabled,
            buttons: _ctx.buttons,
            regExp: _ctx.nameReg,
            maxLength: _ctx.fullNameMaxLength,
            placeholder: _ctx.inputable ? _ctx.local('namePlaceholder') : '',
            class: _ctx.mergeClass('s-fullname', _ctx.$attrs.class),
            style: _ctx.$attrs.style
        }, _ctx.excludeVue2Attributes(_ctx.$attrs), {
            onKeypress: _withKeys(_ctx.show, ["enter"])
        }), null, 16 /* FULL_PROPS */, ["modelValue", "label", "readonly", "disabled", "buttons", "regExp", "maxLength", "placeholder", "class", "style", "onKeypress"]),
        _createVNode(_component_s_modal, {
            modelValue: _ctx.visible,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.visible) = $event)),
            class: "s-fullname__modal",
            caption: _ctx.local('editDialogTitle'),
            width: 600,
            centralize: ""
        }, {
            footer: _withCtx(() => [
                _createVNode(_component_s_button, {
                    class: "btn-footer-ok",
                    success: "",
                    onClick: _ctx.okClick
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$i18n.resource('global', 'ok')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"]),
                _createVNode(_component_s_button, {
                    class: "btn-footer-close",
                    onClick: _ctx.cancelClick
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$i18n.resource('global', 'cancel')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"])
            ]),
            default: _withCtx(() => [
                _createVNode(_component_s_input, {
                    modelValue: _ctx.mutableLastName,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.mutableLastName) = $event)),
                    label: _ctx.local('lastNameText'),
                    required: _ctx.lastNameRequired,
                    maxLength: _ctx.minNameLength(_ctx.lastNameMaxLength),
                    regExp: _ctx.nameReg
                }, null, 8 /* PROPS */, ["modelValue", "label", "required", "maxLength", "regExp"]),
                _createVNode(_component_s_input, {
                    modelValue: _ctx.mutableFirstName,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.mutableFirstName) = $event)),
                    label: _ctx.local('firstNameText'),
                    required: _ctx.firstNameRequired,
                    maxLength: _ctx.minNameLength(_ctx.firstNameMaxLength),
                    regExp: _ctx.nameReg
                }, null, 8 /* PROPS */, ["modelValue", "label", "required", "maxLength", "regExp"]),
                _createVNode(_component_s_input, {
                    modelValue: _ctx.mutableMiddleName,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.mutableMiddleName) = $event)),
                    label: _ctx.local('middleNameText'),
                    required: _ctx.middleNameRequired,
                    maxLength: _ctx.minNameLength(_ctx.middleNameMaxLength),
                    regExp: _ctx.nameReg
                }, null, 8 /* PROPS */, ["modelValue", "label", "required", "maxLength", "regExp"])
            ]),
            _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "caption"])
    ], 64 /* STABLE_FRAGMENT */));
}
