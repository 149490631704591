import { services } from '@fbc/core/services';
import { defineFunctionalComponent } from '@fbc/core/utils';
import { omit } from 'lodash';
import { h, resolveComponent } from 'vue';
import props from './props';
class DataInlineLookupProvider {
    method;
    parameters;
    filters;
    pick;
    dataService = new services.dataApi();
    constructor(method, parameters, filters, pick) {
        this.method = method;
        this.parameters = parameters;
        this.filters = filters;
        this.pick = pick;
    }
    async getData(options) {
        const [error, data = []] = await this.dataService.get(this.method, this.parameters?.(options.text), __filename, {
            count: options.count,
            startIndex: 0,
            filters: this.filters?.(options.text),
            pick: this.pick,
        });
        if (error)
            log.prod.error(__filename, error);
        return data;
    }
}
const DataInlineLookupComponent = defineFunctionalComponent({
    ...omit(props, 'dataProvider'),
    parameters: Function,
    filters: Function,
    method: { type: String, required: true },
    pick: { type: Array },
}, (props, options) => h(resolveComponent('s-inline-lookup'), {
    ...options.attrs,
    ...props,
    dataProvider: new DataInlineLookupProvider(props.method, props.parameters, props.filters, props.pick),
}, { default: options.slots.default }), ['update:modelValue', 'update:columns']);
export default DataInlineLookupComponent;
