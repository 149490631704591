/**
 * Выполняет форматирование значения как процентов
 * @param value значение
 * @param [count=2] кол-во символов после запятой
 */
export default function percent(value, count = 2) {
    const num = Number.parseFloat(String(value)) * 100;
    if (Number.isNaN(num))
        return '';
    return value || value === 0 ? num.toFixed(count) + '%' : '';
}
