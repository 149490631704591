import { DateTime } from 'luxon';
/**
 * Конвертирует время в локальное и форматирует его
 * @param value DateTime
 * @param format формат, по дефолту 'dd.MM.yyyy HH:mm'
 * @param noValue если исходное значение falsy вовращает это значение
 */
export default function formatLocal(value, format = 'dd.MM.yyyy HH:mm', noValue = '') {
    if (!value)
        return noValue;
    return value instanceof DateTime ? value.toLocal().toFormat(format) : value;
}
