const cmp = {};
const setCmp = (name, module) => {
    cmp[name] = module;
};
const getCmp = (name) => cmp[name];
export function addComponent(name, module, context) {
    context.$modules.setCmp(name, module);
}
export const getComponent = (name, context) => context.$modules.getCmp(name);
export const MicroModule = {
    install(app) {
        app.config.globalProperties.$modules = {
            setCmp,
            getCmp,
        };
    },
};
