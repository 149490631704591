import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_s_navigation_menu = _resolveComponent("s-navigation-menu");
    const _component_s_popup = _resolveComponent("s-popup");
    return (_openBlock(), _createBlock(_component_s_popup, {
        visible: _ctx.visible,
        position: _ctx.position,
        transitionName: "s-navigation-popup-transition",
        class: "s-navigation-popup",
        backdrop: _ctx.$store.app.isDarkTheme || _ctx.$store.app.screen.device !== 'desktop',
        backdropAttrs: { style: { zIndex: 1100 } },
        centralizeOnMobile: "",
        "onUpdate:visible": _cache[3] || (_cache[3] = ($event) => (_ctx.$emit('update:visible', $event)))
    }, {
        default: _withCtx(() => [
            _createVNode(_component_s_navigation_menu, {
                items: _ctx.items,
                showBack: _ctx.showBack,
                visible: _ctx.visible,
                submenuPosition: _ctx.submenuPosition,
                class: "s-navigation-context-menu",
                onBack: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('update:visible', false))),
                onSelect: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('select', $event))),
                onHide: _cache[2] || (_cache[2] = ($event) => {
                    _ctx.$emit('hide', $event);
                    _ctx.$emit('update:visible', false);
                })
            }, null, 8 /* PROPS */, ["items", "showBack", "visible", "submenuPosition"])
        ]),
        _: 1 /* STABLE */
    }, 8 /* PROPS */, ["visible", "position", "backdrop"]));
}
