import { services } from '@fbc/core/services';
import { DateTime } from 'luxon';
import { computed, defineComponent, h, ref, resolveComponent } from 'vue';
const resources = {
    default: {
        offset: 'Offset',
        nameShort: 'GMT',
        nameLong: 'Name',
        name: 'Time zone',
        caption: 'Time zone selection',
    },
    'ru-ru': {
        offset: 'Смещение',
        nameShort: 'GMT',
        nameLong: 'Название',
        name: 'Часовой пояс',
        caption: 'Выбор часового пояса',
    },
};
export default defineComponent({
    name: 's-time-zone-lookup',
    props: {
        modelValue: { type: String },
    },
    emits: ['update:modelValue', 'update:rawValue'],
    setup(props, { attrs, slots, emit }) {
        const { i18n } = services;
        const local = (name) => i18n.extract(resources, name);
        const timeZones = computed(() => 
        //Chromium V8 doesn't have Etc/UTC or UTC timezone, according to bug https://bugs.chromium.org/p/v8/issues/detail?id=13084
        //Firefox has only UTC timezone, which is link, but Etc/UTC is canonical according to https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
        Intl.supportedValuesOf('timeZone')
            .concat(['Etc/UTC'])
            .map(x => {
            const dt = DateTime.local().setZone(x);
            return {
                id: x,
                name: x,
                valid: dt.isValid,
                offset: dt.offset,
                nameLong: dt.offsetNameLong ?? x,
                nameShort: dt.offsetNameShort,
            };
        })
            .filter(x => x.valid));
        const localValue = computed(() => props.modelValue ? timeZones.value.find(x => x.name === props.modelValue) : null);
        const dataProvider = new services.lookupStaticProvider(timeZones);
        const columns = ref([
            {
                field: 'nameLong',
                caption: local('nameLong'),
                filter: 'text',
                isDisplayName: true,
            },
            {
                field: 'name',
                caption: local('name'),
                filter: 'text',
            },
            {
                field: 'nameShort',
                caption: local('nameShort'),
                filter: 'text',
                isDisplayName: true,
            },
            {
                field: 'offset',
                caption: local('offset'),
                filter: 'number',
            },
        ]);
        return () => h(resolveComponent('s-lookup'), {
            columns: columns.value,
            'onUpdate:columns': (x) => (columns.value = x),
            dataProvider,
            idField: 'id',
            caption: local('caption'),
            ...attrs,
            modelValue: localValue.value,
            'onUpdate:modelValue': (zone) => {
                emit('update:modelValue', zone?.name ?? null);
                emit('update:rawValue', zone);
            },
        }, slots);
    },
});
