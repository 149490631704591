import { h, mergeProps, resolveComponent } from 'vue';
import { defineFunctionalComponent } from '../render';
export const phoneCell = defineFunctionalComponent({
    entity: { type: Object, required: true },
    column: Object,
    index: Number,
}, ({ entity, column }, context) => h(resolveComponent('s-phone'), mergeProps({
    modelValue: entity[column.field],
    disabled: true,
}, context.attrs)), undefined, 'phone-link-cell', false);
export const mailCell = defineFunctionalComponent({
    entity: { type: Object, required: true },
    column: Object,
    index: Number,
}, ({ entity, column }, context) => h(resolveComponent('s-email'), mergeProps({
    modelValue: entity[column.field],
    disabled: true,
}, context.attrs)), undefined, 'mail-link-cell', false);
