import { DateTime } from 'luxon';
import { isNotNullOrUndefined } from './guards';
export const removeTimeConverter = (x) => (x ? x.startOf('day') : null);
export const roundDateTime = (dt, up = true) => dt.set({ minute: Math[up ? 'ceil' : 'floor'](dt.minute / 15) * 15 });
export const fromIsoConverter = (x) => (x ? DateTime.fromISO(x, { zone: 'utc' }) : null);
/** Возвращает целую и дробную часть числа. [integer, decimal] */
export function getIntegerAndDecimalOfNumber(x) {
    if (!isNotNullOrUndefined(x) || Number.isNaN(Number(x)))
        return [null, null];
    return [Math.trunc(Number(x)), Number(x) % 1];
}
