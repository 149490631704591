import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "s-file-input" };
const _hoisted_2 = { key: 0 };
const _hoisted_3 = {
    key: 1,
    style: { "height": "100%" }
};
const _hoisted_4 = { class: "s-file-input__drop_area-container" };
const _hoisted_5 = { class: "s-file-input__drop_area" };
const _hoisted_6 = ["multiple", "accept"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_s_input = _resolveComponent("s-input");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
            onDragenter: _cache[0] || (_cache[0] =
                //@ts-ignore
                (...args) => (_ctx.onDragEnter && _ctx.onDragEnter(...args))),
            onDragover: _cache[1] || (_cache[1] =
                //@ts-ignore
                (...args) => (_ctx.onDragOver && _ctx.onDragOver(...args))),
            onDragleave: _cache[2] || (_cache[2] =
                //@ts-ignore
                (...args) => (_ctx.onDragLeave && _ctx.onDragLeave(...args))),
            onDrop: _cache[3] || (_cache[3] =
                //@ts-ignore
                (...args) => (_ctx.onDrop && _ctx.onDrop(...args)))
        }, [
            (!_ctx.enableDragContainer)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _renderSlot(_ctx.$slots, "input", { selectFile: _ctx.selectFile }, () => [
                        _createVNode(_component_s_input, {
                            label: _ctx.label,
                            computed: "",
                            disabled: _ctx.disabled,
                            class: "s-file-input__input",
                            buttons: _ctx.buttons,
                            "model-value": _ctx.vInputText,
                            placeholder: _ctx.placeholder,
                            onClick: _ctx.selectFile
                        }, null, 8 /* PROPS */, ["label", "disabled", "buttons", "model-value", "placeholder", "onClick"])
                    ])
                ]))
                : _createCommentVNode("v-if", true),
            (_ctx.dragAndDrop && _ctx.enableDragContainer)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _renderSlot(_ctx.$slots, "dragAndDrop", {}, () => [
                        _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.local('drag')), 1 /* TEXT */)
                        ])
                    ])
                ]))
                : _createCommentVNode("v-if", true)
        ], 32 /* NEED_HYDRATION */),
        _createElementVNode("input", {
            ref: "inputUploadFile",
            type: "file",
            multiple: _ctx.multiple,
            accept: _ctx.accept,
            style: { "display": "none" },
            onChange: _cache[4] || (_cache[4] =
                //@ts-ignore
                (...args) => (_ctx.onChange && _ctx.onChange(...args)))
        }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_6)
    ]));
}
