import clickOutside from './click-outside';
import container from './container';
import swipe from './swipe';
import tooltip from './tooltip';
import waves from './waves';
export { default as masker } from './mask/masker';
export { default as tokens } from './mask/tokens';
export const directivesList = {
    'click-outside': clickOutside,
    tooltip,
    waves,
    container,
    swipe,
};
export const directives = {
    install(app) {
        for (const name in directivesList)
            app.directive(name, directivesList[name]);
    },
};
