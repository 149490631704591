import { h } from 'vue';
import { defineFunctionalComponent } from '../render';
export const objectToYml = (x, n = 0) => x && typeof x === 'object'
    ? Object.entries(x)
        .map(([key, value]) => typeof value === 'object'
        ? key + ':\n' + objectToYml(value, n + 1)
        : '\t'.repeat(n) + key + ':\t' + String(value))
        .join('\n')
    : String(x ?? '');
export const ymlCell = defineFunctionalComponent({
    entity: { type: Object, required: true },
    column: Object,
    index: Number,
}, ({ entity, column }) => h('span', { style: 'white-space: break-spaces;' }, objectToYml(entity[column.field])), undefined, 'yml-cell', false);
