import { createUniqueId, excludeVue2Attributes, isNotNullOrUndefined, mergeClass } from '@fbc/core/utils';
import { computed, defineComponent, onMounted, ref, watch, } from 'vue';
import { tokens } from '../../../directives';
import { useMaskInput } from './use-mask-input';
import { numberTypes, useNumberInput } from './use-number-input';
import { validateRegExp } from './use-validate';
const types = ['text', 'password', 'mask', 'search', ...numberTypes];
const useClasses = (props, noLabel) => {
    const labelClasses = ref({
        's-label--active': false,
    });
    const mutableRootClasses = ref({
        's-input--focused': false,
        's-input--error': false,
    });
    const rootClasses = computed(() => ({
        's-input--focused': mutableRootClasses.value['s-input--focused'],
        's-input--error': mutableRootClasses.value['s-input--error'],
        's-input--is-readonly': Boolean(props.readonly),
        's-input--is-disabled': Boolean(props.disabled),
        's-input--no-label': noLabel.value,
        's-input--required': Boolean(props.required),
        's-input--bordered': Boolean(props.bordered),
        ['s-input--' + props.type]: true,
        's-input--bordered-number': props.type && Boolean(numberTypes.join(', ').includes(props.type) && props.bordered),
        's-input--bordered-mask': Boolean(props.type === 'mask' && props.bordered),
    }));
    return {
        labelClasses,
        mutableRootClasses,
        rootClasses,
    };
};
const InputComponent = defineComponent({
    name: 's-input',
    inheritAttrs: false,
    emits: ['update:valid', 'update:modelValue', 'focus', 'blur', 'input', 'keypress', 'paste'],
    props: {
        modelValue: { type: [String, Number] },
        id: { type: String, default: () => 'input-' + createUniqueId() },
        label: { type: String },
        detail: { type: String },
        counter: { type: String },
        type: {
            type: String,
            default: 'text',
            validator: (x) => types.includes(x),
        },
        validation: {
            type: Function,
            default: () => true,
        },
        bordered: { type: Boolean },
        readonly: { type: Boolean },
        disabled: { type: Boolean },
        required: { type: Boolean },
        autocomplete: { type: [Boolean, String], default: () => false },
        preventPaste: { type: Boolean },
        trim: { type: Boolean },
        buttons: { type: Array, default: () => [] },
        min: { type: [Number, String], default: Number.MIN_SAFE_INTEGER },
        max: { type: [Number, String], default: Number.MAX_SAFE_INTEGER },
        decimalPlaces: { type: [Number, String], default: -1 },
        numberFormatOptions: { type: Object, default: () => ({}) },
        measureStr: { type: String, default: '' },
        regExp: { type: RegExp, default: null },
        mask: { type: String },
        tokens: {
            type: Object,
            default: tokens,
        },
    },
    setup(props, { attrs, emit }) {
        const input = ref();
        const detailMessage = ref(null);
        const noLabel = computed(() => !props.label);
        const labelValue = computed(() => {
            if (props.label && props.counter)
                return props.label + ` (${String(props.modelValue ?? '').length}/${props.counter})`;
            if (props.label)
                return props.label;
            if (props.counter)
                return `${String(props.modelValue ?? '').length}/${props.counter}`;
            return '';
        });
        const { labelClasses, rootClasses, mutableRootClasses } = useClasses(props, noLabel);
        const { attributes: a, valueWrapper: vw } = props.type === 'mask'
            ? useMaskInput(props, { emit }, mutableRootClasses, validate)
            : useNumberInput(props, { attrs, emit }, mutableRootClasses, validate);
        //! Это все ради обмана ts, чтобы не было циклических типов
        const overloadAttributes = a;
        // eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
        const overloadValueWrapper = vw;
        const update = (value, event) => {
            if (props.trim && typeof value === 'string')
                emit('update:modelValue', value.trim(), event);
            else
                emit('update:modelValue', value, event);
        };
        const valueWrapper = overloadValueWrapper ??
            computed({
                get() {
                    return props.modelValue;
                },
                set(value) {
                    update(value);
                },
            });
        const attributes = computed(() => ({
            ...excludeVue2Attributes(attrs),
            onInput: (event) => {
                emit('input', event.target.value, event);
            },
            onChange: (event) => {
                const value = event.target.value;
                if (props.validation(value) === true)
                    update(value, event);
            },
            onFocus: (event) => {
                focus();
                emit('focus', event);
            },
            onBlur: (event) => {
                unfocus();
                emit('blur', event);
            },
            onKeypress: (event) => {
                validateRegExp(props.regExp, event.target.value + event.key)
                    ? emit('keypress', event)
                    : event.preventDefault();
            },
            onPaste: (event) => {
                if (props.preventPaste)
                    event.preventDefault();
                else
                    emit('paste', event);
            },
            ...overloadAttributes.value,
        }));
        let previousValid = true;
        function updateValid(validation) {
            if (previousValid === validation)
                return;
            previousValid = validation;
            emit('update:valid', validation === true);
        }
        function validate(value) {
            let validation = props.validation(value);
            if (props.readonly || props.disabled)
                validation = true;
            mutableRootClasses.value['s-input--error'] = validation !== true;
            if (detailMessage.value !== validation)
                updateValid(validation);
            detailMessage.value = validation === true ? null : validation;
        }
        validate(props.modelValue);
        function focus() {
            if (props.disabled)
                return;
            mutableRootClasses.value['s-input--focused'] = true;
            labelClasses.value['s-label--active'] = true;
        }
        function unfocus() {
            if (document.activeElement === input.value)
                return;
            mutableRootClasses.value['s-input--focused'] = false;
            if (!isNotNullOrUndefined(props.modelValue) || props.modelValue.toString() === '')
                labelClasses.value['s-label--active'] = false;
            validate(props.modelValue);
        }
        onMounted(() => (labelClasses.value['s-label--active'] =
            isNotNullOrUndefined(props.modelValue) && props.modelValue.toString() !== ''));
        watch(() => props.modelValue, () => {
            if (isNotNullOrUndefined(props.modelValue) && props.modelValue.toString() !== '')
                labelClasses.value['s-label--active'] = true;
            validate(props.modelValue);
        }, { immediate: true });
        return {
            noLabel,
            labelValue,
            detailMessage,
            labelClasses,
            rootClasses,
            attributes,
            input,
            focus,
            unfocus,
            mergeClass,
            valueWrapper,
        };
    },
});
export default InputComponent;
