import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "s-spoiler" };
const _hoisted_2 = { class: "s-spoiler__label" };
const _hoisted_3 = { class: "s-spoiler__icon" };
const _hoisted_4 = {
    key: 0,
    class: "s-spoiler__detail"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_s_transition_collapse_height = _resolveComponent("s-transition-collapse-height");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
            style: _normalizeStyle(_ctx.variablesHeader),
            class: "s-spoiler__header"
        }, [
            (!_ctx.disabled)
                ? (_openBlock(), _createElementBlock("i", {
                    key: 0,
                    class: _normalizeClass(["s-spoiler__button", _ctx.mutableModelValue ? 'fb-common-top' : 'fb-common-bottom']),
                    onClick: _cache[0] || (_cache[0] =
                        //@ts-ignore
                        (...args) => (_ctx.onClick && _ctx.onClick(...args)))
                }, null, 2 /* CLASS */))
                : _createCommentVNode("v-if", true),
            _createElementVNode("div", _hoisted_2, [
                _renderSlot(_ctx.$slots, "label", { label: _ctx.label }, () => [
                    _createElementVNode("label", {
                        style: _normalizeStyle({ cursor: _ctx.clickOnLabel && !_ctx.disabled ? 'pointer' : 'auto' }),
                        class: _normalizeClass(_ctx.labelClass),
                        onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.clickOnLabel && !_ctx.disabled && _ctx.onClick()))
                    }, _toDisplayString(_ctx.label), 7 /* TEXT, CLASS, STYLE */),
                    _createElementVNode("label", _hoisted_3, [
                        _renderSlot(_ctx.$slots, "icon")
                    ])
                ])
            ]),
            (_ctx.mutableModelValue && _ctx.extra)
                ? _renderSlot(_ctx.$slots, "extra", { key: 1 })
                : _createCommentVNode("v-if", true)
        ], 4 /* STYLE */),
        _createVNode(_component_s_transition_collapse_height, null, {
            default: _withCtx(() => [
                (_ctx.mutableModelValue)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _renderSlot(_ctx.$slots, "default")
                    ]))
                    : _createCommentVNode("v-if", true)
            ]),
            _: 3 /* FORWARDED */
        })
    ]));
}
