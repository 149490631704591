import Tree, { strategies } from '.';
import { TraversalOperations } from './traversal-operations';
export default class TreeCollection extends TraversalOperations {
    _items = [];
    constructor(...items) {
        super();
        this._items.push(...items);
    }
    /**
     * Получает корень по индексу
     * @param index Индекс корня
     * @returns Корень
     */
    at(index) {
        return this._items[index];
    }
    /**
     * Добавляет корни в дерево
     * @param items корни для добавления
     */
    push(...items) {
        this._items.push(...items);
    }
    /**
     * Удаляет корень из дерева
     * @param id корневого элемента
     */
    remove(id) {
        this._items = this._items.filter(x => x.id !== id);
    }
    get length() {
        return this._items.length;
    }
    [Symbol.iterator]() {
        return this._items.values();
    }
    map(...args) {
        return this._items.map(...args);
    }
    /**
     * Обходит дерево и вызывает action для каждого элемента\
     * level будет на 1 больше (!) т.к. создается фиктивный корень
     * @param action для каждого элемента
     * @param strategy стратегия обхода. По дефолту pre, подробнее в traverse-strategies.ts
     * @returns this
     */
    traversal(action, strategy = 'pre') {
        return this.useFakeRoot(fakeRoot => strategies.traversal[strategy](item => (item !== fakeRoot ? action(item) : true), fakeRoot));
    }
    toArray() {
        return [...this];
    }
    /**
     * Сортирует элементы в дереве
     * @param comparator функция сравнения элементов
     * @returns this
     */
    sort(comparator) {
        return this.useFakeRoot(fakeRoot => fakeRoot.sort(comparator));
    }
    useFakeRoot(action) {
        const item = this.at(0);
        const fakeRoot = new Tree({
            item: {},
            key: () => 'fake',
            label: () => 'fake',
        }, {
            parentSelectionStrategy: item?.parentSelectionStrategy,
            childsSelectionStrategy: item?.childsSelectionStrategy,
            sortStrategy: item?.sortStrategy,
        });
        for (const node of this)
            fakeRoot.addChild(node);
        action(fakeRoot);
        const isIsItemsChanged = this._items.some((x, i) => fakeRoot.childs[i] !== x);
        if (isIsItemsChanged)
            this._items = [...fakeRoot.childs]; // Не меняем итемы, если они не изменились(пр. сортировка), т.к. это вызовет перерасчет всех реактивных зависимостей vue (а если в них есть обход дерева, то бесконечный перерасчет)
        for (const node of this)
            node.remove();
        return this;
    }
}
