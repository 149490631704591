import { createGuid, getErrorMessage, isString } from '@fbc/core/utils';
import { reactive } from 'vue';
import { services } from '.';
import i18n from './i18n';
import variables from './variables';
function toast(cfg) {
    if (!cfg || !cfg.body)
        cfg = { body: cfg };
    const { body, classes, delay, style, onTop, dismissible = true, on = {} } = cfg;
    if (typeof body === 'string')
        log.prod.info(undefined, '%cToast', consoleStyles[String(consoleStylesRegExp.exec(classes ?? '')?.[0])], body);
    const toast = reactive({ guid: createGuid(), body, classes, delay, style, onTop, dismissible, on });
    const store = services.store;
    store.toast.add(toast);
    return ({ body, classes, style, close, on } = {}) => {
        if (body !== undefined)
            toast.body = body;
        if (classes !== undefined)
            toast.classes = classes;
        if (style !== undefined)
            toast.style = style;
        if (on !== undefined)
            Object.assign(toast.on, on);
        if (close)
            store.toast.remove(toast);
    };
}
const consoleBaseStyle = `background: {background}; color: ${variables['on-error']}; border-radius: 6px; padding: ${variables.x2s}px`;
const consoleStyles = {
    undefined: consoleBaseStyle.replace('{background}', variables['on-surface']),
    critic: consoleBaseStyle.replace('{background}', variables.error),
    error: consoleBaseStyle.replace('{background}', variables.error),
    warning: consoleBaseStyle.replace('{background}', variables.warning),
    success: consoleBaseStyle.replace('{background}', variables.success),
};
const consoleStylesRegExp = new RegExp(`(${Object.keys(consoleStyles).join('|')})`);
toast.error = (body) => toast({ body: isString(body) ? body : getErrorMessage(body), classes: 'critic' });
toast.success = (body) => toast({ body, classes: 'success' });
toast.warning = (body) => toast({ body, classes: 'warning' });
toast.notSaved = () => toast({ body: i18n.global('not-saved'), classes: 'critic' });
toast.saved = () => toast({ body: i18n.global('saved'), classes: 'success', delay: 800 });
toast.notDeleted = () => toast({ body: i18n.global('not-deleted'), classes: 'critic' });
toast.deleted = () => toast({ body: i18n.global('deleted'), classes: 'success', delay: 800 });
export default toast;
