import { getFileFromServer, isTruthy, sizeOfObject } from '@fbc/core/utils';
import { omit } from 'lodash';
import { authFetch } from './api/authenticate-fetch';
import { getFeatures, mapData } from './api/data';
export const formatQueryParameters = (config) => {
    let options = '';
    if (config.where)
        options +=
            '&where=' +
                config.where
                    .map((x) => `${String(x.fieldName)} ${x.operator} ${x.value}`)
                    .join(' and ');
    if (config.include?.length)
        options += '&include=' + config.include.join(',');
    if (config.select)
        options += '&select=' + config.select.join(',');
    if ('count' in config && config.count && config.count > 0)
        options += `&count=${config.count}`;
    if ('startIndex' in config && config.startIndex && config.startIndex > 0)
        options += `&startIndex=${config.startIndex}`;
    if ('orderBy' in config && config.orderBy)
        options += `&orderBy=${String(config.orderBy)}`;
    return options;
};
/**
 * FBConsult Data API
 */
export class DataApi {
    path;
    headers = {
        'content-type': 'application/json',
        'cache-control': 'no-cache, no-store, must-revalidate',
    };
    /**
     * @constructor
     * @param path Путь к Data API
     */
    constructor(path = window['fb-base-api-path'] + '/api/') {
        this.path = path;
    }
    /**
     * Выполняет запрос данных
     * @param method метод получения данных вида `seccodeJoins/userTeamMembership`
     * @param parameters параметры запроса если есть, если нет - `undefined`
     * @param fileName название файла для логгирования
     * @param requestParameters параметры DataApi для серверной пагинации, сортировки, фильтрации, получения общего кол-ва результатов и выбора полей
     * @param requestParameters.startIndex начальный индекс данных, используется для серверной пагинации как старт необходимого сегмента
     * @param requestParameters.count загружаемое кол-во данных, используется для серверной пагинации как размер необходимого сегмента и для ограничения кол-ва записей
     * @param requestParameters.totalResults нужно ли получить кол-во записей без учета пагинации. Обычно выполняется при первом запросе сегмента данных, чтобы узнать кол-во страниц
     * @param requestParameters.sort серверная сортировка, можно указать несколько полей, сортировка будет зависеть от их порядка в массиве
     * @param requestParameters.filters серверные фильтры, допустимые значения находятся в `DataFilter`
     * @param requestParameters.pick получение ограниченного кол-ва полей в запросе, на вход подается кортеж с названиями полей, в т.ч. и многоуровневыми вроде `clientManager.shortName`
     * @param requestParameters.forcePickAllFieldsOnOnlyDynamicProvided если в `pick` есть ограничения на поля 2+ уровня, получить все поля оригинальной сущности
     * @returns Кортеж из ошибки, результата выполнения запроса и метаданных  \
     * `error` - серверная ошибка или `undefined`  \
     * `result` - массив с данными или `undefined` при ошибке  \
     * `rest` - (`totalResults` с общим кол-вом данных, если был запрошен + `features` со списком поддерживаемых возможностей в конкретном методе + `executedSelect` с выполненными запросами в dev режиме) или `undefined` при ошибке
     */
    async get(// DeepKeys<GetArrayElementType<DataMethods[T]['result']>>
    method, parameters, fileName, { startIndex, count, totalResults, forcePickAllFieldsOnOnlyDynamicProvided, sort, filters, pick, } = {}) {
        if (process.env.NODE_ENV === 'development')
            var time = performance.now(); // eslint-disable-line no-var
        const queryParameters = formatQueryParameters({
            startIndex,
            count,
            include: [
                totalResults && 'totalResults',
                process.env.NODE_ENV === 'development' && 'debug',
                forcePickAllFieldsOnOnlyDynamicProvided && 'forcePickAllFieldsOnOnlyDynamicProvided',
            ].filter(isTruthy),
        }).replace('&', '');
        const response = await authFetch(this.path + `data/${method}${queryParameters ? `?${queryParameters}` : ''}`, {
            credentials: 'include',
            headers: this.headers,
            body: JSON.stringify(this.getBaseRequest(parameters, { sort, filters, pick })),
            method: 'POST',
            mode: 'cors',
        });
        const result = await response
            .json()
            .then((x) => x)
            .catch((x) => x);
        const data = response.ok ? result : undefined;
        const error = data ? undefined : (Array.isArray(result) ? result[0] : result);
        if (process.env.NODE_ENV === 'development' && fileName !== 'never' && localStorage['fora.debug.api']) {
            log.dev.debug(fileName ?? 'НЕИЗВЕСТНЫЙ ФАЙЛ, ОПРЕДЕЛИТЬ', method, {
                error,
                size: sizeOfObject(result),
                time: performance.now() - time, // eslint-disable-line @typescript-eslint/no-unnecessary-type-assertion
                filters,
                pick,
                sort,
                startIndex,
                count,
                ...(data ?? {}),
            });
        }
        if (error ?? !data)
            return [error, undefined, undefined];
        return [
            undefined,
            mapData(data.data, data.fieldsToMap),
            {
                totalResults: data.totalResults ?? null,
                features: getFeatures(data.features),
                executedSelect: data.executedSelect,
            },
        ];
    }
    /**
     * Загружает excel-документ по запросу в базу данных
     * @param select {Select} Объект состоящий из запроса, параметров и свойств
     * @param attachmentName название файла для загрузки (включая расширение)
     * @param headers заголовки столбцов внутри документа
     * @param fileName название файла для логирования, с его помощью можно фильтровать запросы в консоли
     * @returns {ServerError|null} Наличие ошибки или null, если прошло успешно
     */
    async getExcel(method, parameters, attachmentName, headers, fileName, { startIndex, count, sort, filters, pick } = {}) {
        const queryParameters = formatQueryParameters({
            startIndex,
            count,
        }).replace('&', '');
        const response = await authFetch(this.path + `data/attachment/${method}${queryParameters ? `?${queryParameters}` : ''}`, {
            credentials: 'include',
            headers: this.headers,
            body: JSON.stringify({
                ...this.getBaseRequest(parameters, { sort, filters, pick }),
                attachment: {
                    fileName: attachmentName.replaceAll(/[",/\\]/g, ''),
                    headers,
                    contentType: 'application/xlsx',
                },
            }),
            method: 'POST',
            mode: 'cors',
        });
        const result = await response
            .blob()
            .then(x => getFileFromServer(attachmentName, x))
            .catch((x) => x);
        const error = response.ok ? null : (Array.isArray(result) ? result[0] : result);
        if (process.env.NODE_ENV === 'development' && fileName !== 'never' && localStorage['fora.debug.api']) {
            log.dev.debug(fileName ?? 'НЕИЗВЕСТНЫЙ ФАЙЛ, ОПРЕДЕЛИТЬ', method, {
                error,
                size: sizeOfObject(result),
                filters,
                pick,
                sort,
                startIndex,
                count,
            });
        }
        return error;
    }
    getBaseRequest(parameters, { sort, filters, pick }) {
        return {
            parameters,
            sort,
            filters: filters?.map(x => ({
                fieldName: x.fieldName,
                type: x.type,
                parameters: omit(x, 'type', 'fieldName'),
            })),
            pick,
        };
    }
}
