import { resolveComponent as _resolveComponent, withKeys as _withKeys, withModifiers as _withModifiers, mergeProps as _mergeProps, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = ["data-id"];
const _hoisted_2 = { class: "s-inline-lookup__list" };
const _hoisted_3 = ["data-id", "onClick"];
const _hoisted_4 = ["textContent"];
const _hoisted_5 = ["textContent"];
const _hoisted_6 = ["textContent"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_s_input = _resolveComponent("s-input");
    const _component_s_popup = _resolveComponent("s-popup");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["s-inline-lookup", [{ 's-inline-lookup--has-value': _ctx.hasValue, 's-inline-lookup--disabled': _ctx.disabled }, _ctx.$attrs.class]]),
        style: _normalizeStyle(_ctx.$attrs.style),
        "data-id": _ctx.modelValue ? _ctx.getId(_ctx.modelValue) : undefined
    }, [
        _createVNode(_component_s_input, _mergeProps({
            ref: "input",
            modelValue: _ctx.text,
            disabled: _ctx.disabled,
            placeholder: _ctx.$attrs.label ? undefined : _ctx.$i18n.global('search-placeholder')
        }, _ctx.excludeVue2Attributes(_ctx.$attrs), {
            onInput: _ctx.load,
            onFocus: _ctx.focus,
            onKeyup: [
                _withKeys(_ctx.onEnterPress, ["enter"]),
                _cache[0] || (_cache[0] = _withKeys(($event) => (_ctx.selectNext(1)), ["down"])),
                _cache[1] || (_cache[1] = _withKeys(($event) => (_ctx.selectNext(-1)), ["up"]))
            ],
            onClick: _cache[2] || (_cache[2] = _withModifiers(() => { }, ["stop"]))
        }), null, 16 /* FULL_PROPS */, ["modelValue", "disabled", "placeholder", "onInput", "onFocus", "onKeyup"]),
        _createVNode(_component_s_popup, {
            visible: _ctx.visible,
            "onUpdate:visible": _cache[3] || (_cache[3] = ($event) => ((_ctx.visible) = $event)),
            position: _ctx.position,
            class: "s-inline-lookup__popup",
            width: _ctx.width
        }, {
            default: _withCtx(() => [
                (_ctx.entities.length)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createElementVNode("ul", _hoisted_2, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entities, (entity) => {
                                return (_openBlock(), _createElementBlock("li", {
                                    key: _ctx.getId(entity),
                                    "data-id": _ctx.getId(entity),
                                    class: _normalizeClass(["s-inline-lookup__item", {
                                            's-inline-lookup__item--selected': _ctx.isEqual(entity, _ctx.modelValue),
                                            's-inline-lookup__item--focused': _ctx.isEqual(entity, _ctx.selected),
                                        }]),
                                    onClick: ($event) => (_ctx.select(entity))
                                }, [
                                    _renderSlot(_ctx.$slots, "default", { entity: entity }, () => [
                                        _createElementVNode("span", {
                                            textContent: _toDisplayString(_ctx.getText(entity))
                                        }, null, 8 /* PROPS */, _hoisted_4)
                                    ])
                                ], 10 /* CLASS, PROPS */, _hoisted_3));
                            }), 128 /* KEYED_FRAGMENT */))
                        ]),
                        (_ctx.entities.length === _ctx.count)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: "s-inline-lookup__count",
                                textContent: _toDisplayString(_ctx.$i18n.global('showFirstNRows', { n: _ctx.count }))
                            }, null, 8 /* PROPS */, _hoisted_5))
                            : _createCommentVNode("v-if", true),
                        _renderSlot(_ctx.$slots, "last")
                    ], 64 /* STABLE_FRAGMENT */))
                    : _renderSlot(_ctx.$slots, "no-data", { key: 1 }, () => [
                        _createElementVNode("div", {
                            class: "s-inline-lookup__no-data",
                            textContent: _toDisplayString(_ctx.$i18n.global('no-data-to-display'))
                        }, null, 8 /* PROPS */, _hoisted_6),
                        _renderSlot(_ctx.$slots, "last")
                    ])
            ]),
            _: 3 /* FORWARDED */
        }, 8 /* PROPS */, ["visible", "position", "width"])
    ], 14 /* CLASS, STYLE, PROPS */, _hoisted_1));
}
